package support

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import react.*

fun useAsyncEffectOnce(effect: suspend () -> Unit) {
    useEffectOnce {
        val job = MainScope().launch {
            if (isActive) {
                effect()
            }
        }

        cleanup {
            job.cancel()
        }
    }
}

fun call(effect: suspend () -> Unit) {
    MainScope().launch {
        effect()
    }
}


fun useAsyncEffect(vararg dependencies: Any?, effect: suspend (coroutineScope: CoroutineScope) -> Unit) {
    useEffect(dependencies) {
        val job = MainScope().launch {
            effect(this)
        }
        cleanup {
            job.cancel()
        }
    }
}

// https://github.com/Atri-Labs/til/blob/main/react/controlled-input.md
fun useSetCursor() : (() -> Unit) -> Unit {
    val ref = useRef {}
    useLayoutEffect {
        ref.current?.let { it() }
    }
    return {
        ref.current = it
    }
}