package support


object Validator {
    fun <T> notNull(
        value: T?,
        isEmpty: () -> Unit,
        formatted: (T) -> Unit,
        passed: () -> Unit,
    ) {
        // Validate
        when {
            value == null -> isEmpty()
            else -> {
                formatted(value)
                passed()
            }
        }
    }

    fun text(
        text: String?,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter

        // Validate
        when {
            text.isNullOrEmpty() -> isEmpty()
            else -> {
                formatted(text)
                passed()
            }
        }
    }

    fun govId(
        govId: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        isWrongLength: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        var filtrered = govId?.filter { "0123456789".contains(it) } ?: ""
        val filtrered2 = govId?.filter { "0123456789-".contains(it) } ?: ""

        // Validate
        when {
            filtrered2 != govId -> illegalCharacters()
            filtrered.isEmpty() -> isEmpty()
            filtrered.length != 10 && filtrered.length != 12 -> isWrongLength()
            else -> passed()
        }

        // Format
        if (filtrered.length == 10) {
            val year = filtrered.dropLast(8).toIntOrNull() ?: 70
            filtrered = if (year > 20) "19$filtrered" else "20$filtrered"
        }

        formatted(filtrered)
    }

    fun email(
        email: String?,
        isWrongFormat: () -> Unit,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Validate
        when {
            email.isNullOrEmpty() -> isEmpty()
            email.firstOrNull { it == '@' } == null -> isWrongFormat()
            else -> passed()
        }

        formatted(email?.lowercase() ?: "")
    }

    fun postalCode(
        postalCode: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        isWrongLength: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        val filtrered = postalCode?.filter { "0123456789".contains(it) } ?: ""

        // Validate
        when {
            filtrered != postalCode -> illegalCharacters()
            filtrered.isEmpty() -> isEmpty()
            filtrered.length != 5 -> isWrongLength()
            else -> passed()
        }

        formatted(filtrered)
    }

    fun phone(
        phone: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        isCorrectLength: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        val filtered = phone?.filter { "0123456789-+ ".contains(it) } ?: ""

        // Validate
        when {
            filtered != phone -> illegalCharacters()
            filtered.isEmpty() -> isEmpty()
            filtered.length !in 8..14 -> isCorrectLength()
            else -> passed()
        }

        formatted(filtered)
    }


    fun downPayment(
        price: String?,
        min: Int,
        max: Int,
        downPayment: String?,
        fkFee: String,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        isLess: () -> Unit,
        isMore: () -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        val filtreredDownPayment = downPayment?.filter { "0123456789".contains(it) } ?: ""
        val filtreredPrice = price?.filter { "0123456789".contains(it) } ?: ""

        val filtreredPriceAndFee = if (filtreredPrice.trim().isEmpty()) 0.0 else filtreredPrice.trim().toDouble().plus(fkFee.toDouble())
        val minPayment = if (filtreredPriceAndFee == 0.0) filtreredPriceAndFee else filtreredPriceAndFee.times(min.toDouble().div(100.0))
        val maxPayment = if (filtreredPriceAndFee == 0.0) filtreredPriceAndFee else filtreredPriceAndFee.times(max.toDouble().div(100.0))

        val payment = if (filtreredDownPayment.trim().isEmpty()) 0.0 else filtreredDownPayment.trim().toDouble()

        // Validate
        when {
            filtreredDownPayment != downPayment -> illegalCharacters()
            filtreredDownPayment.isEmpty() -> isEmpty()
            payment < minPayment -> isLess()
            payment > maxPayment -> isMore()
            else -> passed()
        }
        formatted(filtreredDownPayment)
    }


    fun residualBound(
        min: Int,
        max: Int,
        value: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        isLess: () -> Unit,
        isMore: () -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        val filtered = value?.filter { "0123456789".contains(it) } ?: ""

        val number = if (filtered.trim().isEmpty()) 0 else filtered.trim().toInt()

        // Validate
        when {
            filtered != value -> illegalCharacters()
            filtered.isEmpty() -> isEmpty()
            number < min -> isLess()
            number > max -> isMore()
            else -> passed()
        }
        formatted(filtered)
    }


    fun discount(
        value: String?,
        answer: String?,
        illegalCharacters: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        val filtered = value?.trim() ?: ""

        // Validate
        when {
            answer.isNullOrEmpty() -> passed()
            filtered.isNullOrEmpty() -> passed()
            filtered != answer -> illegalCharacters()

            else -> passed()
        }
        formatted(filtered)
    }

}