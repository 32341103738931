package views

import StoreContext
import components.fButton
import components.*
import components.fLoader
import components.items.*
import emotion.react.css
import js.core.jso
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import mui.material.Box
import mui.material.Divider
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.a
import react.router.useNavigate
import react.router.useParams
import screens.SellScreen
import support.*
import web.cssom.*


val Sell = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: SellScreen.ViewModel by useState(SellScreen.ViewModel.None)
    val navigate = useNavigate()
    val params = useParams()
    var count: Int by useState(0)

    useAsyncEffectOnce {
        SellScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce {
        SellScreen.load(sceneInputOf(store, viewModel), params["objectId"])
    }

    fun handleLogout() = call {
        SellScreen.logout(sceneInputOf(store, viewModel))
        navigate(Routes.loginMatters, jso { replace = true; state = null })
    }

    fun recover() = call {
        SellScreen.success(sceneInputOf(store, viewModel))
    }

    fun handleNext() = call {
        SellScreen.loadContact(sceneInputOf(store, viewModel))
        Window.scrollTo()
    }

    fun handleRedeemCode() = call {
        SellScreen.redeemCode(sceneInputOf(store, viewModel))
    }

    fun handleSubmitForm() = call {
        SellScreen.validate(sceneInputOf(store, viewModel))
    }

    fun handleBack() = call {
        when (viewModel) {
            is SellScreen.ViewModel.RedeemCode, is SellScreen.ViewModel.Finished, is SellScreen.ViewModel.CompanyFinished -> navigate(Routes.matters)
            else -> {
                SellScreen.previous(sceneInputOf(store, viewModel))
                Window.scrollTo()
            }
        }
    }

    fun handleUpdate(id: String, value: String) = call {
        SellScreen.update(sceneInputOf(store, viewModel), id, value)
    }

    fun handleCode(value: String) = call {
        SellScreen.setValues(sceneInputOf(store, viewModel), value)
    }

    fun handleFinished() = call {
        SellScreen.finished(sceneInputOf(store, viewModel))
    }


    fun handleCheck() = call {
        SellScreen.check(sceneInputOf(store, viewModel))
    }


    useAsyncEffect(count) { coroutineScope ->
        if (viewModel.state.startCheck) {
            if (coroutineScope.isActive) {
                delay(3000L)
                handleCheck()

                viewModel.asAgreement?.let {
                    count += 1
                }
                viewModel.asFailed?.let {
                    count = 0
                }
            }
        }
    }

    fun handleSetValue(value: DesignSystem.Option) = call {
        SellScreen.setValue(sceneInputOf(store, viewModel), value)
    }

    FScaffold {
        isFullScreen = when (viewModel) {
            is SellScreen.ViewModel.ContactInfo -> true
            is SellScreen.ViewModel.RedeemCode -> true
            else -> false
        }
        //  this.navigation = viewModel.navigation

        viewModel.asFinished?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }


            mui.material.Paper {
                sx {
                    backgroundColor = Color(Palette.Background.paper)
                    borderRadius = Shape.borderRadius10
                    padding = responsive(
                        Breakpoint.xs to Padding(1.rem, 1.rem),
                        Breakpoint.md to Padding(3.rem, 3.rem),
                    )
                }
                Stack {
                    spacing = responsive(2)
                    direction = responsive(StackDirection.column)
                    sx {
                        alignItems = AlignItems.center
                    }
                    fText { design = finishedTitle }
                    fText { design = finishedBody1; className = ClassName("n-markdown") }
                    /*a {
                        css {
                            textDecorationColor = Color(Palette.transparent)
                        }
                        href = "mailto:${protocolEmail.text}"
                        fText { design = protocolEmail }
                    }

                     */
                    // fText { design = finishedBody2 }
                    fButton { design = next; onClick = { navigate(Routes.matters) } }
                }
            }
        }


        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }


        viewModel.asRedeemCode?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }

            Stack {
                spacing = responsive(4)
                sx {
                    width = 100.pct
                    alignItems = AlignItems.center
                }
                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = responsive(
                            Breakpoint.xs to Padding(1.rem, 1.rem),
                            Breakpoint.md to Padding(3.rem, 3.rem),
                        )
                        width = responsive(
                            Breakpoint.xs to 100.pct,
                            Breakpoint.md to 55.pct,
                            Breakpoint.lg to 45.pct,
                            Breakpoint.xl to 35.pct
                        )
                    }

                    Stack {
                        spacing = responsive(2)
                        fText { design = redeemTitle }
                        fText { design = redeemIntro }
                        Stack {
                            spacing = responsive(2)
                            Box {
                                Divider {
                                    sx {
                                        backgroundColor = Palette.Text.primary
                                        marginTop = important(1.rem)
                                    }
                                }
                            }
                            fText { design = codeTitle }

                            fTextInput { design = code; onChange = ::handleCode }
                            fButton { design = redeemCode; onClick = ::handleRedeemCode }
                        }
                        Box {
                            Divider {
                                sx {
                                    backgroundColor = Palette.Text.primary
                                    marginTop = important(1.rem)
                                }
                            }
                        }
                        Stack {
                            Stack {
                                spacing = responsive(1)
                                direction = responsive(StackDirection.column)
                                sx {
                                    justifyContent = JustifyContent.center
                                    alignItems = AlignItems.center
                                }
                                fText { design = agreementTittle }
                                fText { design = regNumber }
                                fText { design = make }
                                fText { design = sumTitle }
                                fText { design = sum }
                                fText { design = sellTypeLabel }
                                fSelectInput { design = sellType; onChange = ::handleSetValue }
                            }
                        }
                        fButton { design = next; onClick = ::handleNext }
                    }
                }
            }


        }


        viewModel.asContactInfo?.run {

            fAppbar { design = navigation; onClick = { handleBack() } }
            Stack {
                spacing = responsive(4)
                sx {
                    width = 100.pct
                    alignItems = AlignItems.center
                }

                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = responsive(
                            Breakpoint.xs to Padding(1.rem, 1.rem),
                            Breakpoint.md to Padding(3.rem, 3.rem),
                        )
                        width = responsive(
                            Breakpoint.xs to 100.pct,
                            Breakpoint.md to 55.pct,
                            Breakpoint.lg to 45.pct,
                            Breakpoint.xl to 35.pct
                        )
                    }

                    fForm {
                        onSubmit = ::handleSubmitForm
                        Stack {
                            spacing = responsive(2)
                            items.map { item ->
                                when (item) {
                                    is FormItem.ViewModel.Header -> FormItemHeader { this.viewModel = item }
                                    is FormItem.ViewModel.Label -> FormItemLabel { this.viewModel = item }
                                    is FormItem.ViewModel.TextInput -> FormItemTextInput { this.viewModel = item; onChange = ::handleUpdate }
                                    else -> {}
                                }
                            }
                            fButton { design = next }
                        }
                    }
                }
            }
        }



        viewModel.asAgreement?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }
            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)
                sx {
                    alignItems = AlignItems.center
                }
                fImage { design = contract }
                fText { design = contractTitle }
                fText { design = contractIntro }

                fButton { design = further; onClick = ::handleFinished }
            }
        }

        viewModel.asCompanyFinished?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }
            mui.material.Paper {
                sx {
                    backgroundColor = Color(Palette.Background.paper)
                    borderRadius = Shape.borderRadius10
                    padding = responsive(
                        Breakpoint.xs to Padding(1.rem, 1.rem),
                        Breakpoint.md to Padding(3.rem, 3.rem),
                    )
                }
                Stack {
                    spacing = responsive(2)
                    direction = responsive(StackDirection.column)
                    sx {
                        alignItems = AlignItems.center
                    }
                    fImage { design = contract }
                    fText { design = finishTitle }
                    fText { design = finishIntro; className = ClassName("n-markdown") }

                    fButton { design = next; onClick = { navigate(Routes.matters) } }
                }
            }
        }

        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}