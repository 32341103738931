package support

import io.ktor.client.*
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import techla.base.*
import techla.content.*
import techla.guard.*

data class Deployment(
    val applicationKey: String,
    val applicationSecret: String,
    val isSandbox: Boolean,
    val version: String,
    val build: Int,
    val home: String,
    val app: String,
)

typealias ApplicationContext = Any?

val storeUpdates = MutableSharedFlow<Boolean>(
    extraBufferCapacity = 1,
    onBufferOverflow = BufferOverflow.DROP_OLDEST
)

data class Store(
    val applicationContext: ApplicationContext,
    val deployment: Deployment,
    val device: Device,

    val loadStoredUserAuthenticationId: () -> Identifier<UserAuthentication>?,
    val updateStoredUserAuthenticationId: (Identifier<UserAuthentication>) -> Unit,
    val removeStoredUserAuthenticationId: () -> Unit,

    val loadStoredCustomerType: () -> CustomerType?,
    val updateStoredCustomerType: (CustomerType) -> Unit,
    val removeStoredCustomerType: () -> Unit,

    val applicationToken: String? = null,
    val inMemoryUserAuthenticationId: Identifier<UserAuthentication>? = null,
    val tokens: List<Token> = emptyList(),
    val medias: List<Media>? = null,
    val profileId: Identifier<Profile>? = null,
    val govId: String? = null,
    val me: Me? = null,
    val group: Key<Group>? = null,
    val objects: List<Object>? = null,
    val inMemoryCustomerType: CustomerType? = null,
) {
    sealed class Action {
        data class ApplicationAuthenticationCompleted(val token: String) : Action()
        data class UserAuthenticationStarted(val userAuthenticationId: Identifier<UserAuthentication>, val tokens: List<Token>) : Action()
        data class UserAuthenticationCompleted(val tokens: List<Token>, val profileId: Identifier<Profile>?, val govId: String, val customerType: CustomerType) : Action()
        data class UserAuthenticationCancelled(val userAuthenticationId: Identifier<UserAuthentication>) : Action()
        data class TokenRefresh(val tokens: List<Token>, val profileId: Identifier<Profile>?, val govId: String?) : Action()
        data class ChangeCustomType(val customerType: CustomerType) : Action()
        data class MediasLoaded(val medias: List<Media>) : Action()
        data class ChangeGroup(val group: Key<Group>) : Action()
        data class ObjectsRefreshed(val me: Me, val objects: List<Object>) : Action()
        data class ReplaceObject(val id: Identifier<Object>, val obj: Object) : Action()
        data object Logout : Action()
    }

    val userAuthenticationId: Identifier<UserAuthentication>?
        get() = inMemoryUserAuthenticationId ?: loadStoredUserAuthenticationId()

    val customerType: CustomerType
        get() = inMemoryCustomerType ?: loadStoredCustomerType() ?: CustomerType.None

    val userToken: String?
        get() = tokens.filterIsInstance<Token.User>().firstOrNull { it.group == (group ?: Group.STANDARD) }?.token

    val tokenExpiresAt: Date?
        get() = tokens.filterIsInstance<Token.User>().firstOrNull()?.expiresAt

    val adminToken: String?
        get() = tokens.filterIsInstance<Token.Admin>().firstOrNull()?.token

    val qrCode: String?
        get() = tokens.filterIsInstance<Token.QR>().firstOrNull()?.code

    val autoStartToken: String?
        get() = tokens.filterIsInstance<Token.AutoStart>().firstOrNull()?.token

    fun updateUserAuthenticationId(userAuthenticationId: Identifier<UserAuthentication>?): Identifier<UserAuthentication>? {
        if (userAuthenticationId != null)
            updateStoredUserAuthenticationId(userAuthenticationId)
        else
            removeStoredUserAuthenticationId()
        storeUpdates.tryEmit(userAuthenticationId != null)
        return userAuthenticationId
    }

    private fun updateCustomerType(customerType: CustomerType?): CustomerType {
        if (customerType != null)
            updateStoredCustomerType(customerType)
        else
            removeStoredCustomerType()
        return customerType ?: CustomerType.None
    }

    val version: String
        get() =
            if (deployment.isSandbox)
                "${deployment.version.replace("-SNAPSHOT", "")} (${deployment.build})"
            else
                deployment.version.replace("-SNAPSHOT", "")

    fun get(media: Key<Tag>, content: Key<Content>) =
        medias?.get(media)?.getOrNull(content)?.text ?: "<Needs translation>"

    fun reduce(action: Action) = when (action) {
        is Action.ApplicationAuthenticationCompleted ->
            copy(applicationToken = action.token)

        is Action.UserAuthenticationStarted ->
            copy(inMemoryUserAuthenticationId = updateUserAuthenticationId(action.userAuthenticationId), tokens = action.tokens)

        is Action.UserAuthenticationCompleted ->
            copy(tokens = action.tokens, profileId = action.profileId, govId = action.govId, inMemoryCustomerType = updateCustomerType(action.customerType))

        is Action.UserAuthenticationCancelled ->
            copy(inMemoryUserAuthenticationId = updateUserAuthenticationId(action.userAuthenticationId), tokens = emptyList(), profileId = null, govId = null)

        is Action.TokenRefresh ->
            copy(tokens = action.tokens, profileId = action.profileId, govId = action.govId)

        is Action.ChangeCustomType ->
            copy(inMemoryCustomerType = updateCustomerType(action.customerType))

        is Action.MediasLoaded ->
            copy(medias = action.medias)

        is Action.ChangeGroup ->
            copy(group = action.group)

        is Action.ObjectsRefreshed ->
            copy(me = action.me, objects = action.objects)

        is Action.ReplaceObject -> {
            copy(objects = objects?.map { if (it.id == action.id) action.obj else it })
        }

        is Action.Logout ->
            copy(
                applicationToken = null,
                inMemoryUserAuthenticationId = updateUserAuthenticationId(null),
                tokens = emptyList(),
                profileId = null,
                govId = null,
                me = null,
                group = null,
                objects = null,
                inMemoryCustomerType = updateCustomerType(null)
            )
    }

    fun reduce(actions: List<Action>) = actions.reversed().fold(this) { store, action -> store.reduce(action) }

    val httpClient = HttpClient() {
        expectSuccess = false
    }
}