package components

import emotion.react.css
import mui.material.*

import mui.system.sx
import react.FC
import react.PropsWithClassName
import react.dom.html.ReactHTML
import support.*
import web.cssom.*

external interface FGridProps : PropsWithClassName {
    var design: DesignSystem.Table?
    var onClick: ((String?) -> Unit)
}

val fGrid = FC<FGridProps> { props ->

    fun col(col: DesignSystem.Color?): ColorProperty? =
        when (col) {
            DesignSystem.Color.DANGER -> Color(Palette.Danger.main)
            DesignSystem.Color.SUCCESS -> Color(Palette.Success.main)
            DesignSystem.Color.WARNING -> Color(Palette.Warning.main)
            else -> Palette.Text.primary
        }


    if (props.design?.visible == true) {
        val design = props.design!!
        Box {
            Grid {
                this.container = true


                design.header?.let { row ->
                    Grid {
                        this.container = true
                        item = true
                        lg = 12
                        row.cells?.map { cell ->
                            Grid {
                                item = true
                                xs = 12 / row.cells.size
                                md = 12 / row.cells.size
                                lg = 12 / row.cells.size
                                Box {
                                    sx {
                                        padding = Padding(1.rem, 0.rem)
                                        color = Palette.Text.primary
                                    }
                                    +cell.title
                                }
                            }
                        }
                    }

                    design.body?.let { body ->
                        body.map { row ->
                            Grid {
                                this.container = true
                                this.item = true
                                lg = 12

                                row.cells?.map { cell ->
                                    Grid {
                                        this.item = true
                                        xs = 12 / row.cells.size
                                        md = 12 / row.cells.size
                                        lg = 12 / row.cells.size
                                        Box {
                                            sx {
                                                borderTop = Border(0.5.px, LineStyle.solid, Palette.Text.secondary)
                                                padding = Padding(1.rem, 0.rem)
                                                color = Palette.Text.primary
                                                display = Display.flex
                                                justifyContent = if (cell.title?.isNotEmpty() == true) JustifyContent.start else JustifyContent.end
                                            }

                                            when (cell) {
                                                is DesignSystem.Option.Item -> {
                                                    +cell?.title
                                                    cell.image

                                                    cell.image?.let { image ->
                                                        ReactHTML.img {
                                                            css {
                                                                cursor = Cursor.pointer
                                                            }
                                                            src = Design.image(image)
                                                            onClick = { props.onClick(cell.value) }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (design.body?.isEmpty() == true) {
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    justifyContent = JustifyContent.center
                    alignItems = AlignItems.center
                    padding = Padding(1.rem, 0.rem, 0.rem, 0.rem)
                }
                fImage {
                    this.design = DesignSystem.ImageView(image = DesignSystem.Image.TABLEEMPTY)
                }
                fText { this.design = DesignSystem.Text(text = design.empty, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER) }

            }
        }
    }
}

