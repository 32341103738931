package support

import techla.guard.Group

val Group.Visualization.groupType: String
    get() =
        when (this) {
            is Group.Visualization.Predefined -> {
                if (template.contains("-")) {
                    template.split("-")[0]
                } else template
            }

            else -> ""
        }

val Group.Visualization.buyType: String
    get() =
        when (this) {
            is Group.Visualization.Predefined -> template
            else -> ""
        }

object GroupCombinations {
    val private = CustomerType.Private.rawValue
    val privatePrivate = "${CustomerType.Private.rawValue}-${CustomerType.Private.rawValue}"
    val company = CustomerType.Company.rawValue
    val privateCompany = "${CustomerType.Private.rawValue}-${CustomerType.Company.rawValue}"
    val companyPrivate = "${CustomerType.Company.rawValue}-${CustomerType.Private.rawValue}"
    val companyCompany = "${CustomerType.Company.rawValue}-${CustomerType.Company.rawValue}"
    val leasing = CustomerType.Leasing.rawValue
    val leasingCompanyCompany = "${CustomerType.Leasing.rawValue}-${CustomerType.Company.rawValue}"
    val leasingCompanyPrivate = "${CustomerType.Leasing.rawValue}-${CustomerType.Private.rawValue}"
}

fun boGroupColor(groupType: String) =
    when (groupType) {
        GroupCombinations.private -> null //Palette.Commitment.private + Opacity.opacity40.toString()
        GroupCombinations.privatePrivate -> Palette.CommitmentRows.private //+ Opacity.opacity40.toString()

        GroupCombinations.company -> null //Palette.Commitment.privateCompany + Opacity.opacity40.toString()
        GroupCombinations.privateCompany -> Palette.CommitmentRows.privateCompany // + Opacity.opacity40.toString()
        GroupCombinations.companyPrivate -> Palette.CommitmentRows.privateCompany //+ Opacity.opacity40.toString()

        GroupCombinations.companyCompany -> Palette.CommitmentRows.company //+ Opacity.opacity40.toString()

        GroupCombinations.leasing -> null//Palette.Commitment.leasing + Opacity.opacity40.toString()
        GroupCombinations.leasingCompanyCompany -> Palette.CommitmentRows.leasing // + Opacity.opacity40.toString()
        GroupCombinations.leasingCompanyPrivate -> Palette.CommitmentRows.leasing //+ Opacity.opacity40.toString()

        else -> null
    }


fun groupColor(groupType: String) =
    when (groupType) {
        CustomerType.Private.rawValue -> Palette.CommitmentRows.buy //+ Opacity.opacity40.toString()
        CustomerType.Leasing.rawValue -> Palette.CommitmentRows.leasing // + Opacity.opacity40.toString()
        CustomerType.Company.rawValue -> Palette.CommitmentRows.buy //+ Opacity.opacity40.toString()
        else -> null
    }