package components

import emotion.react.css
import mui.material.CircularProgress
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import web.cssom.*

val fLoader = FC<Props> {
    div {
        css {
            display = Display.flex
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            height = 80.px
            width = 80.px
        }
        CircularProgress {
            size = 80.px
            thickness = 4.5
        }
    }
}