package support

import services.*
import techla.agreement.Agreement
import techla.agreement.Signature
import techla.base.*
import techla.form.Field
import techla.form.Form
import techla.form.Submission
import techla.guard.Group
import techla.guard.Profile
import techla.payment.Payment


object FK {
    const val isWasaKreditEnabled = false

    const val wasaKredit = "WASA"
    const val privateBuy = "PRIVATE_BUY"
    const val companyBuy = "COMPANY_BUY"
    const val leasingCompany = "LEASING_COMPANY"
    const val privateSell = "PRIVATE_SELL"
    const val companySell = "COMPANY_SELL"

    val allKeys = listOf(privateBuy, companyBuy, privateSell, companySell, wasaKredit, leasingCompany)

    const val privateBuyTemplate = "9222115557499322979"
    const val companyBuyTemplate = "9222115557502309207"
    const val privateSellTemplate = "9222115557499325665"
    const val leasingCompanyTemplate = "9222115557503274466"

    fun templateId(rawKey: String) =
        when (rawKey) {
            privateSell -> privateSellTemplate
            //   companySell -> companySellTemplate
            privateBuy -> privateBuyTemplate
            companyBuy -> companyBuyTemplate
            leasingCompany -> leasingCompanyTemplate
            else -> ""
        }


}

sealed class Object {
    data class Min(
        val id: Identifier<Object>,
        val profileId: Identifier<Profile>,
        val group: Group,
    )

    data class Max(
        val buyerAgreement: Agreement?,
        val sellerAgreement: Agreement?,
        val wasaAgreement: Agreement?,
        val signatures: List<Signature>,
        val buyer: Submission?,
        val seller: Submission?,
        val excessPayment: Payment?,
        val residualDeptPayment: Payment?,
    )

    object None : Object()

    data class Minimal(
        val min: Min,
    ) : Object()

    data class Full(
        val min: Min,
        val max: Max,
    ) : Object()

    data class Edit(
        val name: Modification<String> = Modification.Unmodified
    )

    val minimal
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None can't produce Minimal")
                is Minimal -> this
                is Full -> Minimal(min = min)
            }

    val id
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no id")
                is Minimal -> min.id
                is Full -> min.id
            }

    val key
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no key")
                is Minimal -> Key<Object>(min.group.key.rawValue)
                is Full -> Key<Object>(min.group.key.rawValue)
            }

    val name
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no key")
                is Minimal -> min.group.name
                is Full -> min.group.name
            }

    val profileId
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no profileId")
                is Minimal -> min.profileId
                is Full -> min.profileId
            }

    val group
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no group")
                is Minimal -> min.group
                is Full -> min.group
            }

    val buyerAgreement: Agreement?
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no agreements")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no agreements")
                is Full -> max.buyerAgreement
            }

    val sellerAgreement: Agreement?
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no agreements")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no agreements")
                is Full -> max.sellerAgreement
            }

    val wasaAgreement: Agreement?
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no wasa agreements")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no wasa agreements")
                is Full -> max.wasaAgreement
            }

    val submissionBuyer: Submission?
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no currentAgreement")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no currentAgreement")
                is Full -> max.buyer
            }

    val submissionSeller: Submission?
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no currentAgreement")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no currentAgreement")
                is Full -> max.seller
            }

    val signatures: List<Signature>
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no signatures")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no signatures")
                is Full -> max.signatures
            }

    val paymentExcess: Payment?
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no currentAgreement")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no currentAgreement")
                is Full -> max.excessPayment
            }

    val paymentResidualDept: Payment?
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no currentAgreement")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no currentAgreement")
                is Full -> max.residualDeptPayment
            }


    val isGroupActive get() = group.status is Group.Status.Active
    fun isGroupLeader() = group.leader == profileId

    val hasInvites get() = group.numberOfInvites > 0
}


suspend fun Store.createObject(name: String, formKey: Key<Form>, entries: List<Submission.Entry>, customerType: String): ActionOutcome<Object.Full> =
    successfulOf(Key.random<Group>(20)).noActions()
        .flatMap { (actions, key) ->
            val profileId = profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))

            val group = Group.Create(
                name = name,
                key = key,
                status = Group.Status.None,
                visualization = Group.Visualization.Predefined(template = CustomerType.getCustomType(customerType)),
                join = true,
                order = Checkpoint.BuyerStarted.order,
            )
            reduce(actions).createGroup(group).accumulate(actions)
                .map {
                    val minimal = Object.Minimal(
                        min = Object.Min(
                            id = Identifier(it.second.id.rawValue),
                            profileId = profileId,
                            group = it.second,
                        )
                    )

                    tupleOf(it.first, minimal)
                }
        }
        .flatMap { (actions, minimal) ->
            reduce(actions).refreshTokens()
                .accumulate(actions)
                .map { tupleOf(it.first, minimal) }
        }
        .flatMap { (actions, minimal) ->
            val create = Agreement.Create(
                key = Key(customerType),
                name = minimal.name,
                status = Agreement.Status.Pending,
                content = FK.templateId(customerType),
                approvalMethod = Agreement.ApprovalMethod.BySignatures(count = 1)
            )
            reduce(actions).createAgreement(minimal, create).accumulate(actions)
                .map { tupleOf(it.first, minimal, it.second) }
        }
        .flatMap { (actions, minimal, agreement) ->
            reduce(actions).me()
                .accumulate(actions)
                .map { tupleOf(it.first, minimal, agreement, it.second) }
        }
        .flatMap { (actions, minimal, agreement, me) ->

            val createSubmission = Submission.Create(
                sources = listOf(Source.VehicleLookup, Source.addressLookup, Source.installmentSupplier),
                state = Submission.State.Open,
                formKey = formKey,
                entries = entries
            )
            reduce(actions).createSubmission(minimal, createSubmission).accumulate(actions)
                .map { tupleOf(it.first, minimal, agreement, me, it.second) }
                .map { (actions, minimal, agreement, me, submission) ->
                    val profileId = profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))
                    val full = Object.Full(
                        min = Object.Min(
                            id = minimal.id,
                            profileId = profileId,
                            group = minimal.group,
                        ),
                        max = Object.Max(
                            buyerAgreement = agreement,
                            sellerAgreement = null,
                            wasaAgreement = null,
                            signatures = emptyList(),
                            buyer = submission,
                            seller = null,
                            excessPayment = null,
                            residualDeptPayment = null
                        )
                    )
                    val action = Store.Action.ObjectsRefreshed(me = me, objects = listOf(full))

                    tupleOf(actions + action, full)
                }
        }


suspend fun Store.editSubmissionObject(obj: Object, formId: Identifier<Submission>, editSubmission: Submission.Edit): ActionOutcome<Object.Full> =
    editSubmission(obj, formId, editSubmission)
        .map { (actions, submission) ->
            val profileId = profileId ?: return failedOf(TechlaError.InternalServerError("Missing profile"))
            val full = Object.Full(
                min = Object.Min(
                    id = obj.id,
                    profileId = profileId,
                    group = obj.group,
                ),
                max = Object.Max(
                    buyerAgreement = obj.buyerAgreement,
                    sellerAgreement = null,
                    wasaAgreement = null,
                    signatures = emptyList(),
                    buyer = submission,
                    seller = null,
                    excessPayment = null,
                    residualDeptPayment = null
                )
            )
            val action = Store.Action.ObjectsRefreshed(me = this.me!!, objects = listOf(full))
            tupleOf(actions + action, full)
        }


suspend fun Store.editSellSubmissionObject(obj: Object, formId: Identifier<Submission>, editSubmission: Submission.Edit, formKey: String): ActionOutcome<Object.Full> =
    editSubmission(obj, formId, editSubmission)
        .flatMap { (actions, submission) ->
            reduce(actions).findLatestAgreement(obj = obj, keys = listOf(Key(formKey))).accumulate(actions)
                .map { tupleOf(it.first, submission, it.second) }
        }
        .map { (actions, submission, agreements) ->
            val profileId = profileId ?: return failedOf(TechlaError.InternalServerError("Missing profile"))

            val full = Object.Full(
                min = Object.Min(
                    id = obj.id,
                    profileId = profileId,
                    group = obj.group,
                ),
                max = Object.Max(
                    buyerAgreement = null,
                    sellerAgreement = agreements.firstOrNull(),
                    wasaAgreement = null,
                    signatures = emptyList(),
                    buyer = null,
                    seller = submission,
                    excessPayment = null,
                    residualDeptPayment = null
                )
            )

            val action = Store.Action.ObjectsRefreshed(me = this.me!!, objects = listOf(full))
            tupleOf(actions + action, full)
        }


suspend fun Store.refreshObject(objectId: Identifier<Object>, submissionKeys: List<Key<Form>>): ActionOutcome<Object.Full> =
    successfulOf(objects?.firstOrNull { it.id == objectId }).noActions()
        .map { (actions, obj) ->
            // Check if agreement matches, otherwise refresh
            if (obj is Object.Full)
                tupleOf(actions, obj.minimal)
            else
                tupleOf(actions, obj)
        }
        .flatMap { (actions, obj) ->
            when (obj) {
                is Object.Full -> successfulOf(tupleOf(actions, obj))
                is Object.Minimal ->
                    successfulOf(Unit)
                        .flatMap {
                            reduce(actions).getGroup(id = obj.group.id)
                                .map { tupleOf(it.first, it.second) }
                        }
                        .flatMap { (actions, group) ->
                            reduce(actions).findLatestAgreement(obj = obj, keys = FK.allKeys.map { Key(it) })
                                .accumulate(actions)
                                .map { tupleOf(it.first, group, it.second) }
                        }
                        .flatMap { (actions, group, agreement) ->
                            reduce(actions).findSubmissions(obj, forms = submissionKeys)
                                .accumulate(actions)
                                .map { tupleOf(it.first, group, agreement, it.second) }
                        }
                        .flatMap { (actions, group, agreement, submissions) ->
                            val agreementId = agreement.firstOrNull { it.key.rawValue == FK.privateBuy } ?: agreement.firstOrNull { it.key.rawValue == FK.companyBuy } ?: agreement.firstOrNull { it.key.rawValue == FK.leasingCompany }
                            if (agreementId?.id != null)
                                reduce(actions).findSignatures(obj, agreementId.id).accumulate(actions)
                                    .map { tupleOf(it.first, group, agreement, submissions, it.second) }
                            else
                                successfulOf(actions, group, agreement, submissions, emptyList())
                        }

                        .flatMap { (actions, group, agreements, submissions, signatures) ->
                            val profileId = reduce(actions).profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))
                            val newestSubmission = if (submissionKeys.size >= 2) submissions.maxByOrNull { it.timestamp }?.let { listOf(it) } ?: emptyList() else
                                submissions.filter { it.form?.key?.rawValue == submissionKeys.firstOrNull()?.rawValue }.maxByOrNull { it.timestamp }?.let { listOf(it) } ?: emptyList()

                            val full = Object.Full(
                                min = Object.Min(
                                    id = obj.id,
                                    profileId = profileId,
                                    group = group,
                                ),
                                max = Object.Max(
                                    buyerAgreement = agreements.firstOrNull { it.key.rawValue == FK.privateBuy } ?: agreements.firstOrNull { it.key.rawValue == FK.companyBuy } ?: agreements.firstOrNull { it.key.rawValue == FK.leasingCompany },
                                    sellerAgreement = agreements.firstOrNull { it.key.rawValue == FK.privateSell } ?: agreements.firstOrNull { it.key.rawValue == FK.companySell },
                                    wasaAgreement = agreements.firstOrNull { it.key.rawValue == FK.wasaKredit },
                                    signatures = signatures,
                                    buyer = newestSubmission.firstOrNull { it.form?.key?.rawValue == FK.privateBuy } ?: newestSubmission.firstOrNull { it.form?.key?.rawValue == FK.companyBuy } ?: newestSubmission.firstOrNull { it.form?.key?.rawValue == FK.leasingCompany },
                                    seller = newestSubmission.firstOrNull { it.form?.key?.rawValue == FK.privateSell } ?: newestSubmission.firstOrNull { it.form?.key?.rawValue == FK.companySell },
                                    // TODO SHOULD USER SE ANYTHING ABOUT THE PAYMENT?
                                    excessPayment = null,
                                    residualDeptPayment = null
                                )
                            )
                            val action = Store.Action.ReplaceObject(id = obj.id, obj = full)
                            successfulOf(actions + action, full)
                        }

                else ->
                    failedOf(TechlaError.InternalServerError("Requested missing object"))
            }
        }


suspend fun Store.refreshObjects(): ActionOutcome<List<Object>> =
    successfulOf(true).noActions()
        .flatMap { (actions, _) ->
            reduce(actions).myGroups()
                .accumulate(actions)
        }
        .flatMap { (actions, groups) ->
            val profileId = reduce(actions).profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))

            val objects = groups.map { group ->
                Object.Minimal(
                    min = Object.Min(
                        id = Identifier(group.id.rawValue),
                        profileId = profileId,
                        group = group,
                    ),
                )
            }
            successfulOf(tupleOf(actions, objects))
        }
        .flatMap { (actions, objects) ->
            reduce(actions).me()
                .accumulate(actions, objects)
        }
        .map { (actions, objects, me) ->
            val action = Store.Action.ObjectsRefreshed(me = me, objects = objects)
            tupleOf(actions + action, objects)
        }


suspend fun Store.refreshMinimalObject(objectId: Identifier<Object>): ActionOutcome<Object.Minimal> =
    successfulOf(objects?.firstOrNull { it.id == objectId }).noActions()
        .flatMap { (actions, obj) ->
            when (obj) {
                is Object.Minimal -> successfulOf(tupleOf(actions + Store.Action.ReplaceObject(id = obj.id, obj = obj), obj))
                is Object.Full -> successfulOf(tupleOf(actions + Store.Action.ReplaceObject(id = obj.id, obj = obj), obj.minimal))
                else -> failedOf(TechlaError.InternalServerError("Requested missing object"))
            }
        }


suspend fun Store.adminRefreshObjects(pageIndex: PageIndex, groupFilter: Boolean, id: Identifier<Profile>? = null, predefined: List<Group.Visualization.Predefined>? = null): ActionOutcome<Pair<List<Object>, PageContent<Group>>> =
    successfulOf(true).noActions()
        .flatMap { (actions, _) ->
            val statuses = if (groupFilter) listOf(Group.Status.Active, Group.Status.None) else null
            val profileIds = if (id != null) listOf(id) else null
            reduce(actions).listAllGroupsWithPaging(pageIndex, statuses, profileIds, predefined)
                .accumulate(actions)
        }
        .flatMap { (actions, groups) ->
            val profileId = reduce(actions).profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))

            val objects = groups.contents.map { group ->
                Object.Minimal(
                    min = Object.Min(
                        id = Identifier(group.id.rawValue),
                        profileId = profileId,
                        group = group,
                    ),
                )
            }
            successfulOf(tupleOf(actions, objects, groups))
        }
        .flatMap { (actions, objects, groups) ->
            reduce(actions).me()
                .accumulate(actions, objects)
                .map { tupleOf(it.first, objects, groups, it.third) }
        }
        .map { (actions, objects, groups, me) ->

            val action = Store.Action.ObjectsRefreshed(me = me, objects = objects)
            tupleOf(actions + action, Pair(objects, groups))
        }


suspend fun Store.adminRefreshObject(objectId: Identifier<Object>): ActionOutcome<Object.Full> =
    getGroup(Identifier(objectId.rawValue))
        .flatMap { (actions, group) ->
            val profileId = reduce(actions).profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))
            val minimal = Object.Minimal(
                min = Object.Min(
                    id = Identifier(group.id.rawValue),
                    profileId = profileId,
                    group = group,
                ),
            )
            reduce(actions).listAgreements(obj = minimal)
                .map { tupleOf(it.first, minimal, it.second) }
        }
        .flatMap { (actions, minimal, agreements) ->
            reduce(actions).listSignatures(minimal)
                .accumulate(actions)
                .map { tupleOf(it.first, minimal, agreements, it.second.filter { sign -> sign.signed is Signature.Signed.Policy || sign.signed is Signature.Signed.Agreement }) }
        }
        .flatMap { (actions, minimal, agreements, signatures) ->
            reduce(actions).listSubmissions(minimal)
                .accumulate(actions)
                .map { tupleOf(it.first, minimal, agreements, signatures, it.second) }
        }
        .flatMap { (actions, minimal, agreements, signatures, submissions) ->
            reduce(actions).listPayments(minimal)
                .accumulate(actions)
                .map { tupleOf(it.first, minimal, agreements, signatures, submissions, it.second) }
        }
        .map { (actions, minimal, agreements, signatures, submissions, payments) ->
            val profileId = reduce(actions).profileId ?: return failedOf(TechlaError.InternalServerError("Missing profile"))

            val sellerSubmission = submissions.firstOrNull { it.form?.key?.rawValue == FK.privateSell && it.state == Submission.State.Closed } ?: submissions.firstOrNull { it.form?.key?.rawValue == FK.companySell && it.state == Submission.State.Closed } ?: submissions.firstOrNull { it.form?.key?.rawValue == FK.privateSell && it.state == Submission.State.Open } ?: submissions.firstOrNull { it.form?.key?.rawValue == FK.companySell && it.state == Submission.State.Open }
            val buyerSubmission = submissions.firstOrNull { it.form?.key?.rawValue == FK.privateBuy } ?: submissions.firstOrNull { it.form?.key?.rawValue == FK.companyBuy } ?: submissions.firstOrNull { it.form?.key?.rawValue == FK.leasingCompany }

            val full = Object.Full(
                min = Object.Min(
                    id = minimal.id,
                    profileId = profileId,
                    group = minimal.group,
                ),
                max = Object.Max(
                    buyerAgreement = agreements.firstOrNull { it.key.rawValue == FK.privateBuy } ?: agreements.firstOrNull { it.key.rawValue == FK.companyBuy } ?: agreements.firstOrNull { it.key.rawValue == FK.leasingCompany },
                    sellerAgreement = agreements.firstOrNull { it.key.rawValue == FK.privateSell } ?: agreements.firstOrNull { it.key.rawValue == FK.companySell },
                    wasaAgreement = agreements.firstOrNull { it.key.rawValue == FK.wasaKredit },
                    signatures = signatures,
                    buyer = buyerSubmission,
                    seller = sellerSubmission,
                    excessPayment = payments.firstOrNull { it.reference == "EXCESS" },
                    residualDeptPayment = payments.firstOrNull { it.reference == "RESIDUAL_DEBT" }
                )
            )
            val action = Store.Action.ReplaceObject(id = minimal.id, obj = full)
            tupleOf(actions + action, full)
        }

