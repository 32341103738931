package components.items

import components.*
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.*
import web.cssom.*


external interface FormRadioInputProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.RadioInput
    var onChange: ((id: String, value: String) -> Unit)?
}


val FormItemRadioInput = FC<FormRadioInputProps> { props ->

    fun onChange(inputValue: String) {
        props.onChange?.let { it(props.viewModel.fieldKey.rawValue, inputValue) }
    }

    if (props.viewModel.visible)
        Box {
            sx {
                display = Display.flex
                alignItems = AlignItems.start
                justifyContent = JustifyContent.spaceBetween
            }
            Stack {
                direction = responsive(StackDirection.column)
                fTextWithLinks { design = props.viewModel.textWithLink }
                fRadioInput { design = props.viewModel.value; onChange = ::onChange }
            }

            if (props.viewModel.info != null)
                props.viewModel.info.let {
                    fInfoButton {
                        if (it != null) {
                            infoText = it
                        }
                    }
                }
        }
}

