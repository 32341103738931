package support

import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.TypographyVariant
import mui.material.styles.createTheme
import support.DesignSystem.SizeType.XL2
import support.DesignSystem.SizeType.XL
import support.DesignSystem.SizeType.LG
import support.DesignSystem.SizeType.MD
import support.DesignSystem.SizeType.SM
import support.DesignSystem.SizeType.XS
import support.DesignSystem.SizeType.XS2
import support.DesignSystem.StyleType.*
import techla.base.TechlaError
import web.cssom.px
import web.cssom.rem
import web.cssom.rgb

object Palette {
    object Primary {
        const val light = "#FFF2C3"
        const val main = "#FFEFB5"
        const val dark = "#B2A77E"
    }

    object Secondary {
        const val light = "#43F3B4"
        const val main = "#15F1A2"
        const val dark = "#0EA871"
    }

    object Success {
        const val light = "#43F3B4"
        const val main = "#15F1A2"
        const val dark = "#0EA871"
    }

    object Warning {
        const val light = "#FB9F83"
        const val main = "#FC8864"
        const val dark = "#AF5F46"
    }

    object Danger {
        const val light = "#FF80AB"
        const val main = "#FF6196"
        const val dark = "#B24369"
    }

    object Background {
        const val default = "#000000"
        const val paper = "#1e1e1e"
    }

    object Info {
        const val light = "#7EDFFF"
        const val main = "#5ED8FF"
        const val dark = "#4197B2"
    }

    object CommitmentRows {
        const val private = "#292D2C" //"#7A9B8F"
        const val privateCompany = "#393730"// Primary.main
        const val leasing = "#1D2C39"//"#148EFF"
        const val company = "#2F2339"//"#AC43FF"
        const val buy = "#303939"
    }

    object CommitmentDots {
        const val private = "#7A9B8F"
        const val privateCompany = Primary.main
        const val leasing = "#148EFF"
        const val company = "#AC43FF"
        const val buy = "#B5FFFF"
    }

    object Text {
        val primary = rgb(255, 255, 255, 1.0)
        val secondary = rgb(255, 255, 255, 0.7)
        val disabled = rgb(255, 255, 255, 0.5)
    }

    const val transparent = "transparent"
}

object Shape {
    val borderRadius10 = 10.px
    val borderRadius50 = 50.px
    val borderRadius100 = 100.px
}

object Opacity {
    val opacity10 = 10
    val opacity20 = 20
    val opacity25 = 25
    val opacity40 = 40
    val opacity50 = 50
    val opacity60 = 60
    val opacity80 = 80
}

object Grow {
    val grow1 = 1
    val opacity20 = 20
    val opacity40 = 40
    val opacity50 = 50
    val opacity60 = 60
    val opacity80 = 80
}

object Support {}

fun Support.typographyVariant(size: DesignSystem.SizeType, style: DesignSystem.StyleType) =
    when {
        size == XL2 && style == EXTRA_BOLD -> TypographyVariant.h1
        size == XL && style == EXTRA_BOLD -> TypographyVariant.h2
        size == LG && style == REGULAR -> TypographyVariant.subtitle1
        size == LG && style == BOLD -> TypographyVariant.subtitle2
        size == MD && style == REGULAR -> TypographyVariant.body1
        size == MD && style == BOLD -> TypographyVariant.body2
        size == SM && style == REGULAR -> TypographyVariant.caption
        size == XS && style == REGULAR -> TypographyVariant.h3
        size == XS && style == BOLD -> TypographyVariant.h4
        size == XS2 && style == REGULAR -> TypographyVariant.h5
        size == XS2 && style == BOLD -> TypographyVariant.h6
        else -> throw TechlaError.BadRequest("Combination not supported ($size:$style)")
    }

fun themeFontFamily(size: DesignSystem.SizeType) =
    "Poppins, sans-serif"

fun themeFontWeight(style: DesignSystem.StyleType) =
    when (style) {
        EXTRA_BOLD -> 800
        BOLD -> 700
        REGULAR -> 400
    }

fun themeFontSize(size: DesignSystem.SizeType) =
    when (size) {
        XL2 -> 2.25 // 28px
        XL -> 1.5 // 24px
        LG -> 1.125 // 18px
        MD -> 1.0 // 16px
        SM -> 0.875 // 14px
        XS -> 0.75 // 12px
        XS2 -> 0.625 // 10px
    }

fun themeFontColor(size: DesignSystem.SizeType) =
    when (size) {
        XL2, XL -> Palette.Secondary.main
        LG, MD, XS, XS2 -> Palette.Text.primary
        SM -> Palette.Text.primary
    }

val fTheme = createTheme(jso {
    palette = jso {
        mode = PaletteMode.light
        primary = jso {
            main = Palette.Primary.main
            light = Palette.Primary.light
            dark = Palette.Primary.dark
        }
        secondary = jso {
            main = Palette.Secondary.main
            light = Palette.Secondary.light
            dark = Palette.Secondary.dark
        }
        error = jso {
            main = Palette.Danger.main
            light = Palette.Danger.light
            dark = Palette.Danger.dark
        }
        warning = jso {
            main = Palette.Warning.main
            light = Palette.Warning.light
            dark = Palette.Warning.dark
        }
        success = jso {
            main = Palette.Success.main
            light = Palette.Success.light
            dark = Palette.Success.dark
        }
        info = jso {
            main = Palette.Info.main
            light = Palette.Info.light
            dark = Palette.Info.dark
        }
        background = jso {
            paper = Palette.Background.paper
            default = Palette.Background.default
        }
        text = jso {
            primary = Palette.Text.primary
            secondary = Palette.Text.secondary
            disabled = Palette.Text.disabled
        }
    }
    typography = jso {
        useNextVariants = false
        fontFamily = themeFontFamily(SM)
        button = jso {
            textTransform = none
            fontWeight = 700
        }
        h1 = jso { // XL2
            fontSize = themeFontSize(XL2).rem
            /*fontSize = responsive(
                Breakpoint.xs to themeFontSize(LG).rem,
                Breakpoint.md to themeFontSize(XL).rem,
            )*/
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(XL2)
        }
        h2 = jso {// XL
            fontSize = themeFontSize(XL).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(XL)
        }
        subtitle1 = jso {// LG
            fontSize = themeFontSize(LG).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(LG)
        }
        subtitle2 = jso {// LG
            fontSize = themeFontSize(LG).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(LG)
        }
        body1 = jso {// SM
            fontSize = themeFontSize(MD).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(MD)
        }
        body2 = jso {// SM
            fontSize = themeFontSize(MD).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(MD)
        }
        caption = jso {// LG
            fontSize = themeFontSize(SM).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(SM)
        }
        h3 = jso {// XS
            fontSize = themeFontSize(XS).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(XS)
        }
        h4 = jso {// XS
            fontSize = themeFontSize(XS).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(XS)
        }
        h5 = jso { // XS
            fontSize = themeFontSize(XS2).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(XS2)
        }
        h6 = jso { // XS
            fontSize = themeFontSize(XS2).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(XS2)
        }
    }
    components = jso {
        /* MuiCheckboxBase = jso {
             root = jso {
                 // We need to do an explicit import of the image i.e. `import support.DesignSystem.Image.BANKID`
                 // for this to work
                 icon = Design.image(BANKID)
                 checkedIcon = Design.image(BANKID)
             }
         }

         */
        MuiButtonBase = jso {
            defaultProps = jso {
                disableRipple = true
                fontSize = themeFontSize(MD).rem
                fontWeight = themeFontWeight(BOLD)
            }
        }
        MuiInputBase = jso {
            styleOverrides = jso {
                root = jso {
                    backgroundColor = Palette.transparent
                    notchedOutline = none
                    color = Palette.Text.primary
                    borderColor = Palette.Text.secondary
                    border = none
                    fontSize = themeFontSize(MD).rem
                    lineHeight = themeFontSize(LG).rem
                    fontWeight = themeFontWeight(REGULAR)
                    borderRadius = Shape.borderRadius10
                    textFillColor = Palette.Text.primary
                }
            }
        }
        MuiFormLabel = jso {
            styleOverrides = jso {
                root = jso {
                    disableRipple = true
                    color = Palette.Text.primary
                }
            }
        }
        MuiPaper = jso {
            styleOverrides = jso {
                root = jso {
                    backgroundColor = Palette.Background.paper
                    color = Palette.Text.secondary
                    fontSize = themeFontSize(MD).rem
                    lineHeight = themeFontSize(LG).rem
                    fontWeight = themeFontWeight(REGULAR)
                }
            }
        }
        MuiSvgIcon = jso {
            styleOverrides = jso {
                root = jso {
                    //  fill = Palette.Text.secondary
                    //  color = Palette.Text.secondary
                }
                fontSizeMedium = jso {
                    fill = Palette.Primary.main
                    color = Palette.Text.secondary
                }
                fontSizeSmall = jso {
                    fill = Palette.Background.default
                    color = Palette.Background.default
                }
                MuiAlert = jso {
                    icon = jso {
                        fill = Palette.Background.default
                        color = Palette.Background.default
                    }
                }

            }
        }
        MuiInputAdornment = jso {
            styleOverrides = jso {
                root = jso {
                    color = Palette.Text.secondary
                    fontSize = themeFontSize(MD).rem
                    lineHeight = themeFontSize(LG).rem
                    fontWeight = themeFontWeight(REGULAR)
                }
            }
        }
    }
})