package services

import support.*
import techla.base.*
import techla.form.*
import techla.guard.Group

suspend fun Store.findForms(keys: List<Key<Form>>): ActionOutcome<List<Form>> {
    return formUserAPI { actions, api ->
        measureAPI(FormResource.FindForms(keys, null), api) {
            api.findForms(keys)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.findForms(obj: Object, keys: List<Key<Form>>): ActionOutcome<List<Form>> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).findForms(keys)
    }

suspend fun Store.listSubmissionsPagination(
    index: PageIndex, forms: List<Key<Form>>, states: List<Submission.State>?, fields: List<Key<Field>>? = emptyList()
): ActionOutcome<PageContent<Submission>> {
    return formAdminAPI { actions, api ->
        measureAPI(FormResource.ListSubmissionsWithPaging(index, forms, states, fields), api) {
            api.listSubmissions(index, forms, states, fields)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.listSubmissions(group: Key<Group>): ActionOutcome<List<Submission>> {
    return formAdminAPI { actions, api ->
        api.additionalHeaders = listOf("group" to group.rawValue)
        measureAPI(FormResource.ListSubmissions, api) {
            api.listSubmissions()
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.listSubmissions(obj: Object): ActionOutcome<List<Submission>> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).listSubmissions(obj.group.key)
    }

suspend fun Store.findSubmissions(forms: List<Key<Form>>): ActionOutcome<List<Submission>> {
    return formUserAPI { actions, api ->
        measureAPI(FormResource.FindSubmissions(forms, false), api) {
            api.findSubmissions(forms, false)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.findSubmissions(obj: Object, forms: List<Key<Form>>): ActionOutcome<List<Submission>> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).findSubmissions(forms)
    }

suspend fun Store.createSubmission(obj: Object, create: Submission.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createSubmission(create)
    }

suspend fun Store.createSubmission(create: Submission.Create): ActionOutcome<Submission> {
    return formUserAPI { actions, api ->
        measureAPI(FormResource.CreateSubmission(create), api) {
            api.createSubmission(create)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.adminEditSubmission(id: Identifier<Submission>, edit: Submission.Edit): ActionOutcome<Submission> {
    return formAdminAPI { actions, api ->
        measureAPI(FormResource.EditSubmission(id, edit), api) {
            api.editSubmission(id, edit)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.editSubmission(id: Identifier<Submission>, edit: Submission.Edit): ActionOutcome<Submission> {
    return formUserAPI { actions, api ->
        measureAPI(FormResource.EditSubmission(id, edit), api) {
            api.editSubmission(id, edit)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.editSubmission(obj: Object, id: Identifier<Submission>, edit: Submission.Edit): ActionOutcome<Submission> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).editSubmission(id, edit)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.getSubmission(obj: Object, id: Identifier<Submission>): ActionOutcome<Submission> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getSubmission(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.getSubmission(id: Identifier<Submission>): ActionOutcome<Submission> {
    return formAdminAPI { actions, api ->
        measureAPI(FormResource.GetSubmission(id), api) {
            api.getSubmission(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.deleteSubmission(id: Identifier<Submission>): ActionOutcome<Unit> {
    return formUserAPI { actions, api ->
        measureAPI(FormResource.DeleteSubmission(id), api) {
            api.deleteSubmission(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.report(report: Submission.Report): ActionOutcome<List<Submission.Report.Result>> {
    return formAdminAPI { actions, api ->
        measureAPI(FormResource.Report(report), api) {
            api.report(report)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun <T> Store.formAdminAPI(block: suspend (List<Store.Action>, api: FormAPI) -> ActionOutcome<T>): ActionOutcome<T> {
    return withUserToken { actions ->
        val api = FormAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) FormAPI.sandbox else FormAPI.shared
            api.token = reduce(actions).adminToken
        }
        block(actions, api)
    }
}

suspend fun <T> Store.formUserAPI(block: suspend (List<Store.Action>, api: FormAPI) -> ActionOutcome<T>): ActionOutcome<T> {
    return withUserToken { actions ->
        val api = FormAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) FormAPI.sandbox else FormAPI.shared
            api.token = reduce(actions).let { it.userToken ?: it.applicationToken }
        }
        block(actions, api)
    }
}

object Source {
    val addressLookup = Form.Source.AddressLookup("Merinfo")
    val VehicleLookup = Form.Source.VehicleLookup("Biluppgifter")
    val installmentApplication = Form.Source.InstallmentApplication("WasaKredit")
    val installmentSupplier = Form.Source.InstallmentSupplier("WasaKredit")
}