package components

import StoreContext
import kotlinx.browser.window
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useLocation
import support.DesignSystem
import support.Window
import web.cssom.AlignItems
import web.cssom.JustifyContent
import web.cssom.pct

private external interface QrCodeProps : Props {
    var bgColor: String
    var fgColor: String
    var level: String // "L", "M", "Q", "H"
    var size: Int
    var title: String
    var value: String
}

@JsModule("react-qr-code")
@JsNonModule
private external val reactQrCodeModule: dynamic

@Suppress("UnsafeCastFromDynamic")
private val QrCode: ComponentType<QrCodeProps> = reactQrCodeModule.default

external interface BankIDProps : Props {
    var qrCode: String
    var scanQr: DesignSystem.Text?
    var openApp: DesignSystem.Text?
    var goToUrl: String
}

val BankID = FC<BankIDProps> { props ->
    val (store, _) = useContext(StoreContext)
    val screenWidth = window.screen.width
    val smallQrCode = screenWidth < 400
    val isLargeScreen = screenWidth >= 992
    val isXLargeScreen = screenWidth >= 1200
    val local = useLocation()

    var otherDevice by useState(isLargeScreen)
    var title by useState(if (isLargeScreen) props.scanQr else props.openApp)

    useEffect(otherDevice) {
        title = if (otherDevice) props.scanQr else props.openApp
    }

    fText { design = title }

    Stack {
        spacing = responsive(2)
        direction = responsive(StackDirection.column)
        sx {
            maxWidth = 100.pct
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
        }

        if (otherDevice) {
            QrCode {
                value = props.qrCode
                bgColor = "#fff"
                fgColor = "#000"
                this.size = if (smallQrCode) 128 else 256
                level = "L"
            }
        } else {
            Button {
                +"Öppna BankID"
                variant = ButtonVariant.contained
                onClick = {
                    // Redirect open a new tab. In the new tab you have to sign in again. In the old tab you have been signed in.
                    // Window.navigationUrl("bankid:///?autostarttoken=${store.autoStartToken}&redirect=${store.deployment.app}${props.goToUrl}")
                    Window.navigationUrl("bankid:///?autostarttoken=${store.autoStartToken}&redirect=null")
                }
            }
        }

        Button {
            if (otherDevice) {
                +"BankID på denna enhet"
            } else {
                +"BankID på annan enhet"
            }

            onClick = {
                otherDevice = !otherDevice
            }
        }
    }
}
