package components

import emotion.react.css
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import support.DesignSystem
import support.Palette
import web.cssom.*


external interface FBackgroundImageProps : PropsWithChildren {
    var bg: DesignSystem.Background
}


val fBackgroundImage = FC<FBackgroundImageProps> { props ->

        div {
            css {
                position = Position.fixed
                overflowX = Overflow.hidden
                right = 0.px
                top = 0.px
                zIndex = (-1).unsafeCast<ZIndex>()
                height = 100.pct
                width = 100.pct

                if (props.bg == DesignSystem.Background.IMAGE) {
                    background = url("/img/bg_gold.jpg")
                    backgroundSize = BackgroundSize.cover
                    backgroundRepeat = BackgroundRepeat.noRepeat
                }
                backgroundColor = when (props.bg) {
                    DesignSystem.Background.IMAGE -> important(Color(Palette.transparent))
                    else -> important(Color(Palette.Background.default))
                }
            }
        }
}
