package support

import kotlinx.browser.window
import react.router.NavigateFunction

fun standardNavigation(navigate: NavigateFunction): (DesignSystem.Action) -> Unit =
    { action ->
        when (action) {
            DesignSystem.Action.NONE -> {}
            DesignSystem.Action.BACK -> navigate(-1)
            DesignSystem.Action.PREVIOUS -> {}
            DesignSystem.Action.NEXT -> {}
            DesignSystem.Action.LOGOUT -> {}
            DesignSystem.Action.FORDONSKREDITEN_HOME -> window.location.assign(deployment.home)
            else -> {}
        }
    }