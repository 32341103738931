package components.items

import mui.material.Divider
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.FormItem
import support.Palette
import web.cssom.*


external interface FormDividerProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.Divider
}


val FormItemDivider = FC<FormDividerProps> { props ->
    if (props.viewModel.visible)
        Divider {
            sx {
                backgroundColor = Palette.Text.primary
                marginTop = important(1.rem)
            }
        }
}