package views

import StoreContext
import components.*
import components.fLoader
import components.items.*
import js.core.jso
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import mui.material.*
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useNavigate
import react.router.useParams
import screens.BackOfficeMattersScreen
import screens.PaymentScreen
import support.*
import techla.base.Identifier
import techla.base.techla_log
import web.cssom.*

val BackOfficeMatters = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: BackOfficeMattersScreen.ViewModel by useState(BackOfficeMattersScreen.ViewModel.None)
    val navigate = useNavigate()
    val params = useParams()
    var count: Int by useState(0)

    useAsyncEffectOnce {
        BackOfficeMattersScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce { params["objectId"]?.let { objectId -> BackOfficeMattersScreen.firstLoad(sceneInputOf(store, viewModel), Identifier(objectId)) } }

    fun goToPayment(paymentType: String) = call {
        navigate("${Routes.backOffice}/${params["objectId"]}/$paymentType")
    }

    fun handleCloseToast() = call {
        BackOfficeMattersScreen.toast(sceneInputOf(store, viewModel))
    }

    fun handleLogout() = call {
        BackOfficeMattersScreen.logout(sceneInputOf(store, viewModel))
        navigate(Routes.loginBackOffice, jso { replace = true; state = null })
    }

    fun recover() = call {
        if (params["objectId"] != null)
            BackOfficeMattersScreen.success(sceneInputOf(store, viewModel))
        else
            navigate(Routes.backOffice)
    }

    fun handleBack() = call {
        navigate(Routes.backOffice)
    }

    fun handleSetValue(value: DesignSystem.Option) = call {
        BackOfficeMattersScreen.setValue(sceneInputOf(store, viewModel), value)
    }

    fun handleGroupStatus() = call {
        BackOfficeMattersScreen.groupStatus(sceneInputOf(store, viewModel))
    }

    fun closeModals(value: Boolean) = call {
        BackOfficeMattersScreen.closeModal(sceneInputOf(store, viewModel))
    }

    fun handleInvite(value: Boolean = false) = call {
        BackOfficeMattersScreen.invite(sceneInputOf(store, viewModel), open = value)
    }

    fun copy(code: String) = call {
        BackOfficeMattersScreen.copy(sceneInputOf(store, viewModel))
        Window.clipboard(code)
    }

    fun handleSubmit() = call {
        BackOfficeMattersScreen.submit(sceneInputOf(store, viewModel))
    }

    fun handleUpdate(id: String, value: String) = call {
        BackOfficeMattersScreen.update(sceneInputOf(store, viewModel), id, value)
    }

    fun handleEdit(value: String) = call {
        BackOfficeMattersScreen.editable(sceneInputOf(store, viewModel), edit = value)
    }

    fun handleCancel() = call {
        BackOfficeMattersScreen.cancel(sceneInputOf(store, viewModel))
    }

    fun handleOpenModal(value: String) = call {
        BackOfficeMattersScreen.openModal(sceneInputOf(store, viewModel), edit = value)
    }

    fun handleCheckPayment() = call {
        BackOfficeMattersScreen.checkPayment(sceneInputOf(store, viewModel))
    }
    useAsyncEffect(count) { coroutineScope ->
        if (viewModel.state.paymentCheck) {
            if (coroutineScope.isActive) {
                delay(10000L)
                handleCheckPayment()
            }
        }
    }


    FScaffold {
        isFullScreen = when (viewModel) {
            is BackOfficeMattersScreen.ViewModel.Ready -> true
            else -> false
        }

        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }

        viewModel.asReady?.run {
            fAppbar { design = navigation; onClick = { handleLogout() } }
            fButton { design = back; onClick = { handleBack() } }

            Stack {
                spacing = responsive(2)

                sx {
                    alignItems = AlignItems.center
                    gap = 0.5.rem
                }
                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center

                        maxWidth = responsive(
                            Breakpoint.xs to 35.pct,
                            Breakpoint.lg to 50.pct
                        )
                    }

                    fText { design = readyTitle }
                    fText { design = groupStatusTitle }

                }
                Grid {
                    container = true
                    spacing = responsive(2)

                    Grid {
                        item = true
                        xs = 12
                        lg = 8

                        mui.material.Paper {
                            sx {
                                backgroundColor = Color(Palette.Background.paper)
                                borderRadius = Shape.borderRadius10
                                padding = Padding(1.rem, 1.rem)
                                width = 100.pct
                                marginBottom = 1.rem
                            }
                            fText { design = regNumber }
                            fText { design = make }
                            fText { design = sumTitle }
                            fText { design = sum }
                        }

                        mui.material.Paper {
                            Stack {
                                spacing = responsive(2)
                                sx {
                                    backgroundColor = Color(Palette.Background.paper)
                                    borderRadius = Shape.borderRadius10
                                    padding = Padding(1.rem, 1.rem)
                                    width = 100.pct
                                    marginBottom = 1.rem
                                }
                                fText { design = bankTitle }

                                fGrid { design = bankItems }
                            }
                        }

                        mui.material.Paper {
                            Stack {
                                spacing = responsive(2)
                                sx {
                                    backgroundColor = Color(Palette.Background.paper)
                                    borderRadius = Shape.borderRadius10
                                    padding = Padding(1.rem, 1.rem)
                                    width = 100.pct
                                    marginBottom = 1.rem
                                }
                                Stack {
                                    direction = responsive(StackDirection.row)
                                    sx {
                                        justifyContent = JustifyContent.spaceBetween
                                        alignItems = AlignItems.center
                                    }
                                    fText { design = buyer }
                                    fText { design = buyerStatus }
                                }
                                Stack {
                                    spacing = responsive(2)
                                    buyerItems.map { item ->
                                        when (item) {
                                            is FormItem.ViewModel.TextInput -> FormItemTextInput { this.viewModel = item }
                                            is FormItem.ViewModel.Label -> FormItemLabel { this.viewModel = item }
                                            is FormItem.ViewModel.Checkbox -> FormItemCheckbox { this.viewModel = item }
                                            else -> {}
                                        }
                                    }
                                    fButton { design = applicationBtn; onClick = { handleOpenModal("") } }
                                }
                            }
                        }

                        mui.material.Paper {
                            Stack {
                                spacing = responsive(2)
                                sx {
                                    backgroundColor = Color(Palette.Background.paper)
                                    borderRadius = Shape.borderRadius10
                                    padding = Padding(1.rem, 1.rem)
                                    width = 100.pct
                                    marginBottom = 1.rem
                                }
                                Stack {
                                    direction = responsive(StackDirection.row)
                                    sx {
                                        justifyContent = JustifyContent.spaceBetween
                                        alignItems = AlignItems.center
                                    }
                                    fText { design = seller }
                                    fText { design = sellerStatus }
                                }
                                fForm {
                                    onSubmit = ::handleSubmit
                                    Stack {
                                        spacing = responsive(2)
                                        sellerItems.map { item ->
                                            when (item) {
                                                is FormItem.ViewModel.TextInput -> FormItemTextInput { this.viewModel = item; onChange = ::handleUpdate }
                                                is FormItem.ViewModel.Label -> FormItemLabel { this.viewModel = item; }
                                                is FormItem.ViewModel.Checkbox -> FormItemCheckbox { this.viewModel = item }
                                                else -> {}
                                            }
                                        }
                                    }
                                }
                                fForm {
                                    onSubmit = ::handleSubmit
                                    Stack {
                                        spacing = responsive(2)
                                        sellerExcessItems.map { item ->
                                            when (item) {
                                                is FormItem.ViewModel.TextInput -> FormItemTextInput { this.viewModel = item; onChange = ::handleUpdate }
                                                is FormItem.ViewModel.Label -> FormItemLabel { this.viewModel = item; }
                                                is FormItem.ViewModel.EditableItem -> formEditable { this.viewModel = item; submit = { handleOpenModal(item.paymentType) }; save = ::handleSubmit; cancel = ::handleCancel; edit = { handleEdit(item.fieldKey.rawValue) } }
                                                else -> {}
                                            }
                                        }
                                    }
                                }
                                fForm {
                                    onSubmit = ::handleSubmit
                                    Stack {
                                        spacing = responsive(2)
                                        sellerDeptItems.map { item ->
                                            when (item) {
                                                is FormItem.ViewModel.TextInput -> FormItemTextInput { this.viewModel = item; onChange = ::handleUpdate }
                                                is FormItem.ViewModel.Label -> FormItemLabel { this.viewModel = item; }
                                                is FormItem.ViewModel.EditableItem -> formEditable { this.viewModel = item; submit = { handleOpenModal(item.paymentType) }; save = ::handleSubmit; cancel = ::handleCancel; edit = { handleEdit(item.fieldKey.rawValue) } }
                                                else -> {}
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        mui.material.Paper {
                            Stack {
                                spacing = responsive(2)
                                sx {
                                    backgroundColor = Color(Palette.Background.paper)
                                    borderRadius = Shape.borderRadius10
                                    padding = Padding(1.rem, 1.rem)
                                    width = 100.pct
                                    marginBottom = 1.rem
                                }
                                fText { design = agreement }

                                fGrid { design = agreementItems }
                            }
                        }
                    }
                    Grid {
                        this.item = true
                        xs = 12
                        lg = 4
                        Stack {
                            spacing = responsive(2)

                            sx {
                                alignItems = AlignItems.start
                                gap = 0.5.rem
                            }
                            fText { design = groupStatus }
                            fSelectInput { design = status; onChange = ::handleSetValue }
                            fButton { design = save; onClick = ::handleGroupStatus }
                            Divider {
                                sx {
                                    backgroundColor = Palette.Text.primary
                                    marginTop = important(1.rem)
                                    width = 100.pct

                                }
                            }
                            fButton { design = codeBtn; onClick = { handleInvite(true) } }
                        }
                    }
                }
            }
            fToast { design = groupStatusToast; handleCloseToast = ::handleCloseToast }
            fToast { design = copyCode; handleCloseToast = ::handleCloseToast }
            fModal { design = applicationModal; onClose = ::closeModals }
            fModal { design = codeModal; onClose = ::handleInvite; onClickFirst = { copy(viewModel.texts.code) } }
            fModal { design = excessModal; onClose = ::closeModals; onClickFirst = { goToPayment(BackOfficeMattersScreen.EDITABLE.EXCESS.name) }; onClickSecond = { closeModals(false) } }
            fModal { design = residualDeptModal; onClose = ::closeModals; onClickFirst = { goToPayment(BackOfficeMattersScreen.EDITABLE.RESIDUAL_DEBT.name) }; onClickSecond = { closeModals(false) } }
        }
        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}
