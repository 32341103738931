package support

import mui.material.GridProps
import mui.material.InputBaseComponentProps
import techla.base.Either
import techla.base.Warning

// TODO: Remove when it will be implemented in MUI wrappers
inline var GridProps.xs: Int
    get() = TODO("Prop is write-only!")
    set(value) {
        asDynamic().xs = value
    }

inline var GridProps.md: Int
    get() = TODO("Prop is write-only!")
    set(value) {
        asDynamic().md = value
    }

inline var GridProps.lg: Int
    get() = TODO("Prop is write-only!")
    set(value) {
        asDynamic().lg = value
    }

inline var InputBaseComponentProps.min: Int
    get() = TODO("Prop is write-only!")
    set(value) {
        asDynamic().min = value
    }

inline var InputBaseComponentProps.max: Int
    get() = TODO("Prop is write-only!")
    set(value) {
        asDynamic().max = value
    }

val Either<List<Warning>, Throwable>.reason
    get() =
        when (this) {
            is Either.Left -> value.joinToString("\n") { it.message }
            is Either.Right -> value.message ?: ""
        }