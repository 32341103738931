package screens

import js.core.jso
import kotlinx.browser.document
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import services.*
import support.*
import techla.base.*
import techla.base.Date.Companion.dateAt
import techla.base.onNotSuccess
import techla.form.Submission
import techla.guard.Group

object DashboardScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    object Header {
        val quarter = DesignSystem.Header("quarter")
        val year = DesignSystem.Header("year")
    }

    data class Texts(
        val commitment: String,
        val business: String,
        val dashboard: String,
        val back: String,
        val logout: String,
        val type: String,
        val memberIn: String,
        val buyerPrivate: String,
        val buyerCompany: String,
        val sellerPrivate: String,
        val sellerCompany: String,
        val leasingCompany: String,
        val emptyItems: String,
        val created: String,
        val loan: String,
        val selectYear: String,
        val amount: String,
        val sek: String,
        val selectQuarter: String,
        val totalCommitments: String,
        val totalSum: String,
        val privateCommitments: String,
        val companyCommitments: String,
        val leasingCommitments: String,
        val createCSV: String,
        val q1: String,
        val q2: String,
        val q3: String,
        val q4: String,
        override val failureTitle: String,
        override val failureReason: String
    ) : FailureTexts {
        companion object
    }

    data class Count(
        val totalAmount: String,
        val totalSum: String,
        val private: String,
        val company: String,
        val leasing: String,
    )

    data class State(
        val selectedYear: DesignSystem.Option? = null,
        val years: List<DesignSystem.Option> = emptyList(),
        val quarters: List<DesignSystem.Option> = emptyList(),
        val selectedQuarter: DesignSystem.Option? = null,
        val launchYear: Int = 2023,
        val buyForms: List<String> = listOf(FK.privateBuy, FK.companyBuy, FK.leasingCompany),
        val reports: List<Submission.Report.Result> = emptyList(),
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val dashboardTitle: DesignSystem.Text,
            val totalSum: DesignSystem.Text,
            val totalCommitments: DesignSystem.Text,
            val items: DesignSystem.Table,
            val year: DesignSystem.SelectInput,
            val quarter: DesignSystem.SelectInput,
            val privateCommitments: DesignSystem.Text,
            val companyCommitments: DesignSystem.Text,
            val leasingCommitments: DesignSystem.Text,
            val createCSV: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation = navigation)

        fun ready(texts: Texts, state: State, items: Pair<List<DesignSystem.Row>, Count>) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation(
                    title = "Fordonskrediten", background = DesignSystem.Background.DARK,
                    menu = DesignSystem.Menu(
                        title = "profile name", items = listOf(
                            DesignSystem.Option.item(title = texts.logout, action = DesignSystem.Action.LOGOUT),
                        )
                    ),
                    links = listOf(
                        DesignSystem.Option.item(title = texts.business, location = Location.BackOffice),
                        DesignSystem.Option.item(title = texts.commitment, location = Location.Commitment),
                        DesignSystem.Option.item(title = texts.dashboard, location = Location.Dashboard),
                    ),
                    selectedLink = DesignSystem.Option.item(title = texts.dashboard, location = Location.Dashboard),
                    location = Location.BackOffice
                ),
                dashboardTitle = DesignSystem.Text(text = texts.dashboard, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD),
                items = DesignSystem.Table(
                    empty = texts.emptyItems,
                    body = items.first,
                    header = DesignSystem.Row(
                        cells = listOf(
                            DesignSystem.Option.item(title = texts.created),
                            DesignSystem.Option.item(title = texts.loan),
                            DesignSystem.Option.item(title = texts.memberIn),
                            DesignSystem.Option.item(title = texts.type),
                        )
                    )
                ),
                totalCommitments = DesignSystem.Text(text = listOf(texts.totalCommitments, items.second.totalAmount, texts.amount).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                totalSum = DesignSystem.Text(text = listOf(texts.totalSum, items.second.totalSum, texts.sek).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                year = DesignSystem.SelectInput(header = Header.year, label = texts.selectYear, selected = state.selectedYear, options = state.years),
                quarter = DesignSystem.SelectInput(header = Header.quarter, label = texts.selectQuarter, selected = state.selectedQuarter, options = state.quarters),
                privateCommitments = DesignSystem.Text(text = listOf(texts.privateCommitments, items.second.private, texts.amount).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                companyCommitments = DesignSystem.Text(text = listOf(texts.companyCommitments, items.second.company, texts.amount).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                leasingCommitments = DesignSystem.Text(text = listOf(texts.leasingCommitments, items.second.leasing, texts.amount).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                createCSV = DesignSystem.Button(text = texts.createCSV, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE, loading = true, disabled = items.second.totalAmount == "0"),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed

    }

    private fun isLeapYear(year: Int): Boolean {
        return (year % 4 == 0 && year % 100 != 0) || (year % 400 == 0)
    }

    private suspend fun getReports(scene: Scene.Input<ViewModel>, state: State): List<Submission.Report.Result> {
        val (store, _) = scene
        val m = state.selectedQuarter?.value?.toInt() ?: 1
        val y = state.selectedYear?.value?.toInt() ?: Date().dateTime.year
        var reportsList: MutableList<Submission.Report.Result> = mutableListOf()


        val dates = if (y == state.launchYear)
            (1..31).map { day ->
                dateAt(year = y, month = 12, day = day, hour = 23, minute = 59).toISOString() ?: ""
            }
        else (m..m.plus(2)).flatMap { month ->
            val daysInFebruary = if (month == 2 && isLeapYear(y)) 29 else 28

            when (month) {
                4, 6, 9, 11 -> (1..30).map { day ->
                    dateAt(year = y, month = month, day = day, hour = 23, minute = 59).toISOString() ?: ""
                }

                2 -> (1..daysInFebruary).map { day ->
                    dateAt(year = y, month = month, day = day, hour = 23, minute = 59).toISOString() ?: ""
                }

                else -> (1..31).map { day ->
                    dateAt(year = y, month = month, day = day, hour = 23, minute = 59).toISOString() ?: ""
                }
            }
        }
        state.buyForms.map { form ->
            val report = Submission.Report(
                form = Key(form),
                operations = listOf(
                    Submission.Operation.Value(Key("SUBMITTED")),
                    Submission.Operation.Value(Key("REGNO")),
                    Submission.Operation.Value(Key("LOAN")),
                    Submission.Operation.Include(Submission.State.Rejected),
                    Submission.Operation.Include(Submission.State.Archived),
                    Submission.Operation.Count(Key(form)),
                    Submission.Operation.Filter(
                        Key("SUBMITTED"), dates
                    ),
                ),
            )
            successfulOf(true)
            store.report(report)
                .map { (_, reports) ->
                    reportsList = (reportsList + reports).toMutableList()
                }
        }

        val sortedReportList = reportsList.sortedBy { report ->
            Date.fromISOString(report.entries.find { it.fieldKey.rawValue == "SUBMITTED" }?.text ?: "")
        }

        return sortedReportList
    }

    suspend fun load(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene
        updates.emit(sceneOf(viewModel.loading()))
        store.findMedias()
            .map { (actions) ->
                val reports = getReports(scene, viewModel.state)
                val updated = store.reduce(actions)
                val texts = Texts(
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    back = updated.get(media = Key("screen:Dashboard"), content = Key("back")),
                    type = updated.get(media = Key("screen:Dashboard"), content = Key("type")),
                    memberIn = updated.get(media = Key("screen:Dashboard"), content = Key("memberIn")),
                    logout = updated.get(media = Key("screen:Dashboard"), content = Key("logout")),
                    commitment = updated.get(media = Key("screen:Dashboard"), content = Key("commitment")),
                    business = updated.get(media = Key("screen:Dashboard"), content = Key("business")),
                    buyerPrivate = updated.get(media = Key("screen:Dashboard"), content = Key("buyerPrivate")),
                    buyerCompany = updated.get(media = Key("screen:Dashboard"), content = Key("buyerCompany")),
                    sellerPrivate = updated.get(media = Key("screen:Dashboard"), content = Key("sellerPrivate")),
                    sellerCompany = updated.get(media = Key("screen:Dashboard"), content = Key("sellerCompany")),
                    emptyItems = updated.get(media = Key("screen:Dashboard"), content = Key("emptyItems")),
                    leasingCompany = updated.get(media = Key("screen:Dashboard"), content = Key("leasingCompany")),
                    dashboard = updated.get(media = Key("screen:Dashboard"), content = Key("dashboard")),
                    created = updated.get(media = Key("screen:Dashboard"), content = Key("created")),
                    loan = updated.get(media = Key("screen:Dashboard"), content = Key("loan")),
                    selectYear = updated.get(media = Key("screen:Dashboard"), content = Key("selectYear")),
                    selectQuarter = updated.get(media = Key("screen:Dashboard"), content = Key("selectQuarter")),
                    sek = updated.get(media = Key("screen:Dashboard"), content = Key("sek")),
                    amount = updated.get(media = Key("screen:Dashboard"), content = Key("amount")),
                    totalCommitments = updated.get(media = Key("screen:Dashboard"), content = Key("totalCommitments")),
                    totalSum = updated.get(media = Key("screen:Dashboard"), content = Key("totalSum")),
                    privateCommitments = updated.get(media = Key("screen:Dashboard"), content = Key("privateCommitments")),
                    companyCommitments = updated.get(media = Key("screen:Dashboard"), content = Key("companyCommitments")),
                    leasingCommitments = updated.get(media = Key("screen:Dashboard"), content = Key("leasingCommitments")),
                    createCSV = updated.get(media = Key("screen:Dashboard"), content = Key("createCSV")),
                    q1 = updated.get(media = Key("screen:Dashboard"), content = Key("q1")),
                    q2 = updated.get(media = Key("screen:Dashboard"), content = Key("q2")),
                    q3 = updated.get(media = Key("screen:Dashboard"), content = Key("q3")),
                    q4 = updated.get(media = Key("screen:Dashboard"), content = Key("q4")),
                )

                val years = (viewModel.state.launchYear..Date().dateTime.year).toList().map { DesignSystem.Option.item(title = it.toString(), value = it.toString()) }.reversed()
                val quarters = createQuarters(texts, viewModel.state, Date().dateTime.year)
                val state = viewModel.state.copy(
                    years = years,
                    selectedYear = years.firstOrNull(),
                    quarters = quarters,
                    selectedQuarter = quarters.firstOrNull(),
                    reports = reports,
                )

                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = texts, state = state, buildGrid(state, texts)), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }

    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        return load(scene)
    }

    private fun createQuarters(texts: Texts, state: State, year: Int): List<DesignSystem.Option> {
        val first = dateAt(year = year, month = 1, day = 1, hour = 23, minute = 59).toISOString()
        val second = dateAt(year = year, month = 4, day = 1, hour = 23, minute = 59).toISOString()
        val third = dateAt(year = year, month = 7, day = 1, hour = 23, minute = 59).toISOString()
        val forth = dateAt(year = year, month = 10, day = 1, hour = 23, minute = 59).toISOString()

        return if (state.launchYear == year)
            listOf(
                DesignSystem.Option.item(title = listOf(texts.q4 + dateAt(year = year, month = 12, day = 1, hour = 23, minute = 59).toISOString()?.substringBefore("T")).joinToString(" "), value = "12")
            )
        else
            listOf(
                DesignSystem.Option.item(title = listOf(texts.q1, first?.substringBefore("T")).joinToString(" "), value = "1"),
                DesignSystem.Option.item(title = listOf(texts.q2, second?.substringBefore("T")).joinToString(" "), value = "4"),
                DesignSystem.Option.item(title = listOf(texts.q3, third?.substringBefore("T")).joinToString(" "), value = "7"),
                DesignSystem.Option.item(title = listOf(texts.q4 + forth?.substringBefore("T")).joinToString(" "), value = "10")
            )
    }

    suspend fun setYear(scene: Scene.Input<ViewModel>, year: DesignSystem.Option? = null) {
        val (_, viewModel) = scene
        val quarters = createQuarters(viewModel.texts, viewModel.state, year?.value?.toInt() ?: viewModel.state.launchYear)
        val state = viewModel.state.copy(selectedYear = year ?: viewModel.state.selectedYear, quarters = quarters, selectedQuarter = null, reports = emptyList())
        updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, buildGrid(state, viewModel.texts))))
    }

    suspend fun setQuarter(scene: Scene.Input<ViewModel>, quarter: DesignSystem.Option? = null) {
        val (_, viewModel) = scene
        val quarters = createQuarters(viewModel.texts, viewModel.state, viewModel.state.selectedYear?.value?.toInt() ?: viewModel.state.launchYear)
        val state = viewModel.state.copy(quarters = quarters, selectedQuarter = quarter ?: viewModel.state.selectedQuarter)
        val reports = getReports(scene, state)

        updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state.copy(reports = reports), buildGrid(state.copy(reports = reports), viewModel.texts))))
    }

    suspend fun createCVG(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene

        val m = viewModel.state.selectedQuarter?.value?.toInt() ?: 1
        val y = viewModel.state.selectedYear?.value?.toInt() ?: Date().dateTime.year

        val from = dateAt(year = y, month = m, day = 1, hour = 23, minute = 59).toISOString()?.substringBefore("T") ?: ""
        val to = if (viewModel.state.launchYear == y)
            dateAt(year = y, month = 12, day = 31, hour = 23, minute = 59).toISOString()?.substringBefore("T") ?: ""
        else
            when (val month = m.plus(2)) {
                4, 6, 9, 11 -> dateAt(year = y, month = month, day = 30, hour = 23, minute = 59).toISOString()?.substringBefore("T") ?: ""
                2 -> dateAt(year = y, month = month, day = if (isLeapYear(y)) 29 else 28, hour = 23, minute = 59).toISOString()?.substringBefore("T") ?: ""
                else -> dateAt(year = y, month = month, day = 31, hour = 23, minute = 59).toISOString()?.substringBefore("T") ?: ""
            }

        successfulOf(true)
            .map {
                val builder = StringBuilder()
                when {
                    viewModel.state.reports.isNotEmpty() -> {
                        viewModel.state.reports.mapIndexed { ind, report ->

                            if (ind == 0) {
                                report.entries.forEachIndexed { index, field ->
                                    if (ind == 0)
                                        if (index > 0) builder.append(";")
                                    val label = when (field.fieldKey.rawValue) {
                                        "LOAN" -> viewModel.texts.loan
                                        "SUBMITTED" -> viewModel.texts.created
                                        "REGNO" -> viewModel.texts.memberIn
                                        FK.privateBuy, FK.companyBuy, FK.leasingCompany -> viewModel.texts.type
                                        else -> ""
                                    }
                                    builder.append("\"$label\"")
                                }
                                builder.append("\r\n")
                            }

                            report.entries.forEachIndexed { index, field ->
                                if (index > 0) builder.append(";")
                                val value = when (field.fieldKey.rawValue) {
                                    "LOAN" -> listOf(formatNumber(field.text.toDouble()), viewModel.texts.sek).joinToString(" ")
                                    "SUBMITTED" -> field.text.substringBefore("T")
                                    else ->
                                        when (field.fieldKey.rawValue) {
                                            FK.privateBuy -> viewModel.texts.buyerPrivate
                                            FK.companyBuy -> viewModel.texts.buyerCompany
                                            FK.leasingCompany -> viewModel.texts.leasingCompany
                                            else -> field.text
                                        }
                                }
                                builder.append("\"$value\"")
                            }
                            builder.append("\r\n")
                        }
                        builder.append("\r\n")
                    }
                }

                val blob = Blob(arrayOf(builder.toString()), jso { type = "text/csv;charset=utf-8;" })
                val url = URL.createObjectURL(blob)
                val a = document.createElement("a") as HTMLAnchorElement
                a.href = url
                a.download = "fordonskrediten-$from-$to.csv"
                a.click()

                URL.revokeObjectURL(url)

                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = viewModel.state, buildGrid(viewModel.state, viewModel.texts))))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }


    private fun buildGrid(state: State, texts: Texts): Pair<List<DesignSystem.Row>, Count> {
        var totalSum = 0.0
        var private = 0.0
        var company = 0.0
        var leasing = 0.0
        val rows = state.reports.map { report ->
            val x = report.entries.find { it.fieldKey.rawValue == "LOAN" }?.text?.trim()?.toDouble() ?: 0.0
            totalSum = totalSum.plus(x)

            val date = report.entries.find { it.fieldKey.rawValue == "SUBMITTED" }?.text?.substringBefore("T")
            DesignSystem.Row(
                cells = listOf(
                    DesignSystem.Option.item(title = date),
                    DesignSystem.Option.item(title = listOf(formatNumber(x), texts.sek).joinToString(" ")),
                    DesignSystem.Option.item(title = report.entries.find { it.fieldKey.rawValue == "REGNO" }?.text ?: "-"),
                    DesignSystem.Option.item(
                        title = when (report.entries.find { state.buyForms.contains(it.fieldKey.rawValue) }?.fieldKey?.rawValue) {
                            FK.privateBuy -> {
                                private += 1
                                texts.buyerPrivate
                            }

                            FK.companyBuy -> {
                                company += 1
                                texts.buyerCompany
                            }

                            FK.leasingCompany -> {
                                leasing += 1
                                texts.leasingCompany
                            }

                            else -> "-"
                        },
                    ),
                )
            )
        }

        return Pair(
            rows, Count(
                totalAmount = formatNumber(rows.size),
                totalSum = formatNumber(totalSum),
                private = formatNumber(private),
                company = formatNumber(company),
                leasing = formatNumber(leasing)
            )
        )
    }
}