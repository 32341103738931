package screens

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableSharedFlow
import services.*
import support.*
import techla.base.*
import techla.guard.*
import kotlin.time.Duration.Companion.seconds

object LoginScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    object Header {
        val govId = DesignSystem.Header("govId")
    }

    data class Texts(
        val loginButton: String,
        val govIdLabel: String,
        val privateTitle: String,
        val scanQr: String,
        val openApp: String,
        val back: String,
        val govIdLength: String,
        val fieldRequired: String,
        val illegalCharacters: String,
        val readyTitle: String,
        val backOfficeTitle: String,
        val termBody: String,
        val policy: String,
        val terms: String,
        val expireTitle: String,
        val expireBody: String,
        val resume: String,
        override val failureTitle: String,
        override val failureReason: String
    ) : FailureTexts {
        companion object
    }

    data class State(
        val govId: String,
        val version: String,
        val currentPath: String,
        val goToUrl: String,
        val customerType: CustomerType = CustomerType.None
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State("", "", "", ""),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class ReadyApply(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val loginButton: DesignSystem.Button,
            val govId: DesignSystem.TextInput,
            val applyTitle: DesignSystem.Text,
            val scanQr: DesignSystem.Text,
            val openApp: DesignSystem.Text,
            val termBody: DesignSystem.TextWithLinks,
            val coin: DesignSystem.ImageView,
            val version: DesignSystem.Text,
            val goToUrl: String,
        ) : ViewModel(texts, state, navigation)

        data class ReadyLogin(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val loginButton: DesignSystem.Button,
            val govId: DesignSystem.TextInput,
            val readyTitle: DesignSystem.Text,
            val scanQr: DesignSystem.Text,
            val openApp: DesignSystem.Text,
            val termBody: DesignSystem.TextWithLinks,
            val version: DesignSystem.Text,
            val goToUrl: String,
        ) : ViewModel(texts, state, navigation)

        data class Started(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Finished(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val goToUrl: String,
        ) : ViewModel(texts, state, navigation)

        data class Expired(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val resume: DesignSystem.Button = DesignSystem.Button(),
            val expireTitle: DesignSystem.Text,
            val expireBody: DesignSystem.Text,
            val expireOut: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation)

        fun readyApply(texts: Texts, state: State, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()) =
            ReadyApply(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.FORDONSKREDITEN_HOME),
                loginButton = DesignSystem.Button(text = texts.loginButton, type = DesignSystem.Button.Type.SUBMIT),
                govId = DesignSystem.TextInput(
                    header = Header.govId,
                    label = texts.govIdLabel,
                    value = state.govId,
                    status = status.statusOf(Header.govId)
                ),
                applyTitle = DesignSystem.Text(text = texts.privateTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                scanQr = DesignSystem.Text(text = texts.scanQr, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                openApp = DesignSystem.Text(text = texts.openApp, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                termBody = DesignSystem.TextWithLinks(text = texts.termBody, links = listOf(texts.policy to "${deployment.home}/doc/fordonskrediten_policy.pdf", texts.terms to "${deployment.home}/doc/fordonskrediten_villkor.pdf")),
                coin = DesignSystem.ImageView(image = DesignSystem.Image.COINS),
                version = DesignSystem.Text(text = state.version, size = DesignSystem.SizeType.XS, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
                goToUrl = state.goToUrl
            )

        fun readyLogin(texts: Texts, state: State, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()) =
            ReadyLogin(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.FORDONSKREDITEN_HOME),
                loginButton = DesignSystem.Button(text = texts.loginButton, type = DesignSystem.Button.Type.SUBMIT),
                govId = DesignSystem.TextInput(
                    header = Header.govId,
                    label = texts.govIdLabel,
                    value = state.govId,
                    status = status.statusOf(Header.govId)
                ),
                readyTitle = DesignSystem.Text(text = if (state.currentPath == Routes.loginBackOffice) texts.backOfficeTitle else texts.readyTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                scanQr = DesignSystem.Text(text = texts.scanQr, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                openApp = DesignSystem.Text(text = texts.openApp, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                termBody = DesignSystem.TextWithLinks(text = texts.termBody, links = listOf(texts.policy to "${deployment.home}/doc/fordonskrediten_policy.pdf", texts.terms to "${deployment.home}/doc/fordonskrediten_villkor.pdf"), visible = state.currentPath != Routes.loginBackOffice),
                version = DesignSystem.Text(text = state.version, size = DesignSystem.SizeType.XS, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
                goToUrl = state.goToUrl
            )

        fun finished(state: State) =
            Finished(
                texts = texts, state = state, navigation = navigation, goToUrl = state.goToUrl
            )

        fun started() =
            Started(texts = texts, state = state, navigation = navigation)

        fun expired(texts: Texts): ViewModel =
            Expired(
                texts = texts,
                state = state,
                navigation = navigation,
                expireTitle = DesignSystem.Text(text = texts.expireTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                expireBody = DesignSystem.Text(text = texts.expireBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                expireOut = DesignSystem.ImageView(image = DesignSystem.Image.EXPIREIMAGE),
                resume = DesignSystem.Button(text = texts.resume),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = navigation,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReadyApply get() = this as? ReadyApply

        val asReadyLogin get() = this as? ReadyLogin
        val asStarted get() = this as? Started
        val asFinished get() = this as? Finished
        val asExpired get() = this as? Expired
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.expired(texts: Texts) =
        sceneOf(viewModel.expired(texts))

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))


    private suspend fun renderLoginView(url: String? = null, scene: Scene.Input<ViewModel>, state: State, texts: Texts, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList(), actions: List<Store.Action>) {
        val (_, viewModel) = scene
        val loginUrl = url ?: state.currentPath
        when (loginUrl) {
            Routes.loginBackOffice -> updates.emit(sceneOf<ViewModel>(viewModel.readyLogin(texts = texts, state = state, status = status), actions))
            Routes.loginApplyPrivate, Routes.loginApplyCompany, Routes.loginApplyLeasing -> updates.emit(sceneOf<ViewModel>(viewModel.readyApply(texts = texts, state = state, status = status), actions))
            else -> updates.emit(sceneOf<ViewModel>(viewModel.readyLogin(texts = texts, state = state, status = status), actions))
        }
    }

    private suspend fun renderLoginView(url: String? = null, scene: Scene.Input<ViewModel>, state: State, texts: Texts, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList(), action: Store.Action) =
        renderLoginView(url, scene, state, texts, status, listOf(action))


    private suspend fun renderLoginView(url: String? = null, scene: Scene.Input<ViewModel>, state: State, texts: Texts, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()) {
        val (_, viewModel) = scene
        val loginUrl = url ?: state.currentPath
        when (loginUrl) {
            Routes.loginBackOffice -> updates.emit(sceneOf<ViewModel>(viewModel.readyLogin(texts = texts, state = state, status = status)))
            Routes.loginApplyPrivate, Routes.loginApplyCompany, Routes.loginApplyLeasing -> updates.emit(sceneOf<ViewModel>(viewModel.readyApply(texts = texts, state = state, status = status)))
            else -> updates.emit(sceneOf<ViewModel>(viewModel.readyLogin(texts = texts, state = state, status = status)))
        }
    }

    suspend fun load(scene: Scene.Input<ViewModel>, url: String) {
        val (store, viewModel) = scene
        val customerType = CustomerType.fromRawValue(url.substringAfter("-"))

        updates.emit(sceneOf(viewModel.loading()))

        successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).createUserAuthentication()
                    .accumulate(actions)
            }
            .flatMap { (actions, auth) ->
                if (auth.tokens.filterIsInstance<Token.QR>().firstOrNull()?.code != null) {
                    val action = Store.Action.UserAuthenticationStarted(
                        userAuthenticationId = auth.index.id,
                        tokens = auth.tokens
                    )
                    successfulOf(tupleOf(actions + action, auth))
                } else {
                    val action = Store.Action.UserAuthenticationCancelled(
                        userAuthenticationId = auth.index.id,
                    )
                    store.reduce(actions + action).deleteUserAuthentication()
                        .accumulate(actions + action)
                        .map { tupleOf(it.first, null) }
                }
            }
            .flatMap { (actions, auth) ->
                store.reduce(actions).findMedias()
                    .accumulate(actions)
                    .map { tupleOf(it.first, auth, it.second) }
            }
            .map { (actions, userAuthentication) ->
                val updated = store.reduce(actions)

                val texts = Texts(
                    govIdLabel = updated.get(media = Key("screen:login"), content = Key("govIdLabel")),
                    loginButton = updated.get(media = Key("screen:login"), content = Key("loginButton")),
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    privateTitle = updated.get(media = Key("screen:login"), content = Key("privateTitle")),
                    //   leasingBody = updated.get(media = Key("screen:login"), content = Key("leasingBody")),
                    //   body = updated.get(media = Key("screen:login"), content = Key("body")),
                    scanQr = updated.get(media = Key("screen:login"), content = Key("scanQr")),
                    openApp = updated.get(media = Key("screen:login"), content = Key("openApp")),
                    back = updated.get(media = Key("screen:login"), content = Key("back")),
                    govIdLength = updated.get(media = Key("screen:login"), content = Key("govIdLength")),
                    fieldRequired = updated.get(media = Key("screen:login"), content = Key("fieldRequired")),
                    illegalCharacters = updated.get(media = Key("screen:login"), content = Key("illegalCharacters")),
                    readyTitle = updated.get(media = Key("screen:login"), content = Key("readyTitle")),
                    backOfficeTitle = updated.get(media = Key("screen:login"), content = Key("backOfficeTitle")),
                    termBody = updated.get(media = Key("screen:login"), content = Key("termBody")),
                    policy = updated.get(media = Key("screen:login"), content = Key("policy")),
                    terms = updated.get(media = Key("screen:login"), content = Key("terms")),
                    expireTitle = updated.get(media = Key("screen:login"), content = Key("expireTitle")),
                    expireBody = updated.get(media = Key("screen:login"), content = Key("expireBody")),
                    resume = updated.get(media = Key("screen:login"), content = Key("resume")),
                )

                val state = viewModel.state.copy(
                    customerType = customerType,
                    version = store.version,
                    currentPath = url,
                    goToUrl = when (url) {
                        Routes.loginApplyPrivate, Routes.loginApplyCompany, Routes.loginApplyLeasing -> "${Routes.apply}/${customerType.rawValue}"
                        Routes.loginMatters -> Routes.matters
                        Routes.loginBackOffice -> Routes.backOffice
                        else -> Routes.matters
                    }
                )

                renderLoginView(url = url, scene = scene, state = state, texts = texts, actions = actions)

                if (userAuthentication?.tokens?.filterIsInstance<Token.QR>()?.firstOrNull()?.code != null) {
                    do {
                        val (auth2, error, warnings) = store.reduce(
                            actions + Store.Action.UserAuthenticationStarted(
                                userAuthenticationId = userAuthentication.index.id,
                                tokens = userAuthentication.tokens
                            )
                        ).getUserAuthentication().onNotSuccess {
                            updates.emit(
                                scene.expired(
                                    texts = viewModel.texts.copy(
                                        expireTitle = "Tiden för inloggning har gått ut",
                                        expireBody = "Ladda om sidan eller tryck på knappen nedan för att påbörja igen.",
                                        resume = "Försök igen",
                                    )
                                )
                            )
                        }
                        val (actions2, auth) = auth2!!
                        when (auth.status) {
                            is UserAuthentication.Status.Complete -> {
                                val updated2 = actions2 +
                                        Store.Action.UserAuthenticationCompleted(
                                            tokens = auth.tokens,
                                            profileId = auth.profileId!!,
                                            govId = auth.govId!!,
                                            customerType = state.customerType
                                        )
                                if (state.currentPath == Routes.loginBackOffice && auth.tokens.filterIsInstance<Token.Admin>().firstOrNull()?.token == null)
                                    return updates.emit(scene.failed(message = "You are not a admin"))

                                updates.emit(sceneOf(viewModel.finished(state), updated2))
                            }

                            is UserAuthentication.Status.Outstanding -> {
                                val action = Store.Action.UserAuthenticationStarted(
                                    userAuthenticationId = auth.index.id,
                                    tokens = auth.tokens
                                )
                                renderLoginView(scene = scene, state = state, texts = texts, action = action)
                            }

                            is UserAuthentication.Status.Cancelled ->
                                renderLoginView(scene = scene, state = viewModel.state, texts = texts)

                            is UserAuthentication.Status.Expired ->
                                updates.emit(scene.expired(texts))

                            is UserAuthentication.Status.Failed ->
                                updates.emit(scene.failed(auth.reason ?: "Unknown Error"))

                            else -> {
                                if (error is TechlaError.ServiceUnavailable)
                                    updates.emit(
                                        scene.expired(
                                            texts = viewModel.texts.copy(
                                                expireTitle = "Tiden för inloggning har gått ut",
                                                expireBody = "Ladda om sidan eller tryck på knappen nedan för att påbörja igen.",
                                                resume = "Försök igen",
                                            )
                                        )
                                    )
                                //updates.emit(scene.expired(texts))
                                else if (warnings != null)
                                    updates.emit(scene.failed(leftOf(warnings)))
                                else if (error != null)
                                    updates.emit(scene.failed(rightOf(error)))
                                else
                                    updates.emit(scene.failed("Unknown Error"))
                            }
                        }

                        delay(1.seconds)
                    } while (auth != null && auth.status !is UserAuthentication.Status.Complete)
                }

            }.onNotSuccess {
                if (it.rightOrNull() is TechlaError.ServiceUnavailable)
                    updates.emit(
                        scene.expired(
                            texts = viewModel.texts.copy(
                                expireTitle = "Tiden för inloggning har gått ut",
                                expireBody = "Ladda om sidan eller tryck på knappen nedan för att påbörja igen.",
                                resume = "Försök igen",
                            )
                        )
                    )
                else
                    updates.emit(scene.failed(it))
            }
    }

    private suspend fun check(scene: Scene.Input<ViewModel>, s: State? = null) {
        val (store, viewModel) = scene
        val state = s ?: viewModel.state

        store.createUserAuthentication(state.govId).map { (actions, userAuthentication) ->
            val updated = actions + Store.Action.UserAuthenticationStarted(
                userAuthenticationId = userAuthentication.index.id,
                tokens = userAuthentication.tokens
            )
            updates.emit(sceneOf<ViewModel>(viewModel.started(), updated))

            do {
                val (auth2, error, warnings) = store.reduce(
                    updated + Store.Action.UserAuthenticationStarted(
                        userAuthenticationId = userAuthentication.index.id,
                        tokens = userAuthentication.tokens
                    )
                ).getUserAuthentication().onNotSuccess {
                    updates.emit(
                        scene.expired(
                            texts = viewModel.texts.copy(
                                expireTitle = "Tiden för inloggning har gått ut",
                                expireBody = "Ladda om sidan eller tryck på knappen nedan för att påbörja igen.",
                                resume = "Försök igen",
                            )
                        )
                    )
                }
                val (actions2, auth) = auth2!!

                when (auth.status) {
                    is UserAuthentication.Status.Complete -> {
                        val updated2 = actions2 +
                                Store.Action.UserAuthenticationCompleted(
                                    tokens = auth.tokens,
                                    profileId = auth.profileId!!,
                                    govId = auth.govId!!,
                                    customerType = state.customerType
                                )
                        if (state.currentPath == Routes.loginBackOffice && auth.tokens.filterIsInstance<Token.Admin>().firstOrNull()?.token == null)
                            return updates.emit(scene.failed(message = "You are not a admin"))
                        updates.emit(sceneOf(viewModel.finished(state), updated2))
                    }

                    is UserAuthentication.Status.Outstanding -> {
                        val action = Store.Action.UserAuthenticationStarted(
                            userAuthenticationId = auth.index.id,
                            tokens = auth.tokens
                        )
                        updates.emit(sceneOf<ViewModel>(viewModel.started(), action))
                    }

                    is UserAuthentication.Status.Cancelled -> {
                        renderLoginView(scene = scene, state = viewModel.state, texts = viewModel.texts)
                    }

                    is UserAuthentication.Status.Expired ->
                        updates.emit(scene.expired(viewModel.texts))


                    is UserAuthentication.Status.Failed -> {
                        updates.emit(scene.failed(auth.reason ?: "Unknown Error"))
                    }

                    else -> {
                        if (error is TechlaError.ServiceUnavailable)
                            updates.emit(scene.expired(viewModel.texts))
                        else if (warnings != null)
                            updates.emit(scene.failed(leftOf(warnings)))
                        else if (error != null)
                            updates.emit(scene.failed(rightOf(error)))
                        else
                            updates.emit(scene.failed("Unknown Error"))
                    }
                }

                delay(1.seconds)
            } while (auth != null && auth.status !is UserAuthentication.Status.Complete)
        }.onNotSuccess {
            if (it.rightOrNull() is TechlaError.ServiceUnavailable)
                updates.emit(scene.expired(viewModel.texts))
            else
                updates.emit(scene.failed(it))
        }
    }

    suspend fun login(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        var state = viewModel.state
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()

        Validator.govId(
            govId = state.govId,
            illegalCharacters = {
                status.add(Header.govId to DesignSystem.Status.Invalid(viewModel.texts.illegalCharacters))
            },
            isEmpty = { status.add(Header.govId to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired)) },
            isWrongLength = { status.add(Header.govId to DesignSystem.Status.Invalid(viewModel.texts.govIdLength)) },
            formatted = { state = state.copy(govId = it) },
            passed = { status.add(Header.govId to DesignSystem.Status.Valid) },
        )

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            renderLoginView(scene = scene, state = state, texts = viewModel.texts, status = status)
        else
            check(scene, state)
    }

    suspend fun setValues(scene: Scene.Input<ViewModel>, govId: String? = null) {
        val (_, viewModel) = scene
        renderLoginView(scene = scene, state = viewModel.state.copy(govId = govId ?: viewModel.state.govId), texts = viewModel.texts)
    }

    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        return load(scene, viewModel.state.currentPath)
    }

    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }
}


