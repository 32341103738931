package components.items

import components.*
import mui.material.Box
import mui.material.Stack
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.DesignSystem
import support.FormItem
import web.cssom.*


external interface FormSelectInputProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.SelectInput
    var onChange: ((id: String, value: String) -> Unit)?
}


val FormItemSelectInput = FC<FormSelectInputProps> { props ->

    fun onChange(inputValue: DesignSystem.Option) {
        inputValue.value?.let { props.onChange?.let { it1 -> it1(props.viewModel.fieldKey.rawValue, it) } }
    }
    if (props.viewModel.visible) {
        Box {
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                justifyContent = JustifyContent.spaceBetween
            }
            Stack {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.flexStart
                    width = 100.pct
                }
                fSelectInput { design = props.viewModel.value; onChange = ::onChange }
            }

            if (props.viewModel.info != null)
                props.viewModel.info.let {
                    Box {
                        sx {
                            marginLeft = 1.rem
                        }
                        fInfoButton {
                            if (it != null) {
                                infoText = it
                            }
                        }
                    }
                }
        }
    }
}