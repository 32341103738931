package screens

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import support.FormItem
import support.fPlaceholder
import services.*
import support.*
import techla.agreement.Agreement
import techla.base.*
import techla.base.onNotSuccess
import techla.form.Field
import techla.form.Form
import techla.form.Submission
import techla.guard.Group
import techla.guard.Profile
import kotlin.math.ceil
import kotlin.math.pow
import kotlin.math.roundToInt

object ApplyScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    data class Texts(
        val vehicleInformation: String,
        val next: String,
        val finish: String,
        val apply: String,
        val sek: String,
        val vehicleTypes: String,
        val repaymentMonth: String,
        val employmentMonth: String,
        val employment: String,
        val excludeEmployment: String,
        val minChildren: String,
        val maxChildren: String,
        val moreChildren: String,
        val employmentSinceYear: String,
        val back: String,
        val vehicle: String,
        val background: String,
        val other: String,
        val fieldRequired: String,
        val emailFormat: String,
        val approvedTitle: String,
        val approveBody: String,
        val deniedTitle: String,
        val deniedBody: String,
        val postalCodeWrongLength: String,
        val phoneIllegalCharacters: String,
        val downPaymentIsLess: String,
        val postalCodeIllegalCharacters: String,
        val illegalCharacters: String,
        val km: String,
        val vehicleCondition: String,
        val residentType: String,
        val fkHome: String,
        val interestRate: String,
        val hk: String,
        val cost: String,
        val maxWeight: String,
        val maxPower: String,
        val lowAllWarrantyOptions: String,
        val powerHighAllWarrantyOptions: String,
        val fkFee: String,
        val discountCodeTitle: String,
        val discountCode: String,
        val summaryLoanInfo: String,
        val summaryTryWarrantyInfo: String,
        val summaryWarrantyInfo: String,
        val summaryBankBody: String,
        val summaryLoanBody: String,
        val kg: String,
        val downPaymentIsMore: String,
        val residualValueInfo: String,
        val percent: String,
        val resiualBoudnIsLess: String,
        val resiualBoudnIsMore: String,
        val wasa: String,
        val interest: String,
        val residualValueTitle: String,
        val agreedPrice: String,
        val summaryLoan: String,
        val summaryCashStake: String,
        val summaryFkFee: String,
        val installmentLeasing: String,
        val installmentBuy: String,
        val loanAmount: String,
        val monthCost: String,
        val servicesIncludedTitle: String,
        val tryWarranty: String,
        val countExample: String,
        val illegalCode: String,
        val discount: String,
        val summaryFkDiscount: String,
        val click: String,
        val pepHelp: String,
        val readMore: String,
        val govIdLength: String,
        val fuelType: String,
        val electricCarWarrantyOptions: String,
        val warrantyPDF: String,
        val warrantyPDFHere: String,
        val tryGuarantee3monthLabel: String,
        val tryGuarantee1monthLabel: String,
        val tryGuarantee3monthHint: String,
        val tryGuarantee1monthHint: String,
        val tryGuaranteeMake: String,
        val summaryDisclaimerWarranty: String,
        override val failureTitle: String,
        override val failureReason: String
    ) : FailureTexts {
        companion object
    }

    data class FieldValues(
        val fieldKey: Key<Field>,
        val text: String,
        val modified: Boolean = false
    )

    data class State(
        val page: Int = 1,
        val lastPage: Int = 0,
        val form: Form? = null,
        val formId: Identifier<Submission>? = null,
        val entries: List<Submission.Entry> = emptyList(),
        val fieldValues: List<FieldValues> = emptyList(),
        val enableSources: List<Form.Source> = emptyList(),
        val status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList(),
        val obj: Object = Object.None,
        val startCheck: Boolean = false,
        val interestRateAndRepaymentMonth: String = "",
        val suggestCashStake: Boolean = true,
        val customerType: CustomerType = CustomerType.None
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val next: DesignSystem.Button,
            val back: DesignSystem.Button,
            val logout: DesignSystem.Button,
            val submit: DesignSystem.Button,
            val stepper: DesignSystem.Stepper,
            val items: List<FormItem.ViewModel>
        ) : ViewModel(texts, state, navigation)

        data class Approve(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val approveTitle: DesignSystem.Text,
            val approveBody: DesignSystem.Text,
            val next: DesignSystem.Button,
            val objectId: String,
            val submissionId: String,
            val customerType: String,
            val check: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation)

        data class Denied(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val deniedTitle: DesignSystem.Text,
            val deniedBody: DesignSystem.Text,
            val next: DesignSystem.Button,
            val fkHome: String,
            val nogo: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation)

        data class Done(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val objectId: String,
            val submissionId: String,
            val startCheck: Boolean,
            val back: Boolean,
            val customerType: String,
            val routing: String
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        data class Logout(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val goTo: String,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation = navigation)

        fun ready(texts: Texts, state: State, formItems: List<FormItem.ViewModel>) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.PREVIOUS, location = Location.Matters),
                submit = DesignSystem.Button(text = texts.finish, type = DesignSystem.Button.Type.SUBMIT, visible = state.page == state.lastPage, loading = true),
                next = DesignSystem.Button(text = if (state.page == state.lastPage - 1) texts.apply else texts.next, type = DesignSystem.Button.Type.BUTTON, visible = state.page != state.lastPage, loading = true),
                back = DesignSystem.Button(text = texts.back, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.TEXT),
                logout = DesignSystem.Button(text = texts.back, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.TEXT, visible = state.page == 1),
                stepper = DesignSystem.Stepper(
                    active = state.page, visible = state.page != state.lastPage,
                    steps = if (state.customerType != CustomerType.Private) listOf(
                        DesignSystem.Option.item(title = texts.vehicle),
                        DesignSystem.Option.item(title = texts.other)
                    ) else listOf(
                        DesignSystem.Option.item(title = texts.vehicle),
                        DesignSystem.Option.item(title = texts.background),
                        DesignSystem.Option.item(title = texts.other)
                    )
                ),
                items = formItems,
            )

        fun approve(state: State) =
            Approve(
                texts = texts,
                state = state ?: this.state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.PREVIOUS, location = Location.Matters),
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON, disabled = false),
                approveTitle = DesignSystem.Text(text = texts.approvedTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.SUCCESS),
                approveBody = DesignSystem.Text(text = texts.approveBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
                objectId = state.obj.id.rawValue,
                submissionId = state.formId?.rawValue ?: "",
                check = DesignSystem.ImageView(image = DesignSystem.Image.OK),
                customerType = state.customerType.rawValue,
            )

        fun denied() =
            Denied(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON),
                deniedTitle = DesignSystem.Text(text = texts.deniedTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.DANGER),
                deniedBody = DesignSystem.Text(text = texts.deniedBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
                nogo = DesignSystem.ImageView(image = DesignSystem.Image.NOGO),
                fkHome = texts.fkHome
            )

        fun done(state: State, back: Boolean = false, routing: String = "") =
            Done(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                objectId = if (state.obj != Object.None) state.obj.id.rawValue else "",
                submissionId = state.formId?.rawValue ?: "",
                startCheck = state.startCheck,
                back = back,
                customerType = state.customerType.rawValue,
                routing = routing,
            )

        fun logout(goTo: String) =
            Logout(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                goTo = goTo,
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = true): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asApproved get() = this as? Approve
        val asDenied get() = this as? Denied
        val asDone get() = this as? Done
        val asLogout get() = this as? Logout
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))

    private suspend fun loadText(store: Store) =
        store.findMedias()
            .map { (actions, _) ->
                val updated = store.reduce(actions)
                val texts = Texts(
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    vehicleInformation = updated.get(media = Key("screen:apply"), content = Key("vehicleInformation")),
                    next = updated.get(media = Key("screen:apply"), content = Key("next")),
                    apply = updated.get(media = Key("screen:apply"), content = Key("apply")),
                    sek = updated.get(media = Key("screen:apply"), content = Key("sek")),
                    finish = updated.get(media = Key("screen:apply"), content = Key("finish")),
                    vehicleTypes = updated.get(media = Key("screen:apply"), content = Key("vehicleTypes")),
                    repaymentMonth = "",
                    employmentMonth = updated.get(media = Key("screen:apply"), content = Key("employmentMonth")),
                    employment = updated.get(media = Key("screen:apply"), content = Key("employment")),
                    excludeEmployment = updated.get(media = Key("screen:apply"), content = Key("excludeEmployment")),
                    minChildren = updated.get(media = Key("screen:apply"), content = Key("minChildren")),
                    maxChildren = updated.get(media = Key("screen:apply"), content = Key("maxChildren")),
                    moreChildren = updated.get(media = Key("screen:apply"), content = Key("moreChildren")),
                    employmentSinceYear = updated.get(media = Key("screen:apply"), content = Key("employmentSinceYear")),
                    back = updated.get(media = Key("screen:apply"), content = Key("back")),
                    vehicle = updated.get(media = Key("screen:apply"), content = Key("vehicle")),
                    background = updated.get(media = Key("screen:apply"), content = Key("background")),
                    other = updated.get(media = Key("screen:apply"), content = Key("other")),
                    fieldRequired = updated.get(media = Key("screen:apply"), content = Key("fieldRequired")),
                    emailFormat = updated.get(media = Key("screen:apply"), content = Key("emailFormat")),
                    approvedTitle = updated.get(media = Key("screen:apply"), content = Key("approvedTitle")),
                    approveBody = updated.get(media = Key("screen:apply"), content = Key("approveBody")),
                    deniedTitle = updated.get(media = Key("screen:apply"), content = Key("deniedTitle")),
                    deniedBody = updated.get(media = Key("screen:apply"), content = Key("deniedBody")),
                    postalCodeWrongLength = updated.get(media = Key("screen:apply"), content = Key("postalCodeWrongLength")),
                    phoneIllegalCharacters = updated.get(media = Key("screen:apply"), content = Key("phoneIllegalCharacters")),
                    downPaymentIsLess = updated.get(media = Key("screen:apply"), content = Key("downPaymentIsLess")),
                    downPaymentIsMore = updated.get(media = Key("screen:apply"), content = Key("downPaymentIsMore")),
                    resiualBoudnIsLess = updated.get(media = Key("screen:apply"), content = Key("resiualBoudnIsLess")),
                    resiualBoudnIsMore = updated.get(media = Key("screen:apply"), content = Key("resiualBoudnIsMore")),
                    postalCodeIllegalCharacters = updated.get(media = Key("screen:apply"), content = Key("postalCodeIllegalCharacters")),
                    illegalCharacters = updated.get(media = Key("screen:apply"), content = Key("illegalCharacters")),
                    km = updated.get(media = Key("screen:apply"), content = Key("km")),
                    vehicleCondition = updated.get(media = Key("screen:apply"), content = Key("vehicleCondition")),
                    residentType = updated.get(media = Key("screen:apply"), content = Key("residentType")),
                    fkHome = updated.deployment.home,
                    interestRate = "",
                    hk = updated.get(media = Key("screen:apply"), content = Key("hk")),
                    cost = updated.get(media = Key("screen:apply"), content = Key("cost")),
                    maxWeight = updated.get(media = Key("screen:apply"), content = Key("maxWeight")),
                    maxPower = updated.get(media = Key("screen:apply"), content = Key("maxPower")),
                    lowAllWarrantyOptions = updated.get(media = Key("screen:apply"), content = Key("lowAllWarrantyOptions")),
                    powerHighAllWarrantyOptions = updated.get(media = Key("screen:apply"), content = Key("powerHighAllWarrantyOptions")),
                    fkFee = updated.get(media = Key("screen:apply"), content = Key("fkFee")),
                    discountCodeTitle = updated.get(media = Key("screen:apply"), content = Key("discountCodeTitle")),
                    discountCode = updated.get(media = Key("screen:apply"), content = Key("discountCode")),
                    summaryLoanInfo = updated.get(media = Key("screen:apply"), content = Key("summaryLoanInfo")),
                    summaryTryWarrantyInfo = updated.get(media = Key("screen:apply"), content = Key("summaryTryWarrantyInfo")),
                    summaryWarrantyInfo = updated.get(media = Key("screen:apply"), content = Key("summaryWarrantyInfo")),
                    summaryBankBody = updated.get(media = Key("screen:apply"), content = Key("summaryBankBody")),
                    summaryLoanBody = updated.get(media = Key("screen:apply"), content = Key("summaryLoanBody")),
                    kg = updated.get(media = Key("screen:apply"), content = Key("kg")),
                    residualValueInfo = updated.get(media = Key("screen:apply"), content = Key("residualValueInfo")),
                    percent = updated.get(media = Key("screen:apply"), content = Key("percent")),
                    wasa = updated.get(media = Key("screen:apply"), content = Key("wasa")),
                    interest = updated.get(media = Key("screen:apply"), content = Key("interest")),
                    residualValueTitle = updated.get(media = Key("screen:apply"), content = Key("residualValueTitle")),
                    agreedPrice = updated.get(media = Key("screen:apply"), content = Key("agreedPrice")),
                    summaryLoan = updated.get(media = Key("screen:apply"), content = Key("summaryLoan")),
                    summaryCashStake = updated.get(media = Key("screen:apply"), content = Key("summaryCashStake")),
                    summaryFkFee = updated.get(media = Key("screen:apply"), content = Key("summaryFkFee")),
                    installmentBuy = updated.get(media = Key("screen:apply"), content = Key("installmentBuy")),
                    installmentLeasing = updated.get(media = Key("screen:apply"), content = Key("installmentLeasing")),
                    loanAmount = updated.get(media = Key("screen:apply"), content = Key("loanAmount")),
                    monthCost = updated.get(media = Key("screen:apply"), content = Key("monthCost")),
                    servicesIncludedTitle = updated.get(media = Key("screen:apply"), content = Key("servicesIncludedTitle")),
                    tryWarranty = updated.get(media = Key("screen:apply"), content = Key("tryWarranty")),
                    countExample = updated.get(media = Key("screen:apply"), content = Key("countExample")),
                    illegalCode = updated.get(media = Key("screen:apply"), content = Key("illegalCode")),
                    discount = "0",
                    summaryFkDiscount = updated.get(media = Key("screen:apply"), content = Key("summaryFkDiscount")),
                    click = updated.get(media = Key("screen:apply"), content = Key("click")),
                    pepHelp = updated.get(media = Key("screen:apply"), content = Key("pepHelp")),
                    readMore = updated.get(media = Key("screen:apply"), content = Key("readMore")),
                    govIdLength = updated.get(media = Key("screen:apply"), content = Key("govIdLength")),
                    fuelType = updated.get(media = Key("screen:apply"), content = Key("fuelType")),
                    electricCarWarrantyOptions = updated.get(media = Key("screen:apply"), content = Key("electricCarWarrantyOptions")),
                    warrantyPDF = updated.get(media = Key("screen:apply"), content = Key("warrantyPDF")),
                    warrantyPDFHere = updated.get(media = Key("screen:apply"), content = Key("warrantyPDFHere")),
                    tryGuarantee3monthLabel = updated.get(media = Key("screen:apply"), content = Key("tryGuarantee3monthLabel")),
                    tryGuarantee1monthLabel = updated.get(media = Key("screen:apply"), content = Key("tryGuarantee1monthLabel")),
                    tryGuarantee3monthHint = updated.get(media = Key("screen:apply"), content = Key("tryGuarantee3monthHint")),
                    tryGuarantee1monthHint = updated.get(media = Key("screen:apply"), content = Key("tryGuarantee1monthHint")),
                    tryGuaranteeMake = updated.get(media = Key("screen:apply"), content = Key("tryGuaranteeMake")),
                    summaryDisclaimerWarranty = updated.get(media = Key("screen:apply"), content = Key("summaryDisclaimerWarranty")),
                )
                tupleOf(actions, texts)
            }

    suspend fun load(scene: Scene.Input<ViewModel>, url: String, objectId: String? = null, type: String? = null) {
        val (store, viewModel) = scene
        val id = Identifier<Object>(objectId ?: "")
        val customerType = CustomerType.fromRawValue(type ?: url.substringAfterLast("/"))


        updates.emit(sceneOf(viewModel.loading()))
        if (objectId != null) {
            // Continue with existing submission
            loadText(store)
                .flatMap { (actions, texts) ->
                    store.reduce(actions).refreshObject(id, (listOf(Key(CustomerType.getFkKey(customerType, true))))).accumulate(actions)
                        .map { tupleOf(it.first, texts, it.second) }
                }
                .map { (actions, texts, full) ->
                    // Go to Contract
                    if (full.submissionBuyer?.state is Submission.State.Closed) {
                        val state = viewModel.state.copy(
                            obj = full,
                            formId = full.submissionBuyer?.index?.id,
                            customerType = customerType
                        )
                        return updates.emit(sceneOf<ViewModel>(viewModel.done(state = state), actions))
                    }

                    if (full.wasaAgreement?.status is Agreement.Status.Approved) {
                        val state = viewModel.state.copy(
                            obj = full,
                            formId = full.submissionBuyer?.index?.id,
                            customerType = customerType
                        )
                        return updates.emit(sceneOf<ViewModel>(viewModel.done(state = state), actions))
                    }

                    val fieldValues = full.submissionBuyer?.form?.fields?.filter { it.key.rawValue != "HEADING" }?.map { field ->
                        if (field.key.rawValue == "TRY_GUARANTEE") {
                            val entry = full.submissionBuyer?.entries?.find { it.fieldKey == field.key }?.text
                            val tryValue = if (entry == texts.tryGuarantee1monthLabel) true else if (entry == texts.tryGuarantee3monthLabel) true else false
                            FieldValues(fieldKey = field.key, text = tryValue.toString())
                        } else {
                            val entry = full.submissionBuyer?.entries?.find { it.fieldKey == field.key }
                            FieldValues(fieldKey = field.key, text = entry?.text ?: "")
                        }
                    }

                    val state = viewModel.state.copy(
                        form = full.submissionBuyer?.form,
                        lastPage = full.submissionBuyer?.form?.fields?.maxByOrNull { it.page }?.page ?: 0,
                        fieldValues = fieldValues ?: emptyList(),
                        obj = full,
                        formId = full.submissionBuyer?.index?.id,
                        entries = full.submissionBuyer?.entries ?: emptyList(),
                        customerType = customerType,
                    )
                    updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = texts, state = state, formItems = buildItems(state, texts, state.form)), actions))
                }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
        } else {
            // Create new submission
            loadText(store)
                .flatMap { (actions, texts) ->
                    store.reduce(actions).findForms(listOf(Key(CustomerType.getFkKey(customerType, true)))).accumulate(actions)
                        .map { tupleOf(it.first, texts, it.second) }
                }
                .map { (actions, texts, form) ->
                    val fieldValues = form.firstOrNull()?.fields?.filter { it.key.rawValue != "HEADING" }?.map { field ->
                        when (field.key.rawValue) {
                            "INSTALLMENT_PROVIDER" -> FieldValues(fieldKey = field.key, text = "wasa", modified = true)
                            "GOV_ID" -> FieldValues(fieldKey = field.key, text = store.govId ?: "", modified = true)
                            else -> {
                                FieldValues(fieldKey = field.key, text = field.predefined ?: "")
                            }
                        }
                    }

                    val state = viewModel.state.copy(
                        form = form.firstOrNull(),
                        lastPage = form.firstOrNull()?.fields?.maxByOrNull { it.page }?.page ?: 0,
                        fieldValues = fieldValues ?: emptyList(),
                        customerType = customerType,
                    )
                    updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = texts, state = state, formItems = buildItems(state, texts, state.form)), actions))
                }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
        }
    }


    private fun warranty(texts: Texts, state: State, weightValue: String?, powerValue: String?, fuelTypeValue: String?): String? {
        val type = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "TYPE" }?.text ?: ""
        val fuelType = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "FUEL_TYPE" }?.text ?: "Nej"
        val maxWeight = texts.maxWeight.split("|").firstOrNull { it.contains(type) }?.split(",")?.lastOrNull()?.trim() ?: "0"
        val weight = if (weightValue.isNullOrEmpty()) "0" else weightValue
        val maxPower = texts.maxPower.split("|").firstOrNull { it.contains(type) }?.split(",")?.lastOrNull()?.trim() ?: "0"
        val power = if (powerValue.isNullOrEmpty()) "0" else powerValue

        val allWarrantyOptions =
            if (fuelType == "Ja" || fuelTypeValue == "Ja")
                texts.electricCarWarrantyOptions.split("|")
            else if (maxWeight.isEmpty() || weight.isEmpty()) {
                emptyList()
            } else if (power.toLong() >= maxPower.toLong() && weight.toLong() <= maxWeight.toLong()) {
                texts.powerHighAllWarrantyOptions.split("|")
            } else if (weight.toLong() <= maxWeight.toLong()) {
                texts.lowAllWarrantyOptions.split("|")
            } else emptyList()
        val typeWarrantyOptions = allWarrantyOptions.firstOrNull { it.contains(type) }?.split(",") ?: emptyList()

        return typeWarrantyOptions.lastOrNull()
    }

    suspend fun update(scene: Scene.Input<ViewModel>, id: String, value: String) {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.Ready) updates.emit(sceneOf(viewModel.failed("Can't update values if not Ready")))
        val enableSources = when (id) {
            "REGNO" -> listOf(Source.VehicleLookup, Source.installmentSupplier)
            "TYPE" -> listOf(Source.installmentSupplier)
            "PRICE" -> listOf(Source.installmentSupplier)
            "CASH_STAKE" -> listOf(Source.installmentSupplier)
            else -> emptyList()
        }
        val isNewValue = if (viewModel.state.entries.firstOrNull { it.fieldKey.rawValue == id } == null) true else viewModel.state.entries.firstOrNull { it.fieldKey.rawValue == id }?.text != value
        val price = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "PRICE" }?.text ?: ""
        var texts = viewModel.texts

        var updateFieldValues = viewModel.state.fieldValues.map { field -> if (field.fieldKey.rawValue == id) field.copy(text = value, modified = isNewValue) else field }
        if (id == "PRICE") {
            val fkFee = viewModel.texts.discount.toDoubleOrNull()?.let { viewModel.texts.fkFee.toDoubleOrNull()?.minus(it) } ?: 0.0
            val x = if (value.isNotEmpty()) ceil(fkFee.plus(value.toLong()) * 0.2).toString() else ""
            updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "CASH_STAKE") field.copy(text = x, modified = true) else field }
        }

        if (id == "WEIGHT" && value.isNotEmpty()) {
            val type = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "TYPE" }?.text ?: ""
            val powerValue = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "POWER" }?.text ?: "0"
            val fuelType = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "FUEL_TYPE" }?.text ?: "Nej"
            val maxWeight = viewModel.texts.maxWeight.split("|").firstOrNull { it.contains(type) }?.split(",")?.lastOrNull()?.trim() ?: "0"
            val warranty = warranty(viewModel.texts, viewModel.state, value, powerValue, fuelType)

            val warrantyOptions = if (warranty?.isEmpty() == false) listOf(warranty) else emptyList()

            updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "WARRANTY") field.copy(text = if (fuelType == "Ja") warrantyOptions.firstOrNull().toString() else if (value.toLong() > maxWeight.toLong()) "-" else warrantyOptions.firstOrNull().toString(), modified = true) else field }
        }

        if (id == "POWER" && value.isNotEmpty()) {
            val type = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "TYPE" }?.text ?: ""
            val fuelType = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "FUEL_TYPE" }?.text ?: "Nej"
            val weightValue = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "WEIGHT" }?.text ?: "0"
            val weight = weightValue.ifEmpty { "0" }
            val maxWeight = viewModel.texts.maxWeight.split("|").firstOrNull { it.contains(type) }?.split(",")?.lastOrNull()?.trim() ?: "0"
            val warranty = warranty(viewModel.texts, viewModel.state, weightValue, value, fuelType)
            val warrantyOptions = if (warranty?.isEmpty() == false) listOf(warranty) else emptyList()

            updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "WARRANTY") field.copy(text = if (fuelType == "Ja") warrantyOptions.firstOrNull().toString() else if (weight.toLong() > maxWeight.toLong()) "-" else warrantyOptions.firstOrNull().toString(), modified = true) else field }
        }

        if (id == "FUEL_TYPE") {
            val type = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "TYPE" }?.text ?: ""
            val powerValue = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "POWER" }?.text ?: "0"
            val weightValue = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "WEIGHT" }?.text ?: "0"
            val weight = weightValue.ifEmpty { "0" }
            val maxWeight = viewModel.texts.maxWeight.split("|").firstOrNull { it.contains(type) }?.split(",")?.lastOrNull()?.trim() ?: "0"
            val warranty = warranty(viewModel.texts, viewModel.state, weightValue, powerValue, value)
            val warrantyOptions = if (warranty?.isEmpty() == false) listOf(warranty) else emptyList()

            updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "WARRANTY") field.copy(text = if (value == "Ja") warrantyOptions.firstOrNull().toString() else if (weight.toLong() > maxWeight.toLong()) "-" else warrantyOptions.firstOrNull().toString(), modified = true) else field }
        }


        val excludeEmployment = viewModel.texts.excludeEmployment.replace(" ", "").split(",")
        if (id == "EMPLOYMENT" && excludeEmployment.firstOrNull { excludeEmployment.contains(value) } != null) {
            updateFieldValues = updateFieldValues.map { field ->
                when (field.fieldKey.rawValue) {
                    "EMPLOY", "EMPLOYMENT_YEAR", "EMPLOYMENT_MONTH" -> field.copy(text = "-", modified = true)
                    else -> field
                }
            }
        }

        if (id == "EMPLOYMENT" && excludeEmployment.firstOrNull { excludeEmployment.contains(value) } == null) {
            updateFieldValues = updateFieldValues.map { field ->
                when (field.fieldKey.rawValue) {
                    "EMPLOY", "EMPLOYMENT_YEAR", "EMPLOYMENT_MONTH" -> field.copy(text = "", modified = true)
                    else -> field
                }
            }
        }

        if (id == "LEASING_UPPER_BOUND") {
            updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "RESIDUAL_UPPER_BOUND") field.copy(text = value, modified = true) else field }
        }

        if (id == "DISCOUNT") {
            val discount = viewModel.state.obj.submissionBuyer?.form?.fields?.firstOrNull { it.key.rawValue == "DISCOUNT" }
            texts = texts.copy(discount = if (value.trim() == discount?.answer) discount.min.toString() else "0")
            val fkFee = texts.discount.toDoubleOrNull()?.let { viewModel.texts.fkFee.toDoubleOrNull()?.minus(it) } ?: 0.0
            val updatedCashStake = if (price.isNotEmpty()) ceil(fkFee.plus(price.toLong()) * 0.2).toString() else ""

            val oldCashStake = viewModel.state.obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "CASH_STAKE" }?.text
            updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "CASH_STAKE") field.copy(text = if(viewModel.state.customerType == CustomerType.Leasing && oldCashStake == "0") oldCashStake else updatedCashStake, modified = true) else field }

            val loan = countOnLoan(updateFieldValues, texts)
            updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "LOAN") field.copy(text = loan, modified = true) else field }
        }

        if (id == "CREDITOR") {
            updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "CREDIT_AMOUNT") field.copy(text = " ", modified = true) else field }
        }

        if (id == "CIVIL_STATE") {
            val civilStateChoice = when (val civilChoice = viewModel.state.form?.fields?.firstOrNull { it.key.rawValue == "CIVIL_STATE" }?.style) {
                is Field.Style.Choice -> civilChoice.choices.firstOrNull()
                else -> null
            }
            if (value != civilStateChoice)
                updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "SUPPLY_RESPONSIBILITY") field.copy(text = "-", modified = true) else field }
        }

        val state = viewModel.state.copy(
            fieldValues = updateFieldValues,
            enableSources = viewModel.state.enableSources + enableSources,
            suggestCashStake = id == "PRICE" && price != value
        )
        return updates.emit((sceneOf(viewModel.ready(state = state, texts = texts, formItems = buildItems(state, texts, state.form, viewModel.state.status)))))
    }

    suspend fun searchRegno(scene: Scene.Input<ViewModel>, id: String, value: String) {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.Ready) updates.emit(sceneOf(viewModel.failed("Can't update values if not Ready")))

        val isNewValue = if (viewModel.state.entries.firstOrNull { it.fieldKey.rawValue == id } == null) true else viewModel.state.entries.firstOrNull { it.fieldKey.rawValue == id }?.text != value

        val state = viewModel.state.copy(
            fieldValues = viewModel.state.fieldValues.map { field -> if (field.fieldKey.rawValue == id) field.copy(text = value, modified = isNewValue) else field },
            enableSources = listOf(Source.installmentSupplier, Source.VehicleLookup),
            status = emptyList()
        )
        save(scene, state)
    }

    suspend fun next(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.Ready) updates.emit(sceneOf(viewModel.failed("Can't update values if not Ready")))

        val state = viewModel.state.copy(page = viewModel.state.page + 1)
        validate(scene, state)
    }

    suspend fun previous(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene
        if (viewModel !is ViewModel.Ready) updates.emit(sceneOf(viewModel.failed("Can't update values if not Ready")))
        if (viewModel.state.page > 1) {
            val state = viewModel.state.copy(page = viewModel.state.page - 1)
            updates.emit((sceneOf(viewModel.ready(state = state, texts = viewModel.texts, formItems = buildItems(state, viewModel.texts, state.form)))))
        } else if (store.customerType == CustomerType.None) {
            updates.emit((sceneOf(viewModel.done(state = viewModel.state, back = true, routing = Routes.matters))))
        } else {
            logout(scene)
        }
    }

    private suspend fun validate(scene: Scene.Input<ViewModel>, s: State) {
        val (_, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()

        val loan = countOnLoan(viewModel.state.fieldValues, viewModel.texts)

        var state = viewModel.state.copy(
            fieldValues = viewModel.state.fieldValues.map { field -> if (field.fieldKey.rawValue == "LOAN") field.copy(text = loan, modified = true) else field },
        )
        val realPrincipal = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "REAL_PRINCIPAL" }

        state.form?.fields?.map { field ->
            if (field.page == state.page) {
                val value = state.fieldValues.firstOrNull { it.fieldKey == field.key }?.text
                val header = header(field.key)
                when (field.style) {
                    is Field.Style.Email ->
                        if (field.required)
                            Validator.email(
                                email = value,
                                isWrongFormat = { status.add(header to DesignSystem.Status.Invalid(viewModel.texts.emailFormat)) },
                                isEmpty = { status.add(header to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired)) },
                                formatted = { state = state.copy(fieldValues = state.fieldValues.map { fieldValue -> if (fieldValue.fieldKey == field.key) fieldValue.copy(text = it, modified = true) else fieldValue }) },
                                passed = { status.add(header to DesignSystem.Status.Valid) },
                            )
                        else
                            status.add(header to DesignSystem.Status.Valid)

                    is Field.Style.Phone ->
                        if (field.required)
                            Validator.phone(
                                phone = value,
                                illegalCharacters = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.phoneIllegalCharacters)) },
                                isEmpty = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.fieldRequired)) },
                                isCorrectLength = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.phoneIllegalCharacters)) },
                                formatted = { state = state.copy(fieldValues = state.fieldValues.map { fieldValue -> if (fieldValue.fieldKey == field.key) fieldValue.copy(text = it, modified = true) else fieldValue }) },
                                passed = { status.add(header to DesignSystem.Status.Valid) }
                            )
                        else
                            status.add(header to DesignSystem.Status.Valid)

                    is Field.Style.Zipcode -> Validator.postalCode(
                        postalCode = value,
                        isEmpty = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.fieldRequired)) },
                        passed = { status.add(header to DesignSystem.Status.Valid) },
                        formatted = { state = state.copy(fieldValues = state.fieldValues.map { fieldValue -> if (fieldValue.fieldKey == field.key) fieldValue.copy(text = it, modified = true) else fieldValue }) },
                        isWrongLength = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.postalCodeWrongLength)) },
                        illegalCharacters = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.postalCodeIllegalCharacters)) },
                    )

                    is Field.Style.DownPayment -> {
                        Validator.downPayment(
                            downPayment = value,
                            min = field.min ?: 0,
                            max = field.max ?: 0,
                            price = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "PRICE" }?.text,
                            fkFee = viewModel.texts.fkFee,
                            isEmpty = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.fieldRequired)) },
                            passed = { status.add(header to DesignSystem.Status.Valid) },
                            formatted = { state = state.copy(fieldValues = state.fieldValues.map { fieldValue -> if (fieldValue.fieldKey == field.key) fieldValue.copy(text = it, modified = true) else fieldValue }) },
                            isLess = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.downPaymentIsLess)) },
                            isMore = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.downPaymentIsMore)) },
                            illegalCharacters = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.illegalCharacters)) },
                        )
                    }

                    else ->
                        // Check for required
                        if (field.required && value.isNullOrEmpty())
                            status.add(header to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
                        else if (field.required && value == "false" && field.key.rawValue == "PROXY")
                            status.add(header to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
                        else if (field.required && value?.contains(",") == true && field.key.rawValue == "MONTH")
                            status.add(header to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
                        else if (field.required && value == "false" && field.key.rawValue == "PEP")
                            status.add(header to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
                        else if (field.required && field.key.rawValue == "LEASING_UPPER_BOUND")
                            Validator.residualBound(
                                value = value,
                                min = field.min ?: 0,
                                max = field.max ?: 0,
                                isEmpty = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.fieldRequired)) },
                                passed = { status.add(header to DesignSystem.Status.Valid) },
                                formatted = { state = state.copy(fieldValues = state.fieldValues.map { fieldValue -> if (fieldValue.fieldKey == field.key) fieldValue.copy(text = it, modified = true) else fieldValue }) },
                                isLess = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.resiualBoudnIsLess)) },
                                isMore = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.resiualBoudnIsMore)) },
                                illegalCharacters = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.illegalCharacters)) },
                            )
                        else if (field.key.rawValue == "DISCOUNT")
                            Validator.discount(
                                value = value,
                                answer = field.answer,
                                passed = { status.add(header to DesignSystem.Status.Valid) },
                                formatted = { state = state.copy(fieldValues = state.fieldValues.map { fieldValue -> if (fieldValue.fieldKey == field.key) fieldValue.copy(text = it, modified = true) else fieldValue }) },
                                illegalCharacters = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.illegalCode)) },
                            )
                        else if ((realPrincipal != null && realPrincipal.text == "Nej" && field.key.rawValue == "NO_NAME_1") || (realPrincipal != null && realPrincipal.text == "Nej" && field.key.rawValue == "NO_GOVID_1")) {
                            if (field.key.rawValue == "NO_GOVID_1")
                                Validator.govId(
                                    govId = value,
                                    illegalCharacters = { status.add(header(field.key) to DesignSystem.Status.Invalid(viewModel.texts.illegalCharacters)) },
                                    isEmpty = { status.add(header(field.key) to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired)) },
                                    isWrongLength = { status.add(header(field.key) to DesignSystem.Status.Invalid(viewModel.texts.govIdLength)) },
                                    formatted = { },
                                    passed = { status.add(header(field.key) to DesignSystem.Status.Valid) },
                                )
                            else
                                if (value.isNullOrEmpty()) status.add(header to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired)) else status.add(header(field.key) to DesignSystem.Status.Valid)
                        } else if (field.key.rawValue == "REAL_PRINCIPAL" && value == "Ja") {
                            val yesNameFields = listOf("YES_NAME_1", "YES_NAME_2", "YES_NAME_3", "YES_NAME_4")
                            val yesGovIdFields = listOf("YES_GOVID_1", "YES_GOVID_2", "YES_GOVID_3", "YES_GOVID_4")

                            val filledYesNameFields = yesNameFields.filter { !state.fieldValues.firstOrNull { fieldValue -> fieldValue.fieldKey.rawValue == it }?.text.isNullOrEmpty() }
                            val filledYesGovIdFields = yesGovIdFields.filter { !state.fieldValues.firstOrNull { fieldValue -> fieldValue.fieldKey.rawValue == it }?.text.isNullOrEmpty() }

                            if (filledYesNameFields.isNotEmpty() && filledYesGovIdFields.isNotEmpty()) {
                                yesNameFields.mapIndexed { index: Int, nameKey: String ->
                                    val number = index + 1
                                    val nameIsFilled = filledYesNameFields.map { it.contains(number.toString()) }.firstOrNull { it }
                                    val govIdIsFilled = filledYesGovIdFields.map { it.contains(number.toString()) }.firstOrNull { it }
                                    if (nameIsFilled != null && govIdIsFilled != null) {
                                        status.add(header(Key(nameKey)) to DesignSystem.Status.Valid)
                                        Validator.govId(
                                            govId = state.fieldValues.firstOrNull { fieldValue -> fieldValue.fieldKey.rawValue == yesGovIdFields[index] }?.text,
                                            illegalCharacters = { status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Invalid(viewModel.texts.illegalCharacters)) },
                                            isEmpty = { status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired)) },
                                            isWrongLength = { status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Invalid(viewModel.texts.govIdLength)) },
                                            formatted = { },
                                            passed = { status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Valid) },
                                        )
                                    } else if (nameIsFilled != null && govIdIsFilled == null) {
                                        status.add(header(Key(nameKey)) to DesignSystem.Status.Valid)
                                        status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
                                    } else if (nameIsFilled == null && govIdIsFilled != null) {
                                        Validator.govId(
                                            govId = state.fieldValues.firstOrNull { fieldValue -> fieldValue.fieldKey.rawValue == yesGovIdFields[index] }?.text,
                                            illegalCharacters = { status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Invalid(viewModel.texts.illegalCharacters)) },
                                            isEmpty = { status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired)) },
                                            isWrongLength = { status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Invalid(viewModel.texts.govIdLength)) },
                                            formatted = { },
                                            passed = { status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Valid) },
                                        )
                                        status.add(header(Key(nameKey)) to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
                                    } else {
                                        status.add(header(Key(nameKey)) to DesignSystem.Status.Valid)
                                        status.add(header(Key(yesGovIdFields[index])) to DesignSystem.Status.Valid)
                                    }
                                }

                            } else {
                                status.add(header(Key("YES_NAME_1")) to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
                                status.add(header(Key("YES_GOVID_1")) to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
                            }
                        } else
                            status.add(header to DesignSystem.Status.Valid)
                }
            }
        }

        val newState = state.copy(status = status)
        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return updates.emit((sceneOf(viewModel.ready(state = newState, texts = viewModel.texts, formItems = buildItems(newState, viewModel.texts, state.form, status = status)))))

        return save(scene, newState.copy(page = s.page))
    }

    private fun splitRepaymentTime(cleanup: String? = null): String? {
        val numbers = mutableListOf<String>()
        if (cleanup == null) return cleanup

        val pairs = cleanup.split('|')
        for (pair in pairs) {
            val parts = pair.split(',')
            if (parts.isNotEmpty()) {
                val firstNumber = parts[0]
                numbers.add(firstNumber)
            }
        }
        return numbers.joinToString()
    }

    private fun getResidualBound(interestRateAndRepaymentMonths: String, selectedMonth: String): String {
        val resultMap = mutableMapOf<String, String>()

        val pairs = interestRateAndRepaymentMonths.split('|')
        for (pair in pairs) {
            val parts = pair.split(',')
            if (parts.size == 2) {
                resultMap[parts[0].trim()] = parts[1].trim()
            }
        }
        return resultMap[selectedMonth] ?: ""
    }

    private fun translateBoolean(texts: Texts, state: State, key: String): String {
        val conditionText = texts.vehicleCondition.split(",").map { it.trim() }
        val creditor = state.fieldValues.firstOrNull { f -> f.fieldKey.rawValue == key }?.text?.trim()
        return if (creditor == "true" || creditor == conditionText.firstOrNull()) conditionText.firstOrNull() ?: "" else conditionText.lastOrNull() ?: ""
    }

    private suspend fun save(scene: Scene.Input<ViewModel>, s: State?) {
        val (store, viewModel) = scene
        val state = s ?: viewModel.state

        if (viewModel !is ViewModel.Ready) updates.emit(sceneOf(viewModel.failed("Can't go next if not Ready")))

        val realPrincipal = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "REAL_PRINCIPAL" }?.text
        val updatedFieldValues = state.fieldValues.map {
            val yesRealPrincipal = if (realPrincipal == "Nej") listOf("YES_NAME_1", "YES_NAME_2", "YES_NAME_3", "YES_NAME_4", "YES_GOVID_1", "YES_GOVID_2", "YES_GOVID_3", "YES_GOVID_4") else if (realPrincipal == "Ja") listOf("NO_NAME_1", "NO_GOVID_1") else emptyList()
            if (yesRealPrincipal.contains(it.fieldKey.rawValue))
                it.copy(text = " ", modified = true)
            else it

        }

        val entries = updatedFieldValues.filter { it.text != "" && it.modified }.map {
            when (it.fieldKey.rawValue) {
                "REGNO" -> Submission.Entry(fieldKey = it.fieldKey, text = it.text.uppercase().replace(" ", ""))
                "TRY_GUARANTEE" -> {
                    val tryGuaranteeMakeIsOneMonth = viewModel.texts.tryGuaranteeMake.replace(" ", "").split(",")
                    val type = state.fieldValues.firstOrNull { f -> f.fieldKey.rawValue == "TYPE" }?.text?.trim()
                    val make = state.fieldValues.firstOrNull { f -> f.fieldKey.rawValue == "MAKE" }?.text?.trim()
                    val label = if (tryGuaranteeMakeIsOneMonth.contains(make) || type != "Personbil") viewModel.texts.tryGuarantee1monthLabel else viewModel.texts.tryGuarantee3monthLabel
                    val tryValue = if (it.text == "true" || it.text == label) label else "-"
                    Submission.Entry(fieldKey = it.fieldKey, text = tryValue)
                }

                "CONDITION" -> Submission.Entry(fieldKey = it.fieldKey, text = translateBoolean(viewModel.texts, state, "CONDITION"))
                "CREDITOR" -> Submission.Entry(fieldKey = it.fieldKey, text = translateBoolean(viewModel.texts, state, "CREDITOR"))
                "PEP" -> Submission.Entry(fieldKey = it.fieldKey, text = translateBoolean(viewModel.texts, state, "PEP"))
                "PROXY" -> Submission.Entry(fieldKey = it.fieldKey, text = translateBoolean(viewModel.texts, state, "PROXY"))
                else -> Submission.Entry(fieldKey = it.fieldKey, text = it.text)
            }
        }
        val editSubmission = Submission.Edit(
            sources = if (state.page > state.lastPage) listOf(Source.installmentApplication) else state.enableSources,
            entries = modifiedOf(entries),
            state = if (state.page > state.lastPage) modifiedOf(Submission.State.Closed) else modifiedOf(Submission.State.Open),
        )

        if (state.formId == null) {
            store.createObject(state.fieldValues.firstOrNull { it.fieldKey.rawValue == "REGNO" }?.text?.uppercase()?.replace(" ", "") ?: "*", state.form?.key!!, entries, CustomerType.getFkKey(state.customerType, true))
                .map { (actions, obj) ->
                    val submission = obj.submissionBuyer

                    val fieldValues = viewModel.state.fieldValues.map { fieldValue ->
                        val interestRateAndRepaymentMonth = submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "REPAYMENT_TIMES" }?.predefined ?: state.interestRateAndRepaymentMonth

                        when (fieldValue.fieldKey.rawValue) {
                            "WARRANTY" -> {
                                val type = submission?.form?.fields?.firstOrNull { it.key.rawValue == "TYPE" }?.predefined ?: viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "TYPE" }?.text ?: ""
                                val weight = submission?.form?.fields?.firstOrNull { it.key.rawValue == "WEIGHT" }?.predefined ?: viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "WEIGHT" }?.text ?: "0"
                                val power = submission?.form?.fields?.firstOrNull { it.key.rawValue == "POWER" }?.predefined ?: viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "POWER" }?.text ?: "0"
                                val maxWeight = viewModel.texts.maxWeight.split("|").firstOrNull { it.contains(type) }?.split(",")?.lastOrNull()?.trim() ?: "0"
                                val fuelType = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "FUEL_TYPE" }?.text ?: "Nej"
                                val warrantyOptions = warranty(viewModel.texts, viewModel.state, weight, power, fuelType) ?: "-"

                                FieldValues(fieldKey = fieldValue.fieldKey, text = if (weight.toLong() > maxWeight.toLong()) "-" else warrantyOptions, modified = true)
                            }

                            "RESIDUAL_UPPER_BOUND" -> {
                                val leasingUpperBound = submission?.entries?.firstOrNull { ent -> ent.fieldKey.rawValue == "LEASING_UPPER_BOUND" }?.text ?: "0"
                                val month = submission?.entries?.firstOrNull { ent -> ent.fieldKey.rawValue == "MONTH" }?.text ?: submission?.form?.fields?.firstOrNull { it.key.rawValue == "MONTH" }?.predefined ?: ""
                                val residualBound = if (month.isNotEmpty()) getResidualBound(interestRateAndRepaymentMonth, month) else "0"
                                FieldValues(fieldKey = fieldValue.fieldKey, text = if (state.customerType == CustomerType.Leasing) leasingUpperBound else residualBound, modified = true)
                            }

                            "LEASING_UPPER_BOUND" -> {
                                val month = submission?.entries?.firstOrNull { ent -> ent.fieldKey.rawValue == "MONTH" }?.text ?: submission?.form?.fields?.firstOrNull { it.key.rawValue == "MONTH" }?.predefined ?: ""
                                val residualBound = if (month.isNotEmpty()) getResidualBound(interestRateAndRepaymentMonth, month) else "0"
                                FieldValues(fieldKey = fieldValue.fieldKey, text = residualBound, modified = true)
                            }

                            "MONTH" -> {
                                val amountOfMonth = splitRepaymentTime(submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "REPAYMENT_TIMES" }?.predefined) ?: viewModel.texts.repaymentMonth
                                FieldValues(fieldKey = fieldValue.fieldKey, text = amountOfMonth, modified = true)
                            }

                            "FUEL_TYPE" -> {
                                val fuelText = viewModel.texts.fuelType.split(",").map { it.trim() }
                                val fuel = if (submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "FUEL_TYPE" }?.predefined == "Electric") fuelText.firstOrNull() ?: "" else fuelText.lastOrNull() ?: ""
                                FieldValues(fieldKey = fieldValue.fieldKey, text = fuel, modified = true)
                            }

                            "TYPE" -> {
                                val type = submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "TYPE" }?.predefined
                                val vehicleTypes = viewModel.texts.vehicleTypes.split(",")
                                FieldValues(fieldKey = fieldValue.fieldKey, text = vehicleTypes.firstOrNull { x -> x.substringBeforeLast("-").trim() == type }?.substringAfterLast("-")?.trim() ?: "", modified = true)
                            }

                            else -> {
                                val field = submission?.form?.fields?.firstOrNull { it.key == fieldValue.fieldKey }
                                FieldValues(fieldKey = fieldValue.fieldKey, text = field?.predefined ?: fieldValue.text, modified = field?.predefined !== null)
                            }
                        }
                    }
                    val amountOfMonth = splitRepaymentTime(submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "REPAYMENT_TIMES" }?.predefined) ?: viewModel.texts.repaymentMonth
                    val interestRate = submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "INTEREST_RATE" }?.predefined ?: viewModel.texts.interestRate
                    val interestRateAndRepaymentMonth = submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "REPAYMENT_TIMES" }?.predefined ?: state.interestRateAndRepaymentMonth
                    val newState = state.copy(formId = submission?.index?.id, form = submission?.form, entries = submission?.entries ?: emptyList(), enableSources = emptyList(), fieldValues = fieldValues, obj = obj, interestRateAndRepaymentMonth = interestRateAndRepaymentMonth)
                    val texts = viewModel.texts.copy(interestRate = interestRate, repaymentMonth = amountOfMonth)
                    updates.emit((sceneOf(viewModel.ready(state = newState, texts = texts, formItems = buildItems(newState, texts, newState.form)), actions)))
                }
        } else {
            store.editSubmissionObject(state.obj, state.formId, editSubmission)
                .map { (actions, obj) ->
                    val submission = obj.submissionBuyer
                    val interestRateAndRepaymentMonth = submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "REPAYMENT_TIMES" }?.predefined ?: state.interestRateAndRepaymentMonth
                    val amountOfMonth = splitRepaymentTime(submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "REPAYMENT_TIMES" }?.predefined) ?: viewModel.texts.repaymentMonth
                    val interestRate = submission?.form?.fields?.firstOrNull { field -> field.key.rawValue == "INTEREST_RATE" }?.predefined ?: viewModel.texts.interestRate
                    val fieldValues = viewModel.state.fieldValues.map {
                        val field = submission?.form?.fields?.firstOrNull { field -> field.key == it.fieldKey }
                        val entrie = submission?.entries?.firstOrNull { e -> e.fieldKey == it.fieldKey }
                        val value = if (field?.predefined == entrie?.text || field?.predefined == null) entrie?.text else field.predefined
                        when (it.fieldKey.rawValue) {
                            "RESIDUAL_UPPER_BOUND" -> {
                                val leasingUpperBound = submission?.entries?.firstOrNull { ent -> ent.fieldKey.rawValue == "LEASING_UPPER_BOUND" }?.text ?: ""
                                val month = submission?.entries?.firstOrNull { ent -> ent.fieldKey.rawValue == "MONTH" }?.text ?: ""
                                val residualBound = if (month.isNotEmpty()) getResidualBound(interestRateAndRepaymentMonth, month) else "0"
                                FieldValues(fieldKey = it.fieldKey, text = if (state.customerType == CustomerType.Leasing) leasingUpperBound else residualBound, modified = true)
                            }

                            "LEASING_UPPER_BOUND" -> {
                                val month = submission?.entries?.firstOrNull { ent -> ent.fieldKey.rawValue == "MONTH" }?.text ?: ""
                                val residualBound = if (month.isNotEmpty()) getResidualBound(interestRateAndRepaymentMonth, month) else "0"
                                FieldValues(fieldKey = it.fieldKey, text = residualBound, modified = true)
                            }

                            "TYPE" -> {
                                val type = submission?.form?.fields?.firstOrNull { f -> f.key.rawValue == "TYPE" }?.predefined
                                val entryValue = submission?.entries?.firstOrNull { ent -> ent.fieldKey.rawValue == "TYPE" }?.text ?: ""
                                val vehicleTypes = viewModel.texts.vehicleTypes.split(",")
                                val text = if (type == entryValue || type == null) entryValue else vehicleTypes.firstOrNull { x -> x.substringBeforeLast("-").trim() == type }?.substringAfterLast("-")?.trim() ?: ""

                                FieldValues(fieldKey = it.fieldKey, text = text, modified = true)
                            }

                            "WARRANTY" -> {
                                val type = submission?.form?.fields?.firstOrNull { f -> f.key.rawValue == "TYPE" }?.predefined ?: viewModel.state.fieldValues.firstOrNull { f -> f.fieldKey.rawValue == "TYPE" }?.text ?: ""
                                val weight = submission?.form?.fields?.firstOrNull { f -> f.key.rawValue == "WEIGHT" }?.predefined ?: viewModel.state.fieldValues.firstOrNull { f -> f.fieldKey.rawValue == "WEIGHT" }?.text ?: "0"
                                val power = submission?.form?.fields?.firstOrNull { f -> f.key.rawValue == "POWER" }?.predefined ?: viewModel.state.fieldValues.firstOrNull { f -> f.fieldKey.rawValue == "POWER" }?.text ?: "0"
                                val maxWeight = viewModel.texts.maxWeight.split("|").firstOrNull { f -> f.contains(type) }?.split(",")?.lastOrNull()?.trim() ?: "0"
                                val fuelType = viewModel.state.fieldValues.firstOrNull { it.fieldKey.rawValue == "FUEL_TYPE" }?.text ?: "Nej"
                                val warrantyOptions = warranty(viewModel.texts, viewModel.state, weight, power, fuelType) ?: "-"
                                FieldValues(fieldKey = it.fieldKey, text = if (weight.toInt() > maxWeight.toInt()) "-" else warrantyOptions, modified = true)
                            }

                            else -> FieldValues(fieldKey = it.fieldKey, text = value ?: it.text, modified = value !== it.text)
                        }
                    }
                    val texts = viewModel.texts.copy(interestRate = interestRate, repaymentMonth = amountOfMonth)
                    val newState = state.copy(obj = obj, form = submission?.form, entries = submission?.entries ?: emptyList(), enableSources = emptyList(), fieldValues = fieldValues, interestRateAndRepaymentMonth = interestRateAndRepaymentMonth)

                    if (state.page <= state.lastPage) {
                        updates.emit((sceneOf(viewModel.ready(state = newState, texts = texts, formItems = buildItems(newState, texts, newState.form)), actions)))
                    } else {

                        val keys = listOf("REGNO", "MAKE", "MODEL")
                        val price = obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "PRICE" }?.text ?: ""
                        val selectedFields = keys.map { key -> obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == key }?.text ?: "" }
                        val name = selectedFields.map { it }.joinToString("{REPLACE}").plus("{REPLACE}" + price + " " + viewModel.texts.sek)


                        store.reduce(actions).editGroup(obj, Group.Edit(name = modifiedOf(name))).accumulate(actions)
                            .flatMap { (actions, group) ->
                                val email = obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "EMAIL" }?.text ?: ""
                                val phone = obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "PHONE" }?.text ?: ""
                                val edit = Profile.MeEdit(email = modifiedOf(email), phone = modifiedOf(phone))
                                store.reduce(actions).editMe(obj, edit)
                                    .map { tupleOf(it.first, group, it.second) }
                            }
                            .map { (actions, _) ->
                                val updated = store.reduce(actions = actions)
                                agreements(scene.copy(store = updated), newState)

                                // TODO check status from WASA
                                //updates.emit(sceneOf(viewModel.denied(), actions))
                            }
                    }
                }
        }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    private suspend fun agreements(scene: Scene.Input<ViewModel>, state: State) {
        val (store, viewModel) = scene
        val obj = state.obj

        store.editGroup(obj, Group.Edit(status = modifiedOf(Group.Status.Active)))
            .flatMap { (actions, _) ->
                store.reduce(actions).refreshObject(state.obj.id, listOf(Key(CustomerType.getFkKey(viewModel.state.customerType, true)))).accumulate(actions)
                    .map { tupleOf(it.first, it.second) }
            }
            .map { (actions, obj) ->
                return updates.emit(sceneOf(viewModel.approve(state.copy(startCheck = false, obj = obj)), actions))
            }

        /*
        val create = Agreement.Create(
            key = Key(FK.wasaKredit),
            name = obj.name,
            status = if (FK.isWasaKreditEnabled) Agreement.Status.Pending else Agreement.Status.Approved,
            approvalMethod = Agreement.ApprovalMethod.BySignatures(count = 1)

        )

        store.createAgreement(obj, create)
            .flatMap { (actions, agreement) ->
                store.reduce(actions).createTerms(obj, obj.submissionBuyer?.createTerms(agreement)!!).accumulate(actions)
                    .map { tupleOf(it.first, agreement, it.second) }
            }
            .flatMap { (actions, agreement) ->
                if (FK.isWasaKreditEnabled)
                    store.reduce(actions).createSignature(obj, Signature.Create(agreementId = agreement.id)).accumulate(actions)
                        .map { tupleOf(it.first, it.second.signed) }
                else
                    successfulOf(tupleOf(actions, Signature.Signed.Agreement(agreement.id)))
            }
            .map { (actions, signature) ->
                when (signature) {
                    is Signature.Signed.Pending -> return updates.emit(sceneOf(viewModel.approve(state.copy(startCheck = true)), actions))

                    is Signature.Signed.Agreement, is Signature.Signed.Policy -> {
                        store.editGroup(obj, Group.Edit(status = modifiedOf(Group.Status.Active)))
                            .flatMap { (actions, _) ->
                                store.reduce(actions).refreshObject(state.obj.id, listOf(Key(CustomerType.getFkKey(viewModel.state.customerType, true)))).accumulate(actions)
                                    .map { tupleOf(it.first, it.second) }
                            }
                            .map { (actions, obj) ->
                                return updates.emit(sceneOf(viewModel.approve(state.copy(startCheck = false, obj = obj)), actions))
                            }
                    }

                    is Signature.Signed.Cancelled ->
                        return updates.emit(sceneOf(viewModel.loading(), actions))

                    is Signature.Signed.Failed ->
                        return updates.emit(scene.failed(signature.reason))

                    is Signature.Signed.Unknown ->
                        return updates.emit(scene.failed("Unknown Error"))
                }
            }

         */
    }

    suspend fun check(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene
        val state = viewModel.state

        store.findLatestAgreement(obj = viewModel.state.obj, keys = listOf(Key(FK.wasaKredit)))
            .map { (actions, agreements) ->
                when (agreements.firstOrNull()?.status) {
                    is Agreement.Status.Pending -> updates.emit(sceneOf(viewModel.approve(state.copy(startCheck = true)), actions))

                    is Agreement.Status.Approved -> {
                        store.editGroup(state.obj, Group.Edit(status = modifiedOf(Group.Status.Active)))
                            .flatMap { (actions, _) ->
                                store.reduce(actions).refreshObject(state.obj.id, listOf(Key(CustomerType.getFkKey(state.customerType, true)))).accumulate(actions)
                                    .map { tupleOf(it.first, it.second) }
                            }
                            .map { (actions, obj) ->
                                return updates.emit(sceneOf(viewModel.approve(state.copy(obj = obj, startCheck = false)), actions))
                            }
                    }

                    else -> return updates.emit(scene.failed("Unknown Error"))
                }
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    private fun countOnLoan(fieldValues: List<FieldValues>, texts: Texts): String {
        val price = fieldValues.firstOrNull { it.fieldKey.rawValue == "PRICE" }?.text?.replace(" ", "") ?: "0"
        val fkFee = texts.discount.toDoubleOrNull()?.let { texts.fkFee.toDoubleOrNull()?.minus(it) } ?: 0.0
        val cashStake = fieldValues.firstOrNull { it.fieldKey.rawValue == "CASH_STAKE" }?.text?.replace(" ", "") ?: "0"

        val loan = if (price.isNotEmpty() && cashStake.isNotEmpty()) (price.toLong() + fkFee.toLong()) - cashStake.toLong() else 0
        return loan.toString()
    }

    suspend fun success(scene: Scene.Input<ViewModel>, url: String) {
        val (_, _) = scene
        return load(scene, url)
    }

    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene
        val action = Store.Action.Logout
        updates.emit(sceneOf(viewModel.logout(goTo = store.customerType.rawValue), action))
    }

    private fun buildItems(state: State, texts: Texts, form: Form? = null, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()): List<FormItem.ViewModel> {
        val item = form?.fields?.filter { it.page == state.page }?.map { field ->
            val value = state.fieldValues.firstOrNull { it.fieldKey == field.key }?.text ?: ""
            val creditor = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "CREDITOR" }?.text ?: "false"
            val excludeEmployment = texts.excludeEmployment.replace(" ", "").split(",")
            val employmentKeys = listOf("EMPLOY", "EMPLOYMENT_YEAR", "EMPLOYMENT_MONTH")
            val employment = excludeEmployment.contains(state.fieldValues.firstOrNull { it.fieldKey.rawValue == "EMPLOYMENT" }?.text)
            val showField = if (employment && employmentKeys.contains(field.key.rawValue)) false else if (field.key.rawValue == "CREDIT_AMOUNT") creditor.toBoolean() else !field.hidden

            val showAdone = when (field.style) {
                Field.Style.DownPayment -> texts.sek
                Field.Style.MonthlyIncome -> texts.sek
                Field.Style.MonthlyHousingCost -> texts.sek
                Field.Style.MonthlyOtherCost -> texts.sek
                Field.Style.Weight -> texts.kg
                else -> when (field.key.rawValue) {
                    "METER_SETTING" -> texts.km
                    "POWER" -> texts.hk
                    "PRICE", "OTHER_RESIDENT_COST", "CREDIT_AMOUNT" -> texts.sek
                    "LEASING_UPPER_BOUND" -> texts.percent
                    else -> null
                }
            }
            val inputType = when (field.style) {
                Field.Style.DownPayment -> DesignSystem.TextInputType.NUMBER
                Field.Style.MonthlyIncome -> DesignSystem.TextInputType.NUMBER
                Field.Style.ObjectPrice -> DesignSystem.TextInputType.NUMBER
                Field.Style.ManufactureYear -> DesignSystem.TextInputType.NUMBER
                Field.Style.Power -> DesignSystem.TextInputType.NUMBER
                Field.Style.MonthlyHousingCost -> DesignSystem.TextInputType.NUMBER
                Field.Style.MonthlyOtherCost -> DesignSystem.TextInputType.NUMBER
                Field.Style.Weight -> DesignSystem.TextInputType.NUMBER
                Field.Style.GovId -> DesignSystem.TextInputType.GOV_ID
                Field.Style.Email -> DesignSystem.TextInputType.EMAIL
                Field.Style.Phone -> DesignSystem.TextInputType.PHONE
                Field.Style.Number -> DesignSystem.TextInputType.NUMBER
                Field.Style.GovCode -> DesignSystem.TextInputType.NUMBER
                Field.Style.Zipcode -> DesignSystem.TextInputType.NUMBER
                Field.Style.Short -> {
                    when (field.key.rawValue) {
                        "CREDIT_AMOUNT", "OTHER_RESIDENT_COST" -> DesignSystem.TextInputType.NUMBER
                        else -> DesignSystem.TextInputType.TEXT
                    }
                }

                Field.Style.DriversLicense -> DesignSystem.TextInputType.NUMBER
                else -> DesignSystem.TextInputType.TEXT
            }
            val options = when (field.key.rawValue) {
                "CHILDREN" -> listOf(texts.minChildren, texts.maxChildren, texts.moreChildren)
                "MONTH" -> texts.repaymentMonth.trim().split(",").map { it.trim() }
                "EMPLOYMENT_MONTH" -> texts.employmentMonth.split(",").map { it.trim() }
                "EMPLOYMENT_YEAR" -> listOf(texts.employmentSinceYear)
                "EMPLOYMENT" -> texts.employment.split(",").map { it.trim() }
                "TYPE" -> texts.vehicleTypes.split(",").map { it.substringAfterLast("-").trim() }
                "CONDITION" -> texts.vehicleCondition.split(",").map { it.trim() }
                "RESIDENT_TYPE" -> texts.residentType.split(",").map { it.trim() }
                "FUEL_TYPE" -> texts.fuelType.split(",").map { it.trim() }
                else -> emptyList()
            }

            val autocomplete = when (field.style) {
                Field.Style.VehicleType -> true
                else -> false
            }

            when (val style = field.style) {
                is Field.Style.Inline -> when (field.key.rawValue) {
                    "LABEL" -> FormItem.label(title = field.fLabel, text = field.placeholder, key = field.key, info = field.hint, visible = !field.hidden)
                    "MARKDOWN_LABEL" -> FormItem.label(title = field.fLabel, text = field.placeholder, key = field.key, info = field.hint, visible = !field.hidden, isMarkdown = true)
                    "DISCOUNT_CODE" -> FormItem.header(title = field.fLabel, key = field.key, visible = !field.hidden, subTitle = texts.discountCodeTitle, value = texts.discountCode)
                    "DIVIDER" -> FormItem.divider(key = field.key, visible = !field.hidden)
                    "SUMMARY" -> {
                        val month = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "MONTH" }?.text ?: ""
                        val cashStake = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "CASH_STAKE" }?.text ?: ""
                        val interest = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "INTEREST_RATE" }?.text ?: ""
                        val price = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "PRICE" }?.text ?: ""
                        val residualValue = if (month.isNotEmpty()) state.fieldValues.firstOrNull { it.fieldKey.rawValue == "RESIDUAL_UPPER_BOUND" }?.text else null
                        val fkFee = texts.discount.toDoubleOrNull()?.let { texts.fkFee.toDoubleOrNull()?.minus(it) } ?: 0.0
                        val discountCode = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "DISCOUNT" }?.text ?: ""
                        val discountCodeAnswer = state.form?.fields?.firstOrNull { it.key.rawValue == "DISCOUNT" }?.answer
                        val summaryFkDiscount = if (discountCode == discountCodeAnswer) texts.summaryFkDiscount.replace("REPLACE", discountCode) else null
                        val loanAmount = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "LOAN" }?.text ?: ""
                        FormItem.summary(
                            bank = texts.wasa,
                            customerType = state.customerType,
                            key = field.key,
                            visible = !field.hidden,
                            month = month,
                            cashStake = cashStake,
                            interest = listOf(interest, texts.interest).joinToString(" "),
                            loan = loanAmount,
                            summaryPrice = listOf(texts.agreedPrice, formatNumber(price.toDouble()), texts.sek.lowercase()).joinToString(" "),
                            residualValue = if (residualValue != "0") listOf(residualValue, texts.percent).joinToString("") else null,
                            summaryLoanInfo = texts.summaryLoanInfo,
                            summaryBankBody = texts.summaryBankBody,
                            summaryLoanBody = texts.summaryLoanBody,
                            fkFee = fkFee.toString(),
                            residualValueInfo = texts.residualValueInfo,
                            residualValueTitle = texts.residualValueTitle,
                            summaryLoan = texts.summaryLoan,
                            summaryFkFee = texts.summaryFkFee,
                            summaryCashStake = texts.summaryCashStake,
                            installmentLeasing = texts.installmentLeasing,
                            installmentBuy = texts.installmentBuy,
                            summaryFkDiscount = summaryFkDiscount,
                            summaryDisclaimerWarranty = texts.summaryDisclaimerWarranty
                        )
                    }

                    "SUMMARY_MONTHLY_COST" -> {
                        val loanPeriodMonths = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "MONTH" }?.text?.toInt() ?: 0
                        val totalCost = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "PRICE" }?.text?.toLong() ?: 0
                        val residual = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "RESIDUAL_UPPER_BOUND" }?.text?.toDouble() ?: 0.0
                        val interest = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "INTEREST_RATE" }?.text?.toDouble() ?: 0.0

                        val annualInterestRate = (interest / 100.0)
                        val residualValuePercentage = residual / 100

                        // Calculations
                        val loanAmount = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "LOAN" }?.text?.toLong() ?: 0
                        val monthlyInterestRate = annualInterestRate / 12
                        val residualValue = totalCost * residualValuePercentage

                        // The amount that needs to be amortized (excluding the residual value)
                        val amortizedAmount = loanAmount - residualValue

                        // Monthly payment formula for an amortizing loan
                        val monthlyPayment = (amortizedAmount * (monthlyInterestRate * (1 + monthlyInterestRate).pow(loanPeriodMonths.toDouble()))) / ((1 + monthlyInterestRate).pow(loanPeriodMonths.toDouble()) - 1)

                        // Interest on the residual value
                        val interestOnResidual = residualValue * monthlyInterestRate

                        // Total monthly payment including interest on residual value
                        val totalMonthlyPayment = (monthlyPayment + interestOnResidual).roundToInt()
                        FormItem.summaryMonthlyCost(key = field.key, visible = !field.hidden, monthCost = listOf(formatNumber(totalMonthlyPayment.toDouble()), texts.monthCost).joinToString(""))
                    }

                    "SUMMARY_TOTAL_LOAN" -> {
                        val loanAmount = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "LOAN" }?.text?.toLong() ?: 0

                        FormItem.summaryTotalLoan(key = field.key, visible = !field.hidden, loan = texts.loanAmount.replace("REPLACE", formatNumber(loanAmount.toDouble())))
                    }

                    "SUMMARY_SERVICES_INCLUDED" -> {
                        val tryWarranty = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "TRY_GUARANTEE" }?.text
                        val tryValue = if (tryWarranty == texts.tryGuarantee1monthLabel) true else if (tryWarranty == texts.tryGuarantee3monthLabel) true else false
                        val warranty = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "WARRANTY" }?.text

                        FormItem.summaryServicesIncluded(key = field.key, visible = !field.hidden, tryWarranty = tryValue, warranty = if (warranty != "-") warranty else null, servicesIncludedTitle = texts.servicesIncludedTitle, tryWarrantyTitle = texts.tryWarranty, countExample = texts.countExample)
                    }

                    "WARRANTY" -> {
                        val type = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "TYPE" }?.text ?: ""
                        val weight = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "WEIGHT" }?.text?.trim()
                        val power = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "POWER" }?.text?.trim()
                        val fuelType = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "FUEL_TYPE" }?.text ?: "Nej"
                        val warrantyOption = warranty(texts, state, weight, power, fuelType)

                        if (warrantyOption != null) {
                            FormItem.label(title = field.fLabel, text = warrantyOption, key = field.key, info = field.hint, visible = type.isNotEmpty() && weight?.isNotEmpty() == true, image = DesignSystem.Image.CHECK)
                        } else {
                            FormItem.none()
                        }

                    }

                    else -> FormItem.header(title = field.fLabel, key = field.key, visible = !field.hidden)
                }

                is Field.Style.RegistrationNumber -> FormItem.lookup(placeholder = field.fPlaceholder, label = field.fLabel, search = texts.vehicleInformation, info = field.hint, value = value, key = field.key, status = status, visible = !field.hidden, loading = true)
                is Field.Style.OccupationSinceYear, Field.Style.OccupationSinceMonth, Field.Style.VehicleType, Field.Style.EmploymentForm, Field.Style.NumberOfChildren, Field.Style.Accommodation, Field.Style.InstallmentMonths, Field.Style.FuelType -> {
                    FormItem.selectInput(optionList = options, placeholder = field.fPlaceholder, label = field.fLabel, info = field.hint, value = value, key = field.key, autocomplete = autocomplete, status = status, visible = showField)
                }

                is Field.Style.PurchaseDate -> FormItem.dateInput(placeholder = field.fPlaceholder, label = field.fLabel, info = field.hint, addon = showAdone, value = value, key = field.key, status = status, visible = !field.hidden)
                is Field.Style.Choice -> {
                    when (field.key.rawValue) {
                        "CIVIL_STATE" -> FormItem.selectInput(optionList = style.choices, placeholder = field.fPlaceholder, label = field.fLabel, info = field.hint, value = value, key = field.key, autocomplete = autocomplete, status = status, visible = !field.hidden)
                        "REAL_PRINCIPAL" -> {
                            val links = when (state.customerType) {
                                is CustomerType.Company -> listOf(texts.readMore to "${deployment.home}/doc/fordonskrediten_verklig_huvudman.pdf")
                                is CustomerType.Leasing -> listOf(texts.readMore to "${deployment.home}/doc/fordonskrediten_verklig_huvudman.pdf")
                                else -> emptyList()
                            }
                            val choices = style.choices.map { DesignSystem.RadioButton(title = it, value = it) }
                            FormItem.radioInput(label = field.fLabel, info = field.hint, key = field.key, choices = choices, checked = choices.firstOrNull { it.value == value }, status = status, visible = !field.hidden, links = links)
                        }

                        else -> {
                            val civilStateChoice = when (val civilChoice = form.fields.firstOrNull { it.key.rawValue == "CIVIL_STATE" }?.style) {
                                is Field.Style.Choice -> civilChoice.choices.firstOrNull()
                                else -> null
                            }
                            val civilState = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "CIVIL_STATE" }
                            val showSupplyResponsibility = if (field.key.rawValue == "SUPPLY_RESPONSIBILITY" && civilState?.text == civilStateChoice) true else if (field.key.rawValue == "SUPPLY_RESPONSIBILITY" && civilState?.text != civilStateChoice) false else !field.hidden
                            val choices = style.choices.map { DesignSystem.RadioButton(title = it, value = it) }
                            FormItem.radioInput(label = field.fLabel, info = field.hint, key = field.key, choices = choices, checked = choices.firstOrNull { it.value == value }, status = status, visible = showSupplyResponsibility)
                        }
                    }
                }

                is Field.Style.ObjectPrice -> {
                    val loan = countOnLoan(state.fieldValues, texts)
                    val fkFee = texts.discount.toDoubleOrNull()?.let { texts.fkFee.toDoubleOrNull()?.minus(it) } ?: 0.0
                    FormItem.label(title = field.fLabel, text = field.placeholder, key = field.key, info = field.hint, visible = !field.hidden, value = if (loan.toLong() > fkFee) "${formatNumber(loan.toDouble())} ${texts.sek}" else "-")
                }

                is Field.Style.Short -> when (field.key.rawValue) {
                    "CONDITION" -> {
                        val conditionText = texts.vehicleCondition.split(",").map { it.trim() }
                        val conditionValue = if (value == "true" || value == conditionText.firstOrNull()) "true" else if (value.isNullOrEmpty()) "" else "false"
                        FormItem.selectInput(optionList = options, placeholder = field.fPlaceholder, label = field.fLabel, info = field.hint, value = conditionValue, key = field.key, autocomplete = autocomplete, status = status, visible = !field.hidden)
                    }

                    "CREDITOR", "PROXY" -> {
                        val conditionText = texts.vehicleCondition.split(",").map { it.trim() }
                        val tryValue = if (value == "true" || value == conditionText.firstOrNull()) "true" else if (value.isNullOrEmpty()) "" else "false"
                        FormItem.checkbox(label = field.fLabel, info = field.hint, visible = !field.hidden, value = tryValue, key = field.key, status = status)
                    }

                    "GUARANTEE" -> FormItem.checkbox(label = field.fLabel, info = field.hint, visible = !field.hidden, value = value, key = field.key, status = status)
                    "TRY_GUARANTEE" -> {
                        val tryGuaranteeMakeIsOneMonth = texts.tryGuaranteeMake.replace(" ", "").split(",")
                        val make = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "MAKE" }?.text?.trim()
                        val type = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "TYPE" }?.text?.trim()
                        val label = if (tryGuaranteeMakeIsOneMonth.contains(make) || type != "Personbil") texts.tryGuarantee1monthLabel else texts.tryGuarantee3monthLabel
                        val hint = if (tryGuaranteeMakeIsOneMonth.contains(make) || type != "Personbil") texts.tryGuarantee1monthHint else texts.tryGuarantee3monthHint
                        val links = when (state.customerType) {
                            is CustomerType.Private -> listOf(texts.warrantyPDFHere to "${deployment.home}/doc/lansforsakringar_prova_pa.pdf")
                            is CustomerType.Company -> listOf(texts.warrantyPDFHere to "${deployment.home}/doc/lansforsakringar_prova_pa.pdf")
                            is CustomerType.Leasing -> listOf(texts.warrantyPDFHere to "${deployment.home}/doc/lansforsakringar_prova_pa.pdf")
                            else -> emptyList()
                        }
                        val tryValue = if ((value == "true") || (value == label)) true else false
                        FormItem.checkbox(label = label, info = hint, visible = showField, value = tryValue.toString(), key = field.key, status = status, textWithLink = texts.warrantyPDF, links = links)
                    }

                    "PEP" -> {
                        val links = when (state.customerType) {
                            is CustomerType.Private -> listOf(texts.click to "${deployment.home}/doc/fordonskrediten_penningtvatt-och-pep.pdf")
                            is CustomerType.Company -> listOf(texts.click to "${deployment.home}/doc/fordonskrediten_penningtvatt-och-pep.pdf")
                            is CustomerType.Leasing -> listOf(texts.click to "${deployment.home}/doc/fordonskrediten_penningtvatt-och-pep.pdf")
                            else -> emptyList()
                        }
                        val conditionText = texts.vehicleCondition.split(",").map { it.trim() }
                        val conditionValue = if (value == "true" || value == conditionText.firstOrNull()) "true" else if (value.isNullOrEmpty()) "" else "false"

                        FormItem.checkbox(label = field.fLabel, info = field.hint, visible = !field.hidden, value = conditionValue, key = field.key, status = status, textWithLink = texts.pepHelp, links = links)
                    }

                    else -> FormItem.textInput(placeholder = field.fPlaceholder, label = field.fLabel, info = field.hint, addon = showAdone, inputType = inputType, visible = showField, value = value, key = field.key, status = status)
                }

                else -> FormItem.textInput(placeholder = field.fPlaceholder, label = field.fLabel, info = field.hint, addon = showAdone, inputType = inputType, visible = showField, value = value, key = field.key, status = status)
            }
        } ?: emptyList()

        return item
    }
}