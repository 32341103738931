package components

import StoreContext
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.main
import react.router.Outlet
import react.router.useLocation
import react.router.useNavigate
import react.router.useParams
import react.useContext
import support.CustomerType
import support.storeUpdates
import support.useAsyncEffectOnce
import techla.base.techla_log
import web.cssom.*

val fLayout = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    val navigate = useNavigate()
    val local = useLocation()
    val params = useParams()
    val type = params["type"]
    val login = listOf(Routes.login, Routes.loginApplyPrivate, Routes.loginMatters, Routes.loginBackOffice, Routes.loginApplyCompany, Routes.loginApplyLeasing, Routes.preview)

    val logoutTo = when (CustomerType.fromRawValue(type)) {
        is CustomerType.Private -> Routes.loginApplyPrivate
        is CustomerType.Company -> Routes.loginApplyCompany
        is CustomerType.Leasing -> Routes.loginApplyLeasing
        else -> when (local.pathname) {
            Routes.preview -> Routes.preview
            Routes.backOffice, Routes.backOfficeMatters, Routes.commitment, Routes.customer -> Routes.loginBackOffice
            else -> Routes.loginMatters
        }
    }

    useAsyncEffectOnce {
        storeUpdates.collect { isSignedIn ->
            if (!isSignedIn && login.firstOrNull { it == local.pathname } == null) {
                techla_log("LAYOUT: Choosing to logout")
                navigate(logoutTo)
            } else {
                techla_log("LAYOUT: Signed in")
            }
        }
    }

    main {
        css {
            height = 100.pct
            display = Display.flex
            justifyContent = JustifyContent.center
        }
        Outlet {}
    }
}