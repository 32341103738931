package views

import Routes
import StoreContext
import components.fButton
import components.*
import components.fLoader
import components.items.FormItemCheckbox
import components.items.FormItemLabel
import components.items.FormItemTextInput
import js.core.jso
import mui.material.*
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useNavigate
import react.router.useParams
import screens.CustomerScreen
import support.*
import techla.base.Identifier
import web.cssom.*


val Customer = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: CustomerScreen.ViewModel by useState(CustomerScreen.ViewModel.None)
    val navigate = useNavigate()
    val params = useParams()

    useAsyncEffectOnce {
        CustomerScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce {
        params["profileId"]?.let { profileId ->
            params["submissionId"]?.let { submissionId ->
                CustomerScreen.load(sceneInputOf(store, viewModel), Identifier(profileId), Identifier(submissionId))
            }
        }
    }

    fun handleLogout() = call {
        CustomerScreen.logout(sceneInputOf(store, viewModel))
        navigate(Routes.loginBackOffice, jso { replace = true; state = null })
    }


    fun recover() = call {
        if (params["profileId"] != null && params["submissionId"] != null)
            CustomerScreen.success(sceneInputOf(store, viewModel))
        else
            navigate(Routes.backOffice)
    }

    fun handleBack() = call {
        navigate(Routes.commitment)
    }

    fun handleGoTo(option: DesignSystem.Option?) {
        navigate("${Routes.backOffice}/${option?.value}")
    }


    fun handlePagination(page: Int?, row: Int?) = call {
        CustomerScreen.pagination(sceneInputOf(store, viewModel), page = page, row = row)
    }

    fun handleModal(value: Boolean) = call {
        CustomerScreen.modal(sceneInputOf(store, viewModel))
    }

    fun handleAnonymizeCustomer() = call {
        CustomerScreen.anonymizeCustomer(sceneInputOf(store, viewModel))
    }

    fun handleCreateCVS() = call {
        CustomerScreen.createCVG(sceneInputOf(store, viewModel))
    }

    FScaffold {
        //  this.navigation = viewModel.navigation
        isFullScreen = when (viewModel) {
            is CustomerScreen.ViewModel.Ready -> true
            else -> false
        }

        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }


        viewModel.asReady?.run {
            fAppbar { design = navigation; onClick = { handleLogout() } }
            fButton { design = back; onClick = { handleBack() } }

            Stack {
                spacing = responsive(2)

                sx {
                    alignItems = AlignItems.center
                    gap = 0.5.rem
                }
                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center

                        maxWidth = responsive(
                            Breakpoint.xs to 35.pct,
                            Breakpoint.lg to 50.pct
                        )
                    }

                    fText { design = customerTitle }

                }
                Grid {
                    container = true
                    spacing = responsive(2)

                    Grid {
                        item = true
                        xs = 12
                        lg = 8

                        Paper {
                            Stack {
                                spacing = responsive(2)
                                sx {
                                    backgroundColor = Color(Palette.Background.paper)
                                    borderRadius = Shape.borderRadius10
                                    padding = Padding(1.rem, 1.rem)
                                    width = 100.pct
                                    marginBottom = 1.rem
                                }
                                Stack {
                                    direction = responsive(StackDirection.row)
                                    sx {
                                        justifyContent = JustifyContent.spaceBetween
                                        alignItems = AlignItems.center
                                    }
                                    fText { design = personalData }
                                }
                                Stack {
                                    spacing = responsive(2)
                                    submission.map { item ->
                                        when (item) {
                                            is FormItem.ViewModel.TextInput -> FormItemTextInput { this.viewModel = item }
                                            is FormItem.ViewModel.Label -> FormItemLabel { this.viewModel = item }
                                            is FormItem.ViewModel.Checkbox -> FormItemCheckbox { this.viewModel = item }
                                            else -> {}
                                        }
                                    }
                                }
                            }
                        }

                        Paper {
                            Stack {
                                spacing = responsive(2)
                                sx {
                                    backgroundColor = Color(Palette.Background.paper)
                                    borderRadius = Shape.borderRadius10
                                    padding = Padding(1.rem, 1.rem)
                                    width = 100.pct
                                    marginBottom = 1.rem
                                }
                                fTable { design = groups; onClick = ::handleGoTo }
                                Stack {
                                    sx {
                                        alignItems = AlignItems.flexEnd

                                    }
                                    fPagination { design = pagination; onChange = ::handlePagination }
                                }
                            }
                        }

                    }
                    Grid {
                        this.item = true
                        xs = 12
                        lg = 4
                        Stack {
                            spacing = responsive(2)

                            sx {
                                alignItems = AlignItems.start
                                gap = 0.5.rem
                            }

                            fButton { design = create; onClick = ::handleCreateCVS }
                            fButton { design = anonymizeBtn; onClick = { handleModal(true) } }
                            Box {
                                Divider {
                                    sx {
                                        backgroundColor = Palette.Text.primary
                                        marginTop = important(1.rem)
                                    }
                                }
                            }
                        }
                    }
                }
            }

            fModal { design = anonymize; onClose = { handleModal(false) }; onClickFirst = ::handleAnonymizeCustomer; onClickSecond = { handleModal(false) } }
            fModal { design = csvModal; onClose = { handleModal(false) } }
        }



        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}