package components

import emotion.react.css
import js.core.jso
import mui.material.Box
import mui.material.Typography
import mui.material.TypographyAlign
import mui.system.sx
import react.FC
import react.PropsWithClassName
import react.dom.html.ReactHTML
import remarkable.Remarkable
import support.*
import web.cssom.*
import web.window.WindowTarget

external interface FTextProps : PropsWithClassName {
    var design: DesignSystem.Text?
    var onDoubleClick: (() -> Unit)?
}

val remarkable = Remarkable(null, null)

val fText = FC<FTextProps> { props ->
    val textAlign = when (props.design?.align) {
        DesignSystem.TextAlign.CENTER -> TypographyAlign.center
        DesignSystem.TextAlign.RIGHT -> TypographyAlign.right
        else -> TypographyAlign.left
    }

    val color = when (props.design?.color) {
        DesignSystem.Color.DISABLED -> Color(Palette.Text.disabled.toString())
        DesignSystem.Color.PRIMARY -> Color(Palette.Primary.main)
        DesignSystem.Color.WARNING -> Color(Palette.Warning.main)
        DesignSystem.Color.SUCCESS -> Color(Palette.Success.main)
        DesignSystem.Color.DANGER -> Color(Palette.Danger.main)
        DesignSystem.Color.TEXT -> Palette.Text.primary
        else -> Color(Palette.Text.primary.toString())
    }

    if (props.design?.visible == true) {
        val design = props.design!!
        if (design.isMarkdown) {
            Box {
                className = props.className
                dangerouslySetInnerHTML = jso {
                    __html = remarkable.render(design.text ?: "", null)
                }
            }

        } else {

            Typography {
                variant = Support.typographyVariant(design.size, design.style)
                align = textAlign
                onDoubleClick = { props.onDoubleClick?.let { it() } }
                className = props.className

                sx {
                    if (props.design?.color != null)
                        this.color = important(color)
                }

                +design.text
            }
        }
    }
}


external interface FTextWithLinks : PropsWithClassName {
    var design: DesignSystem.TextWithLinks
}


val fTextWithLinks = FC<FTextWithLinks> { props ->
    val color = when (props.design.color) {
        DesignSystem.Color.DISABLED -> Color(Palette.Text.disabled.toString())
        DesignSystem.Color.PRIMARY -> Color(Palette.Primary.main)
        DesignSystem.Color.WARNING -> Color(Palette.Warning.main)
        DesignSystem.Color.SUCCESS -> Color(Palette.Success.main)
        DesignSystem.Color.TEXT -> Palette.Text.primary
        else -> Color(Palette.Text.primary.toString())
    }

    val textAlign = when (props.design.align) {
        DesignSystem.TextAlign.CENTER -> TextAlign.center
        DesignSystem.TextAlign.RIGHT -> TextAlign.right
        else -> TextAlign.left
    }

    if (props.design.visible)
        Box {
            sx {
                this.textAlign = textAlign
                width = 100.pct
            }
            props.design.links.mapIndexed { index, link ->
                val part = props.design.text?.split("{SPLIT}")

                Typography {
                    variant = Support.typographyVariant(props.design.size, props.design.style)
                    align = TypographyAlign.center
                    sx {
                        this.color = important(color)
                        display = Display.inline
                    }
                    if (part?.isNotEmpty() == true)
                        +part[index]
                    else
                        +props.design.text
                }
                ReactHTML.a {
                    target = WindowTarget._blank
                    href = link.second
                    +link.first
                    css {
                        textDecorationColor = Color(Palette.transparent)
                        this.color = Color(Palette.Primary.main)
                        fontWeight = FontWeight.bold
                    }
                }
            }
        }
}

