package views

import components.*
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.*
import screens.CommitmentScreen
import support.*
import techla.base.techla_log
import web.cssom.*

val Preview = FC<Props> {
    var modal: Boolean by useState(false)
    fun dropDown(value: String) {
        //techla_log("dropDown: $value")
    }

    fun onChange(value: Boolean) {
        //  techla_log("onChange: $value")
    }

    val double = 1000000000000000000.0
    val int = 1000000000
    //techla_log("formatNumber: ${formatNumber(int)}")
    //techla_log("formatNumber: ${formatNumber(double)}")

    fun handlePagination(page: Int?, row: Int?) = call {
        techla_log("page: $page row: $row")
    }

    val text = "Be säljaren logga in på {SPLIT} och mata in nedanstående kod för att signera avtalet och därmed fortsätta affären. Du kommer därefter bli kontaktad av Fordonskrediten som ger dig vidare information {SPLIT}"
    val links = listOf("personuppgiftspolicy" to "${deployment.home}/doc/fordonskrediten_policy.pdf", "" to "")


    Stack {
        spacing = responsive(2)

        sx {
            width = 50.pct
            margin = Margin(7.rem, 0.rem, 0.rem, 0.rem)
            padding = Padding(1.rem, 1.rem)
            alignItems = AlignItems.center
            gap = 0.5.rem
            backgroundColor = Color(Palette.Background.paper)
        }

        fAppbar {
            design = DesignSystem.Navigation(
                title = "Fordonskrediten", background = DesignSystem.Background.DARK,
                menu = DesignSystem.Menu(
                    title = "profile name", items = listOf(
                        DesignSystem.Option.item(title = "Logga ut", action = DesignSystem.Action.LOGOUT),
                    )
                ),
                navigation = DesignSystem.Button(text = "Navigation button"),
                links = listOf(
                    DesignSystem.Option.item(title = "Affärer", location = Location.BackOffice),
                    DesignSystem.Option.item(title = "Engagemang", location = Location.Commitment),
                ),
                selectedLink = DesignSystem.Option.item(title = "Engagemang", action = DesignSystem.Action.LOGOUT)
            )
        }
        fMenuButton {
            design = DesignSystem.Menu(
                title = "ANSÖKA TILL FORDON", items = listOf(
                    DesignSystem.Option.item(title = "Privat", value = CustomerType.Private.rawValue),
                    DesignSystem.Option.item(title = "Företag", value = CustomerType.Company.rawValue)
                )
            )
            onClick = ::dropDown
        }

        fTextWithLinks { design = DesignSystem.TextWithLinks(text = text, links = links) }

        fSwitch { design = DesignSystem.Switch(label = "Visa endast pågående", checked = false, status = DesignSystem.Status.Invalid(warning = "Fel")); this.onChange = ::onChange; }

        fTextInput { design = DesignSystem.TextInput(header = CommitmentScreen.Header.search, placeholder = "Sök", uppercase = true, adornmentImage = DesignSystem.Image.SEARCH, adornmentType = DesignSystem.AdornmentPosition.START); onChange = ::dropDown; this.onEnter = ::dropDown; this.onClick = ::dropDown }
        fTextInput { design = DesignSystem.TextInput(header = CommitmentScreen.Header.search, label = "Vikt", uppercase = true, adornment = "KG", adornmentType = DesignSystem.AdornmentPosition.END); onChange = ::dropDown; this.onEnter }

        fRadioInput { design = DesignSystem.RadioInput(buttons = listOf(DesignSystem.RadioButton(title = "texts.govId", value = "GOV_ID"), DesignSystem.RadioButton(title = "texts.regnumber", value = "REGNO")), checked = DesignSystem.RadioButton(title = "texts.govId", value = "")); this.onChange = ::dropDown }

        fPagination { design = DesignSystem.Pagination(currentPage = 1, rowsPerPage = 10, totalAmountOfItems = 100); onChange = ::handlePagination }
        fButton { design = DesignSystem.Button(text = "Öppna modal", type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.CONTAINED); onClick = { modal = true } }
        fModal {
            design = DesignSystem.Modal(
                visible = modal,
                title = DesignSystem.Text(text = "Är du säker?", size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD),
                body = listOf(DesignSystem.Text(text = "Lorem ipsum dolor sit amet consectetur. Pulvinar quam viverra egestas et. Curabitur turpis nibh sed elementum at ac platea. Sed ultrices fermentum urna non enim. Enim scelerisque urna in platea vestibulum scelerisque.", size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR)),
                firstButton = DesignSystem.Button(text = "JA", type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.CONTAINED),
                secondButton = DesignSystem.Button(text = "NEJ", type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE)
            );
            onClose = { modal = false }
            onClickSecond = { modal = false }
        }
    }
}
