package components.items

import components.*
import mui.material.Box
import mui.material.Divider
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.FormItem
import support.Palette
import web.cssom.*


external interface FormEditableProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.EditableItem
    var submit: () -> Unit
    var edit: () -> Unit
    var save: () -> Unit
    var cancel: () -> Unit
}


val formEditable = FC<FormEditableProps> { props ->
    if (props.viewModel.visible)
        Stack {
            spacing = responsive(2)

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(1)
                sx {
                    maxWidth = 350.px
                }
                fButton { design = props.viewModel.edit; onClick = { props.edit() } }
                fButton { design = props.viewModel.save; onClick = { props.save() } }
                fButton { design = props.viewModel.cancel; onClick = { props.cancel() } }
            }

            fButton { design = props.viewModel.pay; onClick = { props.submit() } }
            if (props.viewModel.status.text != null)
                Box {
                    sx {
                        overflow =  Overflow.scroll
                        boxSizing = BoxSizing.borderBox
                        padding = Padding(1.rem, 1.rem)
                        backgroundColor = Color(Palette.Primary.main + support.Opacity.opacity25)
                    }
                    fText { design = props.viewModel.status }
                }
        }
}