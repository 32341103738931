package components.items

import components.*
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.useState
import support.DesignSystem
import support.FormItem
import web.cssom.*


external interface FormSummaryProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.Summary
}


val FormItemSummary = FC<FormSummaryProps> { props ->
    var open: Boolean by useState(false)

    fun handleModal(value: Boolean) {
        open = value
    }

    if (props.viewModel.visible)
        Stack {
            spacing = responsive(2)
            sx {
                paddingBottom = 1.rem
            }

            Stack {
                sx {
                    alignItems = AlignItems.center
                }
                fText { design = props.viewModel.summaryPrice }
                fText { design = props.viewModel.summaryFkFee }
                fText { design = props.viewModel.summaryCashStake }
                fText { design = props.viewModel.summaryLoan }
                fText { design = props.viewModel.summaryMonth }
                fText { design = props.viewModel.summaryFkDiscount }

            }
            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(2)
                sx {
                    justifyContent = JustifyContent.spaceBetween
                }
                Grid {
                    item = true
                    container = true
                    fText { design = props.viewModel.bank }

                }
                Grid {
                    item = true
                    container = true
                    sx {
                        justifyContent = JustifyContent.end
                    }
                    fText { design = props.viewModel.interest }

                }
            }

            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(1)
                fText { design = props.viewModel.bankBody }
                fText { design = props.viewModel.otherBody }
                fText { design = props.viewModel.summaryDisclaimerWarranty }
            }



            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(2)
                sx {
                    justifyContent = JustifyContent.spaceBetween
                }
                Stack {
                    direction = responsive(StackDirection.column)
                    fText { design = props.viewModel.residualValueTitle }
                    fText { design = props.viewModel.residualValue }
                }
                Stack {
                    direction = responsive(StackDirection.column)
                    sx {
                        justifyContent = JustifyContent.end
                    }
                    props.viewModel.residualValueInfo.let {
                        if (it != null) {
                            Box {
                                sx {
                                    marginLeft = 1.rem
                                }
                                fInfoButton {

                                    infoText = it
                                }
                            }
                        }
                    }
                }
            }

            fModal { design = DesignSystem.Modal(body = listOf(DesignSystem.Text(text = props.viewModel.loanInfo, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR)), visible = open); onClose = ::handleModal }
        }
}


