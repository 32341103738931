package screens

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import services.*
import support.*
import techla.base.*
import techla.payment.Payment
import techla.payment.Provider

object PaymentScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    data class Texts(
        val title: String,
        val body: String,
        val yes: String,
        val no: String,
        val payoffExcess: String,
        val payoffResidualDept: String,
        val back: String,
        val next: String,
        val successTitle: String,
        val sek: String,
        val deniedTitle: String,
        val further: String,
        val expireTitle: String,
        val expireBody: String,
        val resume: String,
        val scanQr: String,
        val openApp: String,
        val fordonskrediten: String,
        val paymentSignatureMissing: String,
        val trustlyError: String,
        val cantStartPayment: String,
        val missingQR: String,
        override val failureTitle: String,
        override val failureReason: String,
    ) : FailureTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
        val showPaymentModal: PAYMENT = PAYMENT.NONE,
        val qrCode: String? = null,
        val startSign: Boolean = false,
        val startCheck: Boolean = false,
        val payment: Payment? = null,
    )

    enum class PAYMENT { NONE, EXCESS, RESIDUAL_DEBT }
    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val confirmTitle: DesignSystem.Text,
            val confirmBody: DesignSystem.Text,
            val scanQr: DesignSystem.Text,
            val openApp: DesignSystem.Text,
        ) : ViewModel(texts, state, navigation)

        data class Expired(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val resume: DesignSystem.Button = DesignSystem.Button(),
            val expireTitle: DesignSystem.Text,
            val expireBody: DesignSystem.Text,
            val expireOut: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation)

        data class Success(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val successTitle: DesignSystem.Text,
            val next: DesignSystem.Button,
            val success: DesignSystem.ImageView,
            val objId: Identifier<Object>,
        ) : ViewModel(texts, state, navigation)

        data class Denied(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val deniedTitle: DesignSystem.Text,
            val next: DesignSystem.Button,
            val denied: DesignSystem.ImageView,
            val objId: Identifier<Object>
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(state: State): ViewModel = Loading(texts = texts, state = state, navigation = navigation)

        fun ready(texts: Texts, state: State) =
            Ready(
                texts = texts, state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.fordonskrediten, action = DesignSystem.Action.BACK, location = Location.BackOffice),
                confirmTitle = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                confirmBody = DesignSystem.Text(text = texts.body, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.TEXT),
                scanQr = DesignSystem.Text(text = texts.scanQr, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                openApp = DesignSystem.Text(text = texts.openApp, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
            )

        fun expired(texts: Texts, state: State): ViewModel =
            Expired(
                texts = texts,
                state = state,
                navigation = navigation,
                expireTitle = DesignSystem.Text(text = texts.expireTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                expireBody = DesignSystem.Text(text = texts.expireBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                expireOut = DesignSystem.ImageView(image = DesignSystem.Image.EXPIREIMAGE),
                resume = DesignSystem.Button(text = texts.resume),
            )

        fun success(texts: Texts, state: State) =
            Success(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.BACK, location = Location.BackOffice),
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON),
                successTitle = DesignSystem.Text(text = texts.successTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.SUCCESS),
                success = DesignSystem.ImageView(image = DesignSystem.Image.CONTRACT),
                objId = state.obj.id,
            )

        fun denied(texts: Texts, state: State) =
            Denied(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.fordonskrediten, action = DesignSystem.Action.BACK, location = Location.BackOffice),
                next = DesignSystem.Button(text = texts.further, type = DesignSystem.Button.Type.BUTTON),
                deniedTitle = DesignSystem.Text(text = texts.deniedTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.DANGER),
                denied = DesignSystem.ImageView(image = DesignSystem.Image.CONTRACT),
                objId = state.obj.id,
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading

        val asReady get() = this as? Ready

        val asExpired get() = this as? Expired
        val asSuccess get() = this as? Success
        val asDenied get() = this as? Denied
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.expired(texts: Texts, state: State) =
        sceneOf(viewModel.expired(texts, state))

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    private suspend fun loadText(store: Store) =
        store.findMedias()
            .map { (actions, _) ->
                val updated = store.reduce(actions)
                val texts = Texts(
                    title = updated.get(media = Key("screen:payment"), content = Key("title")),
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    back = updated.get(media = Key("screen:payment"), content = Key("back")),
                    yes = updated.get(media = Key("screen:payment"), content = Key("yes")),
                    no = updated.get(media = Key("screen:payment"), content = Key("no")),
                    sek = updated.get(media = Key("screen:payment"), content = Key("sek")),
                    payoffExcess = updated.get(media = Key("screen:payment"), content = Key("payoffExcess")),
                    payoffResidualDept = updated.get(media = Key("screen:payment"), content = Key("payoffResidualDept")),
                    next = updated.get(media = Key("screen:payment"), content = Key("next")),
                    successTitle = updated.get(media = Key("screen:payment"), content = Key("successTitle")),
                    deniedTitle = updated.get(media = Key("screen:payment"), content = Key("deniedTitle")),
                    further = updated.get(media = Key("screen:payment"), content = Key("further")),
                    expireTitle = updated.get(media = Key("screen:payment"), content = Key("expireTitle")),
                    expireBody = updated.get(media = Key("screen:payment"), content = Key("expireBody")),
                    resume = updated.get(media = Key("screen:payment"), content = Key("resume")),
                    scanQr = updated.get(media = Key("screen:payment"), content = Key("scanQr")),
                    openApp = updated.get(media = Key("screen:payment"), content = Key("openApp")),
                    body = updated.get(media = Key("screen:payment"), content = Key("body")),
                    fordonskrediten = updated.get(media = Key("screen:payment"), content = Key("fordonskrediten")),
                    paymentSignatureMissing = updated.get(media = Key("screen:payment"), content = Key("paymentSignatureMissing")),
                    trustlyError = updated.get(media = Key("screen:payment"), content = Key("trustlyError")),
                    cantStartPayment = updated.get(media = Key("screen:payment"), content = Key("cantStartPayment")),
                    missingQR = updated.get(media = Key("screen:payment"), content = Key("missingQR")),
                )

                tupleOf(actions, texts)

            }

    suspend fun payment(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, payment: String) {
        val (store, viewModel) = scene
        val paymentType = when (payment) {
            PAYMENT.EXCESS.name -> PAYMENT.EXCESS
            PAYMENT.RESIDUAL_DEBT.name -> PAYMENT.RESIDUAL_DEBT
            else -> PAYMENT.NONE
        }
        if (paymentType == PAYMENT.NONE) return updates.emit(scene.failed(message = "Missing payment type"))

        updates.emit(sceneOf(viewModel.loading(viewModel.state)))

        store.adminRefreshObject(objectId)
            .flatMap { (actions, full) ->
                loadText(store)
                    .accumulate(actions)
                    .map { tupleOf(it.first, full, it.second) }
            }
            .flatMap { (actions, full, texts) ->
                val companyName = getValue(full.submissionSeller?.entries, "COMPANY_NAME")?.trim()
                val message = if (paymentType == PAYMENT.EXCESS) texts.payoffExcess else texts.payoffResidualDept

                val clearingNumber = if (paymentType == PAYMENT.EXCESS) getValue(full.submissionSeller?.entries, "CLEARING_NUMBER") else getValue(full.submissionSeller?.entries, "CLEARING_NUMBER_RESIDUAL_DEBT")
                val accountNumber = if (paymentType == PAYMENT.EXCESS) getValue(full.submissionSeller?.entries, "BANK_ACCOUNT_NUMBER") else getValue(full.submissionSeller?.entries, "BANK_ACCOUNT_NUMBER_RESIDUAL_DEBT")
                val amount = if (paymentType == PAYMENT.EXCESS) getValue(full.submissionSeller?.entries, "EXCESS")?.replace("\\s".toRegex(), "")?.substringBefore(":")?.toDouble() ?: 0.0 else getValue(full.submissionSeller?.entries, "RESIDUAL_DEBT")?.replace("\\s".toRegex(), "")?.toDouble() ?: 0.0

                val create = Payment.Create(
                    provider = Provider.TRUSTLY,
                    amount = Amount(amount, "SEK"),
                    creditor = Payment.Creditor.BBAN(
                        clearingNumber = clearingNumber?.replace("\\s".toRegex(), "")!!,
                        accountNumber = accountNumber?.replace("\\s".toRegex(), "")!!,
                        govId = null,
                        firstName = companyName ?: getValue(full.submissionSeller?.entries, "FIRST_NAME")?.trim(),
                        lastName = if (companyName.isNullOrEmpty()) getValue(full.submissionSeller?.entries, "LAST_NAME")?.trim() else "",
                    ),
                    debtor = Payment.Debtor.Merchant,
                    reference = paymentType.name,
                    remittance = Payment.Remittance.Message(listOf(message, getValue(full.submissionSeller?.entries, "REGNO")).joinToString(" ")),
                )
                store.reduce(actions).createPaymentForPayout(full, create)
                    .accumulate(actions)
                    .map { tupleOf(it.first, full, texts, it.second) }
            }
            .flatMap { (actions, full, texts, payment) ->
                store.reduce(actions).findMedias()
                    .accumulate(actions)
                    .map { tupleOf(it.first, full, texts, payment, it.second) }
            }
            .map { (actions, full, texts, payment) ->

                if (payment.status == Payment.Status.Pending) {
                    val qrCode = (payment.provider.status as? Provider.Status.SignatureRequired)?.qrCode
                    val state = viewModel.state.copy(showPaymentModal = paymentType, obj = full, startSign = true, qrCode = qrCode, payment = payment)

                    updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = texts, state = state), actions))
                } else {
                    updates.emit(
                        scene.expired(
                            state = viewModel.state.copy(startSign = false),
                            texts = viewModel.texts.copy(
                                expireTitle = texts.missingQR,
                                expireBody = texts.cantStartPayment,
                                resume = texts.back,
                            )
                        )
                    )
                }

            }.onNotSuccess { updates.emit(scene.failed(it)) }
    }


    suspend fun check(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene

        store.getPayment(viewModel.state.obj, viewModel.state.payment?.id!!)
            .map { (actions, payment) ->
                if (payment.status == Payment.Status.Pending) {
                    val qrCode = (payment.provider.status as? Provider.Status.SignatureRequired)?.qrCode
                    val state = viewModel.state.copy(payment = payment, startSign = true, qrCode = qrCode)
                    updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state), actions))
                    return@check when (payment.status) {
                        is Payment.Status.Pending, Payment.Status.Cancelled ->
                            when (payment.provider.status) {
                                is Provider.Status.SignatureRequired -> updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state.copy(startSign = true)), actions))
                                is Provider.Status.TrustlyPayout -> updates.emit(sceneOf<ViewModel>(viewModel.success(texts = viewModel.texts, state = state.copy(startCheck = true, startSign = false)), actions))
                                is Provider.Status.Failed -> {
                                    val reason = if ((payment.provider.status as? Provider.Status.Failed)?.reason?.isNotEmpty() == true) (payment.provider.status as? Provider.Status.Failed)?.reason?.replace("\"", " ")?.replace(Regex("[{},]"), " ") ?: viewModel.texts.trustlyError else viewModel.texts.trustlyError
                                    updates.emit(sceneOf<ViewModel>(viewModel.denied(texts = viewModel.texts.copy(deniedTitle = reason), state = state.copy(startCheck = false, startSign = false)), actions))
                                }

                                else -> updates.emit(sceneOf<ViewModel>(viewModel.loading(state = state.copy(startCheck = true, startSign = false)), actions))
                            }

                        is Payment.Status.Completed -> updates.emit(sceneOf<ViewModel>(viewModel.success(texts = viewModel.texts, state = state.copy(startCheck = false, startSign = false)), actions))
                        is Payment.Status.Failed -> {
                            val reason = if ((payment.provider.status as? Provider.Status.Failed)?.reason?.isNotEmpty() == true) (payment.provider.status as? Provider.Status.Failed)?.reason?.replace("\"", " ")?.replace(Regex("[{},]"), " ") ?: viewModel.texts.trustlyError else viewModel.texts.trustlyError
                            return updates.emit(scene.failed(reason))
                        }

                        is Payment.Status.Authenticating -> updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state), actions))
                        is Payment.Status.Processing -> updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state), actions))
                        else -> updates.emit(scene.failed("Unknown Error"))
                    }
                } else {
                    val state = viewModel.state.copy(payment = payment, startSign = false, qrCode = null, startCheck = true)
                    return@check when (payment.status) {
                        is Payment.Status.Pending, is Payment.Status.Cancelled ->
                            when (payment.provider.status) {
                                is Provider.Status.SignatureRequired -> updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state.copy(startSign = true, startCheck = false)), actions))
                                is Provider.Status.TrustlyPayout -> updates.emit(sceneOf<ViewModel>(viewModel.success(texts = viewModel.texts, state = state.copy(startCheck = true, startSign = false)), actions))
                                is Provider.Status.Failed -> {
                                    val reason = if ((payment.provider.status as? Provider.Status.Failed)?.reason?.isNotEmpty() == true) (payment.provider.status as? Provider.Status.Failed)?.reason?.replace("\"", " ")?.replace(Regex("[{},]"), " ") ?: viewModel.texts.trustlyError else viewModel.texts.trustlyError
                                    updates.emit(sceneOf<ViewModel>(viewModel.denied(texts = viewModel.texts.copy(deniedTitle = reason), state = state.copy(startCheck = false, startSign = false)), actions))
                                }

                                else -> updates.emit(sceneOf<ViewModel>(viewModel.loading(state = state), actions))
                            }

                        is Payment.Status.Completed -> updates.emit(sceneOf<ViewModel>(viewModel.success(texts = viewModel.texts, state = state.copy(startCheck = false, startSign = false)), actions))
                        is Payment.Status.Failed -> {
                            when (payment.provider.status) {
                                is Provider.Status.SignatureRequired -> return updates.emit(scene.failed(viewModel.texts.paymentSignatureMissing))
                                else -> {
                                    val reason = if ((payment.provider.status as? Provider.Status.Failed)?.reason?.isNotEmpty() == true) (payment.provider.status as? Provider.Status.Failed)?.reason?.replace("\"", " ")?.replace(Regex("[{},]"), " ") ?: "Trustly - Unknown Error" else "Trustly - Unknown Error"
                                    return updates.emit(scene.failed(reason))
                                }
                            }
                        }

                        is Payment.Status.Authenticating -> updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state), actions))
                        is Payment.Status.Processing -> updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state), actions))
                        else -> updates.emit(scene.failed("Unknown Error"))
                    }
                }

            }.onNotSuccess { updates.emit(scene.failed(it)) }
    }

    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        return payment(scene, viewModel.state.obj.id, viewModel.state.showPaymentModal.name)
    }

    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }
}