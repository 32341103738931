package views

import Routes
import StoreContext
import components.fButton
import components.*
import components.fLoader
import js.core.jso
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useNavigate
import screens.MattersScreen
import support.*
import web.cssom.*
import web.cssom.Opacity


val Matters = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: MattersScreen.ViewModel by useState(MattersScreen.ViewModel.None)
    val navigate = useNavigate()

    useAsyncEffectOnce {
        MattersScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce { MattersScreen.load(sceneInputOf(store, viewModel)) }

    fun handleLogout() = call {
        MattersScreen.logout(sceneInputOf(store, viewModel))
        navigate(Routes.loginMatters, jso { replace = true; state = null })
    }

    fun recover() = call {
        MattersScreen.success(sceneInputOf(store, viewModel))
    }

    fun handleNavigateSales(option: DesignSystem.Option?) {
        navigate("${Routes.sell}/${option?.value}")
    }

    fun handleNavigateBuy(option: DesignSystem.Option?) {
        if (option?.data != null) {
            navigate("${Routes.apply}/${option.data}/${option.value}")
        }
    }

    fun navigateApply(value: String) {
        navigate("${Routes.apply}/$value")
    }

    FScaffold {
        //  this.navigation = viewModel.navigation
        isFullScreen = when (viewModel) {
            is MattersScreen.ViewModel.Ready -> true
            else -> false
        }

        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }

        viewModel.asReady?.run {
            fAppbar { design = navigation; onClick = { handleLogout() } }

            Stack {
                spacing = responsive(2)

                sx {
                    alignItems = AlignItems.center
                    gap = 0.5.rem
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        gap = 1.rem
                        width = responsive(
                            Breakpoint.xs to 100.pct,
                            Breakpoint.lg to 50.pct
                        )
                    }

                    fText { design = mattersTitle }
                    fText { design = mattersIntro }
                    Stack {
                        spacing = responsive(1)
                        direction = responsive(StackDirection.row)
                        sx {
                            alignItems = AlignItems.center
                        }
                        fMenuButton { design = buy; onClick = ::navigateApply }
                        fButton { design = sell; onClick = { navigate(Routes.sell) } }
                    }
                }
                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = responsive(
                            Breakpoint.xs to Padding(1.rem, 1.rem),
                            Breakpoint.md to Padding(3.rem, 3.rem),
                        )
                        width = 100.pct
                        minHeight = 283.px
                    }
                    fTable { design = salesItems; onClick = ::handleNavigateSales }
                }
                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = responsive(
                            Breakpoint.xs to Padding(1.rem, 1.rem),
                            Breakpoint.md to Padding(3.rem, 3.rem),
                        )
                        width = 100.pct
                        minHeight = 283.px
                    }
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(1)
                        sx { padding = Padding(0.rem, 0.rem, 1.rem, 0.rem) }
                        infoColor.map { info ->
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(1)
                                sx {
                                    alignItems = AlignItems.center
                                }
                                Box {
                                    sx {
                                        height = 22.px
                                        width = 19.px
                                        backgroundColor = Color(info.value ?: Palette.Info.main)
                                        opacity = 0.40.unsafeCast<Opacity>()
                                    }
                                }
                                fText { design = DesignSystem.Text(text = info.title) }
                            }
                        }
                    }

                    fTable { design = buyItems; onClick = ::handleNavigateBuy }
                }
            }
            fButton { design = contact; onClick = { Window.navigationUrl(mailToSubject) } }
        }




        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}