package components.items

import components.*
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.*
import web.cssom.*


external interface FormCheckboxProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.Checkbox
    var onChange: ((id: String, value: String) -> Unit)?
}

val FormItemCheckbox = FC<FormCheckboxProps> { props ->
    fun onChange(inputValue: Boolean) {
        props.onChange?.let { it(props.viewModel.fieldKey.rawValue, inputValue.toString()) }
    }

    if (props.viewModel.visible)
        Box {
            sx {
                display = Display.flex
                alignItems = AlignItems.start
                justifyContent = JustifyContent.spaceBetween
                ".MuiCheckbox-root" {
                    padding = Padding(0.px, 9.px, 9.px, 0.px)
                    hover {
                        backgroundColor = Color(Palette.transparent)
                    }
                }
            }
            Stack {
                direction = responsive(StackDirection.column)
                fCheckbox { design = props.viewModel.value; onChange = ::onChange }
                Box {
                    sx {
                        padding = Padding(0.px, 0.px, 0.px, 2.rem)
                    }
                    fTextWithLinks { design = props.viewModel.textWithLink }
                }
            }
            if (props.viewModel.info != null)
                props.viewModel.info.let {
                    Box {
                        sx {
                            marginLeft = 1.rem
                        }
                        fInfoButton {
                            if (it != null) {
                                infoText = it
                            }
                        }
                    }
                }
        }
}
