package support

import techla.agreement.Agreement
import techla.base.Identifier
import techla.form.Submission

sealed class Location {
    object None : Location()
    object Login : Location()
    object LoginApplyPrivate : Location()
    object LoginApplyCompany : Location()
    object LoginMatters : Location()
    object LoginBackOffice : Location()
    object Apply : Location()
    object ApplyType : Location()
    object Sell : Location()
    object Matters : Location()
    object BackOffice : Location()
    object Commitment : Location()
    object Dashboard : Location()
    object Preview : Location()


    data class ContinueApply(val objectId: Identifier<Object>) : Location()
    data class ContinueSell(val objectId: Identifier<Object>) : Location()
    data class BackOfficeMatters(val objectId: Identifier<Object>) : Location()
    data class Contract(val objectId: Identifier<Object>, val submissionId: Identifier<Submission>) : Location()


    val route
        get() =
            when (this) {
                is None -> ""
                is Login -> "/"
                is LoginApplyPrivate -> "/login-private"
                is LoginApplyCompany -> "/login-company"
                is LoginMatters -> "/login-matters"
                is LoginBackOffice -> "/login-backoffice"
                is Apply -> "/apply"
                is ApplyType -> "/apply/:type"
                is ContinueApply -> "/apply/:type/$objectId"
                is Sell -> "/sell"
                is ContinueSell -> "/sell/$objectId"
                is Contract -> "/contract/:type/$objectId/$submissionId"
                is Matters -> "/matters"
                is BackOffice -> "/backOffice"
                is BackOfficeMatters -> "/backOffice/$objectId"
                is Commitment -> "/commitment"
                is Dashboard -> "/dashboard"
                is Preview -> "/preview"
            }

    companion object {
        fun continueApply(obj: Object) = ContinueApply(objectId = obj.id)
        fun continueSell(obj: Object) = ContinueSell(objectId = obj.id)
        fun backOfficeMatters(obj: Object) = BackOfficeMatters(objectId = obj.id)
        fun contract(obj: Object) = obj.submissionBuyer?.index?.id?.let { Contract(objectId = obj.id, submissionId = it) }
    }

}