package components.items

import components.*
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.useState
import support.DesignSystem
import support.FormItem
import support.Palette
import web.cssom.*


external interface FormSummaryTotalLoanProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.SummaryTotalLoan
}


val formSummaryTotalLoan = FC<FormSummaryTotalLoanProps> { props ->

    if (props.viewModel.visible)
        Stack {
            spacing = responsive(2)
            sx {
                paddingBottom = 1.rem
            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(2)

                sx {
                    justifyContent = JustifyContent.flexStart
                }
                Stack {
                    direction = responsive(StackDirection.column)
                    fText { design = props.viewModel.loanAmount }

                }
            }

        }
}


