package views

import StoreContext
import components.*
import emotion.react.css
import mui.material.Box
import mui.material.Grid
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.a
import react.router.useLocation
import react.router.useNavigate
import screens.LoginScreen
import support.*
import support.Opacity
import web.cssom.*
import web.window.WindowTarget

val Login = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: LoginScreen.ViewModel by useState(LoginScreen.ViewModel.None)
    val navigate = useNavigate()
    val local = useLocation()

    fun onGovIdChange(value: String) = call {
        LoginScreen.setValues(sceneInputOf(store, viewModel), value)
    }

    fun handleLogin() = call {
        LoginScreen.login(sceneInputOf(store, viewModel))
    }

    fun handleResume() = call {
        LoginScreen.load(sceneInputOf(store, viewModel), local.pathname)
    }

    fun handleLogout() = call {
        LoginScreen.logout(sceneInputOf(store, viewModel))
    }

    fun recover() = call {
        LoginScreen.success(sceneInputOf(store, viewModel))
    }

    useAsyncEffectOnce {
        LoginScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce {
        LoginScreen.load(sceneInputOf(store, viewModel), local.pathname)
    }

    useEffect(viewModel) {
        viewModel.asFinished?.run {
            navigate(goToUrl)
        }

        viewModel.asStarted?.run {
            fLoader {}
        }
    }
    FScaffold {
        isFullScreen = when (viewModel) {
            is LoginScreen.ViewModel.ReadyApply -> true
            else -> false
        }

        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }

        viewModel.asReadyLogin?.run ready@{
            fAppbar { design = navigation; onClick = standardNavigation(navigate) }

            Stack {
                spacing = responsive(1)
                direction = responsive(StackDirection.column)
                fText { design = readyTitle }
                if (store.qrCode != null) {
                    BankID {
                        qrCode = store.qrCode ?: ""
                        scanQr = this@ready.scanQr
                        openApp = this@ready.openApp
                        goToUrl = this@ready.goToUrl
                    }
                    if (state.currentPath == Routes.loginMatters) {
                        fTextWithLinks { design = termBody }
                    }
                } else {
                    fForm {
                        onSubmit = ::handleLogin
                        css {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            alignItems = AlignItems.center
                            gap = 1.rem
                        }

                        fTextInput { design = govId; onChange = ::onGovIdChange }

                        fButton { design = loginButton }

                        if (state.currentPath == Routes.loginMatters) {
                            fTextWithLinks { design = termBody }
                        }
                    }
                }
                fText { design = version }
            }
        }

        viewModel.asReadyApply?.run apply@{
            fAppbar { design = navigation; onClick = standardNavigation(navigate) }

            Stack {
                direction = responsive(
                    Breakpoint.md to StackDirection.row,
                    Breakpoint.sm to StackDirection.column
                )
                sx {
                    alignItems = responsive(
                        Breakpoint.lg to important(AlignItems.stretch),
                    )
                    marginTop = responsive(
                        Breakpoint.sm to important(0.rem),
                        Breakpoint.lg to important(6.rem)
                    )
                    justifyContent = JustifyContent.center
                    width = 100.pct
                }
                Grid {
                    item = true
                    container = true
                    sx {
                        justifyContent = JustifyContent.center
                        maxWidth = responsive(
                            Breakpoint.lg to 30.pct
                        )
                    }
                    Box {
                        sx {
                            backgroundColor = Color(Palette.Background.paper)
                            padding = Padding(3.rem, 3.rem)
                            borderTopLeftRadius = responsive(
                                Breakpoint.lg to Shape.borderRadius10,
                                Breakpoint.sm to 0
                            )
                            borderBottomLeftRadius = responsive(
                                Breakpoint.lg to Shape.borderRadius10,
                                Breakpoint.sm to Shape.borderRadius10,
                            )
                            borderBottomRightRadius = responsive(
                                Breakpoint.lg to 0,
                                Breakpoint.sm to Shape.borderRadius10,
                            )
                            width = 100.pct
                            display = Display.flex
                            alignItems = AlignItems.center
                        }
                        Stack {
                            spacing = responsive(1)
                            direction = responsive(StackDirection.column)
                            sx {
                                width = 100.pct
                                alignItems = AlignItems.center
                            }
                            Box {
                                sx {
                                    maxWidth = responsive(
                                        Breakpoint.xs to 35.pct,
                                        Breakpoint.lg to 50.pct
                                    )
                                }
                                fImage {
                                    design = coin
                                    height = 100.pct
                                    width = 100.pct
                                }
                            }
                            fText { design = applyTitle }
                            //fText { design = applyBody }
                        }
                    }
                }

                Grid {
                    item = true
                    container = true
                    sx {
                        justifyContent = JustifyContent.center
                        maxWidth = responsive(
                            Breakpoint.lg to 30.pct
                        )
                    }
                    Box {
                        sx {
                            backgroundColor = Color(Palette.Background.paper + Opacity.opacity60)
                            padding = Padding(3.rem, 3.rem)
                            borderTopRightRadius = responsive(
                                Breakpoint.lg to Shape.borderRadius10,
                                Breakpoint.sm to Shape.borderRadius10
                            )
                            borderBottomRightRadius = responsive(
                                Breakpoint.lg to Shape.borderRadius10,
                                Breakpoint.sm to 0,
                            )
                            borderTopLeftRadius = responsive(
                                Breakpoint.lg to 0,
                                Breakpoint.sm to Shape.borderRadius10
                            )
                            width = 100.pct
                            display = Display.flex
                            alignItems = AlignItems.center
                        }
                        Stack {
                            spacing = responsive(1)
                            direction = responsive(StackDirection.column)
                            sx {
                                width = 100.pct
                            }

                            if (store.qrCode != null) {
                                BankID {
                                    qrCode = store.qrCode ?: ""
                                    scanQr = this@apply.scanQr
                                    openApp = this@apply.openApp
                                    goToUrl = this@apply.goToUrl
                                }
                                fTextWithLinks { design = termBody }
                            } else {
                                fForm {
                                    onSubmit = ::handleLogin
                                    css {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        alignItems = AlignItems.center
                                        gap = 1.rem
                                    }

                                    fTextInput { design = govId; onChange = ::onGovIdChange }
                                    fButton { design = loginButton }
                                    fTextWithLinks { design = termBody }
                                }
                            }
                            fText { design = version }
                        }
                    }
                }
            }

        }

        viewModel.asExpired?.run {
            fAppbar { design = navigation }

            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)
                sx {
                    alignItems = AlignItems.center
                }
                fImage { design = expireOut }
                fText { design = expireTitle }
                fText { design = expireBody }
                fButton { design = resume; onClick = ::handleResume }
            }
        }

        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}
