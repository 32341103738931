package screens

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import services.*
import support.*
import techla.agreement.Agreement
import techla.agreement.Signature
import techla.base.*
import techla.base.onNotSuccess
import techla.form.Submission
import techla.guard.Invite

object ContractScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    data class Texts(
        val title: String,
        val back: String,
        val finishedTitle: String,
        val finishedIntro: String,
        val bankTransferTitle: String,
        val bankTransferNumber: String,
        val swish: String,
        val swishNumber: String,
        val message: String,
        val bankTransfer: String,
        val inviteTitle: String,
        val inviteIntro: String,
        val codeTitle: String,
        val copy: String,
        val next: String,
        val code: String,
        val sek: String,
        val terms: String,
        val fkFee: String,
        val approvedTitle: String,
        val approveBody: String,
        val deniedTitle: String,
        val deniedBody: String,
        val fkHome: String,
        val paymentInfo: String,
        val copyCode: String,
        val finishedIntroLeasing: String,
        val app: String,
        val swishTitle: String,
        override val failureTitle: String,
        override val failureReason: String
    ) : FailureTexts {
        companion object
    }

    data class State(
        val regnumber: String? = null,
        val sum: String? = null,
        val obj: Object = Object.None,
        val submissionId: String? = null,
        val startCheck: Boolean = false,
        val customerType: CustomerType = CustomerType.None

    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Approve(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val approveTitle: DesignSystem.Text,
            val approveBody: DesignSystem.Text,
            val next: DesignSystem.Button,
            val contract: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation)

        data class Denied(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val deniedTitle: DesignSystem.Text,
            val deniedBody: DesignSystem.Text,
            val next: DesignSystem.Button,
            val fkHome: String,
            val nogo: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation)

        data class Summary(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val finishedTitle: DesignSystem.Text,
            val finishedIntro: DesignSystem.Text,
            val bankTransferTitle: DesignSystem.Text,
            val bankTransferNumber: DesignSystem.Text,
            val message: DesignSystem.Text,
            val swishTitle: DesignSystem.Text,
            val swishImage: DesignSystem.ImageView,
            val swish: DesignSystem.Text,
            val swishNumber: DesignSystem.Text,
            val bankTransfer: DesignSystem.Text,
            val regnumber: DesignSystem.Text,
            val next: DesignSystem.Button,
            val info: String,
        ) : ViewModel(texts, state, navigation)

        data class Invite(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val inviteTitle: DesignSystem.Text,
            val inviteIntro: DesignSystem.TextWithLinks,
            val codeTitle: DesignSystem.Text,
            val copy: DesignSystem.Button,
            val next: DesignSystem.Button,
            val code: DesignSystem.Text,
            val copyCode: DesignSystem.Toast,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation = navigation)


        fun approve(texts: Texts, state: State) =
            Approve(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.BACK, location = Location.Matters),
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON), //disabled = state.obj.buyerAgreement?.status !is Agreement.Status.Approved
                approveTitle = DesignSystem.Text(text = texts.approvedTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.SUCCESS),
                approveBody = DesignSystem.Text(text = texts.approveBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
                contract = DesignSystem.ImageView(image = DesignSystem.Image.CONTRACT),
            )

        fun denied() =
            Denied(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON),
                deniedTitle = DesignSystem.Text(text = texts.deniedTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.DANGER),
                deniedBody = DesignSystem.Text(text = texts.deniedBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                fkHome = texts.fkHome,
                nogo = DesignSystem.ImageView(image = DesignSystem.Image.NOGO),
            )

        fun summary(texts: Texts, state: State) =
            Summary(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.BACK, location = Location.Matters),
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON),
                finishedTitle = DesignSystem.Text(text = texts.finishedTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                finishedIntro = DesignSystem.Text(text = if (state.customerType != CustomerType.Leasing) texts.finishedIntro else texts.finishedIntroLeasing, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                bankTransferTitle = DesignSystem.Text(text = texts.bankTransferTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                bankTransferNumber = DesignSystem.Text(text = texts.bankTransferNumber, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY, align = DesignSystem.TextAlign.CENTER),
                message = DesignSystem.Text(text = texts.message, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                regnumber = DesignSystem.Text(text = state.regnumber, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY, align = DesignSystem.TextAlign.CENTER, visible = !state.regnumber.isNullOrEmpty()),
                swishTitle = DesignSystem.Text(text = texts.swishTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                swishImage = DesignSystem.ImageView(image = DesignSystem.Image.SWISHIMAGE),
                swishNumber = DesignSystem.Text(text = texts.swishNumber, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY, align = DesignSystem.TextAlign.CENTER),
                swish = DesignSystem.Text(text = texts.swish, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                bankTransfer = DesignSystem.Text(text = texts.bankTransfer, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                info = texts.paymentInfo,
            )

        fun invite(texts: Texts, showToast: Boolean = false) =
            Invite(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.BACK, location = Location.Matters),
                copy = DesignSystem.Button(text = texts.copy, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE),
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON),
                inviteTitle = DesignSystem.Text(text = texts.inviteTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                inviteIntro = DesignSystem.TextWithLinks(text = texts.inviteIntro, links = listOf(texts.app to deployment.home, "" to ""), align = DesignSystem.TextAlign.LEFT),
                codeTitle = DesignSystem.Text(text = texts.codeTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                code = DesignSystem.Text(text = texts.code, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                copyCode = DesignSystem.Toast(text = texts.copyCode, open = showToast)
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asApproved get() = this as? Approve
        val asDenied get() = this as? Denied
        val asSummary get() = this as? Summary
        val asInvite get() = this as? Invite
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))


    private suspend fun loadText(store: Store) =
        store.findMedias()
            .map { (actions, _) ->
                val updated = store.reduce(actions)
                val texts = Texts(
                    terms = updated.get(media = Key("screen:contract"), content = Key("terms")),
                    title = updated.get(media = Key("screen:contract"), content = Key("title")),
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    back = updated.get(media = Key("screen:contract"), content = Key("back")),
                    inviteTitle = updated.get(media = Key("screen:contract"), content = Key("inviteTitle")),
                    inviteIntro = updated.get(media = Key("screen:contract"), content = Key("inviteIntro")),
                    codeTitle = updated.get(media = Key("screen:contract"), content = Key("codeTitle")),
                    copy = updated.get(media = Key("screen:contract"), content = Key("copy")),
                    next = updated.get(media = Key("screen:contract"), content = Key("next")),
                    finishedTitle = updated.get(media = Key("screen:contract"), content = Key("finishedTitle")),
                    finishedIntroLeasing = updated.get(media = Key("screen:contract"), content = Key("finishedIntroLeasing")),
                    finishedIntro = updated.get(media = Key("screen:contract"), content = Key("finishedIntro")),
                    bankTransferTitle = updated.get(media = Key("screen:contract"), content = Key("bankTransferTitle")),
                    bankTransferNumber = updated.get(media = Key("screen:contract"), content = Key("bankTransferNumber")),
                    message = updated.get(media = Key("screen:contract"), content = Key("message")),
                    code = "",
                    sek = updated.get(media = Key("screen:contract"), content = Key("sek")),
                    fkFee = updated.get(media = Key("screen:apply"), content = Key("fkFee")),
                    approvedTitle = updated.get(media = Key("screen:apply"), content = Key("approvedTitle")),
                    approveBody = updated.get(media = Key("screen:contract"), content = Key("approveBody")),
                    deniedTitle = updated.get(media = Key("screen:contract"), content = Key("deniedTitle")),
                    deniedBody = updated.get(media = Key("screen:contract"), content = Key("deniedBody")),
                    swish = updated.get(media = Key("screen:contract"), content = Key("swish")),
                    bankTransfer = updated.get(media = Key("screen:contract"), content = Key("bankTransfer")),
                    swishNumber = updated.get(media = Key("screen:contract"), content = Key("swishNumber")),
                    paymentInfo = updated.get(media = Key("screen:contract"), content = Key("paymentInfo")),
                    copyCode = updated.get(media = Key("screen:contract"), content = Key("copyCode")),
                    fkHome = updated.deployment.home,
                    app = updated.get(media = Key("screen:contract"), content = Key("app")),
                    swishTitle =updated.get(media = Key("screen:contract"), content = Key("swishTitle")),
                )
                tupleOf(actions, texts)
            }


    val Signature.Signed.id: Identifier<Agreement>?
        get() =
            when (this) {
                is Signature.Signed.Agreement -> agreementId
                else -> null
            }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, submissionId: Identifier<Submission>, type: String) {
        val (store, viewModel) = scene
        val customerType = CustomerType.fromRawValue(type)
        updates.emit(sceneOf(viewModel.loading()))
        loadText(store)
            .flatMap { (actions, texts) ->
                store.reduce(actions).refreshObject(objectId, listOf(Key(CustomerType.getFkKey(customerType, true)))).accumulate(actions)
                    .map { tupleOf(it.first, texts, it.second) }
            }

            .flatMap { (actions, texts, obj) ->

                val objCashStake = obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "CASH_STAKE" }?.text
                val price = obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "PRICE" }?.text?.toDouble()
                val discountCode = obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "DISCOUNT" }?.text ?: ""
                val discountField = obj.submissionBuyer?.form?.fields?.firstOrNull { it.key.rawValue == "DISCOUNT" }
                val discount = if (discountCode.trim() == discountField?.answer) discountField.min ?: 0 else 0
                val fkFee = viewModel.texts.fkFee.toDoubleOrNull()?.minus(discount) ?: 0.0

                val sellInfo = obj.group.name.split("{REPLACE}")
                val result = (sellInfo + List(4 - sellInfo.size) { "-" }).take(4)
                val state = viewModel.state.copy(regnumber = result[0].trim(), sum = price.toString(), obj = obj, submissionId = submissionId.rawValue, customerType = customerType)
                val newText = texts.copy(
                    finishedIntroLeasing = texts.finishedIntroLeasing.replace("<CASH_STAKE>", formatNumber(objCashStake?.toDouble())).replace("<FEE>", formatNumber(fkFee)),
                    finishedIntro = texts.finishedIntro.replace("<CASH_STAKE>", formatNumber(objCashStake?.toDouble())).replace("<FEE>", formatNumber(fkFee))
                )

                if (obj.buyerAgreement?.status is Agreement.Status.Approved) {
                    return if (customerType == CustomerType.Leasing && obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "CASH_STAKE" }?.text?.toInt()!! <= 0) {
                        //updates.emit(sceneOf(viewModel.invite(texts = newText), actions))
                        val updated = store.reduce(actions = actions)
                        invite(scene.copy(store = updated), newText)
                    } else
                        updates.emit(sceneOf(viewModel.summary(texts = newText, state = state.copy(obj = obj)), actions))
                }

                if (obj.signatures.firstOrNull { it.signed == Signature.Signed.Pending } != null && obj.buyerAgreement?.status == Agreement.Status.Pending)
                    return updates.emit(sceneOf(viewModel.approve(texts = newText, state = state.copy(startCheck = true)), actions))
                store.reduce(actions).createTerms(obj, obj.submissionBuyer?.createTerms(obj.buyerAgreement!!)!!).accumulate(actions)
                    .map { tupleOf(it.first, newText, state, obj) }
            }.flatMap { (actions, texts, state, obj) ->

                store.reduce(actions).checkpointGroup(obj, Checkpoint.BuyerAgreement).accumulate(actions)
                    .map { tupleOf(it.first, texts, state, obj) }
            }
            .flatMap { (actions, texts, state, obj) ->

                store.reduce(actions).createSignature(obj, Signature.Create(agreementId = obj.buyerAgreement?.id!!)).accumulate(actions)
                    .map { tupleOf(it.first, texts, state, it.second) }

            }
            .map { (actions, texts, state, signature) ->
                when (val signed = signature.signed) {
                    is Signature.Signed.Pending -> return updates.emit(sceneOf(viewModel.approve(texts = texts, state = state.copy(startCheck = true)), actions))

                    is Signature.Signed.Agreement, is Signature.Signed.Policy -> {
                        store.refreshObject(state.obj.id, listOf(Key(CustomerType.getFkKey(customerType, true))))
                            .map { (actions, obj) ->
                                return updates.emit(sceneOf(viewModel.approve(texts = texts, state.copy(startCheck = false, obj = obj)), actions + actions))
                            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
                    }

                    is Signature.Signed.Cancelled ->
                        return updates.emit(sceneOf(viewModel.loading(), actions))

                    is Signature.Signed.Failed ->
                        return updates.emit(scene.failed(signed.reason))

                    is Signature.Signed.Unknown ->
                        return updates.emit(scene.failed("Unknown Error"))
                }
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun summary(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene

        return if (viewModel.state.customerType == CustomerType.Leasing && viewModel.state.obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "CASH_STAKE" }?.text?.toDouble()!! <= 0)
        //updates.emit(sceneOf(viewModel.invite(texts = viewModel.texts)))
            invite(scene, viewModel.texts)
        else
            updates.emit(sceneOf(viewModel.summary(texts = viewModel.texts, state = viewModel.state)))

    }

    suspend fun check(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene
        val state = viewModel.state

        store.findLatestAgreement(obj = viewModel.state.obj, keys = listOf(Key(CustomerType.getFkKey(state.customerType, true))))
            .map { (actions, agreements) ->
                when (agreements.firstOrNull()?.status) {
                    is Agreement.Status.Pending -> updates.emit(sceneOf(viewModel.approve(viewModel.texts, state.copy(startCheck = true)), actions))

                    is Agreement.Status.Approved -> {
                        store.refreshObject(state.obj.id, listOf(Key(CustomerType.getFkKey(state.customerType, true))))
                            .map { (actions, obj) ->
                                return if (viewModel.state.customerType == CustomerType.Leasing && obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "CASH_STAKE" }?.text?.toInt()!! <= 0) {
                                    val updated = store.reduce(actions = actions)
                                    invite(scene.copy(updated))
                                    // updates.emit(sceneOf(viewModel.invite(texts = viewModel.texts), actions))
                                } else
                                    updates.emit(sceneOf(viewModel.approve(viewModel.texts, state.copy(startCheck = false, obj = obj)), actions + actions))
                            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
                    }

                    else -> return updates.emit(scene.failed("Unknown Error"))
                }
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }

    }

    suspend fun previous(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        when (viewModel) {
            is ViewModel.Invite -> return updates.emit(sceneOf(viewModel.summary(texts = viewModel.texts, state = viewModel.state)))
            else -> {}
        }
    }

    suspend fun copy(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        updates.emit(sceneOf<ViewModel>(viewModel.invite(texts = viewModel.texts, showToast = true)))
    }

    suspend fun closeToast(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        updates.emit(sceneOf<ViewModel>(viewModel.invite(texts = viewModel.texts, showToast = false)))
    }


    suspend fun invite(scene: Scene.Input<ViewModel>, texts: Texts? = null) {
        val (store, viewModel) = scene
        val newText = texts ?: viewModel.texts

        val create = Invite.Create(group = viewModel.state.obj.group.key)
        store.createInvite(viewModel.state.obj, create)
            .flatMap { (actions, invite) ->
                store.reduce(actions).checkpointGroup(viewModel.state.obj, Checkpoint.BuyerFinished).accumulate(actions)
                    .map { tupleOf(it.first, invite) }
            }
            .map { (action, invite) ->
                updates.emit(sceneOf<ViewModel>(viewModel.invite(texts = newText.copy(code = invite.key.rawValue)), action))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        if (viewModel.state.submissionId != null)
            return load(scene, objectId = viewModel.state.obj.id, submissionId = Identifier(viewModel.state.submissionId!!), type = viewModel.state.customerType.rawValue)
        else
            logout(scene)
    }

    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }
}