package components.items

import components.*
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.DesignSystem
import support.FormItem
import support.Palette
import web.cssom.*


external interface FormLabelProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.Label
}


val FormItemLabel = FC<FormLabelProps> { props ->
    if (props.viewModel.visible)
        Box {
            sx {
                display = Display.flex
                alignItems = AlignItems.end
                justifyContent = JustifyContent.spaceBetween
                color = important(Color(Palette.Secondary.light))
            }
            Stack {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.flexStart
                    width = 100.pct
                }
                Stack {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        width = 100.pct
                        justifyContent = if (props.viewModel.title.align == DesignSystem.TextAlign.CENTER) JustifyContent.center else JustifyContent.spaceBetween
                    }
                    fText { design = props.viewModel.title }
                    fText { design = props.viewModel.value }
                }
                if (props.viewModel.img.image != null)
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(2)
                        sx {
                            alignItems = AlignItems.center
                            padding = Padding(1.rem, 0.rem, 0.rem, 0.rem)
                        }
                        fImage { design = props.viewModel.img }
                        fText { design = props.viewModel.text }
                    }
                else
                    fText { design = props.viewModel.text; className = if (props.viewModel.text?.isMarkdown == true) ClassName("n-markdown-label") else ClassName("") }
            }
            if (props.viewModel.info != null)
                props.viewModel.info.let {
                    Box {
                        sx {
                            marginLeft = 1.rem
                        }
                        fInfoButton {
                            if (it != null) {
                                infoText = it
                            }
                        }
                    }
                }
        }
}