package components

import emotion.react.css
import js.core.jso
import mui.material.*
import mui.system.sx
import react.*
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML
import support.*
import web.cssom.*
import web.html.ButtonType
import web.html.HTMLButtonElement


external interface FButtonProps : Props {
    var design: DesignSystem.Button
    var onClick: (() -> Unit)?
}

val fButton = FC<FButtonProps> { props ->
    var loading: Boolean by useState(false)
    fun onClick(event: MouseEvent<HTMLButtonElement, *>) {
        props.onClick?.let { it() }
        if (props.design.loading == true)
            loading = true

    }
    useEffect(props.design) {
        if (loading)
            loading = false
    }

    val color = when (props.design.palette) {
        DesignSystem.PaletteType.PRIMARY -> ButtonColor.primary
        DesignSystem.PaletteType.SECONDARY -> ButtonColor.secondary
        DesignSystem.PaletteType.TRANSPARENT -> ButtonColor.primary
        DesignSystem.PaletteType.GREY -> ButtonColor.primary
        DesignSystem.PaletteType.WARNING -> ButtonColor.warning
        DesignSystem.PaletteType.SUCCESS -> ButtonColor.success
        DesignSystem.PaletteType.INFO -> ButtonColor.info
        DesignSystem.PaletteType.DANGER -> ButtonColor.error
    }

    if (props.design.visible) {
        when (props.design.style) {
            DesignSystem.Button.Style.TEXT -> {
                mui.material.Button {
                    this.disabled = props.design.disabled || loading
                    this.onClick = ::onClick
                    this.color = color
                    this.variant = ButtonVariant.text
                    this.type = when (props.design.type) {
                        DesignSystem.Button.Type.SUBMIT -> ButtonType.submit
                        DesignSystem.Button.Type.BUTTON -> ButtonType.button
                    }
                    sx {
                        backgroundColor = important(Color(Palette.transparent))
                        padding = 0.px
                        borderRadius = Shape.borderRadius50
                        textAlign = TextAlign.center
                        fontSize = themeFontSize(DesignSystem.SizeType.MD).rem
                        fontWeight = FontWeight.bold
                        lineHeight = themeFontSize(DesignSystem.SizeType.LG).rem
                        height = 48.px
                    }

                    +props.design.text
                    if (props.design.loading == true && loading)
                        CircularProgress {
                            size = 34.px
                            thickness = 4.5
                            sx {
                                position = Position.absolute
                            }
                        }
                }
            }

            DesignSystem.Button.Style.OUTLINE -> {
                mui.material.Button {
                    this.disabled = props.design.disabled || loading
                    this.onClick = ::onClick
                    this.color = color
                    this.variant = ButtonVariant.outlined
                    this.type = when (props.design.type) {
                        DesignSystem.Button.Type.SUBMIT -> ButtonType.submit
                        DesignSystem.Button.Type.BUTTON -> ButtonType.button
                    }
                    this.fullWidth = true

                    sx {
                        backgroundColor = important(Color(Palette.transparent))
                        border = important(Border(2.px, LineStyle.solid, Color(Palette.Primary.main)))
                        borderRadius = Shape.borderRadius50
                        textAlign = TextAlign.center
                        fontSize = themeFontSize(DesignSystem.SizeType.MD).rem
                        fontWeight = FontWeight.bold
                        lineHeight = themeFontSize(DesignSystem.SizeType.LG).rem
                        height = 48.px
                        "&.Mui-disabled" {
                            if (props.design.disabled) {
                                this.color = Palette.Text.disabled
                            }
                        }
                    }

                    +props.design.text
                    if (props.design.loading == true && loading)
                        CircularProgress {
                            size = 34.px
                            thickness = 4.5
                            sx {
                                position = Position.absolute
                            }
                        }
                }
            }

            else ->
                mui.material.Button {
                    this.disabled = props.design.disabled || loading
                    this.onClick = ::onClick
                    this.color = color
                    this.variant = ButtonVariant.contained
                    this.type = when (props.design.type) {
                        DesignSystem.Button.Type.SUBMIT -> ButtonType.submit
                        DesignSystem.Button.Type.BUTTON -> ButtonType.button
                    }
                    this.fullWidth = true

                    sx {
                        backgroundColor = important(Color(Palette.Primary.main))
                        borderRadius = Shape.borderRadius50
                        textAlign = TextAlign.center
                        fontSize = themeFontSize(DesignSystem.SizeType.MD).rem
                        fontWeight = FontWeight.bold
                        lineHeight = themeFontSize(DesignSystem.SizeType.LG).rem
                        height = 48.px
                    }

                    +props.design.text
                    if (props.design.loading == true && loading)
                        CircularProgress {
                            size = 34.px
                            thickness = 4.5
                            sx {
                                position = Position.absolute
                            }
                        }
                }
        }
    }
}


external interface FInfoButtonProps : PropsWithChildren {
    var infoText: String
}


val fInfoButton = FC<FInfoButtonProps> { props ->
    var open: Boolean by useState(false)

    fun handleModal(value: Boolean) {
        open = value
    }

    mui.material.Button {
        onClick = { handleModal(true) }
        type = ButtonType.button

        sx {
            display = Display.flex
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            borderRadius = Shape.borderRadius50
            backgroundColor = Palette.Text.primary
            width = 25.px
            height = 25.px
            minWidth = 25.px
            color = Color(Palette.Background.default)
            hover {
                backgroundColor = Palette.Text.secondary
            }
        }
        +"i"
    }
    fModal { design = DesignSystem.Modal(body = listOf(DesignSystem.Text(text = props.infoText, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR)), visible = open); onClose = ::handleModal }
}

external interface FMenuButtonProps : PropsWithChildren {
    var design: DesignSystem.Menu
    var onClick: (String) -> Unit
}

private data class MenuPoint(
    val x: Double,
    val y: Double
)

val fMenuButton = FC<FMenuButtonProps> { props ->
    var point: MenuPoint? by useState(null)

    fun handleOpen(event: MouseEvent<HTMLButtonElement, *>) {
        point = if (point == null) {
            MenuPoint(
                x = event.clientX - 25,
                y = event.clientY + 25,
            )
        } else {
            null
        }
    }

    fun handleClose(event: MouseEvent<HTMLButtonElement, *>) {
        point = null
    }

    props.design.let {
        Button {
            onClick = ::handleOpen
            type = ButtonType.button
            this.color = ButtonColor.primary
            this.variant = ButtonVariant.contained
            this.fullWidth = true

            sx {
                backgroundColor = important(Color(Palette.Primary.main))
                borderRadius = Shape.borderRadius50
                textAlign = TextAlign.center
                fontSize = themeFontSize(DesignSystem.SizeType.MD).rem
                fontWeight = FontWeight.bold
                lineHeight = themeFontSize(DesignSystem.SizeType.LG).rem
                height = 48.px
            }
            +it.title

            ReactHTML.img {
                css {
                    padding = Padding(0.rem, 0.5.rem)
                }
                src = Design.image(DesignSystem.Image.DOWNARROW)
                alt = ""
            }
        }
    }

    Menu {
        id = "popup-menu"
        keepMounted = true

        anchorReference = PopoverReference.anchorPosition
        anchorPosition = if (point != null) {
            jso {
                top = point!!.y
                left = point!!.x
            }
        } else {
            undefined
        }
        open = point != null
        onClose = ::handleClose

        props.design.items.map { item ->
            MenuItem {
                key = item.title
                onClick = { if (item is DesignSystem.Option.Item) item.value?.let { it1 -> props.onClick(it1) } }
                Typography {
                    +item.title
                }
            }
        }
    }
}

