package views

import StoreContext
import components.*
import components.fLoader
import js.core.jso
import mui.material.*
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.events.MouseEvent
import react.router.useNavigate
import screens.DashboardScreen
import support.*
import web.cssom.*
import web.html.ButtonType
import web.html.HTMLButtonElement

val Dashboard = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: DashboardScreen.ViewModel by useState(DashboardScreen.ViewModel.None)
    val navigate = useNavigate()

    useAsyncEffectOnce {
        DashboardScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce { DashboardScreen.load(sceneInputOf(store, viewModel)) }

    fun handleLogout() = call {
        DashboardScreen.logout(sceneInputOf(store, viewModel))
        navigate(Routes.loginBackOffice, jso { replace = true; state = null })
    }

    fun recover() = call {
        DashboardScreen.success(sceneInputOf(store, viewModel))
    }

    fun handleSetYear(value: DesignSystem.Option) = call {
        DashboardScreen.setYear(sceneInputOf(store, viewModel), year = value)
    }

    fun handleSetQuarter(value: DesignSystem.Option) = call {
        DashboardScreen.setQuarter(sceneInputOf(store, viewModel), quarter = value)
    }

    fun handleCreateCSV() = call {
        DashboardScreen.createCVG(sceneInputOf(store, viewModel))
    }

    FScaffold {
        isFullScreen = when (viewModel) {
            is DashboardScreen.ViewModel.Ready -> true
            else -> false
        }

        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }

        viewModel.asReady?.run {
            fAppbar { design = navigation; onClick = { handleLogout() } }
            Stack {
                spacing = responsive(2)

                sx {
                    alignItems = AlignItems.center
                    gap = 0.5.rem
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        gap = 1.rem
                        maxWidth = responsive(
                            Breakpoint.xs to 35.pct,
                            Breakpoint.lg to 50.pct
                        )
                    }

                    fText { design = dashboardTitle }
                }

                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = responsive(
                            Breakpoint.xs to Padding(1.rem, 1.rem),
                            Breakpoint.md to Padding(3.rem, 3.rem),
                        )
                        width = 100.pct
                        minHeight = 283.pct
                    }

                    Stack {
                        direction = responsive(
                            Breakpoint.xs to StackDirection.columnReverse,
                            Breakpoint.md to StackDirection.row

                        )
                        spacing = responsive(2)
                        sx { padding = Padding(0.rem, 0.rem, 1.rem, 0.rem) }

                        Stack {
                            direction = responsive(StackDirection.column)
                            spacing = responsive(1)
                            sx {
                                width = 100.pct

                            }
                            fText { design = totalSum }
                            fText { design = totalCommitments }
                            fText { design = privateCommitments }
                            fText { design = companyCommitments }
                            fText { design = leasingCommitments }
                        }

                        Stack {
                            direction = responsive(StackDirection.column)
                            spacing = responsive(2)
                            sx {
                                width = responsive(
                                    Breakpoint.xs to 100.pct,
                                    Breakpoint.md to 55.pct,
                                    Breakpoint.lg to 45.pct,
                                    Breakpoint.xl to 35.pct
                                )
                            }
                            fSelectInput { design = year; onChange = ::handleSetYear }
                            fSelectInput { design = quarter; onChange = ::handleSetQuarter }
                            fButton { design = createCSV; onClick = ::handleCreateCSV }
                        }
                    }
                    fTable { design = items }
                }
            }
        }

        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}