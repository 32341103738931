package components

import js.core.jso
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithClassName
import react.router.useNavigate
import react.useEffect
import support.DesignSystem
import web.cssom.AlignItems
import web.cssom.ClassName
import web.cssom.pct

external interface FailureProps : PropsWithClassName {
    var design: DesignSystem.Failure
    var onLogout: () -> Unit
    var onRecover: () -> Unit
}


val fFailure = FC<FailureProps> { props ->
    val navigate = useNavigate()

    useEffect {
        if (props.design.automaticLogout) {
            navigate("/", jso { replace = true; state = null })
        }
    }

    Stack {
        spacing = responsive(2)
        direction = responsive(StackDirection.column)
        sx {
            alignItems = AlignItems.center
            maxWidth = 100.pct
        }
        fImage { design = props.design.image }
        fText { design = props.design.title }
        fText { design = props.design.details;className = ClassName("mw-100") }
        fButton {
            design = props.design.recover;
            onClick = {
                if (props.design.shouldLogout)
                    props.onLogout()
                else
                    props.onRecover()
            }
        }
    }
}