package views

import StoreContext
import components.*
import components.fLoader
import js.core.jso
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useNavigate
import react.router.useParams
import screens.BackOfficeMattersScreen
import screens.PaymentScreen
import support.*
import techla.base.Identifier
import web.cssom.*

val Payment = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: PaymentScreen.ViewModel by useState(PaymentScreen.ViewModel.None)
    var backOfficeMattersViewModel: BackOfficeMattersScreen.ViewModel by useState(BackOfficeMattersScreen.ViewModel.None)
    val navigate = useNavigate()
    val params = useParams()
    var count: Int by useState(0)

    useAsyncEffectOnce {
        PaymentScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce {
        BackOfficeMattersScreen.updates.collect { (updated, actions) ->
            backOfficeMattersViewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce {
        params["objectId"]?.let { objectId ->
            params["payment"]?.let { payment ->
                PaymentScreen.payment(sceneInputOf(store, viewModel), Identifier(objectId), payment)
            }
        }
    }

    fun recover() = call {
        navigate("${Routes.backOffice}/${params["objectId"]}", jso { replace = true; state = null })
    }

    fun handleLogout() = call {
        PaymentScreen.logout(sceneInputOf(store, viewModel))
        Window.scrollTo()
        navigate(Routes.loginBackOffice, jso { replace = true; state = null })

    }

    fun handleBack() = call {
        navigate("${Routes.backOffice}/${params["objectId"]}", jso { replace = true; state = null })
    }

    fun handleFinish(objId: Identifier<Object>) = call {
        navigate("${Routes.backOffice}/${objId.rawValue}", jso { replace = true; state = null })
    }

    fun handleResume() = call {
        params["objectId"]?.let { objectId ->
            params["payment"]?.let { payment ->
                PaymentScreen.payment(sceneInputOf(store, viewModel), Identifier(objectId), payment)
            }
        }
    }

    fun handleSign() = call {
        PaymentScreen.check(sceneInputOf(store, viewModel))
    }

    useAsyncEffect(count) { coroutineScope ->
        if (viewModel.state.startSign || viewModel.state.startCheck) {
            if (coroutineScope.isActive && viewModel != viewModel.asFailed) {
                delay(if (viewModel.state.startSign) 1000L else 10000L)
                handleSign()
            }
            viewModel.asReady?.let {
                count += 1
            }
            viewModel.asSuccess?.let {
                count = 0
            }
            viewModel.asDenied?.let {
                count = 0
            }
            viewModel.asExpired?.let {
                count = 0
            }
            viewModel.asFailed?.let {
                count = 0
            }
        }
    }

    FScaffold {
        isFullScreen = false

        viewModel.asLoading?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }
            fLoader {}
        }

        viewModel.asReady?.run {
            fAppbar { design = navigation; onClick = standardNavigation(navigate) }

            Stack {
                spacing = responsive(1)
                direction = responsive(StackDirection.column)
                fText { design = confirmTitle }
                if (viewModel.state.qrCode != null && viewModel.state.qrCode != undefined) {
                    BankID {
                        qrCode = viewModel.state.qrCode!!
                        scanQr = scanQr
                        openApp = openApp
                    }
                }
                fText { design = confirmBody }
            }
        }

        viewModel.asExpired?.run {
            fAppbar { design = navigation; onClick = standardNavigation(navigate) }

            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)
                sx {
                    alignItems = AlignItems.center
                }
                fImage { design = expireOut }
                fText { design = expireTitle }
                fText { design = expireBody }
                fButton { design = resume; onClick = ::handleBack }
            }
        }

        viewModel.asSuccess?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }

            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)
                sx {
                    alignItems = AlignItems.center
                }
                fImage { design = success }
                fText { design = successTitle }
                fButton { design = next; onClick = { handleFinish(objId) } }
            }
        }

        viewModel.asDenied?.run {
            fAppbar { design = navigation; onClick = standardNavigation(navigate) }

            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)
                sx {
                    alignItems = AlignItems.center
                }
                fImage { design = denied }
                fText { design = deniedTitle }
                fButton { design = next; onClick = { handleFinish(objId) } }
            }
        }

        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}
