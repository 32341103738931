package components

import js.core.jso
import js.core.push
import mui.base.AutocompleteChangeDetails
import mui.base.AutocompleteChangeReason
import mui.material.*
import mui.system.sx
import react.*
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML.fieldset
import support.*
import web.cssom.*
import web.html.HTMLElement
import web.html.HTMLInputElement
import mui.material.AutocompleteProps
import mui.system.Breakpoint
import mui.system.responsive
import react.dom.events.KeyboardEvent
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.input
import web.cssom.Opacity
import web.html.HTMLButtonElement
import web.html.InputMode

external interface FTextInputProps : Props {
    var design: DesignSystem.TextInput
    var onChange: (String) -> Unit
    var onEnter: ((String) -> Unit)?
    var onClick: ((String) -> Unit)?
}

val fTextInput = FC<FTextInputProps> { props ->
    var value: String by useState("")
    val input = useRef<HTMLInputElement>(null)
    val setCursor = useSetCursor()

    useEffectOnce {
        props.onChange(props.design.value ?: "")
    }

    useEffect(props.design.value) {
        value = props.design.value ?: ""
    }

    val onChange = useCallback(setCursor) { _: ChangeEvent<HTMLElement> ->
        val current = input.current?.value ?: ""
        val sanitized = if (props.design.type == DesignSystem.TextInputType.NUMBER)
            current.filter { it.isDigit() || it == '-' || it == ',' }
        else if (props.design.type == DesignSystem.TextInputType.PURE_NUMBER)
            current.filter { it.isDigit() }
        else
            current

        value = sanitized
        props.onChange(sanitized)
    }

    fun onClick(event: MouseEvent<HTMLButtonElement, *>) {
        props.onClick?.invoke(value)
    }


    fun onKey(event: KeyboardEvent<HTMLElement>) {
        val target = event.target as? HTMLInputElement
        if (event.key == "Enter" && target != null) {
            props.onEnter?.invoke(value)
        }
    }

    val inputType = when (props.design.type) {
        DesignSystem.TextInputType.DATE -> "date"
        DesignSystem.TextInputType.EMAIL -> "email"
        DesignSystem.TextInputType.PHONE -> "tel"
        DesignSystem.TextInputType.NUMBER -> "tel" // We use text because number dos not work
        DesignSystem.TextInputType.PURE_NUMBER -> "tel" // We use text because number dos not work
        else -> "text"
    }
    val inputMode = when (props.design.type) {
        DesignSystem.TextInputType.NUMBER -> InputMode.numeric
        DesignSystem.TextInputType.PURE_NUMBER -> InputMode.numeric
        else -> InputMode.text
    }

    val inputProps: InputBaseComponentProps = when (props.design.type) {
        DesignSystem.TextInputType.NUMBER -> jso { min = 1 }
        DesignSystem.TextInputType.PURE_NUMBER -> jso { min = 0 }
        DesignSystem.TextInputType.TEXT -> if (props.design.uppercase == true) jso { style = jso { textTransform = TextTransform.uppercase } } else jso {}
        else -> jso { }
    }

    if (props.design.visible) {
        FormControl {
            this.fullWidth = true
            // this.hiddenLabel = false

            props.design.label?.let { label ->
                if (props.design.type != DesignSystem.TextInputType.DATE) {
                    InputLabel {
                        sx {
                            marginBottom = 0.5.rem
                            fontWeight = 400.unsafeCast<FontWeight>()
                            color = Palette.Text.secondary
                            "&.Mui-focused" {
                                color = important(Palette.Text.secondary)
                            }
                        }
                        htmlFor = props.design.header.id
                        +label
                    }
                } else {
                    FormLabel {
                        sx {
                            marginBottom = 0.5.rem
                            fontWeight = 600.unsafeCast<FontWeight>()
                            color = Palette.Text.secondary
                            "&.Mui-focused" {
                                color = important(Palette.Text.secondary)
                            }
                        }
                        htmlFor = props.design.header.id
                        +label
                    }
                }
            }

            OutlinedInput {
                this.id = props.design.header.id
                this.key = props.design.header.id
                this.onChange = onChange
                this.inputRef = input
                this.type = inputType
                this.inputMode = inputMode
                this.placeholder = props.design.placeholder
                this.disabled = props.design.disabled
                this.name = props.design.header.id
                this.value = value
                this.fullWidth
                this.inputProps = inputProps
                this.onKeyDown = ::onKey
                if (props.design.type != DesignSystem.TextInputType.DATE)
                    this.label = ReactNode(props.design.label)

                if (props.design.type == DesignSystem.TextInputType.NUMBER || props.design.type == DesignSystem.TextInputType.PURE_NUMBER) {
                    input {
                        pattern = "[0-9]*"
                    }
                }

                if (props.design.adornmentType == DesignSystem.AdornmentPosition.START)
                    if (props.design.adornment != null || props.design.adornmentImage != null)
                        startAdornment = InputAdornment.create {
                            position = InputAdornmentPosition.start
                            if (props.design.adornmentImage != null)
                                IconButton {
                                    onClick = ::onClick
                                    fImage { design = DesignSystem.ImageView(image = props.design.adornmentImage) }
                                }
                            else
                                +props.design.adornment
                        }

                if (props.design.adornmentType == DesignSystem.AdornmentPosition.END)
                    if (props.design.adornment != null || props.design.adornmentImage != null)
                        endAdornment = InputAdornment.create {
                            position = InputAdornmentPosition.end
                            if (props.design.adornmentImage != null)
                                IconButton {
                                    onClick = ::onClick
                                    fImage { design = DesignSystem.ImageView(image = props.design.adornmentImage) }
                                }
                            else
                                +props.design.adornment
                        }


                sx {
                    color = important(Palette.Text.secondary)
                    borderRadius = Shape.borderRadius10
                    height = 48.px
                    minWidth = 266.px

                    ".MuiOutlinedInput-notchedOutline" {
                        border = important(Border(1.px, LineStyle.solid, Palette.Text.secondary))
                    }
                }
            }

            if (props.design.status?.message?.isNotEmpty() == true)
                props.design.status?.message?.let {
                    FormLabel {
                        sx {
                            fontSize = 14.px
                            marginTop = 0.25.rem
                            color = important(Color(Palette.Danger.main))
                            padding = Padding(5.px, 5.px, 0.px, 5.px)
                        }
                        error = true
                        htmlFor = props.design.header.id
                        +it
                    }
                }
        }
    }
}

external interface FSelectInputProps : PropsWithClassName {
    var design: DesignSystem.SelectInput
    var onChange: (DesignSystem.Option) -> Unit
    var overrideValue: DesignSystem.Option?
}

private external interface AutocompleteOption {
    var label: String
    var value: String
}

private fun autocompleteOption(label: String, value: String): AutocompleteOption = jso {
    this.label = label
    this.value = value
}

val fSelectInput = FC<FSelectInputProps> { props ->
    fun onChange(event: ChangeEvent<HTMLElement>, node: ReactNode) {
        val value = event.target.asDynamic().value
        val selected = props.design.options.first { it.value == value }
        props.onChange(selected)
    }

    fun onChangeAutocomplete(
        event: react.dom.events.SyntheticEvent<*, *>,
        value: AutocompleteOption,
        reason: AutocompleteChangeReason,
        details: AutocompleteChangeDetails<AutocompleteOption>?,
    ) {
        val selected = props.design.options.first { it.value == value.value }
        props.onChange(selected)
    }


    if (props.design.visible) {
        FormControl {
            fullWidth = true
            if (props.design.label != null && !props.design.autocomplete) {
                InputLabel {
                    sx {
                        marginBottom = 0.5.rem
                        fontWeight = 400.unsafeCast<FontWeight>()
                        color = Palette.Text.secondary
                    }
                    htmlFor = props.design.header.id
                    +props.design.label!!
                }
            }

            if (props.design.autocomplete) {
                val arrayOptions: Array<AutocompleteOption> = emptyArray()
                props.design.options.forEach { arrayOptions.push(autocompleteOption(label = it.title ?: "", value = it.value ?: "")) }

                val value = arrayOptions.firstOrNull { it.value == props.design.selected?.value }
                @Suppress("UPPER_BOUND_VIOLATED")
                Autocomplete<AutocompleteProps<AutocompleteOption>> {
                    this.options = arrayOptions
                    this.disablePortal = true
                    this.fullWidth
                    this.onChange = ::onChangeAutocomplete
                    this.value = value
                    this.renderInput = { params ->
                        TextField.create {
                            +params
                            label = ReactNode(props.design.label)
                        }
                    }
                    sx {
                        height = 48.px
                        ".Mui-focused" {
                            color = important(Palette.Text.secondary)
                        }
                        ".MuiSelect-outlined" {
                            padding = Padding(0.375.rem, 0.75.rem)
                            backgroundColor = Color(Palette.transparent)
                            color = Palette.Text.secondary
                        }
                        ".MuiOutlinedInput-notchedOutline" {
                            border = important(Border(1.px, LineStyle.solid, Palette.Text.secondary))
                            color = Palette.Text.secondary
                            borderRadius = Shape.borderRadius10
                        }
                    }
                }
            } else {
                val value = props.overrideValue?.value ?: props.design.selected?.value ?: ""

                Select {
                    this.id = props.design.header.id
                    this.name = props.design.header.id
                    this.disabled = props.design.disabled
                    this.onChange = ::onChange
                    this.value = value
                    this.fullWidth = true
                    this.className = props.className
                    this.label = ReactNode(props.design.label)

                    sx {
                        height = 48.px
                        ".MuiSelect-outlined" {
                            padding = Padding(0.375.rem, 0.75.rem)
                            backgroundColor = Color(Palette.transparent)
                            color = Palette.Text.primary
                        }
                        ".MuiOutlinedInput-notchedOutline" {
                            border = important(Border(1.px, LineStyle.solid, Palette.Text.primary))
                            color = Palette.Text.primary
                            borderRadius = Shape.borderRadius10
                        }
                    }

                    props.design.options.map { option ->
                        MenuItem {
                            this.disabled = option.disabled
                            this.value = option.value
                            Typography {
                                variant = Support.typographyVariant(DesignSystem.SizeType.MD, DesignSystem.StyleType.REGULAR)
                                +option.title
                            }
                        }
                    }
                }
            }
        }

        if (props.design.status?.message?.isNotEmpty() == true)
            props.design.status?.message?.let {
                FormLabel {
                    sx {
                        fontSize = 14.px
                        marginTop = 0.25.rem
                        color = important(Color(Palette.Danger.main))
                        padding = Padding(5.px, 5.px, 0.px, 5.px)
                    }
                    error = true
                    htmlFor = props.design.header.id
                    +it
                }
            }
    }
}


external interface FRadioInputProps : Props {
    var design: DesignSystem.RadioInput
    var onChange: (String) -> Unit
}

val fRadioInput = FC<FRadioInputProps> { props ->
    var value: String by useState("")

    useEffectOnce {
        props.onChange(props.design.checked?.value ?: "")
    }

    useEffect(props.design.checked?.value) {
        if (props.design.checked?.value != value)
            props.onChange(props.design.checked?.value ?: "")

        value = props.design.checked?.value ?: ""

    }
    fun handleChange(event: ChangeEvent<HTMLInputElement>, v: String) {
        (event.target as? HTMLInputElement)?.let { target ->
            props.onChange(target.value)
            value = target.value
        }
    }

    if (props.design.visible)
        FormControl {
            component = fieldset
            sx { width = 100.pct }

            props.design.label?.let {
                FormLabel {
                    sx {
                        marginBottom = 0.5.rem
                        fontWeight = 400.unsafeCast<FontWeight>()
                        color = Palette.Text.primary
                        "&.Mui-focused" {
                            color = important(Palette.Text.primary)
                        }
                    }
                    +it
                }
            }
            RadioGroup {
                this.name = props.design.header?.id
                this.onChange = ::handleChange
                this.value = value
                sx {
                    flexDirection = responsive(
                        Breakpoint.xs to FlexDirection.column,
                        Breakpoint.md to when (props.design.direction) {
                            DesignSystem.Direction.ROW -> FlexDirection.row
                            DesignSystem.Direction.COLUMN -> FlexDirection.column
                            else -> {}
                        },
                    )
                }

                props.design.buttons.map { button ->
                    FormControlLabel {
                        sx { color = Palette.Text.primary }
                        this.value = button.value
                        this.control = Radio.create()
                        this.label = ReactNode(button.title)
                    }
                }
            }

            if (props.design.status?.message?.isNotEmpty() == true)
                props.design.status?.message?.let {
                    FormLabel {
                        sx {
                            fontSize = 14.px
                            marginTop = 0.25.rem
                            color = important(Color(Palette.Danger.main))
                            padding = Padding(5.px, 5.px, 0.px, 5.px)
                        }
                        error = true
                        htmlFor = props.design.header?.id
                        +it
                    }
                }
        }
}


external interface FCheckboxProps : Props {
    var design: DesignSystem.Checkbox
    var onChange: (Boolean) -> Unit
}

val fCheckbox = FC<FCheckboxProps> { props ->
    var value: Boolean by useState(props.design.checked ?: false)

    useEffectOnce {
        if (value != props.design.checked)
            props.onChange(props.design.checked ?: false)
    }

    useEffect(props.design.checked) {
        if (value != props.design.checked)
            value = props.design.checked ?: false
    }

    fun handleChange(event: ChangeEvent<HTMLInputElement>, v: Boolean) {
        (event.target as? HTMLInputElement)?.let { target ->
            props.onChange(target.checked)
            value = target.checked
        }
    }

    if (props.design.visible)
        FormControl {
            sx {
                display = Display.flex
                alignItems = if (props.design.data == null) AlignItems.flexStart else AlignItems.center
                flexDirection = FlexDirection.row
            }

            Checkbox {
                this.id = props.design.header?.id
                this.name = props.design.header?.id
                this.onChange = ::handleChange
                this.checked = value
                this.disabled = props.design.disabled
            }
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                props.design.label?.let {
                    if (props.design.data != null)
                        Stack {
                            direction = responsive(StackDirection.row)
                            spacing = responsive(1)

                            sx {
                                alignItems = AlignItems.center
                                gap = 0.5.rem
                            }
                            Box {
                                sx {
                                    height = 20.px
                                    width = 20.px
                                    minWidth = 20.px
                                    borderRadius = Shape.borderRadius100
                                    if (props.design.data != "") {
                                        backgroundColor = Color(props.design.data as String)
                                        opacity = 0.40.unsafeCast<Opacity>()
                                    }
                                    if (props.design.data == "")
                                        border = important(Border(1.px, LineStyle.solid, Palette.Text.secondary))
                                }
                            }
                            FormLabel {
                                htmlFor = props.design.header?.id
                                sx {
                                    fontWeight = 400.unsafeCast<FontWeight>()
                                    color = Palette.Text.primary
                                    "&.Mui-focused" {
                                        color = important(Palette.Text.primary)
                                    }
                                }
                                +it
                            }
                        }
                    else
                        FormLabel {
                            htmlFor = props.design.header?.id
                            sx {
                                fontWeight = 400.unsafeCast<FontWeight>()
                                color = Palette.Text.primary
                                "&.Mui-focused" {
                                    color = important(Palette.Text.primary)
                                }
                            }
                            +it
                        }
                }
                if (props.design.status?.message?.isNotEmpty() == true)
                    props.design.status?.message?.let {
                        FormLabel {
                            sx {
                                fontSize = 14.px
                                marginTop = 0.25.rem
                                color = important(Color(Palette.Danger.main))
                                padding = Padding(5.px, 5.px, 0.px, 5.px)
                            }
                            error = true
                            htmlFor = props.design.header?.id
                            +it
                        }
                    }
            }
        }
}


external interface FSwitchProps : Props {
    var design: DesignSystem.Switch
    var onChange: (Boolean) -> Unit
}

val fSwitch = FC<FSwitchProps> { props ->
    var value: Boolean by useState(props.design.checked ?: false)

    useEffectOnce {
        if (value != props.design.checked)
            props.onChange(props.design.checked ?: false)
    }

    useEffect(props.design.checked) {
        if (value != props.design.checked)
            value = props.design.checked ?: false
    }

    fun handleChange(event: ChangeEvent<HTMLInputElement>, v: Boolean) {
        (event.target as? HTMLInputElement)?.let { target ->
            props.onChange(target.checked)
            value = target.checked
        }
    }

    if (props.design.visible)
        Stack {
            FormControl {
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                    flexDirection = FlexDirection.row
                }

                Switch {
                    this.id = props.design.header?.id
                    this.name = props.design.header?.id
                    this.onChange = ::handleChange
                    this.checked = value
                    this.disabled = props.design.disabled
                }
                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                    }
                    props.design.label?.let {
                        FormLabel {
                            htmlFor = props.design.header?.id
                            sx {
                                fontWeight = 400.unsafeCast<FontWeight>()
                                color = Palette.Text.primary
                                "&.Mui-focused" {
                                    color = important(Palette.Text.primary)
                                }
                            }
                            +it
                        }
                    }
                }

            }
            if (props.design.status?.message?.isNotEmpty() == true)
                props.design.status?.message?.let {
                    FormLabel {
                        sx {
                            fontSize = 14.px
                            color = important(Color(Palette.Danger.main))
                            padding = Padding(0.px, 5.px, 0.px, 10.px)
                        }
                        error = true
                        htmlFor = props.design.header?.id
                        +it
                    }
                }
        }
}