package screens

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import services.findMedias
import support.*
import techla.base.*
import techla.base.onNotSuccess
import techla.guard.Group

object MattersScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    data class Texts(
        val title: String,
        val intro: String,
        val back: String,
        val sellVehicle: String,
        val buyVehicle: String,
        val privateBuyVehicle: String,
        val companyBuyVehicle: String,
        val buy: String,
        val sales: String,
        val status: String,
        val done: String,
        val logout: String,
        val emptySales: String,
        val emptyBuy: String,
        val ongoing: String,
        val contact: String,
        val regnumber: String,
        val started: String,
        val active: String,
        val infoColorLeasing: String,
        val companyLeasing: String,
        val infoColorBuy: String,
        val inactive: String,
        override val failureTitle: String,
        override val failureReason: String
    ) : FailureTexts {
        companion object
    }

    data class State(
        val page: Int = 1,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val buy: DesignSystem.Menu,
            val sell: DesignSystem.Button,
            val mattersTitle: DesignSystem.Text,
            val mattersIntro: DesignSystem.Text,
            val salesItems: DesignSystem.Table,
            val buyItems: DesignSystem.Table,
            val contact: DesignSystem.Button,
            val mailToSubject: String,
            val infoColor: List<DesignSystem.Option>,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation = navigation)

        fun ready(texts: Texts, state: State, sellItems: List<DesignSystem.Row>? = null, buyItems: List<DesignSystem.Row>? = null) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation(
                    title = "Fordonskrediten", background = DesignSystem.Background.LIGHT,
                    menu = DesignSystem.Menu(
                        title = "Profile name", items = listOf(
                            DesignSystem.Option.item(title = texts.logout, action = DesignSystem.Action.LOGOUT),
                        )
                    ),
                    location = Location.Matters
                ),
                buy = DesignSystem.Menu(
                    title = texts.buyVehicle, items = listOf(
                        DesignSystem.Option.item(title = texts.privateBuyVehicle, value = CustomerType.Private.rawValue),
                        DesignSystem.Option.item(title = texts.companyBuyVehicle, value = CustomerType.Company.rawValue),
                        DesignSystem.Option.item(title = texts.companyLeasing, value = CustomerType.Leasing.rawValue)
                    )
                ),
                sell = DesignSystem.Button(text = texts.sellVehicle, type = DesignSystem.Button.Type.BUTTON),
                mattersTitle = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD),
                mattersIntro = DesignSystem.Text(text = texts.intro, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                salesItems = DesignSystem.Table(
                    title = DesignSystem.Text(text = texts.sales, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                    empty = texts.emptySales,
                    body = sellItems, header = DesignSystem.Row(
                        cells = listOf(
                            DesignSystem.Option.item(title = texts.regnumber),
                            DesignSystem.Option.item(title = texts.status),
                            DesignSystem.Option.item(title = " ")
                        )
                    )
                ),
                buyItems = DesignSystem.Table(
                    title = DesignSystem.Text(text = texts.buy, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                    empty = texts.emptyBuy,
                    body = buyItems, header = DesignSystem.Row(
                        cells = listOf(
                            DesignSystem.Option.item(title = texts.regnumber),
                            DesignSystem.Option.item(title = texts.status),
                            DesignSystem.Option.item(title = " ")
                        )
                    )
                ),
                contact = DesignSystem.Button(text = texts.contact, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.TEXT),
                mailToSubject = "mailto:${texts.contact}?subject=profileId ${texts.contact}",
                infoColor = listOf(
                    DesignSystem.Option.item(title = texts.infoColorBuy, value = Palette.CommitmentDots.buy),
                    DesignSystem.Option.item(title = texts.infoColorLeasing, value = Palette.CommitmentDots.leasing)
                ),
            )


        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed

    }


    suspend fun load(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene

        updates.emit(sceneOf(viewModel.loading()))
        store.refreshObjects()
            .flatMap { (actions, objects) ->
                store.reduce(actions).findMedias().accumulate(actions)
                    .map { tupleOf(it.first, objects) }
            }
            .map { (actions, objects) ->
                val updated = store.reduce(actions)
                val texts = Texts(
                    title = updated.get(media = Key("screen:matters"), content = Key("title")),
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    intro = updated.get(media = Key("screen:matters"), content = Key("intro")),
                    back = updated.get(media = Key("screen:matters"), content = Key("back")),
                    sellVehicle = updated.get(media = Key("screen:matters"), content = Key("sellVehicle")),
                    privateBuyVehicle = updated.get(media = Key("screen:matters"), content = Key("privateBuyVehicle")),
                    companyBuyVehicle = updated.get(media = Key("screen:matters"), content = Key("companyBuyVehicle")),
                    buy = updated.get(media = Key("screen:matters"), content = Key("buy")),
                    sales = updated.get(media = Key("screen:matters"), content = Key("sales")),
                    status = updated.get(media = Key("screen:matters"), content = Key("status")),
                    done = updated.get(media = Key("screen:matters"), content = Key("done")),
                    logout = updated.get(media = Key("screen:matters"), content = Key("logout")),
                    emptySales = updated.get(media = Key("screen:matters"), content = Key("emptySales")),
                    emptyBuy = updated.get(media = Key("screen:matters"), content = Key("emptyBuy")),
                    ongoing = updated.get(media = Key("screen:matters"), content = Key("ongoing")),
                    contact = updated.get(media = Key("screen:matters"), content = Key("contact")),
                    regnumber = updated.get(media = Key("screen:matters"), content = Key("regnumber")),
                    started = updated.get(media = Key("screen:matters"), content = Key("started")),
                    active = updated.get(media = Key("screen:matters"), content = Key("active")),
                    buyVehicle = updated.get(media = Key("screen:matters"), content = Key("buyVehicle")),
                    infoColorLeasing = updated.get(media = Key("screen:matters"), content = Key("infoColorLeasing")),
                    infoColorBuy = updated.get(media = Key("screen:matters"), content = Key("infoColorBuy")),
                    companyLeasing = updated.get(media = Key("screen:matters"), content = Key("companyLeasing")),
                    inactive = updated.get(media = Key("screen:matters"), content = Key("inactive")),
                    )
                val sellItems = buildRow(texts, objects.filter { !it.isGroupLeader() })
                val buyItems = buildRow(texts, objects.filter { it.isGroupLeader() }, true)
                val action = Store.Action.ChangeCustomType(customerType = CustomerType.None)
                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = texts, state = viewModel.state, sellItems = sellItems, buyItems = buyItems), actions + action))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        return load(scene)
    }

    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }


    private fun buildRow(texts: Texts, obj: List<Object>? = emptyList(), buy: Boolean = false) =
        obj?.sortedByDescending { it.group.createdAt }?.map {
            DesignSystem.Row(
                id = it.id.rawValue,
                data = if (buy) groupColor(it.group.visualization.groupType) else null,
                cells = listOf(
                    DesignSystem.Option.item(title = it.group.name.replace("{REPLACE}", " - ")),
                    DesignSystem.Option.item(
                        title = when (it.group.status) {
                            Group.Status.Archived -> texts.done
                            Group.Status.Active -> texts.active
                            Group.Status.None -> texts.started
                            Group.Status.Inactive -> texts.inactive
                            else -> ""
                        }, data = when (it.group.status) {
                            Group.Status.Archived -> DesignSystem.Color.SUCCESS
                            Group.Status.Active -> DesignSystem.Color.WARNING
                            Group.Status.None -> DesignSystem.Color.DANGER
                            Group.Status.Inactive -> DesignSystem.Color.DANGER
                            else -> null
                        }
                    ),
                    DesignSystem.Option.item(image = DesignSystem.Image.RIGHTARROW, value = it.id.rawValue, data = it.group.visualization.groupType),
                )
            )
        }

}
