package components

import emotion.react.css
import mui.material.*

import mui.system.sx
import react.FC
import react.PropsWithClassName
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML
import support.*
import web.cssom.*

external interface FTableProps : PropsWithClassName {
    var design: DesignSystem.Table?
    var onClick: ((DesignSystem.Option?) -> Unit)
}

val fTable = FC<FTableProps> { props ->

    fun col(col: DesignSystem.Color?): ColorProperty? =
        when (col) {
            DesignSystem.Color.DANGER -> Color(Palette.Danger.main)
            DesignSystem.Color.SUCCESS -> Color(Palette.Success.main)
            DesignSystem.Color.WARNING -> Color(Palette.Warning.main)
            else -> Palette.Text.primary
        }


    if (props.design?.visible == true) {
        val design = props.design!!
        Paper {
            sx {
                width = 100.pct
                overflow = Overflow.hidden
                boxShadow = BoxShadow(0.px, 0.px, 0.px, 0.px, Color(Palette.Background.paper))
            }
            TableContainer {
                component = Paper
                sx {
                    minHeight = 400.px
                }

                fText { this.design = design.title }
                Table {
                    this.stickyHeader = true
                    design.title?.text?.let { ariaLabel = it }
                    design.header?.let { row ->
                        TableHead {

                            TableRow {
                                row.cells?.mapIndexed { index, cell ->
                                    TableCell {
                                        sx {
                                            backgroundColor = important(Color(Palette.Background.paper))
                                            color = Palette.Text.primary
                                            fontSize = FontSize.medium
                                            fontWeight = FontWeight.bold
                                            padding = Padding(1.rem, 0.rem)
                                            minWidth = 8.5.rem
                                            if (row.cells.size - 1 == index) {
                                                textAlign = TextAlign.end
                                                minWidth = 1.rem
                                            }
                                        }
                                        +cell.title
                                    }
                                }
                            }
                        }
                    }
                    if (design.body?.isEmpty() == false)
                        design.body?.let { body ->
                            TableBody {
                                body.map { row ->
                                    TableRow {
                                        row.cells?.mapIndexed { index, cell ->
                                            when (cell) {
                                                is DesignSystem.Option.Item -> {
                                                    TableCell {
                                                        sx {
                                                            backgroundColor = if (row.data != null) important(Color(row.data.toString())) else important(Color(Palette.Background.paper))
                                                            this.color = if (cell.data != null && cell.image == null) col(cell.data as DesignSystem.Color) else Palette.Text.primary
                                                            fontSize = FontSize.medium
                                                            fontWeight = FontWeight.normal
                                                            padding = Padding(1.rem, 0.rem)

                                                            if (index == 0) {
                                                                padding = Padding(1.rem, 10.px)
                                                            }

                                                            if (row.cells.size - 1 == index) {
                                                                textAlign = TextAlign.end
                                                                padding = Padding(1.rem, 10.px)
                                                            }
                                                        }

                                                        +cell.title
                                                        cell.image

                                                        cell.image?.let { image ->
                                                            ReactHTML.img {
                                                                css {
                                                                    cursor = Cursor.pointer
                                                                }
                                                                src = Design.image(image)
                                                                onClick = { props.onClick(cell) }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    if (design.body?.isEmpty() == true)
                        design.header?.let { row ->
                            TableBody {

                                TableRow {
                                    row.cells?.mapIndexed { index, _ ->
                                        TableCell {
                                            sx {
                                                backgroundColor = important(Color(Palette.Background.paper))

                                                fontSize = FontSize.medium
                                                fontWeight = FontWeight.normal
                                                padding = Padding(1.rem, 0.rem)
                                                borderBottom = important(Border(0.px, LineStyle.hidden, Color(Palette.Background.paper)))

                                                if (index == 0) {
                                                    padding = Padding(1.rem, 10.px)
                                                }

                                                if (row.cells.size - 1 == index) {
                                                    textAlign = TextAlign.end
                                                    padding = Padding(1.rem, 10.px)
                                                }
                                            }
                                            +""
                                        }
                                    }
                                }
                            }
                        }
                }
                if (design.body?.isEmpty() == true) {
                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            justifyContent = JustifyContent.center
                            alignItems = AlignItems.center
                            padding = Padding(1.rem, 0.rem, 0.rem, 0.rem)
                        }
                        fImage {
                            this.design = DesignSystem.ImageView(image = DesignSystem.Image.TABLEEMPTY)
                        }
                        fText { this.design = DesignSystem.Text(text = design.empty, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER) }

                    }
                }
            }
        }
    }
}

