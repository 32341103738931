package components.items

import components.*
import mui.material.Box
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.*
import web.cssom.*


external interface FormTextInputProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.TextInput
    var onChange: ((id: String, value: String) -> Unit)?
}

val FormItemTextInput = FC<FormTextInputProps> { props ->
    fun onChange(inputValue: String) {
        props.onChange?.let { it(props.viewModel.fieldKey.rawValue, inputValue) }
    }
    if (props.viewModel.visible)
        Box {
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                justifyContent = JustifyContent.spaceBetween

            }
            fTextInput { design = props.viewModel.value; onChange = ::onChange }

            if (props.viewModel.info != null)
                props.viewModel.info.let {
                    Box {
                        sx {
                            marginLeft = 1.rem
                        }
                        fInfoButton {
                            if (it != null) {
                                infoText = it
                            }
                        }
                    }
                }
        }
}

