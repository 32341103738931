import components.fLayout
import js.core.jso
import kotlinx.browser.localStorage
import kotlinx.browser.window
import mui.material.CssBaseline
import mui.system.ThemeProvider
import react.*
import react.router.RouterProvider
import react.router.dom.createBrowserRouter
import support.CustomerType
import support.Store
import support.deployment
import support.fTheme
import techla.base.Device
import techla.base.Identifier
import views.*

val storeReducer: Reducer<Store, List<Store.Action>> = { store, actions ->
    store.reduce(actions)
}

val emptyStore = Store(
    applicationContext = null,
    deployment = deployment,
    device = Device.Web(window.navigator.userAgent),

    loadStoredUserAuthenticationId = { localStorage.getItem("refresh")?.let { Identifier(it) } },
    updateStoredUserAuthenticationId = { localStorage.setItem("refresh", it.rawValue) },
    removeStoredUserAuthenticationId = { localStorage.removeItem("refresh") },

    loadStoredCustomerType = { localStorage.getItem("type")?.let { CustomerType.fromRawValue(it) } },
    updateStoredCustomerType = { localStorage.setItem("type", it.rawValue) },
    removeStoredCustomerType = { localStorage.removeItem("type") },
)
var StoreContext = createContext<Pair<Store, Dispatch<List<Store.Action>>>>(
    emptyStore to { _ -> emptyList<Store.Action>() }
)

object Routes {
    const val login = "/"
    const val loginApplyPrivate = "/login-private"
    const val loginApplyCompany = "/login-company"
    const val loginApplyLeasing = "/login-leasing"
    const val loginMatters = "/login-matters"
    const val loginBackOffice = "/login-backoffice"
    const val apply = "/apply"
    const val applyType = "/apply/:type"
    const val continueApply = "/apply/:type/:objectId"
    const val sell = "/sell"
    const val continueSell = "/sell/:objectId"
    const val contract = "/contract/:type/:objectId/:submissionId"
    const val matters = "/matters"
    const val backOffice = "/backOffice"
    const val backOfficeMatters = "/backOffice/:objectId"
    const val commitment = "/commitment"
    const val customer = "/customer/:profileId/:submissionId"
    const val dashboard = "/dashboard"
    const val payment = "/backOffice/:objectId/:payment"

    const val preview = "/preview"
}


val App = FC<Props> {
    val (store, storeDispatch) = useReducer(storeReducer, emptyStore)

    val appRouter = createBrowserRouter(
        arrayOf(
            jso {
                path = "/"
                element = fLayout.create()
                children = arrayOf(
                    jso { path = Routes.login; element = Start.create() },
                    jso { path = Routes.loginApplyCompany; element = Login.create() },
                    jso { path = Routes.loginApplyPrivate; element = Login.create() },
                    jso { path = Routes.loginApplyLeasing; element = Login.create() },
                    jso { path = Routes.loginMatters; element = Login.create() },
                    jso { path = Routes.loginBackOffice; element = Login.create() },
                    jso { path = Routes.apply; element = Apply.create() },
                    jso { path = Routes.applyType; element = Apply.create() },
                    jso { path = Routes.continueApply; element = Apply.create() },
                    jso { path = Routes.sell; element = Sell.create() },
                    jso { path = Routes.continueSell; element = Sell.create() },
                    jso { path = Routes.contract; element = Contract.create() },
                    jso { path = Routes.matters; element = Matters.create() },
                    jso { path = Routes.backOffice; element = BackOffice.create() },
                    jso { path = Routes.backOfficeMatters; element = BackOfficeMatters.create() },
                    jso { path = Routes.commitment; element = Commitment.create() },
                    jso { path = Routes.customer; element = Customer.create() },
                    jso { path = Routes.dashboard; element = Dashboard.create() },
                    jso { path = Routes.preview; element = Preview.create(); },
                    jso { path = Routes.payment; element = Payment.create() },
                )
            },
            jso {
                path = "*"
                element = Login.create()
            }
        )
    )

    /*
    children = arrayOf(
                        jso { path = Routes.payment; element = Payment.create() })
     */

    StrictMode {
        StoreContext.Provider(store to storeDispatch) {
            ThemeProvider {
                this.theme = fTheme
                CssBaseline {
                    RouterProvider { router = appRouter }
                }
            }
        }
    }
}