package services

import support.*
import techla.base.*
import techla.agreement.*
import techla.guard.Group
import techla.guard.Token

suspend fun Store.createAgreement(create: Agreement.Create): ActionOutcome<Agreement> {
    return agreementUserAPI { actions, api ->
        measureAPI(AgreementResource.CreateAgreement(create), api) {
            api.createAgreement(create)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createAgreement(obj: Object, create: Agreement.Create): ActionOutcome<Agreement> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createAgreement(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.editAgreement(id: Identifier<Agreement>, edit: Agreement.Edit): ActionOutcome<Agreement> {
    return agreementUserAPI { actions, api ->
        measureAPI(AgreementResource.EditAgreement(id, edit), api) {
            api.editAgreement(id, edit)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.editAgreement(obj: Object, id: Identifier<Agreement>, edit: Agreement.Edit): ActionOutcome<Agreement> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).editAgreement(id, edit)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.findSignatures(id: Identifier<Agreement>): ActionOutcome<List<Signature>> {
    return agreementUserAPI { actions, api ->
        measureAPI(AgreementResource.FindSignatures(listOf(id)), api) {
            api.findSignatures(listOf(id))
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.findSignatures(obj: Object, id: Identifier<Agreement>): ActionOutcome<List<Signature>> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).findSignatures(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.getSignature(id: Identifier<Signature>): ActionOutcome<Signature> {
    return agreementUserAPI { actions, api ->
        measureAPI(AgreementResource.GetSignature(id), api) {
            api.getSignature(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createSignature(create: Signature.Create): ActionOutcome<Signature> {
    return agreementUserAPI { actions, api ->
        measureAPI(AgreementResource.CreateSignature(create), api) {
            api.createSignature(create)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.listTerms(agreement: Key<Agreement>): ActionOutcome<List<Term>> {
    return agreementUserAPI { actions, api ->
        measureAPI(AgreementResource.ListTerms(agreement), api) {
            api.listTerms(agreement)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.deleteAgreement(id: Identifier<Agreement>): ActionOutcome<Unit> {
    return agreementUserAPI { actions, api ->
        measureAPI(AgreementResource.DeleteAgreement(id), api) {
            api.deleteAgreement(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.listAgreements(group: Key<Group>): ActionOutcome<List<Agreement>> {
    return agreementAdminAPI { actions, api ->
        api.additionalHeaders = listOf("group" to group.rawValue)
        measureAPI(AgreementResource.ListAgreements, api) {
            api.listAgreements()
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.listAgreements(obj: Object): ActionOutcome<List<Agreement>> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).listAgreements(obj.group.key)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.listSignatures(group: Key<Group>): ActionOutcome<List<Signature>> {
    return agreementAdminAPI { actions, api ->
        api.additionalHeaders = listOf("group" to group.rawValue)
        measureAPI(AgreementResource.ListSignatures, api) {
            api.listSignatures()
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.listSignatures(obj: Object): ActionOutcome<List<Signature>> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).listSignatures(obj.group.key)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.findAgreements(keys: List<Key<Agreement>>, status: List<Agreement.Status>?): ActionOutcome<List<Agreement>> {
    return agreementUserAPI { actions, api ->
        measureAPI(AgreementResource.FindAgreements(keys, status), api) {
            api.findAgreements(keys, status)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createSignature(obj: Object, create: Signature.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createSignature(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.getSignature(obj: Object, id: Identifier<Signature>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getSignature(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.findLatestAgreement(keys: List<Key<Agreement>>, status: Agreement.Status? = null) =
    findAgreements(keys, status?.let { listOf(it) })
        .map { (actions, agreements) ->
            tupleOf(actions, agreements.filter { it.version == 1 })
        }

suspend fun Store.findLatestAgreement(obj: Object, keys: List<Key<Agreement>>, status: Agreement.Status? = null) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).findLatestAgreement(keys, status)
    }

suspend fun Store.createTerms(create: Term.Batch): ActionOutcome<List<Term>> {
    return agreementUserAPI { actions, api ->
        measureAPI(AgreementResource.CreateTerms(create), api) {
            api.createTerms(create)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createTerms(obj: Object, create: Term.Batch): ActionOutcome<List<Term>> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createTerms(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun <T> Store.agreementAdminAPI(block: suspend (List<Store.Action>, AgreementAPI) -> ActionOutcome<T>): ActionOutcome<T> {
    return withUserToken { actions ->
        val api = AgreementAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) AgreementAPI.sandbox else AgreementAPI.shared
            api.token = reduce(actions).adminToken
        }
        block(actions, api)
    }
}
suspend fun <T> Store.agreementUserAPI(block: suspend (List<Store.Action>, AgreementAPI) -> ActionOutcome<T>): ActionOutcome<T> {
    return withUserToken { actions ->
        val api = AgreementAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) AgreementAPI.sandbox else AgreementAPI.shared
            api.token = reduce(actions).let { it.userToken ?: it.applicationToken }
        }
        block(actions, api)
    }
}
