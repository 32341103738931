package components

import mui.material.*
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.dom.events.SyntheticEvent
import support.DesignSystem
import support.Palette
import web.cssom.Color
import web.cssom.important

external interface FToastProps : PropsWithChildren {
    var design: DesignSystem.Toast
    var handleCloseToast: () -> Unit
}

val fToast = FC<FToastProps> { props ->

    fun onClose(event: SyntheticEvent<*, *>, reason: SnackbarCloseReason?) {
        props.handleCloseToast()
    }

    fun onCloseAlert(event: SyntheticEvent<*, *>) {
        onClose(event, SnackbarCloseReason.clickaway)
    }

    Snackbar {
        this.onClose = ::onClose
        this.autoHideDuration = 3000
        this.open = props.design.open
        sx {
            backgroundColor = important(Color(Palette.Info.main))
        }
        Alert {
            this.onClose = ::onCloseAlert
            this.severity = AlertColor.info
            this.variant = AlertVariant.filled
            +props.design.text
        }
    }
}