package components.items

import components.*
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.FormItem
import support.Palette
import web.cssom.*


external interface FormSummaryServiceIncludedProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.SummaryServicesIncluded
}


val FormSummaryServiceIncluded = FC<FormSummaryServiceIncludedProps> { props ->

    if (props.viewModel.visible)
        Stack {
            spacing = responsive(2)
            sx {
                paddingBottom = 1.rem
            }

            if (props.viewModel.warranty.visible || props.viewModel.tryWarranty.visible)
                Stack {
                    direction = responsive(StackDirection.column)
                    spacing = responsive(2)
                    sx {
                        justifyContent = JustifyContent.start
                    }
                    Divider {
                        sx {
                            backgroundColor = Palette.Text.primary
                            marginTop = important(1.rem)
                        }
                    }
                    fText { design = props.viewModel.servicesIncludedTitle }

                    if (props.viewModel.tryWarranty.visible)
                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                                alignItems = AlignItems.center
                            }
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(2)
                                sx {
                                    alignItems = AlignItems.center
                                }
                                fImage { design = props.viewModel.check }
                                fText { design = props.viewModel.tryWarranty }
                            }
                        }

                    if (props.viewModel.warranty.visible)
                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                                alignItems = AlignItems.center
                            }
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(2)
                                sx {
                                    alignItems = AlignItems.center
                                }
                                fImage { design = props.viewModel.check }
                                fText { design = props.viewModel.warranty }
                            }
                        }
                }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(2)
                fText { design = props.viewModel.countExample }
            }

        }
}


