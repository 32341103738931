package screens

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import services.*
import support.*
import techla.agreement.Agreement
import techla.agreement.Signature
import techla.base.*
import techla.base.Date.Companion.dateAt
import techla.base.onNotSuccess
import techla.form.Field
import techla.form.Submission
import techla.guard.Group
import techla.guard.Invite
import techla.payment.Payment
import techla.payment.Provider

object BackOfficeMattersScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    object Header {
        val status = DesignSystem.Header("status")
    }

    data class Texts(
        val title: String,
        val save: String,
        val back: String,
        val sendEmail: String,
        val actions: String,
        val sumTitle: String,
        val buyer: String,
        val seller: String,
        val agreement: String,
        val emptyItems: String,
        val signBy: String,
        val logout: String,
        val statusFormStarted: String,
        val statusFormActive: String,
        val statusAgreementWasa: String,
        val statusAgreementFK: String,
        val statusAgreementSign: String,
        val statusFormSellerInvite: String,
        val statusWaitingOnFK: String,
        val bankInfo: String,
        val caseNumber: String,
        val wasa: String,
        val approved: String,
        val denied: String,
        val wasaAgreement: String,
        val fkBuyAgreement: String,
        val fkCompanyAgreement: String,
        val fkPrivateAgreement: String,
        val fkLeasingCompanyAgreement: String,
        val fkLeasingPrivateAgreement: String,
        val scrive: String,
        val make: String,
        val regnumber: String,
        val price: String,
        val groupStarted: String,
        val groupActive: String,
        val groupArchived: String,
        val installmentStatus: String,
        val installmentId: String,
        val yes: String,
        val no: String,
        val month: String,
        val warranty: String,
        val cost: String,
        val missingMembers: String,
        val missingBuyerSignatureWasa: String,
        val missingBuyerSignature: String,
        val missingSellerSignature: String,
        val showApplication: String,
        val application: String,
        val hk: String,
        val sek: String,
        val km: String,
        val kg: String,
        val govId: String,
        val interestRate: String,
        val upperBound: String,
        val groupStatus: String,
        val groupStatusToast: String,
        val percent: String,
        val fkAgreement: String,
        val privateCompany: String,
        val companyCompany: String,
        val leasingCompanyCompany: String,
        val privatePrivate: String,
        val leasingCompanyPrivate: String,
        val companyPrivate: String,
        val company: String,
        val private: String,
        val leasing: String,
        val groupInactive: String,
        val tryGuarantee: String,
        val codeTitle: String,
        val code: String,
        val showInviteModal: String,
        val copyCode: String,
        val codeCopied: String,
        val deptPaymentBtn: String,
        val excessPaymentBtn: String,
        val sure: String,
        val excessPayment: String,
        val residualDeptPayment: String,
        val name: String,
        val paymentTo: String,
        val message: String,
        val payoffExcess: String,
        val payoffResidualDept: String,
        val commitmentDone: String,
        val transactionFailed: String,
        val paymentSignatureMissing: String,
        val paymentPending: String,
        val paymentCancelled: String,
        val paymentCompleted: String,
        val fieldRequired: String,
        override val failureTitle: String,
        override val failureReason: String,
        override var itemEdit: String,
        override var itemSave: String,
        override var itemCancel: String,
        override var itemPayment: String,
    ) : FailureTexts, FormItemEditableItemTexts {
        companion object
    }


    data class FieldValues(
        val fieldKey: Key<Field>,
        val text: String,
        val modified: Boolean = false
    )

    enum class EDITABLE { NONE, EXCESS, RESIDUAL_DEBT }

    data class State(
        val page: Int = 1,
        val status: String? = null,
        val obj: Object = Object.None,
        val groupStatusOptions: List<DesignSystem.Option.Item> = emptyList(),
        val selectedGroupStatus: DesignSystem.Option.Item? = null,
        val showApplicationModal: Boolean = false,
        val showInviteModal: Boolean = false,
        val fieldsEditable: EDITABLE = EDITABLE.NONE,
        val fieldValues: List<FieldValues> = emptyList(),
        val paymentCheck: Boolean = false,
        val showPaymentModal: EDITABLE = EDITABLE.NONE,
        val pollPayment: Boolean = false,
        val fieldsStatus: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList(),
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val readyTitle: DesignSystem.Text,
            val save: DesignSystem.Button,
            val regNumber: DesignSystem.Text,
            val make: DesignSystem.Text,
            val sumTitle: DesignSystem.Text,
            val sum: DesignSystem.Text,
            val status: DesignSystem.SelectInput,
            val buyerItems: List<FormItem.ViewModel>,
            val sellerItems: List<FormItem.ViewModel>,
            val buyer: DesignSystem.Text,
            val buyerStatus: DesignSystem.Text,
            val seller: DesignSystem.Text,
            val agreement: DesignSystem.Text,
            val sellerStatus: DesignSystem.Text,
            val agreementItems: DesignSystem.Table,
            val back: DesignSystem.Button,
            val bankItems: DesignSystem.Table,
            val bankTitle: DesignSystem.Text,
            val applicationBtn: DesignSystem.Button,
            val applicationModal: DesignSystem.Modal,
            val groupStatus: DesignSystem.Text,
            val groupStatusToast: DesignSystem.Toast,
            val groupStatusTitle: DesignSystem.Text,
            val codeBtn: DesignSystem.Button,
            val codeModal: DesignSystem.Modal,
            val copyCode: DesignSystem.Toast,
            val sellerDeptItems: List<FormItem.ViewModel>,
            val sellerExcessItems: List<FormItem.ViewModel>,
            val residualDeptModal: DesignSystem.Modal,
            val excessModal: DesignSystem.Modal,
        ) : ViewModel(texts, state, navigation)

        data class Finished(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation = navigation)

        private fun status(texts: Texts, group: Group? = null, submission: Submission? = null, agreement: List<Agreement?> = emptyList(), seller: Boolean): String {
            return if (group?.status != Group.Status.Archived && seller) {
                //return if (group?.visualization?.color != null && group.status != Group.Status.Archived && seller) {
                texts.statusWaitingOnFK
            } else if (group?.members != null && group.members.size == 1 && group.numberOfInvites == 0 && seller) {
                texts.statusFormSellerInvite
            } else if (agreement.isEmpty() && submission != null) {
                when (submission.state) {
                    is Submission.State.Closed -> texts.statusFormActive
                    is Submission.State.Archived -> texts.statusFormActive
                    is Submission.State.Rejected -> texts.statusFormActive
                    else -> texts.statusFormStarted
                }
            } else if (agreement.size == 1 && submission != null) {

                /*if (agreement.firstOrNull { it?.key?.rawValue == FK.wasaKredit } == null)
                    when (agreement.firstOrNull { it?.key?.rawValue == FK.wasaKredit }?.status) {
                        is Agreement.Status.Approved -> texts.statusAgreementWasa
                        else -> texts.statusAgreementSign
                    }
                else {

                 */
                when (agreement.firstOrNull { it?.key?.rawValue != FK.wasaKredit }?.status) {
                    is Agreement.Status.Approved -> texts.statusAgreementFK
                    else -> texts.statusAgreementSign
                }
                //}
            } else if (agreement.isNotEmpty() && submission != null) {
                when (agreement.firstOrNull()?.status) {
                    is Agreement.Status.Approved -> texts.statusAgreementFK
                    else -> if (group?.status == Group.Status.Archived) texts.statusAgreementFK else texts.statusAgreementSign
                }
            } else {
                texts.statusFormStarted
            }
        }


        private fun statusColor(agreement: List<Agreement?> = emptyList(), group: Group) =
            if (group.status is Group.Status.Archived) {
                DesignSystem.Color.SUCCESS
            } else if (group.status is Group.Status.Inactive) {
                DesignSystem.Color.DANGER
            } else if (agreement.filter { it?.status == Agreement.Status.Approved }.size == agreement.size) {
                DesignSystem.Color.SUCCESS
            } else {
                DesignSystem.Color.WARNING
            }


        fun ready(texts: Texts, state: State, buyer: List<FormItem.ViewModel>, seller: List<FormItem.ViewModel>, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList(), showGroupStatusToast: Boolean = false, showCopyToast: Boolean = false, excess: List<FormItem.ViewModel>, dept: List<FormItem.ViewModel>) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation(
                    title = "Fordonskrediten", background = DesignSystem.Background.DARK,
                    menu = DesignSystem.Menu(
                        title = "Profile name", items = listOf(
                            DesignSystem.Option.item(title = texts.logout, action = DesignSystem.Action.LOGOUT),
                        )
                    ),
                    location = Location.BackOffice
                ),
                back = DesignSystem.Button(text = texts.back, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.TEXT),
                readyTitle = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD),
                groupStatusTitle = DesignSystem.Text(
                    text = when (state.obj.group.visualization.buyType) {
                        GroupCombinations.private -> texts.private
                        GroupCombinations.privatePrivate -> texts.privatePrivate
                        GroupCombinations.company -> texts.company
                        GroupCombinations.privateCompany -> texts.privateCompany
                        GroupCombinations.companyPrivate -> texts.companyPrivate
                        GroupCombinations.companyCompany -> texts.companyCompany
                        GroupCombinations.leasing -> texts.leasing
                        GroupCombinations.leasingCompanyCompany -> texts.leasingCompanyCompany
                        GroupCombinations.leasingCompanyPrivate -> texts.leasingCompanyPrivate
                        else -> null
                    }, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER
                ),
                save = DesignSystem.Button(text = texts.save, type = DesignSystem.Button.Type.BUTTON),
                status = DesignSystem.SelectInput(header = Header.status, options = state.groupStatusOptions, selected = state.selectedGroupStatus, status = status.statusOf(Header.status)),
                buyerItems = buyer,
                sellerItems = seller,
                sumTitle = DesignSystem.Text(text = texts.sumTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                sum = DesignSystem.Text(text = texts.price, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.PRIMARY),
                regNumber = DesignSystem.Text(text = texts.regnumber, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                make = DesignSystem.Text(text = texts.make, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.PRIMARY),
                buyer = DesignSystem.Text(text = texts.buyer, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                seller = DesignSystem.Text(text = texts.seller, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                agreement = DesignSystem.Text(text = texts.agreement, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                buyerStatus = DesignSystem.Text(text = status(texts, state.obj.group, state.obj.submissionBuyer, listOf(state.obj.buyerAgreement, state.obj.wasaAgreement), false), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, color = statusColor(listOf(state.obj.buyerAgreement), state.obj.group)),
                sellerStatus = DesignSystem.Text(text = status(texts, state.obj.group, state.obj.submissionSeller, listOf(state.obj.sellerAgreement), true), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, color = statusColor(listOf(state.obj.sellerAgreement), state.obj.group)),
                agreementItems = DesignSystem.Table(
                    empty = texts.emptyItems,
                    header = DesignSystem.Row(
                        cells = listOf(
                            DesignSystem.Option.item(title = ""),
                            DesignSystem.Option.item(title = texts.signBy),
                        )
                    ),
                    body = listOf(
                        DesignSystem.Row(
                            cells = listOf(
                                DesignSystem.Option.item(title = texts.wasaAgreement),
                                DesignSystem.Option.item(title = agreementIsSigned(state.obj.signatures, state.obj.wasaAgreement?.id)),
                            )
                        ),
                        DesignSystem.Row(
                            cells = listOf(
                                DesignSystem.Option.item(
                                    title = when (state.obj.group.visualization.buyType) {
                                        GroupCombinations.leasingCompanyCompany -> texts.fkLeasingCompanyAgreement
                                        GroupCombinations.leasingCompanyPrivate -> texts.fkLeasingPrivateAgreement
                                        else -> texts.fkBuyAgreement
                                    }
                                ),
                                DesignSystem.Option.item(title = agreementIsSigned(state.obj.signatures, state.obj.buyerAgreement?.id)),
                            )

                        ),
                        DesignSystem.Row(
                            cells = listOf(
                                //DesignSystem.Option.item(title = if (state.obj.group.visualization.groupType == CustomerType.Private.rawValue) texts.fkPrivateAgreement else texts.fkCompanyAgreement),
                                DesignSystem.Option.item(
                                    title = when (state.obj.group.visualization.buyType) {
                                        GroupCombinations.privatePrivate, GroupCombinations.companyPrivate, GroupCombinations.leasingCompanyPrivate -> texts.fkPrivateAgreement
                                        GroupCombinations.privateCompany, GroupCombinations.companyCompany, GroupCombinations.leasingCompanyCompany -> texts.fkCompanyAgreement
                                        else -> texts.fkAgreement
                                    }
                                ),
                                DesignSystem.Option.item(title = texts.scrive),
                            )
                        )
                    ),
                ),
                bankTitle = DesignSystem.Text(text = texts.bankInfo, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                bankItems = DesignSystem.Table(
                    empty = texts.emptyItems,
                    body = listOf(
                        DesignSystem.Row(
                            cells = listOf(
                                DesignSystem.Option.item(title = texts.installmentStatus),
                                DesignSystem.Option.item(title = texts.installmentId),
                            )
                        )
                    ),
                    header = DesignSystem.Row(
                        cells = listOf(
                            DesignSystem.Option.item(title = texts.wasa),
                            DesignSystem.Option.item(title = texts.caseNumber),
                        )
                    )
                ),
                applicationModal = DesignSystem.Modal(
                    visible = state.showApplicationModal,
                    title = DesignSystem.Text(text = texts.application, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD),
                    body = listOf(DesignSystem.Text(isMarkdown = true, text = createMarkdownList(state.obj, texts).trimIndent()))
                ),
                applicationBtn = DesignSystem.Button(text = texts.showApplication, type = DesignSystem.Button.Type.BUTTON),
                groupStatus = DesignSystem.Text(text = texts.groupStatus, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                groupStatusToast = DesignSystem.Toast(text = listOf(texts.groupStatusToast, state.selectedGroupStatus?.title ?: "").joinToString(" "), open = showGroupStatusToast),
                codeModal = DesignSystem.Modal(
                    visible = state.showInviteModal,
                    title = DesignSystem.Text(text = texts.codeTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                    body = listOf(DesignSystem.TextInput(header = DesignSystem.Header(""), value = texts.code, disabled = true)),
                    firstButton = DesignSystem.Button(text = texts.copyCode, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE),
                ),
                codeBtn = DesignSystem.Button(text = texts.showInviteModal, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE),
                copyCode = DesignSystem.Toast(text = texts.codeCopied, open = showCopyToast),
                sellerExcessItems = excess,
                sellerDeptItems = dept,
                residualDeptModal = DesignSystem.Modal(
                    visible = state.showPaymentModal == EDITABLE.RESIDUAL_DEBT,
                    title = DesignSystem.Text(text = texts.sure, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD),
                    body = listOf(
                        DesignSystem.Text(text = texts.residualDeptPayment, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                        DesignSystem.Text(text = listOf(formatNumber(getValue(state.obj.submissionSeller?.entries, "RESIDUAL_DEBT")?.replace("\\s".toRegex(), "")?.toDouble()), texts.sek).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                        DesignSystem.Text(text = texts.name, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                        DesignSystem.Text(text = getValue(state.obj.submissionSeller?.entries, "COMPANY_NAME") ?: listOf(getValue(state.obj.submissionSeller?.entries, "FIRST_NAME"), getValue(state.obj.submissionSeller?.entries, "LAST_NAME")).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                        DesignSystem.Divider(),
                        DesignSystem.Text(text = texts.paymentTo, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                        DesignSystem.Text(text = getLabel(state.obj.submissionSeller?.form?.fields, "BANK_ACCOUNT_NUMBER_RESIDUAL_DEBT"), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                        DesignSystem.Text(text = getValue(state.obj.submissionSeller?.entries, "BANK_ACCOUNT_NUMBER_RESIDUAL_DEBT"), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),

                        DesignSystem.Text(text = texts.message, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                        DesignSystem.Text(text = listOf(texts.payoffResidualDept, getValue(state.obj.submissionSeller?.entries, "REGNO")).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                    ),
                    firstButton = DesignSystem.Button(text = texts.yes, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.CONTAINED),
                    secondButton = DesignSystem.Button(text = texts.no, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE)
                ),
                excessModal = DesignSystem.Modal(
                    visible = state.showPaymentModal == EDITABLE.EXCESS,
                    title = DesignSystem.Text(text = texts.sure, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD),
                    body = listOf(
                        DesignSystem.Text(text = texts.excessPayment, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                        DesignSystem.Text(text = listOf(formatNumber(getValue(state.obj.submissionSeller?.entries, "EXCESS")?.replace("\\s".toRegex(), "")?.substringBefore(":")?.toDouble()), texts.sek).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                        DesignSystem.Text(text = texts.name, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                        DesignSystem.Text(text = getValue(state.obj.submissionSeller?.entries, "COMPANY_NAME") ?: listOf(getValue(state.obj.submissionSeller?.entries, "FIRST_NAME"), getValue(state.obj.submissionSeller?.entries, "LAST_NAME")).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                        DesignSystem.Divider(),
                        DesignSystem.Text(text = texts.paymentTo, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                        DesignSystem.Text(text = getLabel(state.obj.submissionSeller?.form?.fields, "CLEARING_NUMBER"), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, visible = getLabel(state.obj.submissionSeller?.form?.fields, "CLEARING_NUMBER") != null),
                        DesignSystem.Text(text = getValue(state.obj.submissionSeller?.entries, "CLEARING_NUMBER"), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, visible = getValue(state.obj.submissionSeller?.entries, "CLEARING_NUMBER") != null),
                        DesignSystem.Text(text = getLabel(state.obj.submissionSeller?.form?.fields, "BANK_ACCOUNT_NUMBER"), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                        DesignSystem.Text(text = getValue(state.obj.submissionSeller?.entries, "BANK_ACCOUNT_NUMBER"), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                        DesignSystem.Text(text = texts.message, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                        DesignSystem.Text(text = listOf(texts.payoffExcess, getValue(state.obj.submissionSeller?.entries, "REGNO")).joinToString(" "), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                    ),
                    firstButton = DesignSystem.Button(text = texts.yes, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.CONTAINED),
                    secondButton = DesignSystem.Button(text = texts.no, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE),
                ),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private suspend fun callReady(viewModel: ViewModel, texts: Texts, state: State, actions: List<Store.Action>? = emptyList(), showCopyToast: Boolean = false, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList(), showGroupStatusToast: Boolean = false) {
        updates.emit(
            sceneOf<ViewModel>(
                viewModel.ready(
                    texts = texts,
                    state = state,
                    seller = buildItems(texts, state, state.obj.submissionSeller),
                    buyer = buildItems(texts, state, state.obj.submissionBuyer),
                    dept = buildItems(texts, state, state.obj.submissionSeller, isDept = true, status = state.fieldsStatus),
                    excess = buildItems(texts, state, state.obj.submissionSeller, isExcess = true, status = state.fieldsStatus),
                    showCopyToast = showCopyToast,
                    showGroupStatusToast = showGroupStatusToast,
                    status = status
                ),
                actions ?: emptyList()
            )
        )
    }

    fun createMarkdownList(obj: Object, texts: Texts): String {
        val stringBuilder = StringBuilder()
        val realPrincipal = obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "REAL_PRINCIPAL" }?.text
        val yesPrincipalFields = listOf("YES_NAME_1", "YES_NAME_2", "YES_NAME_3", "YES_NAME_4", "YES_GOVID_1", "YES_GOVID_2", "YES_GOVID_3", "YES_GOVID_4")
        val noPrincipalFields = listOf("NO_NAME_1", "NO_GOVID_1")
        val excludeSpecialFields = listOf("SUMMARY", "DIVIDER", "REPAYMENT_TIMES", "SUMMARY_SERVICES_INCLUDED", "SUMMARY_MONTHLY_COST", "LEASING_UPPER_BOUND", "SUMMARY_TOTAL_LOAN")

        val excludeFields = when (realPrincipal) {
            "Ja" -> excludeSpecialFields + noPrincipalFields
            "Nej" -> excludeSpecialFields + yesPrincipalFields
            else -> excludeSpecialFields
        }

        obj.submissionBuyer?.form?.fields?.filter { !excludeFields.contains(it.key.rawValue) }?.forEach { field ->
            val entryValue = obj.submissionBuyer?.entries?.firstOrNull { it.fieldKey == field.key }?.text ?: "-"
            val value = if (entryValue == "true") texts.yes else if (entryValue == "false") texts.no else entryValue.trim()

            val formattedValue = when (field.key.rawValue) {
                "PRICE", "LOAN", "WEIGHT", "METER_SETTING", "POWER", "CASH_STAKE" -> if (value.isNullOrEmpty() || value.trim() == "-") "" else "**${formatNumber(value.toDouble())}**"
                "CREDIT_AMOUNT" -> if (value.trim().isNullOrBlank()) "**-**" else "**$value**"
                "LABEL", "HEADING", "MARKDOWN_LABEL" -> ""
                "SUBMITTED" -> "**${value.substringBefore("T")}**"
                else -> "**${value}**"
            }

            val adone = when (field.style) {
                Field.Style.DownPayment -> "**${texts.sek}**"
                Field.Style.MonthlyIncome -> "**${texts.sek}**"
                Field.Style.MonthlyHousingCost -> "**${texts.sek}**"
                Field.Style.MonthlyOtherCost -> "**${texts.sek}**"
                else -> when (field.key.rawValue) {
                    "METER_SETTING" -> "**${texts.km}**"
                    "POWER" -> "**${texts.hk}**"
                    "PRICE", "LOAN", "OTHER_RESIDENT_COST" -> "**${texts.sek}**"
                    "CREDIT_AMOUNT" -> if (value == "-") "" else "**${texts.sek}**"
                    "WEIGHT" -> "**${texts.kg}**"
                    "RESIDUAL_UPPER_BOUND", "INTEREST_RATE" -> "**${texts.percent}**"
                    else -> ""
                }
            }

            val punctuation = if (field.key.rawValue == "LOAN" || field.key.rawValue == "LABEL" || field.key.rawValue == "MARKDOWN_LABEL" || field.key.rawValue == "HEADING") "" else ":"
            val label = when (field.key.rawValue) {
                "GOV_ID" -> texts.govId
                "INTEREST_RATE" -> texts.interestRate
                "RESIDUAL_UPPER_BOUND" -> texts.upperBound
                "LABEL", "MARKDOWN_LABEL" -> {
                    if (field.key.rawValue == "MARKDOWN_LABEL" && field.page == 2) ""
                    else if (field.key.rawValue == "LABEL" && field.page == 1) ""
                    else "  \n ${field?.placeholder ?: field?.label}"
                }

                "HEADING" -> "  \n **${field.label}**"
                "REAL_PRINCIPAL" -> "${field.label?.replace("{SPLIT}", "")}"
                "TRY_GUARANTEE" -> texts.tryGuarantee
                "SUBMITTED" -> texts.commitmentDone
                else -> field.label
            }
            stringBuilder.append("${label}$punctuation &nbsp; $formattedValue $adone   \n")
        }
        return stringBuilder.toString()
    }

    suspend fun firstLoad(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>) {
        val (_, viewModel) = scene
        updates.emit(sceneOf(viewModel.loading()))
        load(scene, objectId)
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>) {
        val (store, viewModel) = scene

        store.adminRefreshObject(objectId)
            .flatMap { (actions, full) ->
                store.reduce(actions).findMedias()
                    .accumulate(actions)
                    .map { tupleOf(it.first, full) }
            }
            .map { (actions, full) ->
                val updated = store.reduce(actions)
                val textParts = full.group.name.split("{REPLACE}")
                val result = (textParts + List(4 - textParts.size) { "-" }).take(4)
                val installmentStatus = full.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "INSTALLMENT_STATUS" }?.text ?: "-"
                val installmentId = full.submissionBuyer?.entries?.firstOrNull { it.fieldKey.rawValue == "WASA_KREDIT_INSTALLMENT_ID" }?.text ?: "-"
                val texts = Texts(
                    title = updated.get(media = Key("screen:backofficeMatters"), content = Key("title")),
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    save = updated.get(media = Key("screen:backofficeMatters"), content = Key("save")),
                    sendEmail = updated.get(media = Key("screen:backofficeMatters"), content = Key("sendEmail")),
                    actions = updated.get(media = Key("screen:backofficeMatters"), content = Key("actions")),
                    back = updated.get(media = Key("screen:backofficeMatters"), content = Key("back")),
                    sumTitle = updated.get(media = Key("screen:backofficeMatters"), content = Key("sumTitle")),
                    buyer = updated.get(media = Key("screen:backofficeMatters"), content = Key("buyer")),
                    seller = updated.get(media = Key("screen:backofficeMatters"), content = Key("seller")),
                    agreement = updated.get(media = Key("screen:backofficeMatters"), content = Key("agreement")),
                    emptyItems = updated.get(media = Key("screen:backofficeMatters"), content = Key("emptyItems")),
                    signBy = updated.get(media = Key("screen:backofficeMatters"), content = Key("signBy")),
                    logout = updated.get(media = Key("screen:backofficeMatters"), content = Key("logout")),
                    statusFormStarted = updated.get(media = Key("screen:backofficeMatters"), content = Key("statusFormStarted")),
                    statusFormActive = updated.get(media = Key("screen:backofficeMatters"), content = Key("statusFormActive")),
                    statusAgreementSign = updated.get(media = Key("screen:backofficeMatters"), content = Key("statusAgreementSign")),
                    statusAgreementWasa = updated.get(media = Key("screen:backofficeMatters"), content = Key("statusAgreementWasa")),
                    statusAgreementFK = updated.get(media = Key("screen:backofficeMatters"), content = Key("statusAgreementFK")),
                    statusFormSellerInvite = updated.get(media = Key("screen:backofficeMatters"), content = Key("statusFormSellerInvite")),
                    bankInfo = updated.get(media = Key("screen:backofficeMatters"), content = Key("bankInfo")),
                    caseNumber = updated.get(media = Key("screen:backofficeMatters"), content = Key("caseNumber")),
                    wasa = updated.get(media = Key("screen:backofficeMatters"), content = Key("wasa")),
                    approved = updated.get(media = Key("screen:backofficeMatters"), content = Key("approved")),
                    denied = updated.get(media = Key("screen:backofficeMatters"), content = Key("denied")),
                    wasaAgreement = updated.get(media = Key("screen:backofficeMatters"), content = Key("wasaAgreement")),
                    fkBuyAgreement = updated.get(media = Key("screen:backofficeMatters"), content = Key("fkBuyAgreement")),
                    fkCompanyAgreement = updated.get(media = Key("screen:backofficeMatters"), content = Key("fkCompanyAgreement")),
                    fkPrivateAgreement = updated.get(media = Key("screen:backofficeMatters"), content = Key("fkPrivateAgreement")),
                    scrive = updated.get(media = Key("screen:backofficeMatters"), content = Key("scrive")),
                    make = "${result[1].trim()}, ${result[2]}",
                    regnumber = result[0],
                    price = "",
                    statusWaitingOnFK = updated.get(media = Key("screen:backofficeMatters"), content = Key("statusWaitingOnFK")),
                    groupStarted = updated.get(media = Key("screen:backofficeMatters"), content = Key("groupStarted")),
                    groupActive = updated.get(media = Key("screen:backofficeMatters"), content = Key("groupActive")),
                    groupArchived = updated.get(media = Key("screen:backofficeMatters"), content = Key("groupArchived")),
                    installmentStatus = installmentStatus,
                    installmentId = installmentId,
                    yes = updated.get(media = Key("screen:backofficeMatters"), content = Key("yes")),
                    no = updated.get(media = Key("screen:backofficeMatters"), content = Key("no")),
                    month = updated.get(media = Key("screen:backofficeMatters"), content = Key("month")),
                    warranty = updated.get(media = Key("screen:backofficeMatters"), content = Key("warranty")),
                    cost = updated.get(media = Key("screen:backofficeMatters"), content = Key("cost")),
                    missingMembers = updated.get(media = Key("screen:backofficeMatters"), content = Key("missingMembers")),
                    missingBuyerSignatureWasa = updated.get(media = Key("screen:backofficeMatters"), content = Key("missingBuyerSignatureWasa")),
                    missingBuyerSignature = updated.get(media = Key("screen:backofficeMatters"), content = Key("missingBuyerSignature")),
                    missingSellerSignature = updated.get(media = Key("screen:backofficeMatters"), content = Key("missingSellerSignature")),
                    showApplication = updated.get(media = Key("screen:backofficeMatters"), content = Key("showApplication")),
                    hk = updated.get(media = Key("screen:backofficeMatters"), content = Key("hk")),
                    sek = updated.get(media = Key("screen:backofficeMatters"), content = Key("sek")),
                    km = updated.get(media = Key("screen:backofficeMatters"), content = Key("km")),
                    kg = updated.get(media = Key("screen:backofficeMatters"), content = Key("kg")),
                    govId = updated.get(media = Key("screen:backofficeMatters"), content = Key("govId")),
                    application = updated.get(media = Key("screen:backofficeMatters"), content = Key("application")),
                    interestRate = updated.get(media = Key("screen:backofficeMatters"), content = Key("interestRate")),
                    upperBound = updated.get(media = Key("screen:backofficeMatters"), content = Key("upperBound")),
                    groupStatus = updated.get(media = Key("screen:backofficeMatters"), content = Key("groupStatus")),
                    groupStatusToast = updated.get(media = Key("screen:backofficeMatters"), content = Key("groupStatusToast")),
                    percent = updated.get(media = Key("screen:backofficeMatters"), content = Key("percent")),
                    fkLeasingCompanyAgreement = updated.get(media = Key("screen:backofficeMatters"), content = Key("fkLeasingCompanyAgreement")),
                    fkLeasingPrivateAgreement = updated.get(media = Key("screen:backofficeMatters"), content = Key("fkLeasingPrivateAgreement")),
                    fkAgreement = updated.get(media = Key("screen:backofficeMatters"), content = Key("fkAgreement")),
                    companyCompany = updated.get(media = Key("screen:backofficeMatters"), content = Key("companyCompany")),
                    leasingCompanyCompany = updated.get(media = Key("screen:backofficeMatters"), content = Key("leasingCompanyCompany")),
                    leasingCompanyPrivate = updated.get(media = Key("screen:backofficeMatters"), content = Key("leasingCompanyPrivate")),
                    privateCompany = updated.get(media = Key("screen:backofficeMatters"), content = Key("privateCompany")),
                    privatePrivate = updated.get(media = Key("screen:backofficeMatters"), content = Key("privatePrivate")),
                    companyPrivate = updated.get(media = Key("screen:backofficeMatters"), content = Key("companyPrivate")),
                    company = updated.get(media = Key("screen:backofficeMatters"), content = Key("company")),
                    private = updated.get(media = Key("screen:backofficeMatters"), content = Key("private")),
                    leasing = updated.get(media = Key("screen:backofficeMatters"), content = Key("leasing")),
                    groupInactive = updated.get(media = Key("screen:backofficeMatters"), content = Key("groupInactive")),
                    tryGuarantee = updated.get(media = Key("screen:backofficeMatters"), content = Key("tryGuarantee")),
                    codeTitle = updated.get(media = Key("screen:backofficeMatters"), content = Key("codeTitle")),
                    showInviteModal = updated.get(media = Key("screen:backofficeMatters"), content = Key("showInviteModal")),
                    copyCode = updated.get(media = Key("screen:backofficeMatters"), content = Key("copyCode")),
                    code = "",
                    codeCopied = updated.get(media = Key("screen:backofficeMatters"), content = Key("codeCopied")),
                    itemEdit = updated.get(media = Key("screen:backofficeMatters"), content = Key("itemEdit")),
                    itemSave = updated.get(media = Key("screen:backofficeMatters"), content = Key("itemSave")),
                    itemCancel = updated.get(media = Key("screen:backofficeMatters"), content = Key("itemCancel")),
                    itemPayment = "",
                    deptPaymentBtn = updated.get(media = Key("screen:backofficeMatters"), content = Key("deptPaymentBtn")),
                    excessPaymentBtn = updated.get(media = Key("screen:backofficeMatters"), content = Key("excessPaymentBtn")),

                    name = updated.get(media = Key("screen:backofficeMatters"), content = Key("name")),
                    paymentTo = updated.get(media = Key("screen:backofficeMatters"), content = Key("paymentTo")),
                    message = updated.get(media = Key("screen:backofficeMatters"), content = Key("message")),
                    sure = updated.get(media = Key("screen:backofficeMatters"), content = Key("sure")),
                    excessPayment = updated.get(media = Key("screen:backofficeMatters"), content = Key("excessPayment")),
                    residualDeptPayment = updated.get(media = Key("screen:backofficeMatters"), content = Key("residualDeptPayment")),
                    payoffExcess = updated.get(media = Key("screen:backofficeMatters"), content = Key("payoffExcess")),
                    payoffResidualDept = updated.get(media = Key("screen:backofficeMatters"), content = Key("payoffResidualDept")),
                    commitmentDone = updated.get(media = Key("screen:backofficeMatters"), content = Key("commitmentDone")),
                    transactionFailed = updated.get(media = Key("screen:backofficeMatters"), content = Key("transactionFailed")),
                    paymentSignatureMissing = updated.get(media = Key("screen:backofficeMatters"), content = Key("paymentSignatureMissing")),
                    paymentPending = updated.get(media = Key("screen:backofficeMatters"), content = Key("paymentPending")),
                    paymentCancelled = updated.get(media = Key("screen:backofficeMatters"), content = Key("paymentCancelled")),
                    paymentCompleted = updated.get(media = Key("screen:backofficeMatters"), content = Key("paymentCompleted")),
                    fieldRequired = updated.get(media = Key("screen:backofficeMatters"), content = Key("fieldRequired")),
                )
                val excludeFields = listOf("HEADING", "LABEL_RESIDUAL_DEBT", "LABEL_EXCESS")
                val fieldValues = full.submissionSeller?.form?.fields?.filter { !excludeFields.contains(it.key.rawValue) }?.map { field ->
                    val entry = full.submissionSeller?.entries?.find { it.fieldKey == field.key }
                    FieldValues(fieldKey = field.key, text = entry?.text ?: "", modified = false)
                } ?: emptyList()


                val price = if (result[3].trim() == "-") result[3].trim() else listOf(formatNumber(result[3].trim().substringBefore(" ").toDouble()), texts.sek).joinToString(" ")
                val options = listOf(DesignSystem.Option.item(title = texts.groupStarted, value = "None"), DesignSystem.Option.item(title = texts.groupActive, value = "Active"), DesignSystem.Option.item(title = texts.groupArchived, value = "Archived"), DesignSystem.Option.item(title = texts.groupInactive, value = "Inactive"))
                val groupStatus = when (full.group.status) {
                    is Group.Status.Active -> DesignSystem.Option.item(title = texts.groupActive, value = "Active")
                    is Group.Status.Archived -> DesignSystem.Option.item(title = texts.groupArchived, value = "Archived")
                    is Group.Status.Inactive -> DesignSystem.Option.item(title = texts.groupInactive, value = "Inactive")
                    else -> DesignSystem.Option.item(title = texts.groupStarted, value = "None")
                }
                val state = viewModel.state.copy(obj = full, groupStatusOptions = options, selectedGroupStatus = groupStatus, fieldValues = fieldValues, paymentCheck = startPaymentCheck(full))
                val updatedText = texts.copy(price = price)

                return callReady(viewModel, updatedText, state, actions = actions)
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    private fun agreementIsSigned(signatures: List<Signature>, agreementId: Identifier<Agreement>?): String {
        val signature = signatures.mapNotNull { signature ->
            when (signature.signed) {
                is Signature.Signed.Agreement -> {
                    if ((signature.signed as Signature.Signed.Agreement).agreementId == agreementId) "${signature?.firstName ?: "***"} ${signature?.lastName ?: "***"}" else null// Don`t remove the safe cal on signature. The name will be null when person is anonymized.
                }

                else -> null
            }
        }
        return signature.firstOrNull() ?: "-"
    }


    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        return load(scene, viewModel.state.obj.id)
    }

    suspend fun toast(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene

        return callReady(viewModel, viewModel.texts, viewModel.state)
    }

    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }

    private fun startPaymentCheck(obj: Object): Boolean {
        val excess = when (obj.paymentExcess?.status) {
            Payment.Status.Pending, Payment.Status.Processing, Payment.Status.Authenticating -> true
            Payment.Status.Completed, Payment.Status.Failed, Payment.Status.Cancelled -> false
            else -> false
        }

        val residualDept = when (obj.paymentResidualDept?.status) {
            Payment.Status.Pending, Payment.Status.Processing, Payment.Status.Authenticating -> true
            Payment.Status.Completed, Payment.Status.Failed, Payment.Status.Cancelled -> false
            else -> false
        }
        return excess || residualDept

    }

    suspend fun checkPayment(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene

        store.listPayments(viewModel.state.obj)
            .flatMap { (actions, payments) ->
                val excessPayment = payments.firstOrNull { it.reference == "EXCESS" }
                val residualDeptPayment = payments.firstOrNull { it.reference == "RESIDUAL_DEBT" }

                val excess = when (excessPayment?.status) {
                    Payment.Status.Pending, Payment.Status.Processing, Payment.Status.Authenticating -> true
                    Payment.Status.Completed, Payment.Status.Failed, Payment.Status.Cancelled -> false
                    else -> false
                }

                val residualDept = when (residualDeptPayment?.status) {
                    Payment.Status.Pending, Payment.Status.Processing, Payment.Status.Authenticating -> true
                    Payment.Status.Completed, Payment.Status.Failed, Payment.Status.Cancelled -> false
                    else -> false
                }

                if ((excessPayment != null || residualDeptPayment != null) && (excess || residualDept))
                    store.reduce(actions).adminRefreshObject(viewModel.state.obj.id).accumulate(actions)
                        .map { tupleOf(it.first, it.second) }
                else
                    successfulOf(true)
                        .map { tupleOf(actions, viewModel.state.obj) }
            }
            .map { (actions, obj) ->

                return callReady(viewModel, viewModel.texts, viewModel.state.copy(obj = obj, paymentCheck = startPaymentCheck(obj)), actions)
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }


    suspend fun cancel(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene

        val excludeFields = listOf("HEADING", "LABEL_RESIDUAL_DEBT", "LABEL_EXCESS")
        val fieldValues = viewModel.state.obj.submissionSeller?.form?.fields?.filter { !excludeFields.contains(it.key.rawValue) }?.map { field ->
            val entry = viewModel.state.obj.submissionSeller?.entries?.find { it.fieldKey == field.key }
            FieldValues(fieldKey = field.key, text = entry?.text ?: "", modified = false)
        } ?: emptyList()
        val state = viewModel.state.copy(fieldsEditable = EDITABLE.NONE, fieldValues = fieldValues, fieldsStatus = emptyList())
        return callReady(viewModel, viewModel.texts, state)
    }

    suspend fun editable(scene: Scene.Input<ViewModel>, edit: String) {
        val (_, viewModel) = scene

        val state = viewModel.state.copy(
            fieldsEditable = when (edit) {
                EDITABLE.EXCESS.name -> EDITABLE.EXCESS
                EDITABLE.RESIDUAL_DEBT.name -> EDITABLE.RESIDUAL_DEBT
                else -> EDITABLE.NONE
            }
        )
        return callReady(viewModel, viewModel.texts, state)
    }


    suspend fun update(scene: Scene.Input<ViewModel>, id: String, value: String) {
        val (_, viewModel) = scene
        val isNewValue = if (viewModel.state.obj.submissionSeller?.entries?.firstOrNull { it.fieldKey.rawValue == id } == null) true else viewModel.state.obj.submissionSeller?.entries?.firstOrNull { it.fieldKey.rawValue == id }?.text != value
        val newValue = when (id) {
            "EXCESS", "RESIDUAL_DEBT", "CLEARING_NUMBER", "CLEARING_NUMBER_RESIDUAL_DEBT", "BANK_ACCOUNT_NUMBER", "BANK_ACCOUNT_NUMBER_RESIDUAL_DEBT" -> value.replace("\\s".toRegex(), "")
            else -> value
        }
        val state = viewModel.state.copy(
            fieldValues = viewModel.state.fieldValues.map { field -> if (field.fieldKey.rawValue == id) field.copy(text = newValue, modified = isNewValue) else field },
        )
        return callReady(viewModel, viewModel.texts, state)
    }

    suspend fun submit(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()

        viewModel.state.obj.submissionSeller?.form?.fields?.map { field ->
            val value = viewModel.state.fieldValues.firstOrNull { it.fieldKey == field.key }?.text
            val modified = viewModel.state.fieldValues.filter { it.fieldKey == field.key && it.modified }
            val header = header(field.key)
            if (field.required && value.isNullOrEmpty() && modified.isNotEmpty())
                status.add(header to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
            else
                status.add(header to DesignSystem.Status.Valid)
        }

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return callReady(viewModel, viewModel.texts, viewModel.state.copy(fieldsStatus = status))

        val edit = Submission.Edit(entries = modifiedOf(viewModel.state.fieldValues.filter { it.modified }.map { Submission.Entry(fieldKey = it.fieldKey, text = if (it.fieldKey.rawValue == "EXCESS" && it.text.isEmpty()) "0" else if (it.fieldKey.rawValue == "RESIDUAL_DEBT" && it.text.isEmpty()) "0" else it.text) }))
        store.adminEditSubmission(viewModel.state.obj.submissionSeller?.index?.id!!, edit)
            .flatMap { (actions, _) ->
                store.reduce(actions).adminRefreshObject(viewModel.state.obj.id).accumulate(actions)
            }
            .map { (actions, obj) ->
                val excludeFields = listOf("HEADING", "LABEL_RESIDUAL_DEBT", "LABEL_EXCESS")
                val fieldValues = obj.submissionSeller?.form?.fields?.filter { !excludeFields.contains(it.key.rawValue) }?.map { field ->
                    val entry = obj.submissionSeller?.entries?.find { it.fieldKey == field.key }
                    val value = if (field.key.rawValue == "EXCESS" && entry?.text?.isEmpty() == true) "0" else if (field.key.rawValue == "RESIDUAL_DEBT" && entry?.text?.isEmpty() == true) "0" else entry?.text ?: ""
                    FieldValues(fieldKey = field.key, text = value, modified = false)
                } ?: emptyList()

                val state = viewModel.state.copy(obj = obj, fieldValues = fieldValues, fieldsEditable = EDITABLE.NONE, paymentCheck = startPaymentCheck(obj), fieldsStatus = emptyList())

                return callReady(viewModel, viewModel.texts, state, actions = actions)
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun closeModal(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        val state = viewModel.state.copy(showApplicationModal = false, showPaymentModal = EDITABLE.NONE)

        return callReady(viewModel, viewModel.texts, state)
    }

    suspend fun openModal(scene: Scene.Input<ViewModel>, edit: String) {
        val (_, viewModel) = scene
        val state = viewModel.state.copy(
            showApplicationModal = edit.isBlank(), showPaymentModal = when (edit) {
                EDITABLE.EXCESS.name -> EDITABLE.EXCESS
                EDITABLE.RESIDUAL_DEBT.name -> EDITABLE.RESIDUAL_DEBT
                else -> EDITABLE.NONE
            }
        )

        return callReady(viewModel, viewModel.texts, state)
    }

    suspend fun invite(scene: Scene.Input<ViewModel>, open: Boolean = false) {
        val (store, viewModel) = scene
        if (!open) {
            val state = viewModel.state.copy(showInviteModal = !viewModel.state.showInviteModal)

            return callReady(viewModel, viewModel.texts, state)
        }

        val create = Invite.Create(group = viewModel.state.obj.group.key)
        store.adminCreateInvite(create)
            .map { (actions, invite) ->
                val state = viewModel.state.copy(showInviteModal = !viewModel.state.showInviteModal, paymentCheck = startPaymentCheck(viewModel.state.obj))

                return callReady(viewModel, viewModel.texts.copy(code = invite.key.rawValue), state, actions)
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }


    suspend fun copy(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene

        return callReady(viewModel, viewModel.texts, viewModel.state, showCopyToast = true)
    }

    suspend fun setValue(scene: Scene.Input<ViewModel>, option: DesignSystem.Option) {
        val (_, viewModel) = scene
        if (option is DesignSystem.Option.Item)
            return callReady(viewModel, viewModel.texts, viewModel.state.copy(selectedGroupStatus = option))
    }

    suspend fun groupStatus(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene

        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        val state = viewModel.state

        if (status.overallStatus() !is DesignSystem.Status.Valid) {
            val groupStatus = when (state.obj.group.status) {
                is Group.Status.Active -> DesignSystem.Option.item(title = viewModel.texts.statusFormActive, value = "Active")
                is Group.Status.Archived -> DesignSystem.Option.item(title = viewModel.texts.statusAgreementWasa, value = "Archived")
                is Group.Status.Inactive -> DesignSystem.Option.item(title = viewModel.texts.groupInactive, value = "Inactive")
                else -> DesignSystem.Option.item(title = viewModel.texts.statusFormStarted, value = "None")
            }
            val newState = state.copy(selectedGroupStatus = groupStatus)
            return callReady(viewModel, viewModel.texts, newState, status = status)
        }

        val groupStatus = when (state.selectedGroupStatus?.value) {
            "Active" -> Group.Status.Active
            "Archived" -> Group.Status.Archived
            "Inactive" -> Group.Status.Inactive
            else -> Group.Status.None
        }
        store.adminEditGroup(state.obj.group.id, Group.Edit(status = modifiedOf(groupStatus)))
            .flatMap { (action, _) ->
                store.reduce(action).adminRefreshObject(state.obj.id).accumulate(action)
                    .map { tupleOf(it.first, it.second) }
            }
            .flatMap { (action, obj) ->

                val submissionStatus = when (obj.group.status) {
                    Group.Status.Archived -> Submission.State.Archived
                    Group.Status.Inactive -> Submission.State.Rejected
                    else -> state.obj.submissionBuyer?.state!!
                }
                val date = Date().dateTime
                val edit = Submission.Edit(
                    entries = modifiedOf(listOf(Submission.Entry(fieldKey = Key("SUBMITTED"), text = dateAt(year = date.year, month = date.monthNumber, day = date.dayOfMonth, hour = 23, minute = 59).toISOString() ?: ""))),
                    state = modifiedOf(submissionStatus)
                )
                store.adminEditSubmission(state.obj.submissionBuyer?.index?.id!!, edit).accumulate(action)
                    .map { tupleOf(it.first, obj) }
            }
            .map { (action, obj) ->
                val newState = state.copy(obj = obj, paymentCheck = startPaymentCheck(obj))

                return callReady(viewModel, viewModel.texts, newState, actions = action, status = status, showGroupStatusToast = true)
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    private fun paymentStatus(texts: Texts, payment: Payment?, text: String? = null): String? {
        return when (val status = payment?.provider?.status) {
            is Provider.Status.Failed -> {
                val reason = if ((payment.provider.status as? Provider.Status.Failed)?.reason?.isNotEmpty() == true) (payment.provider.status as? Provider.Status.Failed)?.reason?.replace("\"", " ")?.replace(Regex("[{},]"), " ") ?: "-" else "-"

                listOf(texts.transactionFailed, reason).joinToString(" ")
            }

            is Provider.Status.SignatureRequired -> texts.paymentSignatureMissing
            is Provider.Status.TrustlyPayout -> listOf(text ?: "", status.orderId).joinToString(" ")
            else -> null
        }
    }

    private fun buildItems(texts: Texts, state: State, submission: Submission? = null, isDept: Boolean? = false, isExcess: Boolean? = false, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()): List<FormItem.ViewModel> {
        val excess = listOf("BANK", "CLEARING_NUMBER", "BANK_ACCOUNT_NUMBER", "EXCESS", "LABEL_EXCESS", "REPAY_EXCESS")
        val dept = listOf("RESIDUAL_DEBT", "BANK_RESIDUAL_DEBT", "BANK_ACCOUNT_NUMBER_RESIDUAL_DEBT", "LABEL_RESIDUAL_DEBT", "REPAY_RESIDUAL_DEBT", "CLEARING_NUMBER_RESIDUAL_DEBT")
        val sellerAndBuyer = listOf("COMPANY_NAME", "GOV_CODE", "FIRST_NAME", "LAST_NAME", "STREET", "ZIPCODE", "CITY", "EMAIL", "PHONE", "GOV_ID")
        val form = if (isDept == true) submission?.form?.fields?.filter { dept.contains(it.key.rawValue) } else if (isExcess == true) submission?.form?.fields?.filter { excess.contains(it.key.rawValue) } else submission?.form?.fields?.filter { sellerAndBuyer.contains(it.key.rawValue) }

        val keyPriorities = mapOf(
            "WARRANTY" to 1,
            "TRY_GUARANTEE" to 2,
            "DISCOUNT" to 3,
        )
        return form?.sortedWith(
            compareBy({ it.order }, { keyPriorities[it.key.rawValue] ?: Int.MAX_VALUE })
        )?.sortedBy { keyPriorities[it.key.rawValue] }?.map { field ->
            val inputType = when (field.key.rawValue) {
                "RESIDUAL_DEBT", "EXCESS" -> DesignSystem.TextInputType.PURE_NUMBER
                "CLEARING_NUMBER", "BANK_ACCOUNT_NUMBER", "BANK_ACCOUNT_NUMBER_RESIDUAL_DEBT", "CLEARING_NUMBER_RESIDUAL_DEBT" -> DesignSystem.TextInputType.NUMBER
                else -> DesignSystem.TextInputType.TEXT
            }

            val value = if ((submission?.form?.key?.rawValue == FK.privateSell || submission?.form?.key?.rawValue == FK.companySell) && state.fieldValues.isNotEmpty())
                state.fieldValues.firstOrNull { it.fieldKey == field.key }?.text ?: submission.entries.firstOrNull { it.fieldKey == field.key }?.text
            else
                submission?.entries?.firstOrNull { it.fieldKey == field.key }?.text

            when (field.key.rawValue) {
                "WARRANTY" -> {
                    val title = listOf(texts.warranty, value ?: "-")
                    FormItem.label(text = title.joinToString(" "), key = field.key, visible = true)
                }

                "TRY_GUARANTEE" -> {
                    val title = listOf(texts.tryGuarantee, ":", value ?: "-")
                    FormItem.label(text = title.joinToString(" "), key = field.key, visible = value != "-")
                }

                "DISCOUNT" -> {
                    FormItem.label(text = field.label + ": " + value, key = field.key, visible = field.answer == value?.trim())
                }

                "LABEL_RESIDUAL_DEBT", "LABEL_EXCESS" -> FormItem.label(title = field.fLabel, text = field.placeholder, key = field.key, info = field.hint, visible = !field.hidden)

                "REPAY_RESIDUAL_DEBT", "REPAY_EXCESS" -> {
                    val groupIsArchived = state.obj.group.status == Group.Status.Archived
                    val canRepay = when (field.key.rawValue) {
                        "REPAY_RESIDUAL_DEBT" -> (getValue(submission?.entries, "RESIDUAL_DEBT")?.replace("\\s".toRegex(), "")?.toDouble() ?: 0.0) > 0.0
                        else -> (getValue(submission?.entries, "EXCESS")?.replace("\\s".toRegex(), "")?.substringBefore(":")?.toDouble() ?: 0.0) > 0.0
                    }

                    val payment = if (isDept == true) state.obj.paymentResidualDept else if (isExcess == true) state.obj.paymentExcess else null
                    val paymentStatus = when (payment?.status) {
                        is Payment.Status.Failed -> paymentStatus(texts, payment)
                        is Payment.Status.Pending -> paymentStatus(texts, payment, texts.paymentPending)
                        is Payment.Status.Cancelled -> paymentStatus(texts, payment, texts.paymentCancelled)
                        is Payment.Status.Completed -> paymentStatus(texts, payment, texts.paymentCompleted)
                        else -> null
                    }

                    FormItem.editableItem(
                        texts = texts.copy(itemPayment = if (isDept == true) texts.deptPaymentBtn else texts.excessPaymentBtn),
                        key = if (isDept == true) EDITABLE.RESIDUAL_DEBT.name else if (isExcess == true) EDITABLE.EXCESS.name else EDITABLE.NONE.name,
                        visible = isDept == true || isExcess == true,
                        editable = if (isDept == true && state.fieldsEditable == EDITABLE.RESIDUAL_DEBT) true else if (isExcess == true && state.fieldsEditable == EDITABLE.EXCESS) true else false,
                        canRepay = groupIsArchived && canRepay && state.fieldsEditable == EDITABLE.NONE,
                        objectId = state.obj.id,
                        paymentType = if (isDept == true) EDITABLE.RESIDUAL_DEBT.name else if (isExcess == true) EDITABLE.EXCESS.name else EDITABLE.NONE.name,
                        status = paymentStatus,
                        showButtons = when (payment?.status) {
                            Payment.Status.Completed, Payment.Status.Pending, Payment.Status.Processing, Payment.Status.Authenticating ->
                                when (payment.provider?.status) {
                                    is Provider.Status.SignatureRequired -> true
                                    else -> false
                                }

                            Payment.Status.Failed, Payment.Status.Cancelled -> true
                            else -> true
                        }
                    )
                }

                "COMPANY_NAME", "GOV_CODE", "FIRST_NAME", "LAST_NAME", "STREET", "ZIPCODE", "CITY", "EMAIL", "PHONE", "GOV_ID", "BANK", "CLEARING_NUMBER", "BANK_ACCOUNT_NUMBER", "RESIDUAL_DEBT", "EXCESS", "BANK_RESIDUAL_DEBT", "BANK_ACCOUNT_NUMBER_RESIDUAL_DEBT", "CLEARING_NUMBER_RESIDUAL_DEBT" -> {
                    val label = when (field.key.rawValue) {
                        "EXCESS" -> submission?.form?.fields?.firstOrNull { it.key.rawValue == "EXCESS_LABEL" }?.label ?: ""
                        "GOV_ID" -> texts.govId
                        else -> field.fLabel
                    }

                    val disabled = when (state.fieldsEditable) {
                        EDITABLE.EXCESS -> excess.firstOrNull { it == field.key.rawValue } == null
                        EDITABLE.RESIDUAL_DEBT -> dept.firstOrNull { it == field.key.rawValue } == null
                        EDITABLE.NONE -> true
                    }
                    val formatedValue = when (field.key.rawValue) {
                        "RESIDUAL_DEBT" -> if (value.isNullOrEmpty() || value.trim() == "-") "" else if (!disabled) formatNumber(value.replace("\\s".toRegex(), "").toDouble()).replace("\\s".toRegex(), "") else formatNumber(value.replace("\\s".toRegex(), "").toDouble())
                        "EXCESS" -> if (value.isNullOrEmpty() || value.trim() == "-") "" else if (!disabled) formatNumber(value.replace("\\s".toRegex(), "").substringBefore(":").toDouble()).replace("\\s".toRegex(), "") else formatNumber(value.replace("\\s".toRegex(), "").substringBefore(":").toDouble())
                        else -> value
                    }
                    FormItem.textInput(label = label, disabled = disabled, value = formatedValue, key = field.key, visible = !field.hidden, status = status, inputType = inputType)
                }

                else -> FormItem.none()
            }
        } ?: emptyList()
    }
}