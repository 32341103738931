package screens

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import support.FormItem
import support.fPlaceholder
import services.*
import support.*
import techla.agreement.Agreement
import techla.base.*
import techla.base.onNotSuccess
import techla.form.Field
import techla.form.Form
import techla.form.Submission
import techla.guard.Group
import techla.guard.Invite
import techla.guard.Profile

object SellScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    object Header {
        val code = DesignSystem.Header("code")
        val sellType = DesignSystem.Header("sellType")
    }

    data class Texts(
        val next: String,
        val done: String,
        val back: String,
        val fieldRequired: String,
        val emailFormat: String,
        val agreementTitle: String,
        val redeemCode: String,
        val inviteTitle: String,
        val inviteIntro: String,
        val codeTitle: String,
        val cancel: String,
        val openBankId: String,
        val contractTitle: String,
        val contractIntro: String,
        val finishedTitle: String,
        val finishedBody1: String,
        val finishedBody2: String,
        val protocolEmail: String,
        val regNumber: String,
        val make: String,
        val model: String,
        val price: String,
        val postalCodeIllegalCharacters: String,
        val postalCodeWrongLength: String,
        val phoneIllegalCharacters: String,
        val alreadyAMember: String,
        val sellTypeLabel: String,
        val private: String,
        val company: String,
        val companyFinishedTitle: String,
        val companyFinishedBody: String,
        val sumTitle: String,
        val agreementInfo: String,
        val sek: String,
        val privateFinishedBody: String,
        val residualDeptHighSum: String,
        override val failureTitle: String,
        override val failureReason: String
    ) : FailureTexts {
        companion object
    }

    data class FieldValues(
        val fieldKey: Key<Field>,
        val text: String,
        val modified: Boolean = false
    )

    data class State(
        val form: Form? = null,
        val formId: Identifier<Submission>? = null,
        val entries: List<Submission.Entry> = emptyList(),
        val fieldValues: List<FieldValues> = emptyList(),
        val status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList(),
        val obj: Object = Object.None,
        val code: String? = null,
        val startCheck: Boolean = false,
        val sellType: DesignSystem.Option? = null,
        val sellTypeOptions: List<DesignSystem.Option> = emptyList(),
        val buyType: CustomerType = CustomerType.None,
        val price: Double = 0.0,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class RedeemCode(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val next: DesignSystem.Button,
            val redeemCode: DesignSystem.Button,
            val redeemTitle: DesignSystem.Text,
            val redeemIntro: DesignSystem.Text,
            val codeTitle: DesignSystem.Text,
            val code: DesignSystem.TextInput,
            val agreementTittle: DesignSystem.Text,
            val info: String? = null,
            val regNumber: DesignSystem.Text,
            val sum: DesignSystem.Text,
            val make: DesignSystem.Text,
            val sellTypeLabel: DesignSystem.Text,
            val sellType: DesignSystem.SelectInput,
            val sumTitle: DesignSystem.Text,
        ) : ViewModel(texts, state, navigation)

        data class ContactInfo(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val next: DesignSystem.Button,
            val back: DesignSystem.Button,
            val logout: DesignSystem.Button,
            val items: List<FormItem.ViewModel>
        ) : ViewModel(texts, state, navigation)

        data class Agreement(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val further: DesignSystem.Button,
            val contractTitle: DesignSystem.Text,
            val contractIntro: DesignSystem.Text,
            val contract: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation)

        data class CompanyFinished(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val next: DesignSystem.Button,
            val finishTitle: DesignSystem.Text,
            val finishIntro: DesignSystem.Text,
            val contract: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation)

        data class Finished(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val finishedTitle: DesignSystem.Text,
            val finishedBody1: DesignSystem.Text,
            val next: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation = navigation)

        fun redeemCode(texts: Texts, state: State, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()) =
            RedeemCode(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.PREVIOUS, location = Location.Matters),
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON, visible = state.obj != Object.None),
                redeemCode = DesignSystem.Button(text = texts.redeemCode, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE),
                redeemTitle = DesignSystem.Text(text = texts.inviteTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                redeemIntro = DesignSystem.Text(text = texts.inviteIntro, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
                codeTitle = DesignSystem.Text(text = texts.codeTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                code = DesignSystem.TextInput(header = Header.code, label = texts.codeTitle, value = state.code, status = status.statusOf(Header.code), uppercase = true),
                agreementTittle = DesignSystem.Text(text = texts.agreementTitle, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, visible = state.obj != Object.None),
                info = if (state.obj != Object.None) texts.agreementInfo else null,
                regNumber = DesignSystem.Text(text = texts.regNumber, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER, visible = state.obj != Object.None),
                sum = DesignSystem.Text(text = texts.price, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.PRIMARY, visible = state.obj != Object.None),
                make = DesignSystem.Text(text = "${texts.make}, ${texts.model}", size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.PRIMARY, visible = state.obj != Object.None),
                sellTypeLabel = DesignSystem.Text(text = texts.sellTypeLabel, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, visible = if (state.buyType == CustomerType.Leasing) false else state.obj != Object.None),
                sellType = DesignSystem.SelectInput(header = Header.sellType, options = state.sellTypeOptions, selected = state.sellType, status = status.statusOf(BackOfficeMattersScreen.Header.status), visible = if (state.buyType == CustomerType.Leasing) false else state.obj != Object.None),
                sumTitle = DesignSystem.Text(text = texts.sumTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER, visible = if (state.buyType == CustomerType.Leasing) false else state.obj != Object.None),
            )

        fun contactInfo(texts: Texts, state: State, formItems: List<FormItem.ViewModel>) =
            ContactInfo(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.PREVIOUS, location = Location.Matters),
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.SUBMIT, visible = true),
                back = DesignSystem.Button(text = texts.back, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.TEXT, visible = true),
                logout = DesignSystem.Button(text = texts.back, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.TEXT, visible = true),
                items = formItems,
            )

        fun agreement(texts: Texts, state: State) =
            Agreement(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.PREVIOUS, location = Location.Matters),
                further = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON, disabled = state.obj.sellerAgreement?.status !is techla.agreement.Agreement.Status.Approved),
                contractTitle = DesignSystem.Text(text = texts.contractTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                contractIntro = DesignSystem.Text(text = texts.contractIntro, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
                contract = DesignSystem.ImageView(image = DesignSystem.Image.CONTRACT),
            )

        fun companyFinished(texts: Texts, state: State) =
            CompanyFinished(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.PREVIOUS, location = Location.Matters),
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON),
                finishTitle = DesignSystem.Text(text = texts.companyFinishedTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                finishIntro = DesignSystem.Text(text = if (state.sellType?.value == CustomerType.Private.rawValue) texts.privateFinishedBody else texts.companyFinishedBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.LEFT, isMarkdown = true),
                contract = DesignSystem.ImageView(image = DesignSystem.Image.CONTRACT),
            )

        fun finished(texts: Texts, state: State) =
            Finished(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, action = DesignSystem.Action.PREVIOUS, location = Location.Matters),
                next = DesignSystem.Button(text = texts.next, type = DesignSystem.Button.Type.BUTTON),
                finishedTitle = DesignSystem.Text(text = texts.companyFinishedTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
                finishedBody1 = DesignSystem.Text(text = if (state.sellType?.value == CustomerType.Private.rawValue) texts.privateFinishedBody else texts.companyFinishedBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.LEFT, isMarkdown = true),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun Scene.Input<ViewModel>.invalid() =
            sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asRedeemCode get() = this as? RedeemCode
        val asContactInfo get() = this as? ContactInfo
        val asAgreement get() = this as? Agreement
        val asCompanyFinished get() = this as? CompanyFinished
        val asFinished get() = this as? Finished
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))


    private suspend fun loadText(store: Store) =
        store.findMedias()
            .map { (actions, _) ->
                val updated = store.reduce(actions)
                val texts = Texts(
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    next = updated.get(media = Key("screen:sell"), content = Key("next")),
                    done = updated.get(media = Key("screen:sell"), content = Key("done")),
                    cancel = updated.get(media = Key("screen:sell"), content = Key("cancel")),
                    openBankId = updated.get(media = Key("screen:sell"), content = Key("openBankId")),
                    back = updated.get(media = Key("screen:sell"), content = Key("back")),
                    fieldRequired = updated.get(media = Key("screen:sell"), content = Key("fieldRequired")),
                    emailFormat = updated.get(media = Key("screen:sell"), content = Key("emailFormat")),
                    inviteTitle = updated.get(media = Key("screen:sell"), content = Key("inviteTitle")),
                    inviteIntro = updated.get(media = Key("screen:sell"), content = Key("inviteIntro")),
                    codeTitle = updated.get(media = Key("screen:sell"), content = Key("codeTitle")),
                    agreementTitle = updated.get(media = Key("screen:sell"), content = Key("agreementTitle")),
                    redeemCode = updated.get(media = Key("screen:sell"), content = Key("redeemCode")),
                    contractTitle = updated.get(media = Key("screen:sell"), content = Key("contractTitle")),
                    contractIntro = updated.get(media = Key("screen:sell"), content = Key("contractIntro")),
                    finishedTitle = updated.get(media = Key("screen:sell"), content = Key("finishedTitle")),
                    finishedBody1 = updated.get(media = Key("screen:sell"), content = Key("finishedBody1")),
                    finishedBody2 = updated.get(media = Key("screen:sell"), content = Key("finishedBody2")),
                    protocolEmail = updated.get(media = Key("screen:sell"), content = Key("protocolEmail")),
                    regNumber = "",
                    postalCodeIllegalCharacters = updated.get(media = Key("screen:sell"), content = Key("postalCodeIllegalCharacters")),
                    postalCodeWrongLength = updated.get(media = Key("screen:sell"), content = Key("postalCodeWrongLength")),
                    phoneIllegalCharacters = updated.get(media = Key("screen:sell"), content = Key("phoneIllegalCharacters")),
                    alreadyAMember = updated.get(media = Key("screen:sell"), content = Key("alreadyAMember")),
                    sellTypeLabel = updated.get(media = Key("screen:sell"), content = Key("sellTypeLabel")),
                    private = updated.get(media = Key("screen:sell"), content = Key("private")),
                    company = updated.get(media = Key("screen:sell"), content = Key("company")),
                    companyFinishedTitle = updated.get(media = Key("screen:sell"), content = Key("companyFinishedTitle")),
                    companyFinishedBody = updated.get(media = Key("screen:sell"), content = Key("companyFinishedBody")),
                    make = "",
                    price = "",
                    sumTitle = updated.get(media = Key("screen:sell"), content = Key("sumTitle")),
                    model = "",
                    agreementInfo = updated.get(media = Key("screen:sell"), content = Key("agreementInfo")),
                    sek = updated.get(media = Key("screen:sell"), content = Key("sek")),
                    privateFinishedBody = updated.get(media = Key("screen:sell"), content = Key("privateFinishedBody")),
                    residualDeptHighSum = updated.get(media = Key("screen:sell"), content = Key("residualDeptHighSum")),
                )
                tupleOf(actions, texts)
            }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: String? = null) {
        val (store, viewModel) = scene
        val id = Identifier<Object>(objectId ?: "")
        updates.emit(sceneOf(viewModel.loading()))

        if (objectId != null) {
            loadText(store)
                .flatMap { (actions, texts) ->
                    store.reduce(actions).refreshMinimalObject(id).accumulate(actions)
                        .map { tupleOf(it.first, texts, it.second) }
                }
                .flatMap { (actions, texts, minimal) ->
                    store.reduce(actions).refreshObject(id, listOf(Key(FK.privateSell), Key(FK.companySell))).accumulate(actions)
                        .map { tupleOf(it.first, texts, it.second) }
                }
                .map { (actions, texts, full) ->

                    if (full.submissionSeller?.state is Submission.State.Closed) {
                        return updates.emit(sceneOf<ViewModel>(viewModel.finished(texts = texts.copy(regNumber = full.group.name), state = viewModel.state.copy(obj = full)), actions))
                    }

                    if (full.sellerAgreement?.status is Agreement.Status.Approved) {
                        return updates.emit(sceneOf<ViewModel>(viewModel.finished(texts = texts.copy(regNumber = full.group.name), state = viewModel.state.copy(obj = full)), actions))
                    }
                    val sellInfo = full.group.name.split("{REPLACE}")
                    val result = (sellInfo + List(4 - sellInfo.size) { "-" }).take(4)
                    val price = if (result[3].trim() == "-") result[3].trim() else listOf(formatNumber(result[3].trim().substringBefore(" ").toDouble()), texts.sek).joinToString(" ")
                    val buyType = if (full.group.visualization.buyType == CustomerType.Leasing.rawValue) CustomerType.Leasing else CustomerType.None

                    val state = viewModel.state.copy(
                        obj = full,
                        buyType = buyType,
                        sellTypeOptions = sellTypeOptions(texts),
                        sellType = if (buyType is CustomerType.Leasing) sellTypeOptions(texts).lastOrNull() else sellTypeOptions(texts).firstOrNull { full.submissionSeller?.form?.key?.rawValue == CustomerType.getFkKey(CustomerType.fromRawValue(it.value), false) },
                        price = result[3].trim().substringBefore(" ").toDouble()
                    )
                    updates.emit(sceneOf<ViewModel>(viewModel.redeemCode(texts = texts.copy(regNumber = result[0].trim(), make = result[1].trim(), price = price, model = result[2]), state = state), actions))
                }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
        } else {
            loadText(store)
                .map { (actions, texts) ->
                    val state = viewModel.state.copy(
                        obj = store.objects?.firstOrNull { it.id == id } ?: Object.None,
                        sellTypeOptions = sellTypeOptions(texts),
                        sellType = sellTypeOptions(texts).firstOrNull()
                    )
                    val reg = if (state.obj != Object.None) state.obj.group.name else ""
                    updates.emit(sceneOf<ViewModel>(viewModel.redeemCode(texts = texts.copy(regNumber = reg), state = state), actions))
                }
        }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    private fun sellTypeOptions(texts: Texts) =
        listOf(DesignSystem.Option.item(title = texts.private, value = CustomerType.Private.rawValue), DesignSystem.Option.item(title = texts.company, value = CustomerType.Company.rawValue))

    suspend fun setValue(scene: Scene.Input<ViewModel>, option: DesignSystem.Option) {
        val (store, viewModel) = scene

        val sellForm = when (option.value) {
            CustomerType.Private.rawValue -> FK.privateSell
            CustomerType.Company.rawValue -> FK.companySell
            else -> ""
        }

        store.refreshObject(viewModel.state.obj.id, listOf(Key(sellForm)))
            .map { (actions, obj) ->
                updates.emit(sceneOf<ViewModel>(viewModel.redeemCode(texts = viewModel.texts, state = viewModel.state.copy(obj = obj, sellType = option)), actions))
            }
    }

    suspend fun redeemCode(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene
        var state = viewModel.state
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()

        Validator.text(
            text = state.code,
            isEmpty = { status.add(Header.code to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired)) },
            formatted = { state = state.copy(code = it) },
            passed = { status.add(Header.code to DesignSystem.Status.Valid) },
        )
        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return updates.emit(sceneOf<ViewModel>(viewModel.redeemCode(texts = viewModel.texts, state = viewModel.state, status = status)))

        val accept = Invite.Accept(invite = Key(viewModel.state.code?.uppercase() ?: ""))

        store.acceptInvite(accept = accept)
            .flatMap { (actions) ->
                store.reduce(actions).refreshObjects().map { objects ->
                    val previousObjects = store.objects?.map { it.id } ?: emptyList()
                    val newObject = objects.second.singleOrNull { !previousObjects.contains(it.id) }
                    tupleOf(actions + objects.first, newObject)
                }
            }
            .flatMap { (actions, obj) ->
                val newStatusStatus: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
                if (obj == null) {
                    newStatusStatus.add(Header.code to DesignSystem.Status.Invalid(viewModel.texts.alreadyAMember))
                    return updates.emit(sceneOf<ViewModel>(viewModel.redeemCode(texts = viewModel.texts, state = viewModel.state, status = newStatusStatus)))
                }
                store.reduce(actions).refreshMinimalObject(obj.id).accumulate(actions)
                    .map { tupleOf(it.first, it.second) }
            }
            .flatMap { (actions, obj) ->
                store.reduce(actions).refreshTokens().accumulate(actions)
                    .map { tupleOf(it.first, obj) }
            }
            .flatMap { (actions, obj) ->
                store.reduce(actions).refreshObject(obj.id, listOf(Key(CustomerType.getFkKey(CustomerType.fromRawValue(state.sellType?.value), false)))).accumulate(actions)
                    .map { tupleOf(it.first, it.second) }
            }
            .map { (actions, obj) ->
                val sellInfo = obj.group.name.split("{REPLACE}")
                val result = (sellInfo + List(4 - sellInfo.size) { "-" }).take(4)
                val price = if (result[3].trim() == "-") result[3].trim() else listOf(formatNumber(result[3].trim().substringBefore(" ").toDouble()), viewModel.texts.sek).joinToString(" ")
                val buyType = if (obj.group.visualization.buyType == CustomerType.Leasing.rawValue) CustomerType.Leasing else CustomerType.None
                val newState = viewModel.state.copy(
                    obj = obj,
                    buyType = buyType,
                    sellType = if (buyType is CustomerType.Leasing) sellTypeOptions(viewModel.texts).lastOrNull() else sellTypeOptions(viewModel.texts).firstOrNull(),
                    price = result[3].trim().substringBefore(" ").toDouble()
                )

                updates.emit(sceneOf<ViewModel>(viewModel.redeemCode(texts = viewModel.texts.copy(regNumber = result[0].trim(), make = result[1].trim(), price = price, model = result[2]), state = newState), actions))
            }

            .onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun loadContact(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene

        if (viewModel.state.obj.sellerAgreement?.status is Agreement.Status.Approved) {
            // Submisison is signed
            return updates.emit(sceneOf(viewModel.finished(viewModel.texts, state = viewModel.state)))
        }

        val sellForm = when (viewModel.state.sellType?.value) {
            CustomerType.Private.rawValue -> FK.privateSell
            CustomerType.Company.rawValue -> FK.companySell
            else -> null
        }

        if (viewModel.state.obj.submissionSeller?.state is Submission.State.Open && sellForm == viewModel.state.obj.submissionSeller?.form?.key?.rawValue) {
            // Continue with submission
            store.refreshObject(viewModel.state.obj.id, listOf(Key(CustomerType.getFkKey(CustomerType.fromRawValue(viewModel.state.sellType?.value), false))))
                .flatMap { (actions, obj) ->
                    val entries = obj.submissionSeller?.entries?.filter { it.text != "" }?.map {
                        Submission.Entry(fieldKey = it.fieldKey, text = it.text)
                    }
                    store.editSellSubmissionObject(
                        obj, obj.submissionSeller?.index?.id!!,
                        Submission.Edit(
                            sources = listOf(Source.addressLookup),
                            entries = modifiedOf(entries),
                        ), CustomerType.getFkKey(CustomerType.fromRawValue(viewModel.state.sellType?.value), false)
                    )
                        .accumulate(actions)
                        .map { tupleOf(it.first, it.second) }
                }
                .map { (actions, obj) ->
                    val fieldValues = obj.submissionSeller?.form?.fields?.filter { it.key.rawValue != "HEADING" }?.map { field ->
                        val entry = obj.submissionSeller?.entries?.find { it.fieldKey == field.key }
                        FieldValues(fieldKey = field.key, text = field?.predefined ?: entry?.text ?: "")
                    } ?: emptyList()

                    val state = viewModel.state.copy(
                        form = obj.submissionSeller?.form,
                        fieldValues = fieldValues,
                        entries = obj.submissionSeller?.entries ?: emptyList(),
                        formId = obj.submissionSeller?.index?.id
                    )

                    updates.emit(sceneOf<ViewModel>(viewModel.contactInfo(texts = viewModel.texts, state = state, formItems = buildItems(state, state.form)), actions))
                }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
        } else {
            store.findForms(listOf(Key(CustomerType.getFkKey(CustomerType.fromRawValue(viewModel.state.sellType?.value), false))))
                .flatMap { (actions, form) ->
                    val fieldValues = form.firstOrNull()?.fields?.filter { it.key.rawValue != "HEADING" && it.key.rawValue != "LABEL" }?.map { field ->
                        when (field.key.rawValue) {
                            "GOV_ID" -> FieldValues(fieldKey = field.key, text = store.govId ?: "", modified = true)
                            "REGNO" -> FieldValues(fieldKey = field.key, text = viewModel.texts.regNumber, modified = true)
                            "MAKE" -> FieldValues(fieldKey = field.key, text = viewModel.texts.make, modified = true)
                            "MODEL" -> FieldValues(fieldKey = field.key, text = viewModel.texts.model, modified = true)
                            "PRICE" -> FieldValues(fieldKey = field.key, text = viewModel.texts.price, modified = true)
                            else -> FieldValues(fieldKey = field.key, text = field.predefined ?: "", modified = true)
                        }
                    }

                    val entries = fieldValues?.filter { it.text != "" && it.modified }?.map { Submission.Entry(fieldKey = it.fieldKey, text = it.text) } ?: emptyList()
                    val createSubmission = Submission.Create(
                        sources = listOf(Source.addressLookup),
                        state = Submission.State.Open,
                        formKey = form.firstOrNull()?.key!!,
                        entries = entries
                    )

                    store.reduce(actions).createSubmission(viewModel.state.obj, createSubmission).accumulate(actions)
                        .map { tupleOf(it.first, fieldValues, it.second) }
                }
                .flatMap { (actions, fieldValues, submission) ->
                    store.reduce(actions).checkpointGroup(viewModel.state.obj, Checkpoint.SellerStarted).accumulate(actions)
                        .map { tupleOf(it.first, fieldValues, submission) }
                }
                .map { (actions, fieldValues, submission) ->

                    val updateFieldValues = fieldValues?.map {
                        val field = submission.form?.fields?.firstOrNull { field -> field.key == it.fieldKey }
                        FieldValues(fieldKey = it.fieldKey, text = field?.predefined ?: it.text, modified = field?.predefined !== null)
                    } ?: emptyList()
                    val state = viewModel.state.copy(
                        form = submission.form,
                        fieldValues = updateFieldValues,
                        entries = submission.entries,
                        formId = submission.index.id
                    )

                    updates.emit(sceneOf<ViewModel>(viewModel.contactInfo(texts = viewModel.texts, state = state, formItems = buildItems(state, state.form)), actions))
                }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
        }
    }

    suspend fun update(scene: Scene.Input<ViewModel>, id: String, value: String) {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.ContactInfo) updates.emit(sceneOf(viewModel.failed("Can't update values if not Ready")))

        val isNewValue = if (viewModel.state.entries.firstOrNull { it.fieldKey.rawValue == id } == null) true else viewModel.state.entries.firstOrNull { it.fieldKey.rawValue == id }?.text != value

        var updateFieldValues = viewModel.state.fieldValues.map { field -> if (field.fieldKey.rawValue == id) field.copy(text = value, modified = isNewValue) else field }
        if (id == "RESIDUAL_DEBT") {
            val debt = if (value.isEmpty()) 0.0 else value.toDouble()
            updateFieldValues = updateFieldValues.map { field -> if (field.fieldKey.rawValue == "EXCESS") field.copy(text = formatNumber(viewModel.state.price.minus(debt)) + ":-", modified = true) else field }
        }

        val state = viewModel.state.copy(
            fieldValues = updateFieldValues
        )

        return updates.emit((sceneOf(viewModel.contactInfo(state = state, texts = viewModel.texts, formItems = buildItems(state, state.form, viewModel.state.status)))))
    }

    suspend fun setValues(scene: Scene.Input<ViewModel>, code: String? = null) {
        val (_, viewModel) = scene
        updates.emit((sceneOf(viewModel.redeemCode(state = viewModel.state.copy(code = code ?: viewModel.state.code), texts = viewModel.texts))))
    }

    suspend fun previous(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        when (viewModel) {
            is ViewModel.ContactInfo -> updates.emit(sceneOf<ViewModel>(viewModel.redeemCode(texts = viewModel.texts, state = viewModel.state)))
            is ViewModel.Agreement -> updates.emit(sceneOf<ViewModel>(viewModel.contactInfo(texts = viewModel.texts, state = viewModel.state, formItems = buildItems(viewModel.state, viewModel.state.form))))
            else -> {}

        }
    }

    suspend fun finished(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene

        return updates.emit(sceneOf(viewModel.finished(viewModel.texts, viewModel.state)))
    }

    suspend fun validate(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        var state = viewModel.state

        state.form?.fields?.map { field ->
            val value = state.fieldValues.firstOrNull { it.fieldKey == field.key }?.text
            val price = state.fieldValues.firstOrNull { it.fieldKey.rawValue == "PRICE" }?.text?.replace("\\s".toRegex(), "")?.substringBefore("Kr")?.toDouble()

            val header = header(field.key)

            when (field.style) {
                is Field.Style.Email ->
                    Validator.email(
                        email = value,
                        isWrongFormat = { status.add(header to DesignSystem.Status.Invalid(viewModel.texts.emailFormat)) },
                        isEmpty = { status.add(header to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired)) },
                        formatted = { state = state.copy(fieldValues = state.fieldValues.map { fieldValue -> if (fieldValue.fieldKey == field.key) fieldValue.copy(text = it, modified = it != fieldValue.text) else fieldValue }) },
                        passed = { status.add(header to DesignSystem.Status.Valid) },
                    )

                is Field.Style.Phone -> Validator.phone(
                    phone = value,
                    illegalCharacters = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.phoneIllegalCharacters)) },
                    isEmpty = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.fieldRequired)) },
                    isCorrectLength = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.phoneIllegalCharacters)) },
                    formatted = { state = state.copy(fieldValues = state.fieldValues.map { fieldValue -> if (fieldValue.fieldKey == field.key) fieldValue.copy(text = it, modified = it != fieldValue.text) else fieldValue }) },
                    passed = { status.add(header to DesignSystem.Status.Valid) }
                )

                is Field.Style.Zipcode -> Validator.postalCode(
                    postalCode = value,
                    isEmpty = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.fieldRequired)) },
                    passed = { status.add(header to DesignSystem.Status.Valid) },
                    formatted = { state = state.copy(fieldValues = state.fieldValues.map { fieldValue -> if (fieldValue.fieldKey == field.key) fieldValue.copy(text = it, modified = it != fieldValue.text) else fieldValue }) },
                    isWrongLength = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.postalCodeWrongLength)) },
                    illegalCharacters = { status.add(header to DesignSystem.Status.Invalid(warning = viewModel.texts.postalCodeIllegalCharacters)) },
                )

                else ->
                    // Check for required
                    if (field.required && value.isNullOrEmpty())
                        status.add(header to DesignSystem.Status.Invalid(viewModel.texts.fieldRequired))
                    else if (field.key.rawValue == "RESIDUAL_DEBT" && !value.isNullOrEmpty() && price != null && value.toDouble() > price) {
                        status.add(header to DesignSystem.Status.Invalid(listOf(viewModel.texts.residualDeptHighSum, formatNumber(price), viewModel.texts.sek).joinToString(" ")))
                    } else
                        status.add(header to DesignSystem.Status.Valid)
            }

        }
        val newState = state.copy(status = status)
        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return updates.emit((sceneOf(viewModel.contactInfo(state = newState, texts = viewModel.texts, formItems = buildItems(newState, state.form, status = status)))))
        return save(scene, newState)
    }

    private suspend fun save(scene: Scene.Input<ViewModel>, s: State?) {
        val (store, viewModel) = scene
        val state = s ?: viewModel.state

        if (viewModel !is ViewModel.ContactInfo) updates.emit(sceneOf(viewModel.failed("Can't go next if not Ready")))

        val entries = state.fieldValues.filter { it.text != "" }.map { Submission.Entry(fieldKey = it.fieldKey, text = it.text) }

        val editSubmission = Submission.Edit(
            entries = modifiedOf(entries),
            state = modifiedOf(Submission.State.Closed)
        )

        store.editSellSubmissionObject(state.obj, state.formId!!, editSubmission, CustomerType.getFkKey(CustomerType.fromRawValue(state.sellType?.value), false))
            .flatMap { (actions, obj) ->
                val edit = Profile.MeEdit(email = modifiedOf(obj.submissionSeller?.entries?.firstOrNull { it.fieldKey.rawValue == "EMAIL" }?.text), phone = modifiedOf(obj.submissionSeller?.entries?.firstOrNull { it.fieldKey.rawValue == "PHONE" }?.text))
                store.reduce(actions).editMe(obj, edit).accumulate(actions)
                    .map {
                        tupleOf(it.first, obj, it.second)
                    }
            }.flatMap { (actions, obj) ->
                store.reduce(actions).editGroup(viewModel.state.obj, Group.Edit(order = modifiedOf(Checkpoint.SellerFinished.order), visualization = modifiedOf(Group.Visualization.Predefined(template = "${state.obj.group.visualization.buyType}-${state.sellType?.value}")))).accumulate(actions)
                    .map { tupleOf(it.first, obj) }
            }

            .map { (actions, obj) ->

                updates.emit(sceneOf(viewModel.companyFinished(viewModel.texts, state = state.copy(obj = obj)), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
        /*
                 .flatMap { (actions, obj) ->
                        val create = Agreement.Create(
                            key = Key(CustomerType.getFkKey(CustomerType.fromRawValue(state.sellType?.value), false)),
                            name = obj.name,
                            status = Agreement.Status.Pending,
                            content = FK.templateId(CustomerType.getFkKey(CustomerType.fromRawValue(state.sellType?.value), false)),
                            approvalMethod = Agreement.ApprovalMethod.BySignatures(count = 1)
                        )
                        store.reduce(actions).createAgreement(obj, create).accumulate(actions)
                            .map { tupleOf(it.first, obj, it.second) }
                    }
                    .flatMap { (actions, obj, agreement) ->
                        store.reduce(actions).createTerms(obj, obj.submissionSeller?.createTerms(agreement)!!).accumulate(actions)
                            .map { tupleOf(it.first, obj, agreement, it.second) }
                    }
                    .flatMap { (actions, obj, agreement) ->
                        val create = Signature.Create(
                            agreementId = agreement.id,
                        )
                        store.reduce(actions).createSignature(obj = obj, create = create).accumulate(actions)
                            .map { tupleOf(it.first, obj, it.second) }
                    }

                    .map { (actions, obj, signature) ->

                        val fieldValues = viewModel.state.fieldValues.map {
                            val field = obj.submissionSeller?.form?.fields?.firstOrNull { field -> field.key == it.fieldKey }
                            val entrie = obj.submissionSeller?.entries?.firstOrNull { entrie -> entrie.fieldKey == it.fieldKey }
                            val value = if (field?.predefined == entrie?.text || field?.predefined == null) entrie?.text else field.predefined

                            FieldValues(fieldKey = it.fieldKey, text = value ?: it.text, modified = value !== it.text)
                        }
                        val newState = state.copy(form = obj.submissionSeller?.form, entries = obj.submissionSeller?.entries ?: emptyList(), fieldValues = fieldValues, obj = obj)

                        when (val signed = signature.signed) {
                            is Signature.Signed.Pending -> {
                                updates.emit(sceneOf(viewModel.agreement(texts = viewModel.texts, newState.copy(startCheck = true)), actions))
                            }

                            is Signature.Signed.Agreement, is Signature.Signed.Policy -> {

                                store.editSubmission(newState.obj, Identifier(newState.obj.submissionSeller?.index?.id?.rawValue!!), Submission.Edit(state = modifiedOf(Submission.State.Closed)))
                                    .flatMap { (actions, _) ->
                                        store.reduce(actions).refreshObject(state.obj.id, listOf(Key(CustomerType.getFkKey(CustomerType.fromRawValue(state.sellType?.value), false)))).accumulate(actions)
                                            .map { tupleOf(it.first, it.second) }
                                    }
                                    .map { (actions, obj) ->
                                        return updates.emit(sceneOf(viewModel.finished(viewModel.texts, state = newState.copy(startCheck = false, obj = obj)), actions))
                                    }
                            }

                            is Signature.Signed.Cancelled ->
                                return updates.emit(sceneOf(viewModel.loading(), actions))

                            is Signature.Signed.Failed ->
                                return updates.emit(scene.failed(signed.reason))

                            is Signature.Signed.Unknown ->
                                return updates.emit(scene.failed("Unknown Error"))
                        }

                    }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
                */
    }

    suspend fun check(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene
        val state = viewModel.state

        store.findLatestAgreement(obj = viewModel.state.obj, keys = listOf(Key(CustomerType.getFkKey(CustomerType.fromRawValue(state.sellType?.value), false))))
            .map { (actions, agreements) ->

                when (agreements.firstOrNull()?.status) {
                    is Agreement.Status.Pending -> updates.emit(sceneOf(viewModel.agreement(texts = viewModel.texts, state.copy(startCheck = true)), actions))


                    is Agreement.Status.Approved -> {
                        store.editSubmission(state.obj, Identifier(state.obj.submissionSeller?.index?.id?.rawValue!!), Submission.Edit(state = modifiedOf(Submission.State.Closed)))
                            .flatMap { (actions, _) ->
                                store.reduce(actions).refreshObject(state.obj.id, listOf(Key(CustomerType.getFkKey(CustomerType.fromRawValue(state.sellType?.value), false)))).accumulate(actions)
                                    .map { tupleOf(it.first, it.second) }
                            }
                            .map { (actions, obj) ->
                                return updates.emit(sceneOf(viewModel.finished(viewModel.texts, state = state.copy(startCheck = false, obj = obj)), actions))
                            }
                    }

                    else -> return updates.emit(scene.failed("Unknown Error"))
                }
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        load(scene)
    }

    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }

    private fun buildItems(state: State, form: Form? = null, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()): List<FormItem.ViewModel> {
        val item = form?.fields?.map { field ->
            val value = state.fieldValues.firstOrNull { it.fieldKey == field.key }?.text ?: ""

            val inputType = when (field.style) {
                Field.Style.GovId -> DesignSystem.TextInputType.GOV_ID
                Field.Style.GovCode -> DesignSystem.TextInputType.NUMBER
                Field.Style.Email -> DesignSystem.TextInputType.EMAIL
                Field.Style.Phone -> DesignSystem.TextInputType.PHONE
                Field.Style.Number -> DesignSystem.TextInputType.NUMBER
                Field.Style.Zipcode -> DesignSystem.TextInputType.NUMBER
                Field.Style.Short -> {
                    when (field.key.rawValue) {
                        "RESIDUAL_DEBT", "CLEARING_NUMBER_RESIDUAL_DEBT", "BANK_ACCOUNT_NUMBER_RESIDUAL_DEBT", "BANK_ACCOUNT_NUMBER", "CLEARING_NUMBER" -> DesignSystem.TextInputType.NUMBER
                        else -> DesignSystem.TextInputType.TEXT
                    }
                }

                else -> DesignSystem.TextInputType.TEXT
            }

            when (field.style) {
                is Field.Style.Inline -> when (field.key.rawValue) {
                    "DISCOUNT_CODE" -> FormItem.header(title = field.fLabel, key = field.key, visible = !field.hidden, specialHeader = true)
                    "EXCESS" -> {
                        val excessValue = if (value.isNullOrEmpty()) "0:-" else value
                        FormItem.header(title = excessValue, key = field.key, visible = !field.hidden, specialHeader = true)
                    }

                    "LABEL", "DISCOUNT_CODE_LABEL", "EXCESS_LABEL" -> FormItem.label(title = field.fLabel, key = field.key, visible = !field.hidden, align = DesignSystem.TextAlign.CENTER)
                    "HEADING" -> FormItem.header(title = field.fLabel, key = field.key, visible = !field.hidden)
                    else -> FormItem.label(title = field.fLabel, text = field.placeholder, key = field.key, visible = !field.hidden, info = field.hint)
                }

                else -> FormItem.textInput(placeholder = field.fPlaceholder, label = field.fLabel, visible = !field.hidden, value = value, key = field.key, status = status, inputType = inputType, info = field.hint)
            }

        } ?: emptyList()
        return item
    }
}