package support

import techla.base.Date
import techla.base.Identifier
import techla.base.Key
import techla.form.Field
import techla.form.Submission

val Field.fPlaceholder
    get() =
        placeholder ?: ""

val Field.fLabel
    get() =
        label ?: ""

fun getLabel(fields: List<Field>? = emptyList(), key: String) =
    fields?.firstOrNull { it.key.rawValue == key }?.label

fun getValue(entries: List<Submission.Entry>? = emptyList(), key: String) =
    entries?.firstOrNull { it.fieldKey.rawValue == key }?.text

fun header(key: Key<Field>) =
    DesignSystem.Header(id = key.rawValue)

interface FormItemEditableItemTexts {
    val itemEdit: String
    val itemSave: String
    val itemCancel: String
    val itemPayment: String
}

object FormItem {

    sealed class ViewModel(open val fieldKey: Key<Field>, open val visible: Boolean) {
        data class None(
            override val fieldKey: Key<Field>,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class Header(
            override val fieldKey: Key<Field>,
            val title: DesignSystem.Text,
            val subTitle: DesignSystem.Text?,
            val value: DesignSystem.Text?,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class Label(
            override val fieldKey: Key<Field>,
            val title: DesignSystem.Text,
            val text: DesignSystem.Text?,
            val value: DesignSystem.Text?,
            val info: String?,
            val img: DesignSystem.ImageView,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class Divider(
            override val fieldKey: Key<Field>,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class Lookup(
            override val fieldKey: Key<Field>,
            val value: DesignSystem.TextInput,
            val info: String?,
            val search: DesignSystem.Button,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class SelectInput(
            override val fieldKey: Key<Field>,
            val value: DesignSystem.SelectInput,
            val info: String?,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class DateInput(
            override val fieldKey: Key<Field>,
            val value: DesignSystem.TextInput,
            val info: String?,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class RadioInput(
            override val fieldKey: Key<Field>,
            val value: DesignSystem.RadioInput,
            val info: String?,
            override val visible: Boolean,
            val textWithLink: DesignSystem.TextWithLinks,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class TextInput(
            override val fieldKey: Key<Field>,
            val value: DesignSystem.TextInput,
            val info: String?,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class Checkbox(
            override val fieldKey: Key<Field>,
            val title: DesignSystem.Text,
            val value: DesignSystem.Checkbox,
            val info: String?,
            val textWithLink: DesignSystem.TextWithLinks,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class Summary(
            override val fieldKey: Key<Field>,
            val bank: DesignSystem.Text,
            val bankBody: DesignSystem.Text,
            val interest: DesignSystem.Text,
            val loanInfo: String,
            val otherBody: DesignSystem.Text,
            val residualValueTitle: DesignSystem.Text,
            val residualValue: DesignSystem.Text,
            val residualValueInfo: String?,
            val summaryPrice: DesignSystem.Text,
            val summaryLoan: DesignSystem.Text,
            val summaryCashStake: DesignSystem.Text,
            val summaryMonth: DesignSystem.Text,
            val summaryFkFee: DesignSystem.Text,
            val summaryFkDiscount: DesignSystem.Text,
            val summaryDisclaimerWarranty: DesignSystem.Text,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class SummaryMonthlyCost(
            override val fieldKey: Key<Field>,
            val monthPrice: DesignSystem.Text,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class SummaryTotalLoan(
            override val fieldKey: Key<Field>,
            val loanAmount: DesignSystem.Text,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class SummaryServicesIncluded(
            override val fieldKey: Key<Field>,
            val servicesIncludedTitle: DesignSystem.Text,
            val tryWarranty: DesignSystem.Text,
            val warranty: DesignSystem.Text,
            val check: DesignSystem.ImageView,
            val countExample: DesignSystem.Text,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

        data class EditableItem(
            override val fieldKey: Key<Field>,
            val edit: DesignSystem.Button,
            val save: DesignSystem.Button,
            val cancel: DesignSystem.Button,
            val pay: DesignSystem.Button,
            val status: DesignSystem.Text,
            val objId: Identifier<Object>,
            val paymentType: String,
            override val visible: Boolean,
        ) : ViewModel(fieldKey = fieldKey, visible = visible)

    }

    fun editableItem(texts: FormItemEditableItemTexts, key: String, visible: Boolean, status: String?, editable: Boolean, canRepay: Boolean, objectId: Identifier<Object>, paymentType: String, showButtons: Boolean): ViewModel {
        return ViewModel.EditableItem(
            fieldKey = Key(key),
            edit = DesignSystem.Button(text = texts.itemEdit, style = DesignSystem.Button.Style.OUTLINE, loading = true, disabled = editable, visible = showButtons),
            save = DesignSystem.Button(text = texts.itemSave, style = DesignSystem.Button.Style.OUTLINE, loading = true, disabled = !editable, visible = showButtons, type = DesignSystem.Button.Type.SUBMIT),
            cancel = DesignSystem.Button(text = texts.itemCancel, style = DesignSystem.Button.Style.OUTLINE, loading = true, disabled = !editable, visible = showButtons),
            pay = DesignSystem.Button(text = texts.itemPayment, style = DesignSystem.Button.Style.CONTAINED, loading = true, disabled = !canRepay, visible = showButtons),
            status = DesignSystem.Text(text = status, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.PRIMARY),
            visible = visible,
            objId = objectId,
            paymentType = paymentType,
        )
    }

    fun none(): ViewModel =
        ViewModel.None(
            fieldKey = Key(""),
            visible = false
        )

    fun header(title: String, key: Key<Field>, visible: Boolean, subTitle: String? = null, value: String? = null, specialHeader: Boolean = false): ViewModel =
        ViewModel.Header(
            fieldKey = key,
            title = if (specialHeader) DesignSystem.Text(text = title, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.PRIMARY)
            else DesignSystem.Text(text = title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER),
            subTitle = DesignSystem.Text(text = subTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
            value = DesignSystem.Text(text = value, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, align = DesignSystem.TextAlign.CENTER, color = DesignSystem.Color.PRIMARY),
            visible = visible
        )

    fun label(title: String? = null, text: String? = null, key: Key<Field>, info: String? = null, visible: Boolean, value: String? = null, align: DesignSystem.TextAlign = DesignSystem.TextAlign.LEFT, image: DesignSystem.Image? = null, isMarkdown: Boolean = false): ViewModel =
        ViewModel.Label(
            fieldKey = key,
            title = DesignSystem.Text(text = title, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = align),
            text = DesignSystem.Text(text = text, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = align, isMarkdown = isMarkdown),
            value = DesignSystem.Text(text = value, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
            info = info,
            visible = visible,
            img = DesignSystem.ImageView(image = image),
        )

    fun divider(key: Key<Field>, visible: Boolean): ViewModel =
        ViewModel.Divider(
            fieldKey = key,
            visible = visible
        )

    fun lookup(placeholder: String, label: String? = null, info: String? = null, search: String, value: String?, key: Key<Field>, status: List<Pair<DesignSystem.Header, DesignSystem.Status>>, visible: Boolean, loading: Boolean): ViewModel =
        ViewModel.Lookup(
            fieldKey = key,
            value = DesignSystem.TextInput(header = header(key), label = label, placeholder = placeholder, value = value, status = status.statusOf(header(key)), uppercase = true),
            info = info,
            search = DesignSystem.Button(text = search, style = DesignSystem.Button.Style.OUTLINE, loading = loading, disabled = value.isNullOrEmpty()),
            visible = visible
        )


    fun radioInput(choices: List<DesignSystem.RadioButton>, label: String? = null, info: String? = null, key: Key<Field>, status: List<Pair<DesignSystem.Header, DesignSystem.Status>>, visible: Boolean, checked: DesignSystem.RadioButton? = null, links: List<Pair<String, String>> = emptyList()): ViewModel {
        return ViewModel.RadioInput(
            fieldKey = key,
            value = DesignSystem.RadioInput(header = header(key), label = if (links.isEmpty()) label else null, buttons = choices, checked = checked, status = status.statusOf(header(key))),
            info = info,
            visible = visible,
            textWithLink = DesignSystem.TextWithLinks(text = label, links = links, visible = links.isNotEmpty(), align = DesignSystem.TextAlign.LEFT),
        )
    }

    fun selectInput(optionList: List<String>, placeholder: String, label: String? = null, info: String? = null, value: String?, key: Key<Field>, autocomplete: Boolean, status: List<Pair<DesignSystem.Header, DesignSystem.Status>>, visible: Boolean): ViewModel {
        val options = getOptions(optionList, key)
        val selected = options.firstOrNull { it.value == value } ?: options.firstOrNull { it.value == placeholder }

        return ViewModel.SelectInput(
            fieldKey = key,
            value = DesignSystem.SelectInput(header = header(key), label = label, options = options, selected = selected, autocomplete = autocomplete, placeholder = placeholder, status = status.statusOf(header(key))),
            info = info,
            visible = visible
        )
    }

    fun dateInput(placeholder: String, label: String? = null, info: String? = null, addon: String? = null, value: String?, key: Key<Field>, status: List<Pair<DesignSystem.Header, DesignSystem.Status>>, visible: Boolean): ViewModel =
        ViewModel.DateInput(
            fieldKey = key,
            value = DesignSystem.TextInput(header = header(key), label = label, placeholder = placeholder, value = value, type = DesignSystem.TextInputType.DATE, adornment = addon, status = status.statusOf(header(key))),
            info = info,
            visible = visible
        )

    fun textInput(placeholder: String? = null, label: String? = null, info: String? = null, addon: String? = null, disabled: Boolean = false, inputType: DesignSystem.TextInputType = DesignSystem.TextInputType.TEXT, visible: Boolean, value: String?, key: Key<Field>, status: List<Pair<DesignSystem.Header, DesignSystem.Status>>? = emptyList()): ViewModel =
        ViewModel.TextInput(
            fieldKey = key,
            value = DesignSystem.TextInput(header = header(key), label = label, placeholder = placeholder, type = inputType, disabled = disabled, visible = visible, value = value, adornment = addon, status = status?.statusOf(header(key))),
            info = info,
            visible = visible
        )


    fun checkbox(label: String? = null, info: String? = null, visible: Boolean = true, disabled: Boolean = false, value: String?, key: Key<Field>, status: List<Pair<DesignSystem.Header, DesignSystem.Status>>? = emptyList(), textWithLink: String? = null, links: List<Pair<String, String>> = emptyList()): ViewModel =
        ViewModel.Checkbox(
            fieldKey = key,
            title = DesignSystem.Text(),
            value = DesignSystem.Checkbox(header = header(key), label = label, visible = visible, disabled = disabled, checked = value == "true", status = status?.statusOf(header(key))),
            info = info,
            visible = visible,
            textWithLink = DesignSystem.TextWithLinks(text = textWithLink, links = links, visible = links.isNotEmpty(), align = DesignSystem.TextAlign.LEFT),
        )

    fun summary(installmentBuy: String, installmentLeasing: String, summaryFkFee: String, summaryCashStake: String, summaryLoan: String, summaryPrice: String, residualValueTitle: String, bank: String, customerType: CustomerType, key: Key<Field>, visible: Boolean, month: String, cashStake: String, loan: String, residualValue: String?, interest: String, summaryLoanInfo: String, summaryBankBody: String, summaryLoanBody: String, fkFee: String, residualValueInfo: String, summaryFkDiscount: String?, summaryDisclaimerWarranty: String): ViewModel {
        val installment = if (customerType == CustomerType.Leasing) installmentLeasing.replace("REPLACE", month) else installmentBuy.replace("REPLACE", month)
        return ViewModel.Summary(
            fieldKey = key,
            visible = visible,
            bank = DesignSystem.Text(text = bank, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, color = DesignSystem.Color.TEXT),
            bankBody = DesignSystem.Text(text = summaryBankBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
            interest = DesignSystem.Text(text = interest, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD, color = DesignSystem.Color.TEXT),
            loanInfo = summaryLoanInfo,
            otherBody = DesignSystem.Text(text = summaryLoanBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
            residualValueTitle = DesignSystem.Text(text = residualValueTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, visible = residualValue?.isNotEmpty() == true),
            residualValue = DesignSystem.Text(text = residualValue, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, visible = residualValue?.isNotEmpty() == true),
            residualValueInfo = if (residualValue?.isNotEmpty() == true) residualValueInfo else null,
            summaryPrice = DesignSystem.Text(text = summaryPrice, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
            summaryLoan = DesignSystem.Text(text = summaryLoan.replace("REPLACE", formatNumber(loan.toDouble())), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
            summaryCashStake = DesignSystem.Text(text = summaryCashStake.replace("REPLACE", formatNumber(cashStake.toDouble())), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
            summaryMonth = DesignSystem.Text(text = installment, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
            summaryFkFee = DesignSystem.Text(text = summaryFkFee.replace("REPLACE", formatNumber(fkFee.toDouble())), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER),
            summaryFkDiscount = DesignSystem.Text(text = summaryFkDiscount, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, align = DesignSystem.TextAlign.CENTER, visible = !summaryFkDiscount.isNullOrEmpty()),
            summaryDisclaimerWarranty = DesignSystem.Text(text = summaryDisclaimerWarranty, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, color = DesignSystem.Color.DANGER),
        )
    }

    fun summaryMonthlyCost(key: Key<Field>, visible: Boolean, monthCost: String): ViewModel {
        return ViewModel.SummaryMonthlyCost(
            fieldKey = key,
            visible = visible,
            monthPrice = DesignSystem.Text(text = monthCost, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD, color = DesignSystem.Color.TEXT),
        )
    }

    fun summaryTotalLoan(key: Key<Field>, visible: Boolean, loan: String): ViewModel {
        return ViewModel.SummaryTotalLoan(
            fieldKey = key,
            visible = visible,
            loanAmount = DesignSystem.Text(text = loan, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR),
        )
    }

    fun summaryServicesIncluded(key: Key<Field>, visible: Boolean, tryWarranty: Boolean, warranty: String? = null, servicesIncludedTitle: String, tryWarrantyTitle: String, countExample: String): ViewModel {
        return ViewModel.SummaryServicesIncluded(
            fieldKey = key,
            visible = visible,
            servicesIncludedTitle = DesignSystem.Text(text = servicesIncludedTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
            tryWarranty = DesignSystem.Text(text = tryWarrantyTitle, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, visible = tryWarranty),
            warranty = DesignSystem.Text(text = warranty, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR, visible = !warranty.isNullOrEmpty()),
            check = DesignSystem.ImageView(image = DesignSystem.Image.CHECK),
            countExample = DesignSystem.Text(
                text = countExample,
                size = DesignSystem.SizeType.XS, style = DesignSystem.StyleType.REGULAR
            ),
        )
    }

    private fun getOptions(optionList: List<String>, key: Key<Field>) =
        when (key.rawValue) {
            "CHILDREN" -> {
                val children = (optionList[0].toInt()..optionList[1].toInt()).toList().map { it.toString() } + optionList[2]
                children.map { DesignSystem.Option.item(title = it, value = it) }
            }

            "EMPLOYMENT_YEAR" -> {
                val years = (optionList[0].toInt()..Date().dateTime.year).toList().map { it.toString() }.reversed()
                years.map { DesignSystem.Option.item(title = it, value = it) }
            }

            "MONTH" -> {
                val amountOfMonth = optionList.map { it.toInt() }.sorted().map { it.toString() }
                amountOfMonth.map { DesignSystem.Option.item(title = it, value = it) }
            }

            "EMPLOYMENT_MONTH" -> optionList.map { DesignSystem.Option.item(title = it, value = it) }
            "CONDITION" -> optionList.map { if (it == "Ja") DesignSystem.Option.item(title = it, value = "true") else DesignSystem.Option.item(title = it, value = "false") }

            else -> optionList.sorted().map { DesignSystem.Option.item(title = it, value = it) }
        }
}