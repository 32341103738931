package views

import StoreContext
import components.*
import components.fLoader
import js.core.jso
import mui.material.*
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useNavigate
import screens.BackOfficeScreen
import support.*
import web.cssom.*

val BackOffice = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: BackOfficeScreen.ViewModel by useState(BackOfficeScreen.ViewModel.None)
    val navigate = useNavigate()

    useAsyncEffectOnce {
        BackOfficeScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce { BackOfficeScreen.load(sceneInputOf(store, viewModel)) }

    fun handleLogout() = call {
        BackOfficeScreen.logout(sceneInputOf(store, viewModel))
        navigate(Routes.loginBackOffice, jso { replace = true; state = null })
    }

    fun recover() = call {
        BackOfficeScreen.success(sceneInputOf(store, viewModel))
    }

    fun handleGoTo(option: DesignSystem.Option?) {
        navigate("${Routes.backOffice}/${option?.value}")
    }

    fun handleActiveGroupFilter(value: Boolean) = call {
        BackOfficeScreen.statusFilter(sceneInputOf(store, viewModel), showActiveGroups = value)
    }

    fun handleBusinessFilter(header: DesignSystem.Header, checked: Boolean) = call {
        BackOfficeScreen.statusFilter(sceneInputOf(store, viewModel), businessId = header.id, businessChecked = checked)
    }

    fun handlePagination(page: Int?, row: Int?) = call {
        BackOfficeScreen.statusFilter(sceneInputOf(store, viewModel), page = page, row = row)
    }


    FScaffold {
        //  this.navigation = viewModel.navigation
        isFullScreen = when (viewModel) {
            is BackOfficeScreen.ViewModel.Ready -> true
            else -> false
        }

        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }

        viewModel.asReady?.run {
            fAppbar { design = navigation; onClick = { handleLogout() } }
            Stack {
                spacing = responsive(2)

                sx {
                    alignItems = AlignItems.center
                    gap = 0.5.rem
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        gap = 1.rem
                        maxWidth = responsive(
                            Breakpoint.xs to 35.pct,
                            Breakpoint.lg to 50.pct
                        )
                    }

                    fText { design = backOfficeTitle }
                }

                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = responsive(
                            Breakpoint.xs to Padding(1.rem, 1.rem),
                            Breakpoint.md to Padding(3.rem, 3.rem),
                        )
                        width = 100.pct
                        minHeight = 283.px
                    }

                    Stack {
                        direction = responsive(
                            Breakpoint.xs to StackDirection.column,
                            Breakpoint.sm to StackDirection.row
                        )
                        sx {
                            padding = Padding(0.rem, 0.rem, 1.rem, 0.rem)
                            width = 100.pct
                            flexWrap = FlexWrap.wrap
                        }

                        businessFilter.map { info ->
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(1)
                                sx {
                                    alignItems = AlignItems.center
                                }

                                fCheckbox { design = info; onChange = { value -> handleBusinessFilter(this.design.header!!, value) } }
                            }
                        }
                    }
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(1)

                        sx {
                            flexGrow = number(1.0)
                            justifyContent = responsive(
                                Breakpoint.xs to JustifyContent.flexStart,
                                Breakpoint.sm to JustifyContent.flexEnd,
                            )
                        }
                        fSwitch { design = groupFilter; onChange = ::handleActiveGroupFilter }
                    }


                    fTable { design = items; onClick = ::handleGoTo }
                    Stack {
                        sx {
                            alignItems = AlignItems.flexEnd

                        }
                        fPagination { design = pagination; onChange = ::handlePagination }
                    }

                }
            }
        }


        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}