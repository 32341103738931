package views

import StoreContext
import components.*
import js.core.jso
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import mui.material.*
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useNavigate
import react.router.useParams
import screens.ContractScreen
import support.*
import techla.base.Identifier
import web.cssom.*


val Contract = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: ContractScreen.ViewModel by useState(ContractScreen.ViewModel.None)
    val navigate = useNavigate()
    val params = useParams()
    var count: Int by useState(0)

    useAsyncEffectOnce {
        ContractScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce {
        params["objectId"]?.let { objectId ->
            params["submissionId"]?.let { submissionId ->
                params["type"]?.let { type ->
                    ContractScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(submissionId), type)
                }
            }
        }
    }
    fun handleCloseToast() = call {
        ContractScreen.closeToast(sceneInputOf(store, viewModel))
    }

    fun handleInvite() = call {
        ContractScreen.invite(sceneInputOf(store, viewModel))
    }

    fun handleBack() = call {
        when (viewModel) {
            is ContractScreen.ViewModel.Invite -> ContractScreen.previous(sceneInputOf(store, viewModel))
            else -> {
                navigate(Routes.matters, jso { replace = true; state = null })
            }
        }
    }

    fun handleSummary() = call {
        ContractScreen.summary(sceneInputOf(store, viewModel))
    }

    fun handleCheck() = call {
        ContractScreen.check(sceneInputOf(store, viewModel))
    }

    fun handleLogout() = call {
        ContractScreen.logout(sceneInputOf(store, viewModel))
        navigate(Routes.loginMatters, jso { replace = true; state = null })
    }

    fun recover() = call {
        ContractScreen.success(sceneInputOf(store, viewModel))
    }


    fun copy(code: String) = call {
        ContractScreen.copy(sceneInputOf(store, viewModel))
        Window.clipboard(code)
    }

    /*
    useAsyncEffect(count) { coroutineScope ->
        if (viewModel.state.startCheck) {
            if (coroutineScope.isActive) {
                delay(3000L)
                handleCheck()
            }
            viewModel.asApproved?.let {
                count += 1
            }
            viewModel.asDenied?.let {
                count = 0
            }
        }
    }

     */

    FScaffold {
        isFullScreen = when (viewModel) {
            //  is ContractScreen.ViewModel.Ready -> true
            is ContractScreen.ViewModel.Summary -> true
            is ContractScreen.ViewModel.Invite -> true
            else -> false
        }
        //  this.navigation = viewModel.navigation

        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }

        viewModel.asApproved?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }

            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)
                sx {
                    alignItems = AlignItems.center
                }
                fImage { design = contract }
                fText { design = approveTitle }
                fText { design = approveBody }
                fButton { design = next; onClick = ::handleSummary }
            }
        }

        viewModel.asDenied?.run {
            fAppbar { design = navigation }

            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)
                sx {
                    alignItems = AlignItems.center
                }
                fImage { design = nogo }
                fText { design = deniedTitle }
                fText { design = deniedBody }
                fButton { design = next; onClick = { navigate(fkHome) } }
            }
        }


        viewModel.asSummary?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }
            Stack {
                spacing = responsive(4)
                sx {
                    width = 100.pct
                    alignItems = AlignItems.center
                }
                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = Padding(3.rem, 3.rem)
                        width = responsive(
                            Breakpoint.xs to 100.pct,
                            Breakpoint.md to 55.pct,
                            Breakpoint.lg to 45.pct,
                            Breakpoint.xl to 35.pct
                        )
                    }
                    Stack {
                        spacing = responsive(2)
                        fText { design = finishedTitle }
                        fText { design = finishedIntro }
                        Box {
                            Divider {
                                sx {
                                    backgroundColor = Palette.Text.primary
                                    marginTop = important(1.rem)
                                }
                            }
                        }
                        Stack {
                            spacing = responsive(1)
                            sx {
                                marginBottom = important(1.rem)
                            }
                            Stack {
                                spacing = responsive(1)
                                direction = responsive(StackDirection.row)
                                sx {
                                    justifyContent = JustifyContent.end
                                    alignItems = AlignItems.center
                                }


                                Box {
                                    sx {
                                        marginLeft = 1.rem
                                    }
                                    fInfoButton { infoText = info }
                                }
                            }
                            Stack {
                                spacing = responsive(2)
                                direction = responsive(StackDirection.column)
                                sx {
                                    alignItems = AlignItems.center
                                }
                                fImage { design = swishImage }
                            }
                            Stack {
                                spacing = responsive(1)
                                fText { design = swish }
                                fText { design = swishTitle }
                                fText { design = swishNumber }
                                fText { design = message }
                                fText { design = regnumber }
                            }
                            Stack {
                                spacing = responsive(1)
                                fText { design = bankTransfer }
                                fText { design = bankTransferTitle }
                                fText { design = bankTransferNumber }
                                fText { design = message }
                                fText { design = regnumber }
                            }
                        }
                    }
                    fButton { design = next; onClick = ::handleInvite }
                }
            }
        }


        viewModel.asInvite?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }
            Stack {
                spacing = responsive(4)
                sx {
                    width = 100.pct
                    alignItems = AlignItems.center
                }
                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = responsive(
                            Breakpoint.xs to Padding(1.rem, 1.rem),
                            Breakpoint.md to Padding(3.rem, 3.rem),
                        )
                        width = responsive(
                            Breakpoint.xs to 100.pct,
                            Breakpoint.md to 55.pct,
                            Breakpoint.lg to 45.pct,
                            Breakpoint.xl to 35.pct
                        )
                    }
                    Stack {
                        spacing = responsive(2)
                        fText { design = inviteTitle }
                        fTextWithLinks { design = inviteIntro }
                        Stack {
                            spacing = responsive(2)
                            Box {
                                Divider {
                                    sx {
                                        backgroundColor = Palette.Text.primary
                                        marginTop = important(1.rem)
                                    }
                                }
                            }
                            fText { design = codeTitle }
                            Box {
                                sx {
                                    borderRadius = Shape.borderRadius10
                                    border = Border(1.px, LineStyle.solid, Palette.Text.primary)
                                    padding = Padding(1.rem, 1.rem)
                                }
                                fText { design = code }
                            }
                            fButton { design = copy; onClick = { copy(viewModel.texts.code) } }
                        }
                        fButton { design = next; onClick = { navigate(Routes.matters) } }
                    }
                }
            }
            fToast { design = copyCode; handleCloseToast = ::handleCloseToast }
        }

        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}