package components


import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.span
import support.DesignSystem
import support.Palette
import support.Shape
import web.cssom.*

external interface FStepperProps : Props {
    var design: DesignSystem.Stepper
}

val fStepper = FC<FStepperProps> { props ->
    if (props.design.visible)
        Stack {
            spacing = responsive(1)
            direction = responsive(StackDirection.row)
            sx {
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
            }
            props.design.steps?.mapIndexed { index, option ->
                val step = index.plus(1)

                val title = if (props.design.steps?.lastIndex != index) option.title else option.title
                Box {
                    sx {
                        borderRadius = Shape.borderRadius50
                        backgroundColor = if (step == props.design.active) Color(Palette.Primary.main) else Color(Palette.Text.disabled.toString())
                        height = 25.px
                        width = 25.px
                        display = Display.flex
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.center
                        color = Color(Palette.Background.paper)
                        textAlign = TextAlign.center
                    }
                    span { +step.toString() }
                }

                fText { design = DesignSystem.Text(text = title, size = DesignSystem.SizeType.SM, color = DesignSystem.Color.DISABLED) }
            }
        }
}