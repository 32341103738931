package support

import techla.agreement.Agreement
import techla.agreement.Term
import techla.base.Either
import techla.base.Key
import techla.form.Submission

fun Submission.createTerms(agreement: Agreement) =
    Term.Batch(
        creates = results.map { (field, value) ->
            when (field) {
                is Either.Left ->
                    Term.Create(
                        agreement = agreement.key,
                        key = Key(field.value.key.rawValue),
                        name = field.value.style.rawValue,
                        value = value,
                        removeAfterProcessing = true
                    )

                is Either.Right ->
                    Term.Create(
                        agreement = agreement.key,
                        key = Key(field.value.rawValue),
                        name = "",
                        value = value,
                        removeAfterProcessing = true
                    )
            }
        } + listOf(
            Term.Create(
                agreement = agreement.key,
                key = Key("AGREEMENT_DATE"),
                name = "Agreement date",
                value = agreement.createdAt.dateTime.date.toString(),
                removeAfterProcessing = true
            )
        )
    )
