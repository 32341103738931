package components

import react.FC
import react.PropsWithChildren
import react.PropsWithClassName
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.form
import web.html.HTMLFormElement

external interface FFormProps : PropsWithChildren, PropsWithClassName {
    var onSubmit: () -> Unit
}

val fForm = FC<FFormProps> { props ->
    fun onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        props.onSubmit()
    }

    form {
        onSubmit = ::onSubmit
        className = props.className

        +props.children
    }
}