package screens

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import services.findMedias
import services.listAllGroupsWithPaging
import support.*
import techla.base.*
import techla.base.onNotSuccess
import techla.guard.Group

object BackOfficeScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    data class Texts(
        val business: String,
        val intro: String,
        val back: String,
        val emptyItems: String,
        val regnumber: String,
        val make: String,
        val model: String,
        val price: String,
        val members: String,
        val status: String,
        val logout: String,
        val started: String,
        val active: String,
        val done: String,
        val infoColorPrivateCompany: String,
        val infoColorCompany: String,
        val infoColorLeasing: String,
        val infoColorPrivatePrivate: String,
        val infoColorAlone: String,
        val groupFilter: String,
        val commitment: String,
        val sek: String,
        val inactive: String,
        val dashboard: String,
        override val failureTitle: String,
        override val failureReason: String
    ) : FailureTexts {
        companion object
    }

    data class State(
        val showActiveGroups: Boolean = true,
        val startPage: Int = 1,
        val pageIndex: PageIndex = PageIndex(page = startPage, size = 5),
        val totalAmountOfItems: Int = -1,
        val businessFilters: List<BusinessFilter> = emptyList()
    )

    data class BusinessFilter(
        val id: String,
        val label: String,
        val checked: Boolean,
        val color: String?,
        val amount: Int,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val backOfficeTitle: DesignSystem.Text,
            val backOfficeIntro: DesignSystem.Text,
            val items: DesignSystem.Table,
            val businessFilter: List<DesignSystem.Checkbox>,
            val pagination: DesignSystem.Pagination,
            val groupFilter: DesignSystem.Switch,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation = navigation)

        fun ready(texts: Texts, state: State, items: List<DesignSystem.Row>? = null) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation(
                    title = "Fordonskrediten", background = DesignSystem.Background.DARK,
                    menu = DesignSystem.Menu(
                        title = "profile name", items = listOf(
                            DesignSystem.Option.item(title = texts.logout, action = DesignSystem.Action.LOGOUT),
                        )
                    ),
                    links = listOf(
                        DesignSystem.Option.item(title = texts.business, location = Location.BackOffice),
                        DesignSystem.Option.item(title = texts.commitment, location = Location.Commitment),
                        DesignSystem.Option.item(title = texts.dashboard, location = Location.Dashboard),
                    ),
                    selectedLink = DesignSystem.Option.item(title = texts.business, location = Location.BackOffice),
                    location = Location.BackOffice
                ),
                backOfficeTitle = DesignSystem.Text(text = texts.business, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD),
                backOfficeIntro = DesignSystem.Text(text = texts.intro, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD),
                items = DesignSystem.Table(
                    empty = texts.emptyItems,
                    body = items,
                    header = DesignSystem.Row(
                        cells = listOf(
                            DesignSystem.Option.item(title = texts.regnumber),
                            DesignSystem.Option.item(title = texts.make),
                            DesignSystem.Option.item(title = texts.model),
                            DesignSystem.Option.item(title = texts.price),
                            DesignSystem.Option.item(title = texts.members),
                            DesignSystem.Option.item(title = texts.status),
                            DesignSystem.Option.item(title = " ")
                        )
                    )
                ),
                pagination = DesignSystem.Pagination(currentPage = state.pageIndex.page, rowsPerPage = state.pageIndex.size, totalAmountOfItems = state.totalAmountOfItems),
                groupFilter = DesignSystem.Switch(label = texts.groupFilter, checked = state.showActiveGroups),
                businessFilter = state.businessFilters.map { DesignSystem.Checkbox(header = DesignSystem.Header(id = it.id), label = "${it.label} (${it.amount})", checked = it.checked, data = it.color) }
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed

    }

    private fun businessFilter(businessFilters: List<BusinessFilter>): List<Group.Visualization.Predefined>? {
        val predefined = businessFilters.filter { it.checked }.mapNotNull {
            when (it.id) {
                GroupCombinations.private -> listOf(
                    Group.Visualization.Predefined(GroupCombinations.private),
                    Group.Visualization.Predefined(GroupCombinations.company),
                    Group.Visualization.Predefined(GroupCombinations.leasing),
                )

                GroupCombinations.privatePrivate -> listOf(
                    Group.Visualization.Predefined(GroupCombinations.privatePrivate),
                )

                GroupCombinations.privateCompany -> listOf(
                    Group.Visualization.Predefined(GroupCombinations.privateCompany),
                    Group.Visualization.Predefined(GroupCombinations.companyPrivate)
                )

                GroupCombinations.companyCompany -> listOf(
                    Group.Visualization.Predefined(GroupCombinations.companyCompany)
                )

                GroupCombinations.leasingCompanyCompany -> listOf(
                    Group.Visualization.Predefined(GroupCombinations.leasingCompanyPrivate),
                    Group.Visualization.Predefined(GroupCombinations.leasingCompanyCompany)
                )

                else -> null
            }
        }.flatten()

        return predefined.ifEmpty {
            listOf(
                Group.Visualization.Predefined("none"),
            )
        }
    }

    suspend fun statusFilter(scene: Scene.Input<ViewModel>, showActiveGroups: Boolean? = null, page: Int? = null, row: Int? = null, businessId: String? = null, businessChecked: Boolean? = false) {
        val (store, viewModel) = scene

        val updatedBusinessFilter = viewModel.state.businessFilters.map {
            if (it.id == businessId && businessChecked != null) BusinessFilter(id = it.id, label = it.label, checked = businessChecked, color = it.color, amount = countPredefined(store, businessFilter(listOf(it)) ?: emptyList()))
            else
                BusinessFilter(id = it.id, label = it.label, checked = it.checked, color = it.color, amount = countPredefined(store, businessFilter(listOf(it)) ?: emptyList()))
        }

        val pageIndex = viewModel.state.pageIndex.copy(page = page ?: viewModel.state.startPage, size = row ?: viewModel.state.pageIndex.size)
        store.adminRefreshObjects(pageIndex = pageIndex, groupFilter = showActiveGroups ?: viewModel.state.showActiveGroups, predefined = businessFilter(updatedBusinessFilter))
            .map { (actions, pair) ->
                val obj = pair.first.filter { it.name != "Standard" }

                val content = pair.second
                val state = viewModel.state.copy(
                    totalAmountOfItems = content.items.total.minus(pair.first.filter { it.name == "Standard" }.size),
                    pageIndex = PageIndex(page = content.index.page, size = row ?: viewModel.state.pageIndex.size),
                    showActiveGroups = showActiveGroups ?: viewModel.state.showActiveGroups,
                    businessFilters = updatedBusinessFilter
                )
                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, buildGrid(viewModel.texts, obj)), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }


    private suspend fun countPredefined(store: Store, predefined: List<Group.Visualization.Predefined>): Int {
        var count = 0
        store.listAllGroupsWithPaging(PageIndex(page = 1, size = 0), null, null, predefined).map {
            count = it.second.items.total
        }
        return count
    }

    suspend fun load(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene

        updates.emit(sceneOf(viewModel.loading()))
        store.adminRefreshObjects(viewModel.state.pageIndex, viewModel.state.showActiveGroups)
            .flatMap { (actions, pair) ->
                store.reduce(actions).findMedias()
                    .accumulate(actions)
                    .map { tupleOf(it.first, pair) }
            }
            .map { (actions, pair) ->
                val obj = pair.first.filter { it.name != "Standard" }
                val updated = store.reduce(actions)
                val content = pair.second

                val texts = Texts(
                    business = updated.get(media = Key("screen:backoffice"), content = Key("business")),
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    intro = updated.get(media = Key("screen:backoffice"), content = Key("intro")),
                    back = updated.get(media = Key("screen:backoffice"), content = Key("back")),
                    emptyItems = updated.get(media = Key("screen:backoffice"), content = Key("emptyItems")),
                    regnumber = updated.get(media = Key("screen:backoffice"), content = Key("regnumber")),
                    make = updated.get(media = Key("screen:backoffice"), content = Key("make")),
                    model = updated.get(media = Key("screen:backoffice"), content = Key("model")),
                    price = updated.get(media = Key("screen:backoffice"), content = Key("price")),
                    members = updated.get(media = Key("screen:backoffice"), content = Key("members")),
                    status = updated.get(media = Key("screen:backoffice"), content = Key("status")),
                    logout = updated.get(media = Key("screen:backoffice"), content = Key("logout")),
                    started = updated.get(media = Key("screen:backoffice"), content = Key("started")),
                    active = updated.get(media = Key("screen:backoffice"), content = Key("active")),
                    done = updated.get(media = Key("screen:backoffice"), content = Key("done")),
                    inactive = updated.get(media = Key("screen:backoffice"), content = Key("inactive")),
                    infoColorCompany = updated.get(media = Key("screen:backoffice"), content = Key("infoColorCompany")),
                    infoColorLeasing = updated.get(media = Key("screen:backoffice"), content = Key("infoColorLeasing")),
                    infoColorPrivateCompany = updated.get(media = Key("screen:backoffice"), content = Key("infoColorPrivateCompany")),
                    infoColorPrivatePrivate = updated.get(media = Key("screen:backoffice"), content = Key("infoColorPrivatePrivate")),
                    groupFilter = updated.get(media = Key("screen:backoffice"), content = Key("groupFilter")),
                    commitment = updated.get(media = Key("screen:backoffice"), content = Key("commitment")),
                    sek = updated.get(media = Key("screen:backoffice"), content = Key("sek")),
                    infoColorAlone = updated.get(media = Key("screen:backoffice"), content = Key("infoColorAlone")),
                    dashboard = updated.get(media = Key("screen:backoffice"), content = Key("dashboard")),
                )

                val businessFilter = listOf(
                    BusinessFilter(id = GroupCombinations.private, label = texts.infoColorAlone, checked = true, color = "", amount = countPredefined(store, businessFilter(listOf(BusinessFilter(id = GroupCombinations.private, "", true, "", 0))) ?: emptyList())),
                    BusinessFilter(id = GroupCombinations.privatePrivate, label = texts.infoColorPrivatePrivate, checked = true, color = Palette.CommitmentDots.private, amount = countPredefined(store, businessFilter(listOf(BusinessFilter(id = GroupCombinations.privatePrivate, "", true, "", 0))) ?: emptyList())),
                    BusinessFilter(id = GroupCombinations.privateCompany, label = texts.infoColorPrivateCompany, checked = true, color = Palette.CommitmentDots.privateCompany, amount = countPredefined(store, businessFilter(listOf(BusinessFilter(id = GroupCombinations.privateCompany, "", true, "", 0))) ?: emptyList())),
                    BusinessFilter(id = GroupCombinations.companyCompany, label = texts.infoColorCompany, checked = true, color = Palette.CommitmentDots.company, amount = countPredefined(store, businessFilter(listOf(BusinessFilter(id = GroupCombinations.companyCompany, "", true, "", 0))) ?: emptyList())),
                    BusinessFilter(id = GroupCombinations.leasingCompanyCompany, label = texts.infoColorLeasing, checked = true, color = Palette.CommitmentDots.leasing, amount = countPredefined(store, businessFilter(listOf(BusinessFilter(id = GroupCombinations.leasingCompanyCompany, "", true, "", 0))) ?: emptyList())),
                )

                val state = viewModel.state.copy(
                    totalAmountOfItems = content.items.total.minus(pair.first.filter { it.name == "Standard" }.size),
                    pageIndex = PageIndex(page = content.index.page, size = viewModel.state.pageIndex.size),
                    businessFilters = businessFilter
                )

                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = texts, state = state, buildGrid(texts, obj)), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }

    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        return load(scene)
    }

    private fun buildGrid(texts: Texts, obj: List<Object>? = emptyList()) =
        obj?.map {
            val textParts = it.group.name.split("{REPLACE}")
            val result = (textParts + List(4 - textParts.size) { "-" }).take(4)
            val price = if (result[3].trim() == "-") result[3].trim() else listOf(formatNumber(result[3].trim().substringBefore(" ").toDouble()), texts.sek).joinToString(" ")
            DesignSystem.Row(
                id = it.id.rawValue,
                data = boGroupColor(it.group.visualization.buyType),
                cells = listOf(
                    DesignSystem.Option.item(title = result[0].trim()),
                    DesignSystem.Option.item(title = result[1].trim()),
                    DesignSystem.Option.item(title = result[2].trim()),
                    DesignSystem.Option.item(title = price),
                    DesignSystem.Option.item(title = it.group.members.size.toString()),
                    DesignSystem.Option.item(
                        title = when (it.group.status) {
                            Group.Status.Archived -> texts.done
                            Group.Status.Active -> texts.active
                            Group.Status.None -> texts.started
                            Group.Status.Inactive -> texts.inactive
                            else -> ""
                        }, data = when (it.group.status) {
                            Group.Status.Archived -> DesignSystem.Color.SUCCESS
                            Group.Status.Active -> DesignSystem.Color.WARNING
                            Group.Status.None -> DesignSystem.Color.DANGER
                            Group.Status.Inactive -> DesignSystem.Color.DANGER
                            else -> null
                        }
                    ),
                    DesignSystem.Option.item(image = DesignSystem.Image.RIGHTARROW, value = it.id.rawValue),
                )
            )
        }
}