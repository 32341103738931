package components

import mui.material.LabelDisplayedRowsArgs
import mui.material.TablePagination
import mui.system.PropsWithSx
import mui.system.sx
import react.FC
import react.ReactNode
import react.dom.events.ChangeEvent
import support.DesignSystem
import web.html.HTMLButtonElement
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML
import support.Palette
import techla.base.techla_log
import web.cssom.*
import web.html.HTMLElement

external interface FPaginationProps : PropsWithSx {
    var design: DesignSystem.Pagination
    var onChange: (page: Int?, row: Int?) -> Unit
}

val fPagination = FC<FPaginationProps> { props ->
    val design = props.design


    fun handlePage(event: MouseEvent<HTMLButtonElement, *>?, newPage: Number) {
        props.onChange(newPage.toInt().plus(1), null)
    }

    fun handleRows(event: ChangeEvent<HTMLElement>) {
        val row = event.target.asDynamic().value as? Int
        props.onChange(null, row)
    }

    fun defaultLabelDisplayedRows(args: LabelDisplayedRowsArgs): ReactNode =
        ReactNode("${args.from}–${args.to} av ${args.count}")

    TablePagination {
        component = ReactHTML.div
        rowsPerPageOptions = design.rowsPerPageOptions?.toTypedArray()!!
        count = design.totalAmountOfItems
        page = design.currentPage.minus(1)
        rowsPerPage = design.rowsPerPage
        labelRowsPerPage = ReactNode("Rader per sida:")
        labelDisplayedRows = ::defaultLabelDisplayedRows
        onPageChange = ::handlePage
        onRowsPerPageChange = ::handleRows

        sx {
            borderBottom = Border(0.rem, LineStyle.hidden)

            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows" {
                fontWeight = FontWeight.normal
            }

        }
    }
}

