package services

import support.*
import techla.base.*
import techla.guard.*

private suspend fun Store.createApplicationAuthentication(): ActionOutcome<ApplicationAuthentication> =
    guardApplicationAPI(ignoreRefresh = true) { actions, api ->
        api.token = null
        val create = ApplicationAuthentication.Create(
            applicationKey = deployment.applicationKey,
            applicationSecret = deployment.applicationSecret,
            device = device,
        )
        measureAPI(GuardResource.CreateApplicationAuthentication(create), api) {
            api.createApplicationAuthentication(create)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }

suspend fun Store.createUserAuthentication(govId: String? = null): ActionOutcome<UserAuthentication> {
    return guardApplicationAPI { actions, api ->
        val create = UserAuthentication.Create(
            govId = govId,
            device = device,
        )
        measureAPI(GuardResource.CreateUserAuthentication(create), api) {
            api.createUserAuthentication(create)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.getUserAuthentication(): ActionOutcome<UserAuthentication> {
    val id = userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId cannot be null"))
    return guardApplicationAPI { actions, api ->
        measureAPI(GuardResource.GetUserAuthentication(id), api) {
            api.getUserAuthentication(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.refreshTokens(): ActionOutcome<Unit> {
    val id = userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId cannot be null"))
    return guardUserAPI { actions, api ->
        measureAPI(GuardResource.GetUserAuthentication(id), api) {
            api.getUserAuthentication(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
        .map { (actions, userAuth) ->
            tupleOf(actions + Store.Action.TokenRefresh(userAuth.tokens, userAuth.profileId, userAuth.govId), Unit)
        }
}

suspend fun Store.deleteUserAuthentication(): ActionOutcome<Unit> {
    val id = userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId cannot be null"))
    return guardApplicationAPI(ignoreRefresh = true) { actions, api ->
        measureAPI(GuardResource.DeleteUserAuthentication(id), api) {
            api.deleteUserAuthentication(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createGroup(create: Group.Create): ActionOutcome<Group> =
    guardUserAPI { actions, api ->
        measureAPI(GuardResource.CreateGroup(create), api) {
            api.createGroup(create)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }

suspend fun Store.editGroup(obj: Object, edit: Group.Edit): ActionOutcome<Group> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).editGroup(obj.group.id, edit)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.checkpointGroup(obj: Object, checkpoint: Checkpoint): ActionOutcome<Group> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        if (obj.group.order < checkpoint.order)
            reduce(action).editGroup(obj.group.id, Group.Edit(order = modifiedOf(checkpoint.order)))
                .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
        else
            successfulOf(obj.group).noActions()
    }

suspend fun Store.adminEditGroup(id: Identifier<Group>, edit: Group.Edit): ActionOutcome<Group> {
    return guardAdminAPI { actions, api ->
        measureAPI(GuardResource.EditGroup(id, edit), api) {
            api.editGroup(id, edit)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.editGroup(id: Identifier<Group>, edit: Group.Edit): ActionOutcome<Group> {
    return guardUserAPI { actions, api ->
        measureAPI(GuardResource.EditGroup(id, edit), api) {
            api.editGroup(id, edit)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.getGroup(id: Identifier<Group>): ActionOutcome<Group> {
    return guardUserAPI { actions, api ->
        measureAPI(GuardResource.GetGroup(id), api) {
            api.getGroup(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.myGroups(): ActionOutcome<List<Group>> {
    return guardUserAPI { actions, api ->
        measureAPI(GuardResource.MyGroups, api) {
            api.myGroups()
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.adminCreateInvite(create: Invite.Create): ActionOutcome<Invite> {
    return guardAdminAPI { actions, api ->
        measureAPI(GuardResource.CreateInvite(create), api) {
            api.createInvite(create)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createInvite(create: Invite.Create): ActionOutcome<Invite> {
    return guardUserAPI { actions, api ->
        measureAPI(GuardResource.CreateInvite(create), api) {
            api.createInvite(create)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createInvite(obj: Object, create: Invite.Create): ActionOutcome<Invite> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createInvite(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.acceptInvite(accept: Invite.Accept): ActionOutcome<Unit> {
    return guardUserAPI { actions, api ->
        measureAPI(GuardResource.AcceptInvite(accept), api) {
            api.acceptInvite(accept)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.deleteProfile(id: Identifier<Profile>): ActionOutcome<Unit> {
    return guardAdminAPI { actions, api ->
        measureAPI(GuardResource.DeleteProfile(id), api) {
            api.deleteProfile(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.forgetProfile(id: Identifier<Profile>): ActionOutcome<Unit> {
    return guardAdminAPI { actions, api ->
        measureAPI(GuardResource.ForgetProfile(id), api) {
            api.forgetProfile(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.me(): ActionOutcome<Me> {
    return guardUserAPI { actions, api ->
        measureAPI(GuardResource.Me, api) {
            api.me()
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.editMe(edit: Profile.MeEdit): ActionOutcome<Me> {
    return guardUserAPI { actions, api ->
        measureAPI(GuardResource.EditMe(edit), api) {
            api.editMe(edit)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.editMe(obj: Object, edit: Profile.MeEdit): ActionOutcome<Me> =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).editMe(edit)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.listAllGroups(): ActionOutcome<List<Group>> {
    return guardAdminAPI { actions, api ->
        measureAPI(GuardResource.ListGroups, api) {
            api.listGroups()
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.listAllGroupsWithPaging(index: PageIndex, statuses: List<Group.Status>? = null, profileIds: List<Identifier<Profile>>? = null, predefined: List<Group.Visualization.Predefined>? = null): ActionOutcome<PageContent<Group>> {
    return guardAdminAPI { actions, api ->
        measureAPI(GuardResource.ListGroupsWithPaging(index, statuses, profileIds, predefined), api) {
            api.listGroups(index, statuses, profileIds, predefined)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.refreshUserAuthentication(): ActionOutcome<UserAuthentication> {
    val id = userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId needed to refresh token"))
    return guardApplicationAPI { actions, api ->
        api.getUserAuthentication(id)
            .fold(
                onSuccess = {
                    when (it.status) {
                        is UserAuthentication.Status.Complete -> successfulOf(it).noActions().accumulate(actions)
                        is UserAuthentication.Status.Expired -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is expired"))
                        is UserAuthentication.Status.Cancelled -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is cancelled"))
                        is UserAuthentication.Status.Failed -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is failed"))
                        is UserAuthentication.Status.Outstanding -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is outstanding"))
                        is UserAuthentication.Status.Verified -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is verified"))
                    }
                },
                onInvalid = { failedOf(TechlaError.BadRequest("WARN: $it")) },
                onFailure = { failedOf(it) }
            )
    }
}

suspend fun <T> Store.withApplicationToken(ignoreRefresh: Boolean = false, block: suspend (List<Store.Action>) -> ActionOutcome<T>): ActionOutcome<T> {
    return if (applicationToken == null && !ignoreRefresh) {
        techla_log("STORE: Application token missing, refreshing")
        createApplicationAuthentication()
            .flatMap { (actions, applicationAuthentication) ->
                val updated = actions + Store.Action.ApplicationAuthenticationCompleted(
                    token = applicationAuthentication.tokens.filterIsInstance<Token.Application>().first().token
                )
                block(updated)
            }
    } else {
        block(emptyList())
    }
}

suspend fun <T> Store.withUserToken(ignoreRefresh: Boolean = false, block: suspend (List<Store.Action>) -> ActionOutcome<T>): ActionOutcome<T> {
    val expired = tokenExpiresAt?.hasPassed() ?: true
    return if (expired && !ignoreRefresh) {
        techla_log("STORE: Token expired (${tokenExpiresAt}), refreshing")
        refreshUserAuthentication()
            .flatMap { (actions, userAuthentication) ->
                val updated = actions + Store.Action.TokenRefresh(userAuthentication.tokens, userAuthentication.profileId, userAuthentication.govId)
                block(updated)
            }
            .onNotSuccess {
                updateUserAuthenticationId(null)
            }
    } else {
        block(emptyList())
    }
}

suspend fun <T> Store.guardAdminAPI(ignoreRefresh: Boolean = false, block: suspend (List<Store.Action>, api: GuardAPI) -> ActionOutcome<T>): ActionOutcome<T> {
    return withApplicationToken { updates ->
        reduce(updates).withUserToken(ignoreRefresh = ignoreRefresh) { updates2 ->
            val api = GuardAPI(httpClient).also { api ->
                api.host = if (deployment.isSandbox) GuardAPI.sandbox else GuardAPI.shared
                api.token = reduce(updates + updates2).adminToken
            }
            block(updates + updates2, api)
        }
    }
}

suspend fun <T> Store.guardUserAPI(ignoreRefresh: Boolean = false, block: suspend (List<Store.Action>, api: GuardAPI) -> ActionOutcome<T>): ActionOutcome<T> {
    return withApplicationToken { updates ->
        reduce(updates).withUserToken(ignoreRefresh = ignoreRefresh) { updates2 ->
            val api = GuardAPI(httpClient).also { api ->
                api.host = if (deployment.isSandbox) GuardAPI.sandbox else GuardAPI.shared
                api.token = reduce(updates + updates2).userToken
            }
            block(updates + updates2, api)
        }
    }
}

suspend fun <T> Store.guardApplicationAPI(ignoreRefresh: Boolean = false, block: suspend (List<Store.Action>, api: GuardAPI) -> ActionOutcome<T>): ActionOutcome<T> {
    return withApplicationToken(ignoreRefresh = ignoreRefresh) { updates ->
        val api = GuardAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) GuardAPI.sandbox else GuardAPI.shared
            api.token = reduce(updates).applicationToken
        }
        block(updates, api)
    }
}
