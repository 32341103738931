package components.items

import components.*
import mui.material.Box
import mui.material.Divider
import mui.material.Stack
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.FormItem
import support.Palette
import web.cssom.*


external interface FormLookupProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.Lookup
    var onClick: ((id: String, value: String) -> Unit)?
    var onChange: ((id: String, value: String) -> Unit)?
}


val FormItemLookup = FC<FormLookupProps> { props ->

    fun handleSearch() {
        props.onClick?.let { it(props.viewModel.fieldKey.rawValue, props.viewModel.value.value ?: "") }
    }

    fun handleSearch(inputValue: String) {
        props.onClick?.let { it(props.viewModel.fieldKey.rawValue, props.viewModel.value.value ?: "") }
    }

    fun onChange(inputValue: String) {
        props.onChange?.let { it(props.viewModel.fieldKey.rawValue, inputValue) }
    }
    if (props.viewModel.visible)
        Stack {
            spacing = responsive(2)
            fTextInput { design = props.viewModel.value; onChange = ::onChange; onEnter = ::handleSearch }
            fButton { design = props.viewModel.search; onClick = ::handleSearch }

            Box {
                Divider {
                    sx {
                        backgroundColor = Palette.Text.primary
                        marginTop = important(1.rem)
                    }
                }
            }

        }
    if (props.viewModel.info != null)
        props.viewModel.info.let {
            Box {
                sx {
                    marginLeft = 1.rem
                }
                fInfoButton {
                    if (it != null) {
                        infoText = it
                    }
                }
            }
        }
}