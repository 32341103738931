package views

import StoreContext
import components.fButton
import components.*
import components.fLoader
import components.items.*
import js.core.jso
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useLocation
import react.router.useNavigate
import react.router.useParams
import screens.ApplyScreen
import support.*
import web.cssom.*


val Apply = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: ApplyScreen.ViewModel by useState(ApplyScreen.ViewModel.None)
    val navigate = useNavigate()
    val params = useParams()
    val type = params["type"]
    var count: Int by useState(0)
    val local = useLocation()

    useAsyncEffectOnce {
        ApplyScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce { ApplyScreen.load(sceneInputOf(store, viewModel), local.pathname, params["objectId"], type) }

    fun handleNext() = call {
        ApplyScreen.next(sceneInputOf(store, viewModel))
        Window.scrollTo()
    }

    fun handleBack() = call {
        when (viewModel) {
            is ApplyScreen.ViewModel.Ready -> {
                ApplyScreen.previous(sceneInputOf(store, viewModel))
                Window.scrollTo()
            }

            else -> {
                navigate(Routes.matters, jso { replace = true; state = null })
            }
        }
    }

    fun handleUpdate(id: String, value: String) = call {
        ApplyScreen.update(sceneInputOf(store, viewModel), id, value)
    }

    fun handleSearchRegno(id: String, value: String) = call {
        ApplyScreen.searchRegno(sceneInputOf(store, viewModel), id, value)
    }

    fun handleCheck() = call {
        ApplyScreen.check(sceneInputOf(store, viewModel))
    }

    fun handleLogout() = call {
        ApplyScreen.logout(sceneInputOf(store, viewModel))
        navigate(Routes.loginMatters, jso { replace = true; state = null })
    }

    fun handlBackToLogin(goTo: String) = call {
        ApplyScreen.logout(sceneInputOf(store, viewModel))
        navigate(when (CustomerType.fromRawValue(goTo)) {
            CustomerType.Private -> Routes.loginApplyPrivate
            CustomerType.Company -> Routes.loginApplyCompany
            CustomerType.Leasing -> Routes.loginApplyLeasing
            CustomerType.None -> Routes.loginMatters
        }, jso { replace = true; state = null })
    }

    fun recover() = call {
        ApplyScreen.success(sceneInputOf(store, viewModel), local.pathname)
    }

    useAsyncEffect(count) { coroutineScope ->
        if (viewModel.state.startCheck) {
            if (coroutineScope.isActive) {
                delay(3000L)
                handleCheck()
            }
            viewModel.asApproved?.let {
                count += 1
            }
            viewModel.asDenied?.let {
                count = 0
            }
        }
    }

    FScaffold {
        isFullScreen = when (viewModel) {
            is ApplyScreen.ViewModel.Ready -> true
            else -> false
        }

        viewModel.asLogout?.run {
            call {
                handlBackToLogin(goTo)
            }
        }
        viewModel.asApproved?.run {
            fAppbar { design = navigation; onClick = { handleBack() } }

            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)
                sx {
                    alignItems = AlignItems.center
                }
                fImage { design = check }
                fText { design = approveTitle }
                fText { design = approveBody }
                fButton { design = next; onClick = { navigate("/contract/${customerType}/${objectId}/${submissionId}") } }
            }
        }

        viewModel.asDenied?.run {
            fAppbar { design = navigation }

            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)
                sx {
                    alignItems = AlignItems.center
                }
                fImage { design = nogo }
                fText { design = deniedTitle }
                fText { design = deniedBody }
                fButton { design = next; onClick = { navigate(fkHome) } }
            }
        }

        viewModel.asDone?.run {
            call {
                if (back)
                    navigate(routing)
                else
                    navigate("/contract/${customerType}/${objectId}/${submissionId}")
            }
        }

        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }

        viewModel.asReady?.run {

            fAppbar { design = navigation; onClick = { handleBack() } }
            Stack {
                spacing = responsive(4)
                sx {
                    width = 100.pct
                    alignItems = AlignItems.center
                }


                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = responsive(
                            Breakpoint.xs to Padding(1.rem, 1.rem),
                            Breakpoint.md to Padding(3.rem, 3.rem),
                        )
                        width = responsive(
                            Breakpoint.xs to 100.pct,
                            Breakpoint.md to 55.pct,
                            Breakpoint.lg to 45.pct,
                            Breakpoint.xl to 35.pct
                        )
                    }
                    Box {
                        fStepper {
                            design = stepper
                        }
                        sx {
                            marginBottom = if (stepper.visible) 2.rem else 0.rem
                        }
                    }
                    fForm {
                        onSubmit = ::handleNext
                        Stack {
                            spacing = responsive(2)
                            items.map { item ->
                                when (item) {
                                    is FormItem.ViewModel.Lookup -> FormItemLookup { this.viewModel = item; onClick = ::handleSearchRegno; onChange = ::handleUpdate }
                                    is FormItem.ViewModel.SelectInput -> FormItemSelectInput { this.viewModel = item; onChange = ::handleUpdate }
                                    is FormItem.ViewModel.DateInput -> FormItemDateInput { this.viewModel = item; onChange = ::handleUpdate }
                                    is FormItem.ViewModel.Header -> FormItemHeader { this.viewModel = item }
                                    is FormItem.ViewModel.TextInput -> FormItemTextInput { this.viewModel = item; onChange = ::handleUpdate }
                                    is FormItem.ViewModel.RadioInput -> FormItemRadioInput { this.viewModel = item; onChange = ::handleUpdate }
                                    is FormItem.ViewModel.Checkbox -> FormItemCheckbox { this.viewModel = item; onChange = ::handleUpdate }
                                    is FormItem.ViewModel.Label -> FormItemLabel { this.viewModel = item }
                                    is FormItem.ViewModel.Divider -> FormItemDivider { this.viewModel = item }
                                    is FormItem.ViewModel.Summary -> FormItemSummary { this.viewModel = item }
                                    is FormItem.ViewModel.SummaryServicesIncluded -> FormSummaryServiceIncluded { this.viewModel = item }
                                    is FormItem.ViewModel.SummaryMonthlyCost -> formSummaryMonthlyCost { this.viewModel = item }
                                    is FormItem.ViewModel.SummaryTotalLoan -> formSummaryTotalLoan { this.viewModel = item }
                                    is FormItem.ViewModel.None -> {}
                                    else -> {}
                                }
                            }
                            fButton { design = submit; onClick = ::handleNext }
                            fButton { design = next; onClick = ::handleNext }
                        }
                    }
                }
            }
        }

        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}