package components.items

import components.*
import mui.material.Box
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.FormItem
import support.Palette
import web.cssom.*


external interface FormHeaderProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.Header
}


val FormItemHeader = FC<FormHeaderProps> { props ->
    if (props.viewModel.visible)
        Box {
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
                color = important(Color(Palette.Secondary.light))
            }
            fText { design = props.viewModel.title }
            fText { design = props.viewModel.subTitle }
            fText { design = props.viewModel.value }
        }
}