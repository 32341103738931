package support

import kotlinx.browser.window

object Window {
    fun navigationUrl(url: String) {
        window.location.href = url
    }

    fun navigationRedirect(url: String) {
        window.location.assign(url)
    }

    fun clipboard(text: String) {
        window.navigator.clipboard.writeText(text)
    }

    fun scrollTo(top: Double = 0.0) {
        window.scrollTo(0.0,top)
    }
}