package support

val deployment = Deployment(
    applicationKey = "A5F9797E-4788-44C5-A935-704BCBA63AD4",
    applicationSecret = "C9309707-7819-4D6B-AFEF-8E68AD32251D",
    isSandbox = true,
    version = "1.0.8-SNAPSHOT",
    build = 89,
    home = "https://fordonskrediten.sandbox.techla.io",
    app = "https://fordonskrediten-app.sandbox.techla.io",
)