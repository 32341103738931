package components.items

import components.*
import mui.material.Box
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.FormItem
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.rem


external interface FormDateInputProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.DateInput
    var onChange: ((id: String, value: String) -> Unit)?
}


val FormItemDateInput = FC<FormDateInputProps> { props ->
    fun onChange(inputValue: String) {
        props.onChange?.let { it(props.viewModel.fieldKey.rawValue, inputValue) }
    }
    if (props.viewModel.visible)
        Box {
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                justifyContent = JustifyContent.spaceBetween
            }
            fTextInput { design = props.viewModel.value; onChange = ::onChange }

            if (props.viewModel.info != null)
                props.viewModel.info.let {
                    Box {
                        sx {
                            marginLeft = 1.rem
                            marginTop = if (props.viewModel.value.label.isNullOrEmpty()) 0.rem else 1.4375.rem
                        }
                        fInfoButton {
                            if (it != null) {
                                infoText = it
                            }
                        }
                    }
                }
        }
}