package components

import emotion.react.css
import js.core.jso
import mui.material.Box
import mui.material.Divider
import mui.material.Typography
import mui.material.TypographyAlign
import mui.system.sx
import react.FC
import react.PropsWithClassName
import react.dom.html.ReactHTML
import remarkable.Remarkable
import support.*
import web.cssom.*
import web.window.WindowTarget

external interface FDividerProps : PropsWithClassName {
    var design: DesignSystem.Divider?
}

val fDivider = FC<FDividerProps> { props ->
    val color = when (props.design?.color) {
        DesignSystem.Color.DISABLED -> Color(Palette.Text.disabled.toString())
        DesignSystem.Color.PRIMARY -> Color(Palette.Primary.main)
        DesignSystem.Color.WARNING -> Color(Palette.Warning.main)
        DesignSystem.Color.SUCCESS -> Color(Palette.Success.main)
        DesignSystem.Color.DANGER -> Color(Palette.Danger.main)
        DesignSystem.Color.TEXT -> Palette.Text.primary
        else -> Color(Palette.Text.primary.toString())
    }

    if (props.design?.visible == true)
        Divider {
            sx {
                backgroundColor = color
                marginTop = important(1.rem)
                width = 100.pct
            }
        }

}