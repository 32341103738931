package support

import techla.base.Either
import techla.base.TechlaError
import techla.base.Warning
import techla.base.techla_log

sealed class DesignSystem(open val visible: Boolean = true) {
    data class Header(val id: String)
    enum class SizeType { XL2, XL, LG, MD, SM, XS, XS2 }
    enum class Image { LOGOG, LOGOSYMBOL, LOGOTXTBLACK, LOGOTXTWHITE, BANKID, COINS, TABLEEMPTY, RIGHTARROW, MENU, NOGO, OK, CONTRACT, SWISHIMAGE, CHECK, EXPIREIMAGE, DOWNARROW, USER, SEARCH }

    enum class Action { NONE, BACK, LOGOUT, HOME, FORDONSKREDITEN_HOME, PREVIOUS, NEXT }
    enum class Color { DISABLED, PRIMARY, DANGER, SUCCESS, WARNING, TEXT }
    enum class StyleType { REGULAR, BOLD, EXTRA_BOLD }
    enum class TextAlign { LEFT, CENTER, RIGHT }
    enum class AdornmentPosition { END, START }

    enum class Direction { ROW, COLUMN }
    enum class Background { DARK, LIGHT, IMAGE }
    enum class TextInputType { TEXT, GOV_ID, CREW, EMAIL, NUMBER, PHONE, NOVEL, OTP, DATE, PURE_NUMBER }
    enum class PaletteType { PRIMARY, SECONDARY, TRANSPARENT, GREY, WARNING, DANGER, SUCCESS, INFO }


    sealed class Option(open val title: String?, open val value: String?, open val data: Any?, open val disabled: Boolean = false) {
        data class Item(
            override val title: String?,
            override val value: String?,
            override val data: Any?,
            override val disabled: Boolean,
            val image: Image?,
            val action: Action?,
            val location: Location? = null
        ) : Option(title = title, value = value, data = data, disabled = disabled)

        companion object {
            fun item(title: String? = null, image: Image? = null, action: Action? = null, value: String? = null, data: Any? = null, disabled: Boolean = false, location: Location? = null) =
                Item(title = title, image = image, action = action, value = value, data = data, disabled = disabled, location = location)
        }
    }

    sealed class Status {
        object Unknown : Status()
        object Valid : Status()
        data class Invalid(val warning: String? = null) : Status()

        val message: String?
            get() =
                when (val s = this) {
                    is Unknown, is Valid -> null
                    is Invalid -> s.warning
                }
    }

    data class Navigation(val title: String? = null, val background: Background = Background.IMAGE, val navigation: Button? = null, val menu: Menu? = null, val links: List<Option>? = null, val selectedLink: Option? = null, val location: Location? = null) : DesignSystem() {
        companion object
    }

    data class Menu(val title: String? = null, val items: List<Option>, val selected: Option? = null) : DesignSystem()

    data class Failure(
        val title: Text? = null,
        val image: ImageView = ImageView(),
        val details: Text,
        val recover: Button = Button(),
        val shouldLogout: Boolean = false,
        val automaticLogout: Boolean = false


    ) : DesignSystem() {
        companion object
    }

    data class Text(val text: String? = null, override val visible: Boolean = true, val size: SizeType = SizeType.SM, val style: StyleType = StyleType.REGULAR, val align: TextAlign = TextAlign.LEFT, val background: Background = Background.LIGHT, val color: Color? = null, val isMarkdown: Boolean = false) : DesignSystem() {
        companion object
    }

    data class TextWithLinks(val text: String? = null, val links: List<Pair<String, String>>, override val visible: Boolean = true, val size: SizeType = SizeType.MD, val style: StyleType = StyleType.REGULAR, val align: TextAlign = TextAlign.CENTER, val background: Background = Background.LIGHT, val color: Color? = null) : DesignSystem() {
        companion object
    }

    data class Table(val title: Text? = null, override val visible: Boolean = true, val header: Row? = null, val body: List<Row>? = null, val empty: String? = null) : DesignSystem() {
        companion object
    }

    data class Row(val id: String? = null, override val visible: Boolean = true, val cells: List<Option>? = null, val data: Any? = null) : DesignSystem() {
        companion object
    }

    data class ImageView(
        val href: String? = null, val image: Image? = null, val alt: String? = null, val shadow: Boolean = false, override val visible: Boolean = true
    ) : DesignSystem() {
        companion object
    }

    data class Stepper(
        val active: Int? = 0, val steps: List<Option>? = null, override val visible: Boolean = true
    ) : DesignSystem() {
        companion object
    }


    data class TextInput(
        val header: Header,
        val label: String? = null,
        val value: String? = null,
        val placeholder: String? = null,
        override val visible: Boolean = true,
        val type: TextInputType = TextInputType.TEXT,
        val disabled: Boolean = false,
        val adornment: String? = null,
        val adornmentImage: Image? = null,
        val adornmentType: AdornmentPosition = AdornmentPosition.END,
        val status: Status? = null,
        val uppercase: Boolean? = false,
    ) : DesignSystem() {
        companion object
    }

    data class SelectInput(
        val header: Header,
        val label: String? = null,
        val options: List<Option> = emptyList(),
        val selected: Option? = null,
        val status: Status? = null,
        override val visible: Boolean = true,
        val disabled: Boolean = false,
        val placeholder: String? = null,
        val autocomplete: Boolean = false
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class RadioButton(val title: String, val value: String, val status: Status? = null, override val visible: Boolean = true) : DesignSystem(visible = visible) {
        companion object
    }

    data class RadioInput(
        val header: Header? = null,
        val label: String? = null,
        val buttons: List<RadioButton> = emptyList(),
        val checked: RadioButton? = null,
        val status: Status? = null,
        val direction: Direction? = Direction.COLUMN,
        override val visible: Boolean = true
    ) : DesignSystem(visible = visible) {
        companion object
    }


    data class Checkbox(
        val header: Header? = null,
        val label: String? = null,
        val checked: Boolean? = false,
        val disabled: Boolean? = false,
        val status: Status? = null,
        val data: Any? = null,
        override val visible: Boolean = true
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class Switch(
        val header: Header? = null,
        val label: String? = null,
        val checked: Boolean? = false,
        val disabled: Boolean? = false,
        val status: Status? = null,
        override val visible: Boolean = true
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class Modal(
        val open: Boolean? = true,
        val title: Text? = null,
        val image: ImageView? = null,
        val body: List<DesignSystem> = emptyList(),
        val firstButton: Button? = null,
        val secondButton: Button? = null,
        override val visible: Boolean = true
    ) : DesignSystem(visible = visible) {
        companion object
    }

    data class Toast(
        val open: Boolean = false,
        val text: String? = null,
        override val visible: Boolean = true
    ) : DesignSystem(visible = visible) {
        companion object
    }


    data class Button(val text: String? = null, val disabled: Boolean = false, val type: Type = Type.BUTTON, override val visible: Boolean = true, val palette: PaletteType = PaletteType.PRIMARY, val style: Style = Style.CONTAINED, val action: Action? = null, val loading: Boolean? = false) : DesignSystem() {
        enum class Type {
            BUTTON, SUBMIT
        }

        enum class Style {
            CONTAINED, OUTLINE, TEXT
        }

        companion object
    }

    data class Progress(val info: Text, override val visible: Boolean = true) : DesignSystem(visible) {
        companion object
    }

    data class Pagination(val currentPage: Int = 0, val rowsPerPage: Int = 10, val totalAmountOfItems: Int = -1, val rowsPerPageOptions: List<Int>? = listOf(5, 10, 15, 20, 25)) : DesignSystem() {
        companion object
    }

    data class Divider(override val visible: Boolean = true, val color: Color? = Color.DISABLED) : DesignSystem() {
        companion object
    }

}

fun List<DesignSystem.Status>.overallStatus(): DesignSystem.Status =
    when {
        any { it is DesignSystem.Status.Invalid } -> DesignSystem.Status.Invalid()
        all { it is DesignSystem.Status.Valid } -> DesignSystem.Status.Valid
        else -> DesignSystem.Status.Unknown
    }

fun List<Pair<DesignSystem.Header, DesignSystem.Status>>.statusOf(header: DesignSystem.Header): DesignSystem.Status? =
    firstOrNull { it.first == header }?.second

fun List<Pair<DesignSystem.Header, DesignSystem.Status>>.overallStatus(): DesignSystem.Status =
    map { it.second }.overallStatus()

interface FailureTexts {
    val failureTitle: String
    val failureReason: String
}


val DesignSystem.Navigation.Companion.minimalLight
    get() =
        DesignSystem.Navigation(
            title = "Fordonskrediten",
            background = DesignSystem.Background.IMAGE,
        )

fun DesignSystem.Navigation.Companion.backLight(title: String, action: DesignSystem.Action? = null, location: Location? = null) =
    DesignSystem.Navigation(
        title = "Fordonskrediten",
        navigation = DesignSystem.Button(text = title, style = DesignSystem.Button.Style.TEXT, action = action),
        background = DesignSystem.Background.IMAGE,
        location = location
    )

fun failure(texts: FailureTexts, failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): DesignSystem.Failure {
    techla_log("FAILURE: $failure")

    var shouldLogout = false
    val message = when (failure) {
        is Either.Left -> failure.value.joinToString(", ")
        is Either.Right ->
            when (failure.value) {
                is TechlaError.Unauthorized -> {
                    shouldLogout = true
                    "Din inlogging har gått ut och du behöver logga in på nytt."
                }

                is TechlaError.ServiceUnavailable -> "Internetanslutningen verkar vara nedkopplad."
                else -> failure.value.message
            }
    }

    val title = if (shouldLogout)
        ""
    else
        texts.failureTitle

    return DesignSystem.Failure(
        image = DesignSystem.ImageView(image = DesignSystem.Image.NOGO),
        title = DesignSystem.Text(text = title, style = DesignSystem.StyleType.EXTRA_BOLD, size = DesignSystem.SizeType.XL2, color = DesignSystem.Color.DANGER, align = DesignSystem.TextAlign.CENTER),
        details = DesignSystem.Text(
            text = message ?: "Ett okänt fel har inträffat, var god försök senare",
            style = DesignSystem.StyleType.REGULAR,
            size = DesignSystem.SizeType.MD,
            align = DesignSystem.TextAlign.CENTER
        ),
        recover = if (shouldLogout)
            DesignSystem.Button(text = "Logga ut")
        else
            DesignSystem.Button(text = "Vidare"),
        shouldLogout = shouldLogout,
        automaticLogout = automaticLogout
    )
}
