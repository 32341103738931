package screens

import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import services.findMedias
import services.listSubmissionsPagination
import support.*
import techla.base.*
import techla.base.onNotSuccess
import techla.form.Submission

object CommitmentScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    object Header {
        val search = DesignSystem.Header("search")
    }

    data class Texts(
        val commitment: String,
        val business: String,
        val back: String,
        val logout: String,
        val govId: String,
        val regnumber: String,
        val firstName: String,
        val lastName: String,
        val type: String,
        val memberIn: String,
        val buyerPrivate: String,
        val buyerCompany: String,
        val sellerPrivate: String,
        val sellerCompany: String,
        val leasingCompany: String,
        val emptyItems: String,
        val search: String,
        val dashboard: String,
        override val failureTitle: String,
        override val failureReason: String
    ) : FailureTexts {
        companion object
    }

    data class State(
        val startPage: Int = 1,
        val pageIndex: PageIndex = PageIndex(page = startPage, size = 5),
        val totalAmountOfItems: Int = -1,
        val submissions: List<Submission> = emptyList(),
        val search: String = "",
        val searchOptions: List<DesignSystem.RadioButton> = emptyList(),
        val selectedSearchOption: DesignSystem.RadioButton? = null,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val commitmentTitle: DesignSystem.Text,
            val items: DesignSystem.Table,
            val pagination: DesignSystem.Pagination,
            val filter: DesignSystem.RadioInput,
            val search: DesignSystem.TextInput,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation = navigation)

        fun ready(texts: Texts, state: State, items: List<DesignSystem.Row>? = null) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation(
                    title = "Fordonskrediten", background = DesignSystem.Background.DARK,
                    menu = DesignSystem.Menu(
                        title = "profile name", items = listOf(
                            DesignSystem.Option.item(title = texts.logout, action = DesignSystem.Action.LOGOUT),
                        )
                    ),
                    links = listOf(
                        DesignSystem.Option.item(title = texts.business, location = Location.BackOffice),
                        DesignSystem.Option.item(title = texts.commitment, location = Location.Commitment),
                        DesignSystem.Option.item(title = texts.dashboard, location = Location.Dashboard),
                    ),
                    selectedLink = DesignSystem.Option.item(title = texts.commitment, location = Location.Commitment),
                    location = Location.BackOffice
                ),
                commitmentTitle = DesignSystem.Text(text = texts.commitment, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD),
                items = DesignSystem.Table(
                    empty = texts.emptyItems,
                    body = items,
                    header = DesignSystem.Row(
                        cells = listOf(
                            DesignSystem.Option.item(title = texts.govId),
                            DesignSystem.Option.item(title = texts.firstName),
                            DesignSystem.Option.item(title = texts.lastName),
                            DesignSystem.Option.item(title = texts.memberIn),
                            DesignSystem.Option.item(title = texts.type),
                            DesignSystem.Option.item(title = " ")
                        )
                    )
                ),
                pagination = DesignSystem.Pagination(currentPage = state.pageIndex.page, rowsPerPage = state.pageIndex.size, totalAmountOfItems = state.totalAmountOfItems),
                filter = DesignSystem.RadioInput(buttons = state.searchOptions, checked = state.selectedSearchOption, direction = DesignSystem.Direction.ROW),
                search = DesignSystem.TextInput(header = Header.search, placeholder = texts.search, uppercase = true, adornmentImage = DesignSystem.Image.SEARCH, adornmentType = DesignSystem.AdornmentPosition.END, value = state.search)
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed

    }

    suspend fun search(scene: Scene.Input<ViewModel>, search: String? = null, page: Int? = null, row: Int? = null) {
        val (store, viewModel) = scene
        val pageIndex = viewModel.state.pageIndex.copy(page = page ?: viewModel.state.pageIndex.page, size = row ?: viewModel.state.pageIndex.size, search = search?.uppercase() ?: viewModel.state.search.uppercase())

        store.listSubmissionsPagination(pageIndex, FK.allKeys.map { Key(it) }, fields = listOf(Key(viewModel.state.selectedSearchOption?.value ?: "")), states = null)
            .map { (actions, page) ->

                val state = viewModel.state.copy(
                    totalAmountOfItems = page.items.total,
                    pageIndex = PageIndex(page = page.index.page, size = row ?: viewModel.state.pageIndex.size, search = search ?: viewModel.state.search),
                    submissions = page.contents
                )

                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, buildGrid(viewModel.texts, state.submissions)), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun load(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene

        updates.emit(sceneOf(viewModel.loading()))
        store.findMedias()
            .map { (actions) ->
                val updated = store.reduce(actions)
                val texts = Texts(
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    back = updated.get(media = Key("screen:commitment"), content = Key("back")),
                    govId = updated.get(media = Key("screen:commitment"), content = Key("govId")),
                    firstName = updated.get(media = Key("screen:commitment"), content = Key("firstName")),
                    lastName = updated.get(media = Key("screen:commitment"), content = Key("lastName")),
                    type = updated.get(media = Key("screen:commitment"), content = Key("type")),
                    memberIn = updated.get(media = Key("screen:commitment"), content = Key("memberIn")),
                    regnumber = updated.get(media = Key("screen:commitment"), content = Key("regnumber")),
                    logout = updated.get(media = Key("screen:commitment"), content = Key("logout")),
                    commitment = updated.get(media = Key("screen:commitment"), content = Key("commitment")),
                    business = updated.get(media = Key("screen:commitment"), content = Key("business")),
                    buyerPrivate = updated.get(media = Key("screen:commitment"), content = Key("buyerPrivate")),
                    buyerCompany = updated.get(media = Key("screen:commitment"), content = Key("buyerCompany")),
                    sellerPrivate = updated.get(media = Key("screen:commitment"), content = Key("sellerPrivate")),
                    sellerCompany = updated.get(media = Key("screen:commitment"), content = Key("sellerCompany")),
                    emptyItems = updated.get(media = Key("screen:commitment"), content = Key("emptyItems")),
                    search = updated.get(media = Key("screen:commitment"), content = Key("search")),
                    leasingCompany = updated.get(media = Key("screen:commitment"), content = Key("leasingCompany")),
                    dashboard = updated.get(media = Key("screen:backoffice"), content = Key("dashboard")),
                )

                val searchOptions = listOf(DesignSystem.RadioButton(title = texts.govId, value = "GOV_ID"), DesignSystem.RadioButton(title = texts.regnumber, value = "REGNO"))

                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = texts, state = viewModel.state.copy(searchOptions = searchOptions, selectedSearchOption = searchOptions.firstOrNull()), buildGrid(texts, emptyList())), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }


    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }

    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        return load(scene)
    }

    suspend fun setValue(scene: Scene.Input<ViewModel>, search: String? = null, option: String? = null) {
        val (_, viewModel) = scene
        val state = viewModel.state.copy(search = search ?: viewModel.state.search,
            selectedSearchOption = viewModel.state.searchOptions.firstOrNull { it.value == option } ?: viewModel.state.selectedSearchOption)

        updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, buildGrid(viewModel.texts, viewModel.state.submissions))))
    }


    private fun buildGrid(texts: Texts, submissions: List<Submission>? = emptyList()) =
        submissions?.map { submission ->
            DesignSystem.Row(
                id = submission.index.id.rawValue,
                cells = listOf(
                    DesignSystem.Option.item(title = submission.entries.find { it.fieldKey.rawValue == "GOV_ID" }?.text ?: "-"),
                    DesignSystem.Option.item(title = submission.entries.find { it.fieldKey.rawValue == "FIRST_NAME" }?.text ?: "-"),
                    DesignSystem.Option.item(title = submission.entries.find { it.fieldKey.rawValue == "LAST_NAME" }?.text ?: "-"),
                    DesignSystem.Option.item(title = submission.entries.find { it.fieldKey.rawValue == "REGNO" }?.text ?: "-"),
                    DesignSystem.Option.item(
                        title = when (submission.form?.key?.rawValue) {
                            FK.privateBuy -> texts.buyerPrivate
                            FK.companyBuy -> texts.buyerCompany
                            FK.privateSell -> texts.sellerPrivate
                            FK.companySell -> texts.sellerCompany
                            FK.leasingCompany -> texts.leasingCompany
                            else -> "-"
                        },
                    ),
                    DesignSystem.Option.item(image = DesignSystem.Image.RIGHTARROW, value = "${submission.profileId?.rawValue}/${submission.index.id.rawValue}"),
                )
            )
        }
}