package services

import support.*
import techla.base.*
import techla.guard.Group
import techla.payment.Payment
import techla.payment.PaymentAPI
import techla.payment.PaymentResource


suspend fun Store.getPayment(obj: Object, id: Identifier<Payment>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getPayment(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.getPayment(id: Identifier<Payment>): ActionOutcome<Payment> {
    return paymentAdminAPI { actions, api ->
        measureAPI(PaymentResource.GetPayment(id), api) {
            api.getPayment(id)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.listPayments(obj: Object) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).listPayments(obj.group.key)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.listPayments(group: Key<Group>): ActionOutcome<List<Payment>> {
    return paymentAdminAPI { actions, api ->
        api.additionalHeaders = listOf("group" to group.rawValue)
        measureAPI(PaymentResource.ListPayments, api) {
            api.listPayments()
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createPaymentForPayout(obj: Object, create: Payment.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createPaymentForPayout(create, obj.group.key)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.createPaymentForPayout(create: Payment.Create, group: Key<Group>): ActionOutcome<Payment> {
    return paymentAdminAPI { actions, api ->
        measureAPI(PaymentResource.CreatePaymentForPayout(create, group), api) {
            api.createPaymentForPayout(create, group)
                .noActions()
                .accumulate(actions)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun <T> Store.paymentAdminAPI(block: suspend (List<Store.Action>, PaymentAPI) -> ActionOutcome<T>): ActionOutcome<T> {
    return withUserToken { actions ->
        val api = PaymentAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) PaymentAPI.sandbox else PaymentAPI.shared
            api.token = reduce(actions).adminToken
        }
        block(actions, api)
    }
}