package services

import support.ActionOutcome
import support.Store
import techla.base.*
import techla.content.ContentAPI
import techla.content.ContentResource
import techla.content.Media
import techla.content.Tag

suspend fun Store.findMedias(tags: List<Key<Tag>>? = null): ActionOutcome<List<Media>> =
    contentAPI { actions, api ->
        measureAPI(ContentResource.FindMedias(tags = tags), api) {
            api.findMedias(tags = tags)
                .map { media ->
                    val action = Store.Action.MediasLoaded(media)
                    tupleOf(actions + action, media)
                }
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }

suspend fun <T> Store.contentAPI(block: suspend (List<Store.Action>, ContentAPI) -> ActionOutcome<T>): ActionOutcome<T> {
    return withApplicationToken { actions ->
        val api = ContentAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) ContentAPI.sandbox else ContentAPI.shared
            api.token = reduce(actions).applicationToken
        }
        block(actions, api)
    }
}
