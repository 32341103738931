package screens

import js.core.jso
import kotlinx.browser.document
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.flow.MutableSharedFlow
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import services.*
import support.*
import techla.base.*
import techla.base.onNotSuccess
import techla.form.Field
import techla.form.Submission
import techla.guard.Group
import techla.guard.Profile

object CustomerScreen {
    val updates = MutableSharedFlow<Scene.Output<ViewModel>>(
        extraBufferCapacity = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )

    object Header {
        val search = DesignSystem.Header("search")
    }

    data class Texts(
        val commitment: String,
        val business: String,
        val back: String,
        val logout: String,
        val govId: String,
        val regnumber: String,
        val govCode: String,
        val companyName: String,
        val city: String,
        val zipCode: String,
        val street: String,
        val email: String,
        val phone: String,
        val firstName: String,
        val lastName: String,
        val type: String,
        val memberIn: String,
        val buyerPrivate: String,
        val buyerCompany: String,
        val sellerPrivate: String,
        val sellerCompany: String,
        val emptyItems: String,
        val done: String,
        val started: String,
        val active: String,
        val make: String,
        val model: String,
        val price: String,
        val members: String,
        val status: String,
        val personalData: String,
        val create: String,
        val anonymize: String,
        val anonymizeTitle: String,
        val anonymizeBody: String,
        val yes: String,
        val no: String,
        val interestRate: String,
        val upperBound: String,
        val csvModalBody: String,
        val sek: String,
        val dashboard: String,
        override val failureTitle: String,
        override val failureReason: String
    ) : FailureTexts {
        companion object
    }

    data class State(
        val startPage: Int = 1,
        val pageIndex: PageIndex = PageIndex(page = startPage, size = 5),
        val totalAmountOfItems: Int = -1,
        val obj: List<Object> = emptyList(),
        val submission: Submission? = null,
        val profileId: Identifier<Profile>? = null,
        val govId: String? = null,
        val showAnonymizeModal: Boolean = false,
        val allSubmissions: List<Submission> = emptyList()
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimalLight,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val back: DesignSystem.Button,
            val customerTitle: DesignSystem.Text,
            val groups: DesignSystem.Table,
            val pagination: DesignSystem.Pagination,
            val submission: List<FormItem.ViewModel>,
            val personalData: DesignSystem.Text,
            val business: DesignSystem.Text,
            val create: DesignSystem.Button,
            val anonymizeBtn: DesignSystem.Button,
            val anonymize: DesignSystem.Modal,
            val csvModal: DesignSystem.Modal,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(): ViewModel = Loading(texts = texts, state = state, navigation = navigation)

        fun ready(texts: Texts, state: State, groups: List<DesignSystem.Row>? = null, submission: List<FormItem.ViewModel>, showModal: Boolean = false) =
            Ready(
                texts = texts,
                state = state,
                back = DesignSystem.Button(text = texts.back, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.TEXT),
                navigation = DesignSystem.Navigation(
                    title = "Fordonskrediten", background = DesignSystem.Background.DARK,
                    menu = DesignSystem.Menu(
                        title = "profile name", items = listOf(
                            DesignSystem.Option.item(title = texts.logout, action = DesignSystem.Action.LOGOUT),
                        )
                    ),
                    links = listOf(
                        DesignSystem.Option.item(title = texts.business, location = Location.BackOffice),
                        DesignSystem.Option.item(title = texts.commitment, location = Location.Commitment),
                        DesignSystem.Option.item(title = texts.dashboard, location = Location.Commitment),
                    ),
                    selectedLink = DesignSystem.Option.item(title = texts.commitment, location = Location.Commitment),
                    location = Location.BackOffice
                ),
                customerTitle = DesignSystem.Text(text = texts.commitment, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.EXTRA_BOLD),
                groups = DesignSystem.Table(
                    empty = texts.emptyItems,
                    title = DesignSystem.Text(text = texts.business, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                    body = groups,
                    header = DesignSystem.Row(
                        cells = listOf(
                            DesignSystem.Option.item(title = texts.regnumber),
                            DesignSystem.Option.item(title = texts.make),
                            DesignSystem.Option.item(title = texts.model),
                            DesignSystem.Option.item(title = texts.price),
                            DesignSystem.Option.item(title = texts.members),
                            DesignSystem.Option.item(title = texts.status),
                            DesignSystem.Option.item(title = " ")
                        )
                    )
                ),
                pagination = DesignSystem.Pagination(currentPage = state.pageIndex.page, rowsPerPage = state.pageIndex.size, totalAmountOfItems = state.totalAmountOfItems),
                submission = submission,
                personalData = DesignSystem.Text(text = texts.personalData, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                business = DesignSystem.Text(text = texts.business, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, color = DesignSystem.Color.PRIMARY),
                create = DesignSystem.Button(text = texts.create, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE),
                anonymizeBtn = DesignSystem.Button(text = texts.anonymize, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE, disabled = true),
                anonymize = DesignSystem.Modal(
                    visible = state.showAnonymizeModal,
                    title = DesignSystem.Text(text = texts.anonymizeTitle, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.EXTRA_BOLD),
                    body = listOf( DesignSystem.Text(text = texts.anonymizeBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR)),
                    firstButton = DesignSystem.Button(text = texts.yes, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.CONTAINED),
                    secondButton = DesignSystem.Button(text = texts.no, type = DesignSystem.Button.Type.BUTTON, style = DesignSystem.Button.Style.OUTLINE)
                ),
                csvModal = DesignSystem.Modal(
                    visible = showModal,
                    body = listOf(DesignSystem.Text(text = texts.csvModalBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.REGULAR)),
                ),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimalLight,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed

    }

    suspend fun pagination(scene: Scene.Input<ViewModel>, page: Int? = null, row: Int? = null) {
        val (store, viewModel) = scene
        val pageIndex = viewModel.state.pageIndex.copy(page = page ?: viewModel.state.startPage, size = row ?: viewModel.state.pageIndex.size)

        store.adminRefreshObjects(pageIndex, false, viewModel.state.profileId)
            .map { (actions, pair) ->
                val obj = pair.first.filter { it.name != "Standard" }

                val content = pair.second
                val state = viewModel.state.copy(
                    totalAmountOfItems = content.items.total.minus(pair.first.filter { it.name == "Standard" }.size),
                    pageIndex = PageIndex(page = content.index.page, size = row ?: viewModel.state.pageIndex.size),
                )

                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, buildGrid(viewModel.texts, obj), buildItems(viewModel.texts, viewModel.state.submission)), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    suspend fun modal(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene
        val state = viewModel.state.copy(showAnonymizeModal = !viewModel.state.showAnonymizeModal)

        updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, buildGrid(viewModel.texts, state.obj), buildItems(viewModel.texts, state.submission), showModal = false)))
    }


    private suspend fun fetchSubmissions(
        scene: Scene.Input<ViewModel>,
        page: Int,
        allSubmissions: MutableList<Submission>,
        actions: List<Store.Action>
    ) {
        val (store, viewModel) = scene
        val response = store.listSubmissionsPagination(
            PageIndex(page = page, size = 1, search = viewModel.state.govId),
            FK.allKeys.map { Key(it) },
            fields = listOf(Key("GOV_ID")),
            states = null
        ).accumulate(actions)

        response.onSuccess { (newActions, submissions) ->
            val submissionsSize = submissions.contents.size
            val totalAmount = submissions.items.total

            allSubmissions.addAll(submissions.contents)

            if (submissionsSize <= totalAmount && page != submissions.pages.end) {
                val nextPage = page + 1
                fetchSubmissions(scene, nextPage, allSubmissions, newActions)
            }
        }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }


    private suspend fun editSubmissions(
        scene: Scene.Input<ViewModel>,
        allSubmissions: MutableList<Submission>,
        actions: List<Store.Action>
    ) {
        val (store, viewModel) = scene

        val editedubmissions = mutableListOf<Submission>()
        val saleAactions = listOf<Store.Action>()

        allSubmissions.map { submission ->
            val keys = listOf("REGNO", "FIRST_NAME", "LAST_NAME", "STREET", "ZIPCODE", "CITY", "EMAIL", "PHONE", "GOV_ID", "BANK", "CLEARING_NUMBER", "BANK_ACCOUNT_NUMBER", "EMPLOYMENT", "EMPLOY", "EMPLOYMENT_YEAR", "EMPLOYMENT_MONTH", "INCOME", "RESIDENT_TYPE", "RESIDENT_COST", "CHILDREN", "OTHER_COST", "DRIVING-LICENSE", "WASA_KREDIT_INSTALLMENT_ID")
            val entries = submission.entries.map { if (keys.contains(it.fieldKey.rawValue)) it.copy(text = "*****") else it }

            val editSubmission = Submission.Edit(
                entries = modifiedOf(entries),
                //state = modifiedOf(Submission.State.Closed),
            )

            val response = store.adminEditSubmission(submission.index.id, editSubmission).accumulate(actions)
            response.onSuccess { (actions, submission) ->
                editedubmissions.addAll(listOf(submission))
                saleAactions + actions
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }

        }

    }


    suspend fun anonymizeCustomer(scene: Scene.Input<ViewModel>) {
        val (store, viewModel) = scene

        updates.emit(sceneOf(viewModel.loading()))

        val allSubmissions = mutableListOf<Submission>()
        val actions = mutableListOf<Store.Action>()
        fetchSubmissions(scene, 1, allSubmissions, actions)

        editSubmissions(scene, allSubmissions, actions)


        store.adminRefreshObjects(viewModel.state.pageIndex, false, viewModel.state.profileId).accumulate(actions)
            .flatMap { (actions, pair) ->
                store.reduce(actions).forgetProfile(viewModel.state.profileId!!).accumulate(actions)
                    .map { tupleOf(it.first, pair) }
            }
            .flatMap { (actions, pair) ->
                store.reduce(actions).getSubmission(viewModel.state.submission?.index?.id!!).accumulate(actions)
                    .map { tupleOf(it.first, pair, it.second) }
            }
            .map { (actions, pair, submission) ->

                val obj = pair.first.filter { it.name != "Standard" }
                val content = pair.second

                val state = viewModel.state.copy(
                    totalAmountOfItems = content.items.total.minus(pair.first.filter { it.name == "Standard" }.size),
                    pageIndex = PageIndex(page = content.index.page, size = viewModel.state.pageIndex.size),
                    obj = obj,
                    allSubmissions = allSubmissions,
                    showAnonymizeModal = false,
                    submission = submission,
                    govId = null,
                    profileId = null
                )
                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, buildGrid(viewModel.texts, state.obj), buildItems(viewModel.texts, state.submission)), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }


    suspend fun load(scene: Scene.Input<ViewModel>, profileId: Identifier<Profile>, submissionId: Identifier<Submission>) {
        val (store, viewModel) = scene

        updates.emit(sceneOf(viewModel.loading()))
        store.getSubmission(submissionId)
            .flatMap { (actions, submission) ->

                store.reduce(actions).adminRefreshObjects(viewModel.state.pageIndex, false, profileId).accumulate(actions)
                    .map { tupleOf(it.first, submission, it.second) }
            }
            .flatMap { (actions, submission, pair) ->
                store.reduce(actions).findMedias().accumulate(actions)
                    .map { tupleOf(it.first, submission, pair) }
            }
            .map { (actions, submission, pair) ->
                val updated = store.reduce(actions)
                val obj = pair.first.filter { it.name != "Standard" }
                val content = pair.second


                val state = viewModel.state.copy(
                    totalAmountOfItems = content.items.total.minus(pair.first.filter { it.name == "Standard" }.size),
                    pageIndex = PageIndex(page = content.index.page, size = viewModel.state.pageIndex.size),
                    obj = obj,
                    submission = submission,
                    profileId = profileId,
                    govId = submission.entries.find { it.fieldKey.rawValue == "GOV_ID" }?.text
                )

                val texts = Texts(
                    failureTitle = "Oops!",
                    failureReason = "Unknown Error",
                    back = updated.get(media = Key("screen:customer"), content = Key("back")),
                    govId = updated.get(media = Key("screen:customer"), content = Key("govId")),
                    firstName = updated.get(media = Key("screen:customer"), content = Key("firstName")),
                    lastName = updated.get(media = Key("screen:customer"), content = Key("lastName")),
                    type = updated.get(media = Key("screen:customer"), content = Key("type")),
                    memberIn = updated.get(media = Key("screen:customer"), content = Key("memberIn")),
                    regnumber = updated.get(media = Key("screen:customer"), content = Key("regnumber")),
                    logout = updated.get(media = Key("screen:customer"), content = Key("logout")),
                    commitment = updated.get(media = Key("screen:customer"), content = Key("commitment")),
                    business = updated.get(media = Key("screen:customer"), content = Key("business")),
                    buyerPrivate = updated.get(media = Key("screen:customer"), content = Key("buyerPrivate")),
                    buyerCompany = updated.get(media = Key("screen:customer"), content = Key("buyerCompany")),
                    sellerPrivate = updated.get(media = Key("screen:customer"), content = Key("sellerPrivate")),
                    sellerCompany = updated.get(media = Key("screen:customer"), content = Key("sellerCompany")),
                    emptyItems = updated.get(media = Key("screen:customer"), content = Key("emptyItems")),
                    govCode = updated.get(media = Key("screen:customer"), content = Key("govCode")),
                    companyName = updated.get(media = Key("screen:customer"), content = Key("companyName")),
                    city = updated.get(media = Key("screen:customer"), content = Key("city")),
                    zipCode = updated.get(media = Key("screen:customer"), content = Key("zipCode")),
                    street = updated.get(media = Key("screen:customer"), content = Key("street")),
                    email = updated.get(media = Key("screen:customer"), content = Key("email")),
                    phone = updated.get(media = Key("screen:customer"), content = Key("phone")),
                    done = updated.get(media = Key("screen:customer"), content = Key("done")),
                    started = updated.get(media = Key("screen:customer"), content = Key("started")),
                    active = updated.get(media = Key("screen:customer"), content = Key("active")),
                    make = updated.get(media = Key("screen:customer"), content = Key("make")),
                    model = updated.get(media = Key("screen:customer"), content = Key("model")),
                    price = updated.get(media = Key("screen:customer"), content = Key("price")),
                    members = updated.get(media = Key("screen:customer"), content = Key("members")),
                    status = updated.get(media = Key("screen:customer"), content = Key("status")),
                    personalData = updated.get(media = Key("screen:customer"), content = Key("personalData")),
                    create = updated.get(media = Key("screen:customer"), content = Key("create")),
                    anonymize = updated.get(media = Key("screen:customer"), content = Key("anonymize")),
                    anonymizeTitle = updated.get(media = Key("screen:customer"), content = Key("anonymizeTitle")),
                    anonymizeBody = updated.get(media = Key("screen:customer"), content = Key("anonymizeBody")),
                    yes = updated.get(media = Key("screen:customer"), content = Key("yes")),
                    no = updated.get(media = Key("screen:customer"), content = Key("no")),
                    interestRate = updated.get(media = Key("screen:customer"), content = Key("interestRate")),
                    upperBound = updated.get(media = Key("screen:customer"), content = Key("upperBound")),
                    csvModalBody = updated.get(media = Key("screen:customer"), content = Key("csvModalBody")),
                    sek = updated.get(media = Key("screen:customer"), content = Key("sek")),
                    dashboard = updated.get(media = Key("screen:customer"), content = Key("leasingCompany")),
                )

                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = texts, state = state, buildGrid(texts, obj), buildItems(texts, submission)), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }


    suspend fun logout(scene: Scene.Input<ViewModel>) {
        val (_, _) = scene
        val action = Store.Action.Logout

        updates.emit(sceneOf(ViewModel.None, action))
    }

    suspend fun success(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene

        if (viewModel.state.profileId == null && viewModel.state.submission?.index?.id == null)
            return load(scene, viewModel.state.profileId!!, viewModel.state.submission?.index?.id!!)

        return logout(scene)
    }

    suspend fun createCVG(scene: Scene.Input<ViewModel>) {
        val (_, viewModel) = scene

        if (viewModel.state.profileId == null)
            return updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = viewModel.state, buildGrid(viewModel.texts, viewModel.state.obj), buildItems(viewModel.texts, viewModel.state.submission), showModal = true)))


        updates.emit(sceneOf(viewModel.loading()))

        val allSubmissions = mutableListOf<Submission>()
        val actions = mutableListOf<Store.Action>()
        fetchSubmissions(scene, 1, allSubmissions, actions)

        val state = viewModel.state.copy(allSubmissions = allSubmissions)

        successfulOf(true)
            .map {
                val excludeFields = listOf("HEADING", "LABEL", "MARKDOWN_LABEL", "SUMMARY", "DIVIDER", "REPAYMENT_TIMES", "SUMMARY_MONTHLY_COST", "SUMMARY_SERVICES_INCLUDED", "LEASING_UPPER_BOUND", "DISCOUNT_CODE_LABEL", "DISCOUNT_CODE", "EXCESS_LABEL")

                val builder = StringBuilder()
                when {
                    allSubmissions.isNotEmpty() -> {
                        allSubmissions.sortedBy { it.form?.key?.rawValue }.mapIndexed { ind, submission ->

                            val allFields = allSubmissions.flatMap { it.form?.fields.orEmpty() }
                            val uniqueKeys = allFields.filter { !excludeFields.contains(it.key.rawValue) }
                                .sortedWith(compareBy(
                                    { it.key.rawValue != "GOV_ID" && it.key.rawValue != "REGNO" },
                                    { it.order }
                                ))
                                .distinctBy { it.key.rawValue }

                            if (ind == 0) {
                                uniqueKeys.forEachIndexed { index, field ->
                                    val label = when (field.key.rawValue) {
                                        "GOV_ID" -> viewModel.texts.govId
                                        "INTEREST_RATE" -> viewModel.texts.interestRate
                                        "RESIDUAL_UPPER_BOUND" -> viewModel.texts.upperBound
                                        "REAL_PRINCIPAL" -> field.label?.replace("{SPLIT}", "")
                                        "EXCESS" -> allFields.firstOrNull{it.key.rawValue == "EXCESS_LABEL"}?.fLabel ?: ""
                                        else -> field.label
                                    }

                                    if (index > 0) builder.append(";")
                                    builder.append("\"$label\"")
                                }
                                builder.append("\r\n")
                            }

                            uniqueKeys.forEachIndexed { index, field ->
                                if (index > 0) builder.append(";")
                                val results = submission.results.filter { !excludeFields.contains(it.first.rightOrNull()?.rawValue) }.map {
                                    when (val result = it.first) {
                                        is Either.Left ->
                                            when (result.value.style) {
                                                is Field.Style.GovId ->
                                                    result.value.key to it.second.take(8) + "-" + it.second.takeLast(4)

                                                else ->
                                                    result.value.key to it.second
                                            }

                                        is Either.Right ->
                                            result.value to it.second
                                    }
                                }
                                val entry = results.firstOrNull { it.first == field.key }?.second ?: "-"

                                val value = if (entry == "true") viewModel.texts.yes else if (entry == "false") viewModel.texts.no else entry
                                builder.append("\"$value\"")
                            }
                            builder.append("\r\n")
                        }
                    }
                }

                val blob = Blob(arrayOf(builder.toString()), jso { type = "text/csv;charset=utf-8;" })
                val url = URL.createObjectURL(blob)
                val a = document.createElement("a") as HTMLAnchorElement
                a.href = url
                a.download = "export.csv"
                a.click()
                URL.revokeObjectURL(url)

                updates.emit(sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, buildGrid(viewModel.texts, state.obj), buildItems(viewModel.texts, state.submission)), actions))
            }.onNotSuccess { updates.emit(sceneOf(viewModel.failed(it))) }
    }

    private fun buildGrid(texts: Texts, obj: List<Object>? = emptyList()) =
        obj?.map {
            val textParts = it.group.name.split("{REPLACE}")
            val result = (textParts + List(4 - textParts.size) { "-" }).take(4)
            val price = if (result[3].trim() == "-") result[3].trim() else listOf(formatNumber(result[3].trim().substringBefore(" ").toDouble()), texts.sek).joinToString(" ")

            DesignSystem.Row(
                id = it.id.rawValue,
                //data = boGroupColor(it.group.visualization.boGroupType),
                cells = listOf(
                    DesignSystem.Option.item(title = result[0].trim()),
                    DesignSystem.Option.item(title = result[1].trim()),
                    DesignSystem.Option.item(title = result[2].trim()),
                    DesignSystem.Option.item(title = price),
                    DesignSystem.Option.item(title = it.group.members.size.toString()),
                    DesignSystem.Option.item(
                        title = when (it.group.status) {
                            Group.Status.Archived -> texts.done
                            Group.Status.Active -> texts.active
                            Group.Status.None -> texts.started
                            else -> ""
                        }, data = when (it.group.status) {
                            Group.Status.Archived -> DesignSystem.Color.SUCCESS
                            Group.Status.Active -> DesignSystem.Color.WARNING
                            Group.Status.None -> DesignSystem.Color.DANGER
                            else -> null
                        }
                    ),
                    DesignSystem.Option.item(image = DesignSystem.Image.RIGHTARROW, value = it.id.rawValue),
                )
            )
        }

    private fun buildItems(text: Texts, submission: Submission? = null): List<FormItem.ViewModel> {
        return submission?.form?.fields?.sortedBy { it.order }?.map { field ->

            val entry = submission.entries.firstOrNull { it.fieldKey == field.key }

            when (field.key.rawValue) {
                "COMPANY_NAME", "GOV_CODE", "FIRST_NAME", "LAST_NAME", "STREET", "ZIPCODE", "CITY", "EMAIL", "PHONE", "GOV_ID" -> {
                    val label = if (field.key.rawValue == "GOV_ID") text.govId else field.fLabel
                    FormItem.textInput(label = label, disabled = true, value = entry?.text, key = field.key, visible = !field.hidden)
                }

                else -> FormItem.none()
            }
        } ?: emptyList()
    }
}