package views

import StoreContext
import components.*
import components.fLoader
import js.core.jso
import mui.material.*
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useNavigate
import screens.CommitmentScreen
import support.*
import web.cssom.*

val Commitment = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: CommitmentScreen.ViewModel by useState(CommitmentScreen.ViewModel.None)
    val navigate = useNavigate()

    useAsyncEffectOnce {
        CommitmentScreen.updates.collect { (updated, actions) ->
            viewModel = updated
            dispatch(actions)
        }
    }

    useAsyncEffectOnce { CommitmentScreen.load(sceneInputOf(store, viewModel)) }

    fun handleLogout() = call {
        CommitmentScreen.logout(sceneInputOf(store, viewModel))
        navigate(Routes.loginBackOffice, jso { replace = true; state = null })
    }

    fun recover() = call {
        CommitmentScreen.success(sceneInputOf(store, viewModel))
    }

    fun handleSearchFilter(value: String) = call {
        CommitmentScreen.setValue(sceneInputOf(store, viewModel), option = value)
    }

    fun handleSearch(value: String) = call {
        CommitmentScreen.search(sceneInputOf(store, viewModel), search = value)
    }

    fun handleSetValue(value: String) = call {
        CommitmentScreen.setValue(sceneInputOf(store, viewModel), value)
    }

    fun handlePagination(page: Int?, row: Int?) = call {
        CommitmentScreen.search(sceneInputOf(store, viewModel), page = page, row = row)
    }

    fun handleGoTo(option: DesignSystem.Option?) {
        navigate("/customer/${option?.value}")
    }

    FScaffold {
        //  this.navigation = viewModel.navigation
        isFullScreen = when (viewModel) {
            is CommitmentScreen.ViewModel.Ready -> true
            else -> false
        }

        viewModel.asLoading?.run {
            fAppbar { design = navigation }
            fLoader {}
        }

        viewModel.asReady?.run {
            fAppbar { design = navigation; onClick = { handleLogout() } }
            Stack {
                spacing = responsive(2)

                sx {
                    alignItems = AlignItems.center
                    gap = 0.5.rem
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        gap = 1.rem
                        maxWidth = responsive(
                            Breakpoint.xs to 35.pct,
                            Breakpoint.lg to 50.pct
                        )
                    }

                    fText { design = commitmentTitle }
                }

                mui.material.Paper {
                    sx {
                        backgroundColor = Color(Palette.Background.paper)
                        borderRadius = Shape.borderRadius10
                        padding = responsive(
                            Breakpoint.xs to Padding(1.rem, 1.rem),
                            Breakpoint.md to Padding(3.rem, 3.rem),
                        )
                        width = 100.pct
                        minHeight = 283.pct
                    }

                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(1)
                        sx { padding = Padding(0.rem, 0.rem, 1.rem, 0.rem) }

                        Stack {
                            direction = responsive(
                                Breakpoint.xs to FlexDirection.column,
                                Breakpoint.md to FlexDirection.row,
                            )
                            spacing = responsive(1)
                            sx {
                                width = 100.pct
                            }
                            Box {
                                fTextInput { design = search; onChange = ::handleSetValue; onEnter = ::handleSearch; onClick = ::handleSearch; }
                            }
                            fRadioInput { design = filter; onChange = ::handleSearchFilter }
                        }
                    }

                    fTable { design = items; onClick = ::handleGoTo }
                    Stack {
                        sx {
                            alignItems = AlignItems.flexEnd
                        }
                        fPagination { design = pagination; onChange = ::handlePagination }
                    }

                }
            }
        }


        viewModel.asFailed?.run {
            fAppbar { design = navigation }
            fFailure { design = failure; onLogout = ::handleLogout; onRecover = ::recover }
        }
    }
}