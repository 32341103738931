package components

import js.core.jso
import mui.material.*
import mui.system.sx
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML
import react.useState
import web.cssom.*
import web.html.ButtonType
import web.html.HTMLButtonElement
import mui.material.PopoverReference
import mui.system.Breakpoint
import mui.system.responsive
import react.router.useNavigate
import support.*
import techla.base.techla_log

external interface FAppbarProps : Props {
    var design: DesignSystem.Navigation
    var onClick: (DesignSystem.Action) -> Unit
}

private data class AppbarPoint(
    val x: Double,
    val y: Double
)


val fAppbar = FC<FAppbarProps> { props ->
    var pointMenu: AppbarPoint? by useState(null)
    var pointLink: AppbarPoint? by useState(null)
    val navigate = useNavigate()

    fun handleCloseMenu(event: MouseEvent<HTMLButtonElement, *>) {
        pointMenu = null
    }

    fun handleOpenMenu(event: MouseEvent<HTMLButtonElement, *>) {
        pointMenu = if (pointMenu == null) {
            AppbarPoint(
                x = event.clientX - 25,
                y = event.clientY + 25,
            )
        } else {
            null
        }
    }

    fun handleCloseLink(event: MouseEvent<HTMLButtonElement, *>) {
        pointLink = null
    }

    fun handleOpenLink(event: MouseEvent<HTMLButtonElement, *>) {
        pointLink = if (pointLink == null) {
            AppbarPoint(
                x = event.clientX - 25,
                y = event.clientY + 25,
            )
        } else {
            null
        }
    }

    fun onOptionClick(option: DesignSystem.Option) {
        if (option is DesignSystem.Option.Item) {
            when {
                option.location != null -> navigate(option.location.route)
                option.action != null -> props.onClick(option.action)
            }
        }
    }

    fBackgroundImage { bg = props.design.background }

    AppBar {
        position = AppBarPosition.absolute
        sx {

            backgroundColor = when (props.design.background) {
                DesignSystem.Background.DARK -> Color(Palette.Primary.main)
                DesignSystem.Background.IMAGE -> Color(Palette.transparent)
                else -> Color(Palette.Background.paper)
            }

            if (props.design.background == DesignSystem.Background.IMAGE) {
                boxShadow = BoxShadow(0.px, 0.px, 0.px, 0.px, Color(Palette.Background.paper))
            }
        }

        Toolbar {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.spaceBetween
            }
            disableGutters

            Grid {
                item = true
                container = true
                sx {
                    justifyContent = JustifyContent.start
                }
                if (props.design.background == DesignSystem.Background.IMAGE)
                    props.design.navigation?.let { fButton { design = it; onClick = { if (it.action !== null) props.onClick(it.action) } } }
                else {
                    Box {
                        ReactHTML.a {
                            if (props.design.location != null)
                                onClick = { props.design.location?.route?.let { it1 -> navigate(to = it1) } }
                            else
                                href = deployment.home
                            ReactHTML.img {
                                className = ClassName("logo-glow")
                                src = Design.image(DesignSystem.Image.LOGOSYMBOL)
                                alt = "Fordonskrediten"
                            }
                        }
                    }
                    Box {
                        sx {
                            display = responsive(
                                Breakpoint.xs to important(Display.none),
                                Breakpoint.md to important(Display.block),
                            )
                        }
                        ReactHTML.a {
                            if (props.design.location != null)
                                onClick = { props.design.location?.route?.let { it1 -> navigate(to = it1) } }
                            else
                                href = deployment.home
                            if (props.design.background == DesignSystem.Background.DARK) {
                                ReactHTML.img {
                                    src = Design.image(DesignSystem.Image.LOGOTXTBLACK)
                                    alt = "Fordonskrediten"
                                }
                            } else {
                                ReactHTML.img {
                                    src = Design.image(DesignSystem.Image.LOGOTXTWHITE)
                                    alt = "Fordonskrediten"
                                }
                            }
                        }
                    }
                }
            }
            Grid {
                item = true
                container = true
                sx {
                    justifyContent = JustifyContent.center
                }


                if (props.design.background == DesignSystem.Background.IMAGE) {
                    Box {
                        ReactHTML.a {
                            if (props.design.location != null)
                                onClick = { props.design.location?.route?.let { it1 -> navigate(to = it1) } }
                            else
                                href = deployment.home
                            ReactHTML.img {
                                className = ClassName("logo-glow")
                                src = Design.image(DesignSystem.Image.LOGOSYMBOL)
                                alt = "Fordonskrediten"
                            }
                        }
                    }

                    Box {
                        sx {
                            display = responsive(
                                Breakpoint.xs to important(Display.none),
                                Breakpoint.md to important(Display.block),
                            )
                        }
                        ReactHTML.a {
                            if (props.design.location != null)
                                onClick = { props.design.location?.route?.let { it1 -> navigate(to = it1) } }
                            else
                                href = deployment.home
                            if (props.design.background == DesignSystem.Background.DARK) {
                                ReactHTML.img {
                                    src = Design.image(DesignSystem.Image.LOGOTXTBLACK)
                                    alt = "Fordonskrediten"
                                }
                            } else {
                                ReactHTML.img {
                                    src = Design.image(DesignSystem.Image.LOGOTXTWHITE)
                                    alt = "Fordonskrediten"
                                }
                            }
                        }
                    }
                }
            }
            Grid {
                item = true
                container = true
                sx {
                    justifyContent = JustifyContent.end
                }
                Box {
                    sx {
                        flexGrow = number(0.0)
                        display = Display.flex
                        alignItems = AlignItems.center
                    }
                    // TODO Admin links

                    Box {
                        sx {
                            display = responsive(
                                Breakpoint.xs to important(Display.none),
                                Breakpoint.md to important(Display.flex),
                            )
                            flexGrow = number(0.0)
                            alignItems = AlignItems.center
                        }
                        props.design.links?.map { link ->
                            MenuItem {
                                key = link.title
                                selected = link == props.design.selectedLink
                                onClick = { onOptionClick(link) }
                                Typography {
                                    sx {
                                        color = if (props.design.background == DesignSystem.Background.LIGHT) Color(Palette.Text.primary.toString()) else Color(Palette.Background.paper)
                                        fontWeight = if (link == props.design.selectedLink) FontWeight.bold else FontWeight.normal
                                    }
                                    +link.title
                                }
                            }
                        }
                    }

                    Box {
                        sx {
                            display = responsive(
                                Breakpoint.xs to important(Display.flex),
                                Breakpoint.md to important(Display.none),
                            )
                            flexGrow = number(0.0)
                            alignItems = AlignItems.center
                            padding = Padding(0.rem, 1.rem, 0.rem, 0.rem)
                        }

                        props.design.links?.let {
                            Button {
                                onClick = ::handleOpenLink
                                type = ButtonType.button

                                sx {
                                    display = Display.flex
                                    alignItems = AlignItems.center
                                    justifyContent = JustifyContent.center
                                    borderRadius = Shape.borderRadius50
                                    backgroundColor = Palette.Text.secondary
                                    width = 41.px
                                    height = 41.px
                                    minWidth = 25.px
                                    color = Color(Palette.Background.default)
                                    hover {
                                        backgroundColor = Palette.Text.secondary
                                    }
                                }
                                ReactHTML.img {
                                    src = Design.image(DesignSystem.Image.MENU)
                                    alt = "Menu"
                                }
                            }
                        }

                        Menu {
                            id = "menu-appbar"
                            keepMounted = true

                            anchorReference = PopoverReference.anchorPosition
                            anchorPosition = if (pointLink != null) {
                                jso {
                                    top = pointLink!!.y
                                    left = pointLink!!.x
                                }
                            } else {
                                undefined
                            }
                            open = pointLink != null
                            onClose = ::handleCloseLink

                            props.design.links?.map { item ->
                                MenuItem {
                                    key = item.title
                                    onClick = { onOptionClick(item) }
                                    Typography {
                                        +item.title
                                    }
                                }
                            }
                        }

                    }

                    props.design.menu?.let {
                        Button {
                            onClick = ::handleOpenMenu
                            type = ButtonType.button

                            sx {
                                display = Display.flex
                                alignItems = AlignItems.center
                                justifyContent = JustifyContent.center
                                borderRadius = Shape.borderRadius50
                                backgroundColor = Palette.Text.secondary
                                width = 41.px
                                height = 41.px
                                minWidth = 25.px
                                color = Color(Palette.Background.default)
                                hover {
                                    backgroundColor = Palette.Text.secondary
                                }
                            }
                            ReactHTML.img {
                                src = Design.image(DesignSystem.Image.USER)
                                alt = "User menu"
                            }
                            //+props.design.menu?.title
                        }
                    }

                    Menu {
                        id = "menu-appbar"
                        keepMounted = true

                        anchorReference = PopoverReference.anchorPosition
                        anchorPosition = if (pointMenu != null) {
                            jso {
                                top = pointMenu!!.y
                                left = pointMenu!!.x
                            }
                        } else {
                            undefined
                        }
                        open = pointMenu != null
                        onClose = ::handleCloseMenu

                        props.design.menu?.items?.map { item ->
                            MenuItem {
                                key = item.title
                                onClick = { if (item is DesignSystem.Option.Item) item.action?.let { it1 -> props.onClick(it1) } }
                                Typography {
                                    +item.title
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}