package views

import StoreContext
import kotlinx.browser.window
import mui.material.Box
import mui.material.Button
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useNavigate
import support.*
import web.cssom.*

val Start = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)

    useEffect {
        Window.navigationUrl(store.deployment.home)
    }
/*
    val navigate = useNavigate()

    FScaffold {
        isFullScreen = false

        Stack {
            direction = responsive(
                Breakpoint.lg to StackDirection.row,
                Breakpoint.sm to StackDirection.columnReverse
            )
            sx {
                marginTop = responsive(
                    Breakpoint.lg to important(0),
                    Breakpoint.sm to important(20.rem)
                )

                alignItems = responsive(
                    Breakpoint.lg to important(AlignItems.stretch),
                    Breakpoint.sm to important(AlignItems.center),
                )
            }


            Box {
                sx {
                    backgroundColor = Color(Palette.Background.paper)
                    padding = Padding(3.rem, 3.rem)
                    borderTopLeftRadius = Shape.borderRadius10
                    borderBottomLeftRadius = Shape.borderRadius10
                    borderTopRightRadius = Shape.borderRadius10
                    borderBottomRightRadius = Shape.borderRadius10

                }
                Stack {
                    spacing = responsive(1)
                    direction = responsive(StackDirection.column)

                    Button {
                        onClick = { navigate(Routes.loginApply) }
                        +"Ansök om lån"
                    }
                    Button {
                        onClick = { navigate(Routes.loginMatters) }
                        +"Inloggning Köpare / Säljare"
                    }
                    Button {
                        onClick = { navigate(Routes.loginBackOffice) }
                        +"Inloggning Admin"
                    }
                }
            }
        }
    }*/
}
