package components

import emotion.react.css
import mui.system.PropsWithSx
import react.FC
import react.dom.html.ReactHTML.img
import support.Design
import support.DesignSystem
import web.cssom.Percentage

external interface FImageProps : PropsWithSx {
    var design: DesignSystem.ImageView?
    var height: Percentage?
    var width: Percentage?
}

val fImage = FC<FImageProps> { props ->
    val src = when {
        props.design?.href != null -> props.design!!.href
        props.design?.image != null -> Design.image(props.design!!.image)
        else -> null
    }

    if (src != null) {
        img {
            this.src = src
            alt = props.design?.image?.name ?: ""
            style = props.sx
            css{
                height= props.height
                width = props.width

            }
        }
    }
}

