package components.items

import components.*
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.FormItem
import web.cssom.*


external interface FormSummaryMonthlyCostProps : PropsWithChildren {
    var viewModel: FormItem.ViewModel.SummaryMonthlyCost
}


val formSummaryMonthlyCost = FC<FormSummaryMonthlyCostProps> { props ->

    if (props.viewModel.visible)
        Stack {
            spacing = responsive(2)
            sx {
                paddingBottom = 1.rem
            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(2)

                sx {
                    justifyContent = JustifyContent.flexEnd
                }
                Stack {
                    direction = responsive(StackDirection.column)
                    fText { design = props.viewModel.monthPrice }
                }
            }

        }
}


