package components

import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.*
import web.cssom.*
import web.html.ButtonType


external interface FModalProps : PropsWithChildren {
    var design: DesignSystem.Modal
    var onClose: (Boolean) -> Unit
    var onClickFirst: () -> Unit
    var onClickSecond: () -> Unit


}


val fModal = FC<FModalProps> { props ->
    fun handleModal(value: Boolean) {
        props.onClose(value)
    }

    mui.material.Dialog {
        this.open = props.design.visible
        this.onClose = { _, _ -> handleModal(false) }


        Paper {
            sx {
                borderRadius = Shape.borderRadius10
                padding = Padding(1.rem, 1.rem)
                width = 100.pct
                minHeight = 20.pct
                display = Display.flex
                flexDirection = FlexDirection.column
                boxShadow = BoxShadow(0.px, 0.px, 0.px, 0.px, Color(Palette.Background.paper))
            }
            Stack {
                sx {
                    display = Display.flex
                    alignItems = AlignItems.end
                }
                Button {
                    onClick = { handleModal(false) }
                    type = ButtonType.button

                    sx {
                        float = Float.right
                        display = Display.flex
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.center
                        backgroundColor = Color(Palette.transparent)
                        width = 25.px
                        height = 25.px
                        minWidth = 25.px
                        color = Color(Palette.Primary.main)
                        hover {
                            color = Color(Palette.Primary.dark)
                        }
                    }
                    +"X"
                }
            }
            Stack {
                spacing = responsive(2)
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                }
                fImage { design = props.design.image }
                fText { design = props.design.title }
            }

            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                    marginTop = 25.px
                    marginBottom = 25.px
                    padding = Padding(0.rem, 1.rem)
                    gap = 1.rem
                }
                props.design.body.map { content ->
                    when (content) {
                        is DesignSystem.TextInput -> fTextInput { design = content; onChange = {} }
                        is DesignSystem.Text -> fText { design = content }
                        is DesignSystem.Divider -> fDivider { design = content }
                        else -> null
                    }
                }

                props.design.firstButton?.let {
                    fButton { design = it; onClick = { props.onClickFirst() } }
                }
                props.design.secondButton?.let {
                    fButton { design = it; onClick = { props.onClickSecond() } }
                }
            }
        }

    }
}
