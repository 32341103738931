package support


@JsModule("./img/ico_bankid.svg")
@JsNonModule
external val bankID: dynamic

@JsModule("./img/img_apply.png")
@JsNonModule
external val coins: dynamic

@JsModule("./img/logo_fordonskrediten.svg")
@JsNonModule
external val logo: dynamic

@JsModule("./img/ico_chevron.svg")
@JsNonModule
external val chevron: dynamic

@JsModule("./img/symbol_fordonskrediten.svg")
@JsNonModule
external val symbol: dynamic

@JsModule("./img/txt_fordonskrediten_white.svg")
@JsNonModule
external val txtwhite: dynamic

@JsModule("./img/txt_fordonskrediten_black.svg")
@JsNonModule
external val txtblack: dynamic

@JsModule("./img/ico_empty.svg")
@JsNonModule
external val empty: dynamic

@JsModule("./img/ico_menu.svg")
@JsNonModule
external val hamburger: dynamic

@JsModule("./img/ico_checkmark.svg")
@JsNonModule
external val check: dynamic

@JsModule("./img/img_no_go.png")
@JsNonModule
external val applno: dynamic

@JsModule("./img/img_contract.png")
@JsNonModule
external val applcontract: dynamic

@JsModule("./img/img_ok.png")
@JsNonModule
external val applok: dynamic

@JsModule("./img/img_fk_swish.png")
@JsNonModule
external val swishimg: dynamic

@JsModule("./img/img_no_go.png")
@JsNonModule
external val expireimg: dynamic

@JsModule("./img/icon_arrow_down.svg")
@JsNonModule
external val downArrow: dynamic

@JsModule("./img/ico_user.svg")
@JsNonModule
external val user: dynamic

@JsModule("./img/ico-search.svg")
@JsNonModule
external val search: dynamic

object Design {
    fun image(image: DesignSystem.Image?): String? {
        return when (image) {
            DesignSystem.Image.BANKID -> bankID.default
            DesignSystem.Image.COINS -> coins.default
            DesignSystem.Image.LOGOG -> logo.default
            DesignSystem.Image.LOGOSYMBOL -> symbol.default
            DesignSystem.Image.LOGOTXTWHITE -> txtwhite.default
            DesignSystem.Image.LOGOTXTBLACK -> txtblack.default
            DesignSystem.Image.TABLEEMPTY -> empty.default
            DesignSystem.Image.RIGHTARROW -> chevron.default
            DesignSystem.Image.MENU -> hamburger.default
            DesignSystem.Image.CHECK -> check.default
            DesignSystem.Image.NOGO -> applno.default
            DesignSystem.Image.OK -> applok.default
            DesignSystem.Image.CONTRACT -> applcontract.default
            DesignSystem.Image.SWISHIMAGE -> swishimg.default
            DesignSystem.Image.EXPIREIMAGE -> expireimg.default
            DesignSystem.Image.DOWNARROW -> downArrow.default
            DesignSystem.Image.USER -> user.default
            DesignSystem.Image.SEARCH -> search.default

            else -> null
        }
    }

}