package support

sealed class CustomerType {
    object None : CustomerType()
    object Private : CustomerType()
    object Company : CustomerType()

    object Leasing : CustomerType()


    val rawValue: String
        get() =
            when (this) {
                is None -> "none"
                is Private -> "private"
                is Company -> "company"
                is Leasing -> "leasing"
            }

    companion object {
        fun fromRawValue(rawValue: String?) =
            when (rawValue) {
                None.rawValue -> None
                Private.rawValue -> Private
                Company.rawValue -> Company
                Leasing.rawValue -> Leasing
                else -> None
            }

        fun getFkKey(customerType: CustomerType, buy: Boolean) =
            if (buy)
                when (customerType) {
                    is Private -> FK.privateBuy
                    is Company -> FK.companyBuy
                    is Leasing -> FK.leasingCompany
                    else -> ""
                }
            else {
                when (customerType) {
                    is Private -> FK.privateSell
                    is Company -> FK.companySell
                    else -> ""
                }
            }

        fun getCustomType(key: String) =
            when (key) {
                FK.privateBuy -> Private.rawValue
                FK.privateSell -> Private.rawValue
                FK.companyBuy -> Company.rawValue
                FK.companySell -> Company.rawValue
                FK.leasingCompany -> Leasing.rawValue
                else -> ""
            }

    }
}
